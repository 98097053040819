import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Brain, Heart, Users, Award, MessageCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../contexts/AuthContext';

const AboutPage: React.FC = () => {
  const { user } = useAuth();

  const teamMembers = [
    {
      name: 'Ana Oliveira',
      role: 'Psicóloga Clínica',
      image: '/team/ana.jpg',
      bio: 'Especialista em terapia cognitivo-comportamental com mais de 10 anos de experiência no tratamento de transtornos de humor.'
    },
    {
      name: 'Carlos Santos',
      role: 'Desenvolvedor Chefe',
      image: '/team/carlos.jpg',
      bio: 'Engenheiro de software com foco em experiências digitais para saúde mental e bem-estar emocional.'
    },
    {
      name: 'Mariana Costa',
      role: 'Pesquisadora',
      image: '/team/mariana.jpg',
      bio: 'Doutora em neurociência, pesquisa a relação entre hábitos diários e saúde mental para fundamentar nossas recomendações.'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Sobre | Meu Humor - Nossa missão e equipe</title>
        <meta name="description" content="Conheça a história, missão e equipe por trás do Meu Humor, aplicativo dedicado ao monitoramento e melhoria da saúde mental." />
        <meta name="keywords" content="sobre, equipe, missão, saúde mental, bem-estar emocional" />
        <meta property="og:title" content="Sobre | Meu Humor" />
        <meta property="og:description" content="Conheça a história, missão e equipe por trás do Meu Humor, aplicativo dedicado ao monitoramento e melhoria da saúde mental." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://meuhumor.com.br/sobre" />
        <link rel="canonical" href="https://meuhumor.com.br/sobre" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white" id="page-loaded">
        {/* Header/Navigation */}
        <header className="py-3 sm:py-4 px-2 sm:px-4 md:px-8 max-w-7xl mx-auto">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-1 sm:space-x-2">
              <div className="bg-primary-100 p-1.5 sm:p-2 rounded-full">
                <Brain className="h-4 w-4 sm:h-5 sm:w-5 text-primary-500" />
              </div>
              <h1 className="text-base sm:text-lg md:text-xl font-semibold text-neutral-800">Meu Humor</h1>
            </Link>
            <div className="flex space-x-1.5 sm:space-x-2 md:space-x-4 items-center">
              {user ? (
                <Link to="/home" className="btn btn-primary px-3 py-1.5 sm:py-2 text-xs sm:text-sm">
                  Acessar
                </Link>
              ) : (
                <>
                  <Link to="/login" className="btn btn-outline px-5 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap">
                    Entrar
                  </Link>
                  <Link 
                    to="/login?createAccount=true" 
                    className="btn btn-primary px-8 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap" 
                    onClick={() => localStorage.setItem('createAccount', 'true')}
                  >
                    Criar
                  </Link>
                </>
              )}
            </div>
          </div>
        </header>

        {/* Hero Section */}
        <section className="py-12 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-4 lg:gap-8">
            <motion.div 
              className="md:w-1/2 mb-8 md:mb-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-neutral-800 mb-4">
                Nossa missão é <span className="text-primary-500">transformar vidas</span> através da saúde mental
              </h2>
              <p className="text-lg text-neutral-600 mb-6">
                Acreditamos que o monitoramento diário do humor e o acesso a conteúdo de qualidade sobre saúde mental podem fazer uma diferença significativa na vida das pessoas.
              </p>
              <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                <Link 
                  to={user ? "/home" : "/login?createAccount=true"} 
                  className="btn btn-primary px-6"
                  onClick={() => !user && localStorage.setItem('createAccount', 'true')}
                >
                  {user ? "Acessar Dashboard" : "Experimentar Agora"}
                </Link>
                <Link to="/planos" className="btn btn-outline px-6">
                  Conhecer Planos
                </Link>
              </div>
            </motion.div>
            <motion.div 
              className="md:w-1/2 flex justify-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="relative w-full max-w-md">
                <div className="absolute -top-4 -left-4 w-full h-full bg-secondary-100 rounded-2xl"></div>
                <div className="absolute -bottom-4 -right-4 w-full h-full bg-primary-100 rounded-2xl"></div>
                <div className="relative bg-white p-6 rounded-2xl shadow-medium overflow-hidden">
                  <img 
                    src="/app-images/team.jpg" 
                    alt="Equipe Meu Humor" 
                    className="w-full h-auto rounded-lg object-cover"
                    width={400}
                    height={300}
                    loading="lazy"
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Our Story Section */}
        <section className="py-16 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">Nossa História</h2>
            <p className="text-neutral-600 max-w-3xl mx-auto">
              Como nasceu o Meu Humor e o que nos motiva a continuar inovando
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-medium max-w-4xl mx-auto">
            <p className="text-neutral-700 mb-4">
              O Meu Humor nasceu em 2023 a partir de uma necessidade pessoal dos fundadores. Após enfrentar desafios com a própria saúde mental e perceber a dificuldade em monitorar padrões emocionais ao longo do tempo, decidimos criar uma ferramenta que pudesse ajudar não apenas a nós mesmos, mas a milhares de pessoas.
            </p>
            <p className="text-neutral-700 mb-4">
              Nossa jornada começou com uma simples ideia: criar um aplicativo que permitisse registrar o humor diariamente de forma rápida e intuitiva. Com o tempo, expandimos essa visão para incluir análises avançadas, conteúdo educativo e recursos de inteligência artificial que pudessem oferecer insights personalizados.
            </p>
            <p className="text-neutral-700 mb-4">
              Hoje, o Meu Humor é utilizado por milhares de pessoas em todo o Brasil, ajudando-as a entender melhor seus padrões emocionais, identificar gatilhos para mudanças de humor e desenvolver estratégias para melhorar seu bem-estar mental no dia a dia.
            </p>
            <p className="text-neutral-700">
              Estamos apenas no começo dessa jornada e continuamos comprometidos em desenvolver novas funcionalidades e melhorias baseadas no feedback de nossa comunidade e nas mais recentes pesquisas em saúde mental.
            </p>
          </div>
        </section>

        {/* Values Section */}
        <section className="py-16 px-4 md:px-8 bg-neutral-50">
          <div className="max-w-7xl mx-auto">
            <div className="text-center mb-12">
              <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">Nossos Valores</h2>
              <p className="text-neutral-600 max-w-2xl mx-auto">
                Princípios que guiam nosso trabalho e nossa missão
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
              <motion.div 
                className="bg-white p-6 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
              >
                <div className="bg-primary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <Heart className="h-6 w-6 text-primary-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">Empatia</h3>
                <p className="text-neutral-600">
                  Colocamos as necessidades emocionais das pessoas em primeiro lugar, desenvolvendo soluções com sensibilidade e compreensão.
                </p>
              </motion.div>

              <motion.div 
                className="bg-white p-6 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                viewport={{ once: true }}
              >
                <div className="bg-secondary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <Award className="h-6 w-6 text-secondary-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">Excelência</h3>
                <p className="text-neutral-600">
                  Buscamos a mais alta qualidade em tudo o que fazemos, desde o desenvolvimento do aplicativo até o conteúdo que oferecemos.
                </p>
              </motion.div>

              <motion.div 
                className="bg-white p-6 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <div className="bg-accent-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <Brain className="h-6 w-6 text-accent-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">Inovação</h3>
                <p className="text-neutral-600">
                  Incorporamos as mais recentes pesquisas e tecnologias para oferecer soluções inovadoras e eficazes para o cuidado com a saúde mental.
                </p>
              </motion.div>

              <motion.div 
                className="bg-white p-6 rounded-xl shadow-sm"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                viewport={{ once: true }}
              >
                <div className="bg-primary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                  <Users className="h-6 w-6 text-primary-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">Comunidade</h3>
                <p className="text-neutral-600">
                  Valorizamos a construção de uma comunidade de apoio onde as pessoas possam compartilhar experiências e crescer juntas.
                </p>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Team Section */}
        <section className="py-16 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">Nossa Equipe</h2>
            <p className="text-neutral-600 max-w-2xl mx-auto">
              Conheça os profissionais dedicados que trabalham para tornar o Meu Humor uma ferramenta cada vez melhor
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {teamMembers.map((member, index) => (
              <motion.div 
                key={index}
                className="bg-white rounded-xl shadow-medium overflow-hidden"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
                viewport={{ once: true }}
              >
                <div className="h-48 bg-neutral-200 flex items-center justify-center">
                  <div className="bg-primary-100 p-4 rounded-full">
                    <Users className="h-8 w-8 text-primary-500" />
                  </div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-1">{member.name}</h3>
                  <p className="text-primary-500 font-medium mb-3">{member.role}</p>
                  <p className="text-neutral-600">{member.bio}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Contact CTA Section */}
        <section className="py-16 px-4 md:px-8 bg-primary-50">
          <div className="max-w-4xl mx-auto text-center">
            <div className="bg-primary-100 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-6">
              <MessageCircle className="h-8 w-8 text-primary-500" />
            </div>
            <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">
              Tem alguma pergunta ou sugestão?
            </h2>
            <p className="text-lg text-neutral-600 mb-8 max-w-2xl mx-auto">
              Estamos sempre abertos a feedback e novas ideias. Entre em contato conosco para compartilhar suas experiências ou sugestões para melhorar o Meu Humor.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/contato" className="btn btn-primary px-8 py-3">
                Fale Conosco
              </Link>
              <Link to="/planos" className="btn btn-outline px-8 py-3">
                Conhecer Planos
              </Link>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-white py-12 px-4 md:px-8 border-t border-neutral-200">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex items-center space-x-2 mb-4 md:mb-0">
                <div className="bg-primary-100 p-2 rounded-full">
                  <Brain className="h-5 w-5 text-primary-500" />
                </div>
                <span className="text-lg font-semibold text-neutral-800">Meu Humor</span>
              </div>
              <div className="flex space-x-6">
                <Link to="/sobre" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Sobre
                </Link>
                <Link to="/contato" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Contato
                </Link>
                <a href="#" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Termos
                </a>
                <a href="#" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Privacidade
                </a>
              </div>
            </div>
            <div className="mt-8 text-center text-neutral-500 text-sm">
              &copy; {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default AboutPage;
