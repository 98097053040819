import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Brain, BarChart2, Calendar, Shield, FileText, Lightbulb, BookOpen, Activity, Menu, X, Users, ChevronRight, Lock, KeyRound, CheckCircle } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../contexts/AuthContext';

interface NavItem {
  label: string;
  href: string;
}

const navItems: NavItem[] = [
  { label: 'Início', href: '#inicio' },
  { label: 'Como Funciona', href: '#como-funciona' },
  { label: 'Privacidade', href: '#privacidade' },
  { label: 'Sobre', href: '#sobre' },
  { label: 'IA Assistente', href: '#ia-assistente' },
  { label: 'App', href: '#app-showcase' },
];

const LandingPage: React.FC = () => {
  const { user } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  // Detectar se o dispositivo é touch
  useEffect(() => {
    const detectTouch = () => {
      return 'ontouchstart' in window || 
             (window.navigator.maxTouchPoints > 0);
    };
    
    setIsTouchDevice(detectTouch());
  }, []);

  // Fecha o menu móvel quando clicar em um link
  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const href = e.currentTarget.getAttribute('href');
    if (href?.startsWith('#')) {
      e.preventDefault();
      
      // Fechar o menu mobile primeiro
      setIsMobileMenuOpen(false);
      
      // Usar setTimeout para garantir que o menu feche antes da rolagem
      // Aumentar o timeout para dispositivos touch
      const scrollDelay = isTouchDevice ? 300 : 100;
      
      setTimeout(() => {
        const element = document.querySelector(href);
        if (element) {
          const offset = 80; // Ajuste conforme necessário para o header fixo
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          });
        }
      }, scrollDelay);
    }
  };

  // Fecha o menu móvel quando a tela for redimensionada para desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 920) { // 920px é o breakpoint lg do Tailwind
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Meu Humor | Monitore sua saúde mental com privacidade total</title>
        <meta name="description" content="Meu Humor é um aplicativo para monitoramento diário do seu estado emocional com anonimidade garantida, oferecendo insights personalizados e recursos para melhorar sua saúde mental com total segurança de dados." />
        <meta name="keywords" content="saúde mental, monitoramento de humor, bem-estar emocional, mindfulness, autocuidado, privacidade, anonimidade, segurança de dados" />
        <meta property="og:title" content="Meu Humor | Monitore sua saúde mental com privacidade total" />
        <meta property="og:description" content="Registre seu humor diariamente com anonimidade garantida e obtenha insights personalizados para melhorar sua saúde mental." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://meu-humor.app" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Meu Humor | Monitore sua saúde mental com privacidade total" />
        <meta name="twitter:description" content="Registre seu humor diariamente com anonimidade garantida e obtenha insights personalizados para melhorar sua saúde mental." />
        <link rel="canonical" href="https://meu-humor.app" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white" id="page-loaded">
        {/* Header/Navigation */}
        <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm z-50 border-b border-neutral-200 w-full overflow-hidden">
          <div className="py-3 sm:py-4 px-2 sm:px-4 md:px-8 max-w-7xl mx-auto">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-1 sm:space-x-2 shrink-0">
                <div className="bg-primary-100 p-1.5 sm:p-2 rounded-full">
                  <Brain className="h-4 w-4 sm:h-5 sm:w-5 text-primary-500" />
                </div>
                <h1 className="text-base sm:text-lg md:text-xl font-semibold text-neutral-800">Meu Humor</h1>
              </div>

              {/* Desktop Navigation */}
              <nav className="hidden lg:flex items-center space-x-6">
                {navItems.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    onClick={(e) => {
                      e.stopPropagation(); // Impedir propagação do evento
                      handleNavClick(e);
                    }}
                    className="text-neutral-600 hover:text-primary-500 transition-colors"
                  >
                    {item.label}
                  </a>
                ))}
              </nav>

              <div className="flex items-center space-x-1.5 sm:space-x-2 md:space-x-4 shrink-0">
                {user ? (
                  <Link to="/home" className="btn btn-primary px-3 py-1.5 sm:py-2 text-xs sm:text-sm">
                    Acessar
                  </Link>
                ) : (
                  <>
                    <Link to="/login" className="btn btn-outline px-3 sm:px-5 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap">
                      Entrar
                    </Link>
                    <Link 
                      to="/login?createAccount=true" 
                      className="btn btn-primary px-4 sm:px-8 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap" 
                      onClick={() => localStorage.setItem('createAccount', 'true')}
                    >
                      Criar
                    </Link>
                  </>
                )}
                {/* Mobile Menu Button */}
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="lg:hidden p-2 text-neutral-600 hover:text-primary-500 transition-colors"
                  aria-label="Toggle menu"
                >
                  {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                </button>
              </div>
            </div>
          </div>

          {/* Mobile Navigation */}
          <AnimatePresence>
            {isMobileMenuOpen && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.2 }}
                className="lg:hidden bg-white border-t border-neutral-200 w-full overflow-hidden"
                aria-label="Menu de navegação móvel"
              >
                <nav className="px-4 py-2">
                  {navItems.map((item) => (
                    <a
                      key={item.href}
                      href={item.href}
                      onClick={(e) => {
                        e.stopPropagation(); // Impedir propagação do evento
                        handleNavClick(e);
                      }}
                      className="flex items-center space-x-2 py-4 px-2 text-neutral-600 hover:text-primary-500 transition-colors active:bg-neutral-50 touch-manipulation"
                      aria-label={`Navegar para ${item.label}`}
                    >
                      <ChevronRight className="h-5 w-5 flex-shrink-0" />
                      <span className="truncate text-base">{item.label}</span>
                    </a>
                  ))}
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </header>

        {/* Spacer para compensar o header fixo */}
        <div className="h-16 sm:h-20"></div>

        {/* Hero Section */}
        <section id="inicio" className="py-12 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-4 lg:gap-8">
            <motion.div 
              className="md:w-1/2 mb-8 md:mb-0"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl md:text-4xl font-bold text-neutral-800 mb-4">
                Monitore seu humor, <span className="text-primary-500">cuide da sua mente</span>
              </h2>
              <p className="text-lg text-neutral-600 mb-6">
                Registre seu humor diariamente, visualize padrões e receba recomendações personalizadas da nossa IA para melhorar sua saúde mental e qualidade de vida.
              </p>
              <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                {user ? (
                  <Link 
                    to="/home" 
                    className="btn btn-primary px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base inline-block"
                  >
                    Ir para Dashboard
                  </Link>
                ) : (
                  <Link 
                    to="/login?createAccount=true" 
                    className="btn btn-primary px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base inline-block"
                    onClick={() => localStorage.setItem('createAccount', 'true')}
                  >
                    Comece agora
                  </Link>
                )}
                <a 
                  href="#como-funciona" 
                  className="btn btn-outline px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base inline-block"
                >
                  Saiba mais
                </a>
              </div>
            </motion.div>
            <motion.div 
              className="md:w-1/2 flex justify-center"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="relative w-full max-w-md mx-auto">
                <div className="absolute -top-4 -left-4 w-full h-full bg-secondary-100 rounded-2xl"></div>
                <div className="absolute -bottom-4 -right-4 w-full h-full bg-primary-100 rounded-2xl"></div>
                <div className="relative bg-white p-4 sm:p-6 rounded-2xl shadow-medium overflow-hidden">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-base sm:text-lg font-medium">Seu humor na semana</h3>
                    <div className="bg-primary-100 p-2 rounded-full">
                      <BarChart2 className="h-4 w-4 text-primary-500" />
                    </div>
                  </div>
                  <div className="rounded-xl mb-4 flex items-center justify-center">
                    <img 
                      src="/app-images/grafico.png" 
                      alt="Gráfico de humor semanal" 
                      className="w-full h-auto rounded-lg object-contain"
                      width={402}
                      height={280}
                      loading="lazy"
                    />
                  </div>
                  <p className="text-xs sm:text-sm text-neutral-600">
                    Visualize tendências e identifique padrões no seu humor
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>

        {/* Features Section */}
        <section id="como-funciona" className="py-16 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <motion.h2 
              className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Como funciona o Meu Humor
            </motion.h2>
            <p className="text-neutral-600 max-w-2xl mx-auto">
              O Meu Humor oferece uma maneira simples e eficaz de monitorar sua saúde mental através de recursos intuitivos.
            </p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
            <motion.div 
              className="card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <div className="bg-primary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <Calendar className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Registro Diário</h3>
              <p className="text-neutral-600">
                Registre seu humor diariamente com apenas alguns cliques. Adicione notas para contextualizar seus sentimentos.
              </p>
            </motion.div>

            <motion.div 
              className="card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            >
              <div className="bg-secondary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <BarChart2 className="h-6 w-6 text-secondary-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Análise Visual</h3>
              <p className="text-neutral-600">
                Visualize gráficos semanais e mensais para identificar padrões e tendências no seu estado emocional.
              </p>
            </motion.div>

            <motion.div 
              className="card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <div className="bg-accent-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <Brain className="h-6 w-6 text-accent-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Anotações por IA</h3>
              <p className="text-neutral-600">
                Transforme anotações simples em registros detalhados com nossa IA que expande suas notas rápidas em análises completas.
              </p>
            </motion.div>

            <motion.div 
              className="card"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <div className="bg-primary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <FileText className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Prontuários por IA</h3>
              <p className="text-neutral-600">
                Gere prontuários semanais baseados no seu histórico para compartilhar com profissionais de saúde mental.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Privacy Section */}
        <section id="privacidade" className="py-16 px-4 md:px-8 bg-gradient-to-br from-primary-50 via-accent-50 to-secondary-50 relative overflow-hidden border-y border-neutral-200">
          {/* Elementos decorativos para maior destaque visual */}
          <div className="absolute top-0 left-0 w-full h-full opacity-10">
            <div className="absolute top-10 left-10 w-32 h-32 bg-primary-500 rounded-full"></div>
            <div className="absolute bottom-10 right-10 w-40 h-40 bg-secondary-500 rounded-full"></div>
            <div className="absolute top-1/2 left-1/3 w-24 h-24 bg-accent-500 rounded-full"></div>
          </div>
          
          <div className="max-w-7xl mx-auto relative z-10">
            <div className="text-center mb-10">
              <div className="inline-block bg-white px-6 py-2 rounded-full shadow-sm border border-primary-100 mb-4">
                <span className="text-primary-600 font-semibold">Nosso Diferencial</span>
              </div>
              <h2 className="text-2xl md:text-4xl font-bold text-neutral-800 mb-4">Privacidade por Design</h2>
              <p className="text-neutral-600 max-w-2xl mx-auto text-lg">
                Sua saúde mental é assunto seu. Nosso compromisso é garantir que seus dados permaneçam privados e seguros.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="order-2 lg:order-1"
              >
                <div className="space-y-6">
                  <div className="bg-white p-6 rounded-xl shadow-sm border border-neutral-100 hover:shadow-md transition-shadow">
                    <div className="flex items-start space-x-4 mb-4">
                      <div className="bg-primary-100 p-3 rounded-full flex-shrink-0">
                        <Shield className="h-6 w-6 text-primary-500" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">Anonimidade Total</h3>
                        <p className="text-neutral-600">
                          Utilizamos tecnologia avançada para transformar seu email em um código anônimo irreversível, 
                          garantindo que sua identidade real nunca seja armazenada em nossos servidores.
                        </p>
                      </div>
                    </div>
                    <div className="pl-14 text-sm text-neutral-500 bg-neutral-50 p-3 rounded-lg">
                      <p className="italic">
                        "Seu email é processado através de uma função hash SHA-256, criando um identificador único que 
                        não pode ser revertido para descobrir o email original."
                      </p>
                    </div>
                  </div>

                  <div className="bg-white p-6 rounded-xl shadow-sm border border-neutral-100 hover:shadow-md transition-shadow">
                    <div className="flex items-start space-x-4">
                      <div className="bg-secondary-100 p-3 rounded-full flex-shrink-0">
                        <Lock className="h-6 w-6 text-secondary-500" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">Dados Desvinculados da Identidade</h3>
                        <p className="text-neutral-600">
                          Seus registros de humor e anotações pessoais são completamente desvinculados da sua identidade real.
                          Mesmo em caso de acesso não autorizado, não há como associar os dados a você.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-6 rounded-xl shadow-sm border border-neutral-100 hover:shadow-md transition-shadow">
                    <div className="flex items-start space-x-4">
                      <div className="bg-accent-100 p-3 rounded-full flex-shrink-0">
                        <KeyRound className="h-6 w-6 text-accent-500" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">Você no Controle</h3>
                        <p className="text-neutral-600">
                          Você decide quais informações compartilhar. Todos os campos pessoais são opcionais 
                          e você pode excluir seus dados a qualquer momento com um simples clique.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-6 rounded-xl shadow-sm border border-neutral-100 hover:shadow-md transition-shadow">
                    <div className="flex items-start space-x-4">
                      <div className="bg-primary-100 p-3 rounded-full flex-shrink-0">
                        <FileText className="h-6 w-6 text-primary-500" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">Compartilhamento Opcional</h3>
                        <p className="text-neutral-600">
                          O compartilhamento de seus dados de saúde mental é sempre uma escolha sua. 
                          Exportações de PDF ou compartilhamento direto com profissionais mantêm 
                          seu anonimato intacto.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-6 rounded-xl shadow-sm border border-neutral-100 hover:shadow-md transition-shadow">
                    <div className="flex items-start space-x-4">
                      <div className="bg-red-100 p-3 rounded-full flex-shrink-0">
                        <Users className="h-6 w-6 text-red-500" />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">Sem Logins Sociais</h3>
                        <p className="text-neutral-600">
                          Diferente de outros apps, não oferecemos login via redes sociais para preservar completamente 
                          sua privacidade. Logins sociais podem compartilhar dados do seu perfil e comprometer sua anonimidade.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                viewport={{ once: true }}
                className="order-1 lg:order-2"
              >
                <div className="relative">
                  <div className="absolute -top-4 -right-4 w-[90%] h-[90%] bg-primary-100 rounded-2xl transform rotate-3"></div>
                  <div className="absolute -bottom-4 -left-4 w-[90%] h-[90%] bg-secondary-100 rounded-2xl transform -rotate-3"></div>
                  <div className="relative bg-white p-6 rounded-2xl shadow-lg">
                    <h3 className="text-xl font-semibold mb-6 text-center">Por que a anonimidade importa?</h3>
                    
                    <div className="space-y-6">
                      <div className="flex items-start space-x-3">
                        <div className="bg-green-100 p-2 rounded-full flex-shrink-0 mt-1">
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        </div>
                        <p className="text-neutral-700">
                          <span className="font-medium">Eliminação de estigmas:</span> Registrar seu estado emocional sem 
                          medo de julgamentos ou exposição.
                        </p>
                      </div>
                      
                      <div className="flex items-start space-x-3">
                        <div className="bg-green-100 p-2 rounded-full flex-shrink-0 mt-1">
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        </div>
                        <p className="text-neutral-700">
                          <span className="font-medium">Proteção contra vazamentos:</span> Mesmo no caso improvável de uma 
                          violação de dados, suas informações permanecem anônimas.
                        </p>
                      </div>
                      
                      <div className="flex items-start space-x-3">
                        <div className="bg-green-100 p-2 rounded-full flex-shrink-0 mt-1">
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        </div>
                        <p className="text-neutral-700">
                          <span className="font-medium">Conformidade regulatória:</span> Estamos alinhados com as principais 
                          regulamentações de proteção de dados (LGPD e GDPR).
                        </p>
                      </div>
                      
                      <div className="flex items-start space-x-3">
                        <div className="bg-green-100 p-2 rounded-full flex-shrink-0 mt-1">
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        </div>
                        <p className="text-neutral-700">
                          <span className="font-medium">Liberdade de expressão:</span> Registre seus sentimentos reais sem 
                          preocupações com quem terá acesso às suas informações.
                        </p>
                      </div>

                      <div className="flex items-start space-x-3">
                        <div className="bg-green-100 p-2 rounded-full flex-shrink-0 mt-1">
                          <CheckCircle className="h-4 w-4 text-green-600" />
                        </div>
                        <p className="text-neutral-700">
                          <span className="font-medium">Sem conexão com redes sociais:</span> Não usamos logins sociais 
                          que poderiam vincular sua atividade com perfis públicos.
                        </p>
                      </div>

                      <div className="border-t border-neutral-100 pt-5 mt-2">
                        <p className="text-neutral-700 text-sm mb-4">
                          <span className="font-medium text-primary-600">Escolha flexível:</span> Embora priorizemos o anonimato, 
                          você pode optar pelo cadastro tradicional para acessar:
                        </p>
                        <div className="grid grid-cols-2 gap-2 text-sm">
                          <div className="flex items-center">
                            <div className="bg-primary-100 p-1 rounded-full mr-2">
                              <CheckCircle className="h-3 w-3 text-primary-500" />
                            </div>
                            <span>Notificações via WhatsApp</span>
                          </div>
                          <div className="flex items-center">
                            <div className="bg-primary-100 p-1 rounded-full mr-2">
                              <CheckCircle className="h-3 w-3 text-primary-500" />
                            </div>
                            <span>Notificações por Email</span>
                          </div>
                          <div className="flex items-center">
                            <div className="bg-primary-100 p-1 rounded-full mr-2">
                              <CheckCircle className="h-3 w-3 text-primary-500" />
                            </div>
                            <span>Experiência mais personalizada</span>
                          </div>
                          <div className="flex items-center">
                            <div className="bg-primary-100 p-1 rounded-full mr-2">
                              <CheckCircle className="h-3 w-3 text-primary-500" />
                            </div>
                            <span>Compartilhamento simplificado</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-8 text-center">
                      <Link 
                        to="/login?createAccount=true" 
                        className="btn btn-primary px-6 py-3 text-base inline-block"
                        onClick={() => localStorage.setItem('createAccount', 'true')}
                      >
                        Criar conta anônima
                      </Link>
                      <p className="text-xs text-neutral-500 mt-2">
                        Sua jornada de bem-estar com privacidade garantida
                      </p>
                      <p className="text-xs text-red-500 font-medium mt-1">
                        Não utilizamos login via redes sociais para proteger sua privacidade
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Botão voltar ao topo */}
            <button 
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="absolute bottom-4 right-4 p-2 bg-primary-500/20 hover:bg-primary-500/30 rounded-full transition-colors"
              aria-label="Voltar ao topo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m18 15-6-6-6 6"/>
              </svg>
            </button>
          </div>
        </section>

        {/* AI Assistant Section */}
        <section id="ia-assistente" className="py-16 px-4 md:px-8 max-w-7xl mx-auto bg-neutral-50 relative">
          <div className="text-center mb-12">
            <motion.h2 
              className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              IA Assistente para Saúde Mental
            </motion.h2>
            <p className="text-neutral-600 max-w-2xl mx-auto">
              Nossa inteligência artificial analisa seu histórico para oferecer recomendações personalizadas e conteúdos exclusivos para melhorar sua saúde mental.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8 max-w-4xl mx-auto">
            <motion.div 
              className="card bg-white h-full"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <div className="bg-accent-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <Lightbulb className="h-6 w-6 text-accent-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Recomendações Inteligentes</h3>
              <p className="text-neutral-600">
                Receba sugestões personalizadas de técnicas para controle de ansiedade e melhoria da qualidade de vida baseadas no seu histórico emocional.
              </p>
            </motion.div>
            
            <motion.div 
              className="card bg-white h-full"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            >
              <div className="bg-primary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <BookOpen className="h-6 w-6 text-primary-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Conteúdos Exclusivos</h3>
              <p className="text-neutral-600">
                Acesse artigos, exercícios e meditações selecionados especificamente para suas necessidades emocionais atuais.
              </p>
            </motion.div>
            
            <motion.div 
              className="card bg-white h-full"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <div className="bg-secondary-100 p-3 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <Activity className="h-6 w-6 text-secondary-500" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Planos de Ação</h3>
              <p className="text-neutral-600">
                Obtenha planos de ação personalizados com técnicas de respiração, exercícios de mindfulness e estratégias para momentos de crise.
              </p>
            </motion.div>
          </div>
          
          <motion.div 
            className="mt-8 text-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            viewport={{ once: true }}
          >
            <Link 
              to="/login?createAccount=true" 
              className="btn btn-primary px-6 sm:px-8 py-2 sm:py-3 text-sm sm:text-base inline-block"
              onClick={() => localStorage.setItem('createAccount', 'true')}
            >
              Experimente nosso assistente de IA
            </Link>
          </motion.div>

          {/* Botão voltar ao topo */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="absolute bottom-4 right-4 p-2 bg-primary-500/20 hover:bg-primary-500/30 rounded-full transition-colors"
            aria-label="Voltar ao topo"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
          </button>
        </section>
        
        {/* About Section */}
        <section id="sobre" className="py-16 px-4 md:px-8 bg-gradient-to-b from-white to-neutral-50 relative">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="space-y-6"
              >
                <h2 className="text-2xl md:text-3xl font-bold text-neutral-800">Nossa Missão</h2>
                <p className="text-neutral-600 text-lg">
                  Acreditamos que o autoconhecimento é o primeiro passo para uma vida mais equilibrada e saudável. 
                  Nossa missão é tornar o cuidado com a saúde mental acessível e prático para todos.
                </p>
                <div className="space-y-4">
                  <div className="flex items-start space-x-4">
                    <div className="bg-primary-100 p-3 rounded-full flex-shrink-0">
                      <Users className="h-6 w-6 text-primary-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Comunidade</h3>
                      <p className="text-neutral-600">
                        Já ajudamos mais de 10.000 pessoas a entenderem melhor suas emoções e desenvolverem hábitos mais saudáveis.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start space-x-4">
                    <div className="bg-secondary-100 p-3 rounded-full flex-shrink-0">
                      <Brain className="h-6 w-6 text-secondary-500" />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Tecnologia</h3>
                      <p className="text-neutral-600">
                        Utilizamos inteligência artificial avançada para fornecer insights personalizados e recomendações precisas.
                      </p>
                    </div>
                  </div>
                </div>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="relative"
              >
                <div className="absolute -top-4 -left-4 w-full h-full bg-primary-100 rounded-2xl transform rotate-3"></div>
                <div className="absolute -bottom-4 -right-4 w-full h-full bg-secondary-100 rounded-2xl transform -rotate-3"></div>
                <div className="relative bg-white p-6 rounded-2xl shadow-lg">
                  <h3 className="text-xl font-semibold mb-4">Nossos Números</h3>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="text-center p-4 bg-neutral-50 rounded-lg">
                      <div className="text-3xl font-bold text-primary-500 mb-2">10k+</div>
                      <div className="text-neutral-600">Usuários Ativos</div>
                    </div>
                    <div className="text-center p-4 bg-neutral-50 rounded-lg">
                      <div className="text-3xl font-bold text-secondary-500 mb-2">500k+</div>
                      <div className="text-neutral-600">Registros de Humor</div>
                    </div>
                    <div className="text-center p-4 bg-neutral-50 rounded-lg">
                      <div className="text-3xl font-bold text-accent-500 mb-2">98%</div>
                      <div className="text-neutral-600">Satisfação</div>
                    </div>
                    <div className="text-center p-4 bg-neutral-50 rounded-lg">
                      <div className="text-3xl font-bold text-primary-500 mb-2">24/7</div>
                      <div className="text-neutral-600">Suporte</div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
          
          {/* Botão voltar ao topo */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="absolute bottom-4 right-4 p-2 bg-primary-500/20 hover:bg-primary-500/30 rounded-full transition-colors"
            aria-label="Voltar ao topo"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
          </button>
        </section>

        {/* App Showcase Section */}
        <section id="app-showcase" className="py-16 px-4 md:px-8 max-w-7xl mx-auto relative">
          <div className="text-center mb-12">
            <motion.h2 
              className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              Conheça nosso aplicativo
            </motion.h2>
            <p className="text-neutral-600 max-w-2xl mx-auto">
              Interface intuitiva e recursos poderosos para acompanhar sua saúde mental diariamente
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-4 lg:gap-8 items-center">
            <motion.div
              className="md:col-span-1 lg:col-span-1 flex justify-center"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <div className="relative max-w-xs mx-auto">
                <div className="absolute -top-3 -left-3 w-full h-full bg-primary-100 rounded-2xl"></div>
                <div className="relative overflow-hidden rounded-2xl shadow-lg">
                  <img 
                    src="/app-images/app2.png" 
                    alt="Tela de registro de humor" 
                    className="w-full h-auto object-contain"
                    width={405}
                    height={883}
                    loading="lazy"
                  />
                </div>
              </div>
            </motion.div>
            
            <motion.div
              className="md:col-span-1 lg:col-span-1 flex justify-center"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <div className="relative max-w-xs mx-auto">
                <div className="absolute -top-3 -right-3 w-full h-full bg-secondary-100 rounded-2xl"></div>
                <div className="relative overflow-hidden rounded-2xl shadow-lg">
                  <img 
                    src="/app-images/app3.png" 
                    alt="Análise de humor semanal" 
                    className="w-full h-auto object-contain"
                    width={407}
                    height={886}
                    loading="lazy"
                  />
                </div>
              </div>
            </motion.div>
            
            <motion.div
              className="md:col-span-2 lg:col-span-1 flex flex-col justify-center space-y-4 md:space-y-6 md:pl-8 lg:pl-0 px-4 md:px-0"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <div>
                <h3 className="text-xl md:text-2xl font-bold text-neutral-800 mb-2">Fácil de usar</h3>
                <p className="text-neutral-600 text-sm md:text-base">Interface intuitiva que permite registrar seu humor em segundos e acompanhar sua evolução ao longo do tempo.</p>
              </div>
              
              <div>
                <h3 className="text-xl md:text-2xl font-bold text-neutral-800 mb-2">Insights valiosos</h3>
                <p className="text-neutral-600 text-sm md:text-base">Visualize padrões e tendências em seu humor com gráficos detalhados e análises personalizadas.</p>
              </div>
              
              <div>
                <h3 className="text-xl md:text-2xl font-bold text-neutral-800 mb-2">Sempre com você</h3>
                <p className="text-neutral-600 text-sm md:text-base">Acesse o aplicativo de qualquer dispositivo e mantenha seu histórico de humor sempre atualizado.</p>
              </div>
              
              <Link 
                to="/login?createAccount=true" 
                className="btn btn-primary px-6 sm:px-8 py-2 sm:py-3 text-sm sm:text-base self-start mt-4 inline-block"
                onClick={() => localStorage.setItem('createAccount', 'true')}
              >
                Experimente agora
              </Link>
            </motion.div>
          </div>

          {/* Botão voltar ao topo */}
          <button 
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="absolute bottom-4 right-4 p-2 bg-primary-500/20 hover:bg-primary-500/30 rounded-full transition-colors"
            aria-label="Voltar ao topo"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="m18 15-6-6-6 6"/>
            </svg>
          </button>
        </section>
        
        {/* CTA Section */}
        <section className="py-16 px-4 md:px-8 max-w-7xl mx-auto">
          <motion.div 
            className="bg-gradient-to-r from-primary-500 to-secondary-500 rounded-3xl p-8 md:p-12 text-white text-center relative"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Comece sua jornada de autocuidado hoje</h2>
            <p className="text-lg mb-6 max-w-2xl mx-auto">
              Junte-se a milhares de pessoas que estão transformando sua relação com a saúde mental através do monitoramento diário.
            </p>
            <Link 
              to="/login?createAccount=true" 
              className="btn bg-white text-primary-600 hover:bg-neutral-100 px-6 sm:px-8 py-2 sm:py-3 text-sm sm:text-base inline-block"
              onClick={() => localStorage.setItem('createAccount', 'true')}
            >
              Criar conta gratuita
            </Link>
            <p className="text-sm text-white/90 mt-3 max-w-lg mx-auto">
              <span className="font-semibold">Sua privacidade é nossa prioridade:</span> Não oferecemos login via redes 
              sociais para manter sua experiência completamente anônima e segura.
            </p>
            
            {/* Botão voltar ao topo */}
            <button 
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="absolute bottom-4 right-4 p-2 bg-white/20 hover:bg-white/30 rounded-full transition-colors"
              aria-label="Voltar ao topo"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m18 15-6-6-6 6"/>
              </svg>
            </button>
          </motion.div>
        </section>

        {/* Footer */}
        <footer className="py-8 px-4 md:px-8 bg-neutral-50 border-t border-neutral-200">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex items-center space-x-2 mb-4 md:mb-0">
                <div className="bg-primary-100 p-2 rounded-full">
                  <Brain className="h-4 w-4 text-primary-500" />
                </div>
                <span className="text-neutral-800 font-medium">Meu Humor</span>
              </div>
              <div className="flex space-x-6">
                <a href="#inicio" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>Início</a>
                <a href="#como-funciona" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>Como Funciona</a>
                <a href="#privacidade" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>Privacidade</a>
                <a href="#sobre" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>Sobre</a>
                <a href="#ia-assistente" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>IA Assistente</a>
                <a href="#app-showcase" className="text-neutral-600 hover:text-primary-500" onClick={(e) => handleNavClick(e)}>App</a>
              </div>
            </div>
            <div className="mt-6 text-center md:text-left text-neutral-500 text-sm">
              &copy; {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.
              <div className="flex justify-center md:justify-start mt-3 space-x-4">
                <Link to="/politica-de-privacidade" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Política de Privacidade
                </Link>
                <span className="text-neutral-400">|</span>
                <Link to="/termos-de-uso" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Termos de Uso
                </Link>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;
