import React from 'react';

interface FocusTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  showCount?: boolean;
  autoFocus?: boolean;
}

/**
 * Uma solução não-controlada para o problema de textareas em dispositivos móveis.
 * Esta abordagem usa um componente não-controlado para evitar problemas de re-renderização
 * e garante que o texto seja inserido na ordem correta.
 */
class UncontrolledTextarea extends React.Component<FocusTextareaProps> {
  private textareaRef = React.createRef<HTMLTextAreaElement>();
  private isInternalChange = false;
  
  componentDidMount() {
    // Definir o valor inicial e aplicar foco se necessário
    if (this.textareaRef.current) {
      this.textareaRef.current.value = this.props.value;
      
      if (this.props.autoFocus) {
        this.textareaRef.current.focus();
        // Mover o cursor para o final do texto
        const length = this.props.value.length;
        this.textareaRef.current.setSelectionRange(length, length);
      }
    }
  }
  
  componentDidUpdate(prevProps: FocusTextareaProps) {
    // Se o valor foi alterado externamente (não por entrada do usuário),
    // precisamos atualizar o textarea
    if (!this.isInternalChange && prevProps.value !== this.props.value && this.textareaRef.current) {
      const cursorPosition = this.textareaRef.current.selectionStart;
      const selectionLength = this.textareaRef.current.selectionEnd - this.textareaRef.current.selectionStart;
      
      this.textareaRef.current.value = this.props.value;
      
      // Restaurar a posição do cursor
      this.textareaRef.current.setSelectionRange(cursorPosition, cursorPosition + selectionLength);
    }
    
    // Redefinir a flag após cada atualização
    this.isInternalChange = false;
  }
  
  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Marcar como mudança interna para não redefinir o valor no componentDidUpdate
    this.isInternalChange = true;
    
    // Salvar a posição do cursor
    const cursorPosition = e.target.selectionStart;
    
    // Propagar a mudança para o componente pai
    this.props.onChange(e);
    
    // Restaurar a posição do cursor
    // Usar setTimeout para garantir que o cursor seja restaurado após o ciclo de renderização
    setTimeout(() => {
      if (this.textareaRef.current) {
        this.textareaRef.current.focus();
        this.textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };
  
  render() {
    const { placeholder, className, maxLength, showCount, value } = this.props;
    
    return (
      <>
        <textarea
          ref={this.textareaRef}
          // Importante: use defaultValue, não value, para um componente não controlado
          defaultValue={this.props.value}
          onChange={this.handleChange}
          className={className || ''}
          placeholder={placeholder || ''}
          maxLength={maxLength}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        {showCount && maxLength && (
          <p className="text-xs text-neutral-500 text-right mb-3">
            {value.length}/{maxLength} caracteres
          </p>
        )}
      </>
    );
  }
}

export default UncontrolledTextarea;
