import React, { useState, useEffect } from 'react';
import { Smartphone, User, Shield, AlertCircle, Bell, ChevronDown, ChevronUp, Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';
import { Button } from '../../ui/button';

const PersonalInfoStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [name, setName] = useState(userData.name || '');
  const [phone, setPhone] = useState(userData.phone || '');
  const [isAnonymous, setIsAnonymous] = useState(userData.isAnonymous !== false); // Modo anônimo como padrão
  const [showBenefits, setShowBenefits] = useState(false);
  const [showAnonymousWarning, setShowAnonymousWarning] = useState(false);
  const [errors, setErrors] = useState<{ 
    name?: string; 
    phone?: string;
  }>({});
  
  // Limpar campos de nome e telefone quando mudar para modo anônimo
  useEffect(() => {
    if (isAnonymous) {
      setName('');
      setPhone('');
    }
  }, [isAnonymous]);
  
  // Efeito para atualizar dados quando o userData mudar
  useEffect(() => {
    if (userData) {
      console.log('Atualizando dados do usuário no PersonalInfoStep:', userData);
      
      // Atualizar campos com os dados recebidos
      setName(userData.name || '');
      setPhone(userData.phone || '');
      setIsAnonymous(userData.isAnonymous !== false);
    }
  }, [userData]);
  
  // Função para lidar com a mudança no modo anônimo
  const handleAnonymousToggle = () => {
    // Se está mudando de não-anônimo para anônimo e tem dados sensíveis, mostrar aviso
    if (!isAnonymous && (name.trim() || phone.trim())) {
      setShowAnonymousWarning(true);
    } else {
      // Se não há dados sensíveis ou está mudando para não-anônimo, apenas alterna o modo
      setIsAnonymous(!isAnonymous);
    }
  };
  
  // Função para cancelar a mudança para o modo anônimo
  const handleCancelAnonymous = () => {
    setShowAnonymousWarning(false);
  };
  
  // Função para confirmar a mudança para o modo anônimo
  const handleConfirmAnonymous = () => {
    setIsAnonymous(true);
    setShowAnonymousWarning(false);
  };
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validação
    const newErrors: { 
      name?: string; 
      phone?: string;
    } = {};
    
    // Se não for anônimo, o nome é obrigatório
    if (!isAnonymous && !name.trim()) {
      newErrors.name = 'Por favor, informe seu nome';
    }
    
    // Telefone é opcional, mas se for preenchido, deve estar no formato correto
    if (!isAnonymous && phone.trim() && !/^\(\d{2}\) \d{5}-\d{4}$/.test(phone)) {
      newErrors.phone = 'Formato inválido. Use (99) 99999-9999';
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    // Enviar dados
    onNext({ 
      name: isAnonymous ? undefined : name, 
      phone: isAnonymous ? undefined : (phone.trim() || undefined),
      isAnonymous
    });
  };
  
  // Formatar telefone
  const formatPhone = (value: string) => {
    // Remover tudo que não for número
    const numbers = value.replace(/\D/g, '');
    
    // Aplicar máscara (99) 99999-9999
    if (numbers.length <= 2) {
      return numbers.length ? `(${numbers}` : '';
    } else if (numbers.length <= 7) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2)}`;
    } else if (numbers.length <= 11) {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7)}`;
    } else {
      return `(${numbers.slice(0, 2)}) ${numbers.slice(2, 7)}-${numbers.slice(7, 11)}`;
    }
  };
  
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(formatPhone(e.target.value));
  };
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          Vamos começar com algumas informações básicas
        </h3>
        <p className="text-neutral-600">
          Sua privacidade é nossa prioridade
        </p>
      </div>
      
      {/* Opção de anonimidade */}
      <div className="mb-6 p-4 bg-neutral-50 rounded-lg border border-neutral-200">
        <div className="flex items-start mb-3">
          <div className="flex-shrink-0 mt-0.5">
            <Shield size={20} className="text-primary-600" />
          </div>
          <div className="ml-3">
            <h4 className="text-base font-medium text-neutral-800">Privacidade e Anonimidade</h4>
            <p className="text-sm text-neutral-600 mt-1">
              Por padrão, você usará o aplicativo de forma anônima. Seus dados serão criptografados e não serão vinculados à sua identidade real.
            </p>
          </div>
        </div>
        
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-neutral-700">
            Modo anônimo
          </span>
          <button
            type="button"
            onClick={handleAnonymousToggle}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 ${
              isAnonymous ? 'bg-primary-600' : 'bg-neutral-200'
            }`}
            role="switch"
            aria-checked={isAnonymous}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                isAnonymous ? 'translate-x-6' : 'translate-x-1'
              }`}
            />
          </button>
        </div>
        
        {/* Aviso de alteração para modo anônimo */}
        {showAnonymousWarning && (
          <div className="mt-3 p-3 bg-amber-50 border border-amber-300 rounded-md">
            <div className="flex items-start">
              <AlertCircle size={18} className="text-amber-600 mt-0.5 mr-2 flex-shrink-0" />
              <div>
                <p className="text-sm text-amber-800 font-medium">
                  Atenção: Ao mudar para o modo anônimo
                </p>
                <p className="text-xs text-amber-700 mt-1">
                  Seus dados pessoais como nome e telefone serão removidos. Esta ação não pode ser desfeita.
                </p>
                <div className="mt-2 flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={handleCancelAnonymous}
                    className="px-2 py-1 text-xs bg-white border border-amber-300 text-amber-700 rounded hover:bg-amber-50"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={handleConfirmAnonymous}
                    className="px-2 py-1 text-xs bg-amber-600 text-white rounded hover:bg-amber-700"
                  >
                    Entendi
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {/* Benefícios sempre visíveis com opção de expandir/recolher */}
        <div className="mt-3">
          <button
            type="button"
            onClick={() => setShowBenefits(!showBenefits)}
            className="flex items-center justify-between w-full p-2 bg-blue-50 rounded-md border border-blue-200 text-left"
          >
            <span className="text-sm font-medium text-blue-800 flex items-center">
              <Bell size={16} className="mr-1" />
              Benefícios do modo não-anônimo
            </span>
            {showBenefits ? (
              <ChevronUp size={16} className="text-blue-600" />
            ) : (
              <ChevronDown size={16} className="text-blue-600" />
            )}
          </button>
          
          {showBenefits && (
            <div className="mt-2 p-3 bg-blue-50 border border-blue-200 rounded-md">
              <ul className="text-xs text-blue-700 list-disc pl-5 space-y-1">
                <li>Notificações personalizadas via WhatsApp</li>
                <li>Recomendações exclusivas baseadas no seu perfil</li>
                <li>Conteúdos exclusivos enviados por email</li>
                <li>Lembretes para registros de humor</li>
                <li>Suporte personalizado da nossa equipe</li>
              </ul>
              
              {isAnonymous && (
                <div className="mt-2 pt-2 border-t border-blue-200">
                  <p className="text-xs text-blue-800 font-medium">
                    Desative o modo anônimo para aproveitar estes benefícios.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          {/* Campos de nome e telefone - visíveis apenas se não for anônimo */}
          {!isAnonymous && (
            <>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-neutral-700 mb-1">
                  Seu nome
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <User size={18} className="text-neutral-500" />
                  </div>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`block w-full pl-10 pr-3 py-2 border ${
                      errors.name ? 'border-red-500' : 'border-neutral-300'
                    } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                    placeholder="Digite seu nome"
                    required
                  />
                </div>
                {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name}</p>}
              </div>
              
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-neutral-700 mb-1">
                  Seu telefone (opcional)
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Smartphone size={18} className="text-neutral-500" />
                  </div>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    className={`block w-full pl-10 pr-3 py-2 border ${
                      errors.phone ? 'border-red-500' : 'border-neutral-300'
                    } rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500`}
                    placeholder="(99) 99999-9999"
                  />
                </div>
                {errors.phone ? (
                  <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
                ) : (
                  <p className="mt-1 text-xs text-neutral-500">
                    Usaremos seu telefone para enviar notificações via WhatsApp (opcional)
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        
        <div className="pt-4 flex justify-between items-center">
          {showBackButton && (
            <Button
              type="button"
              variant="ghost"
              onClick={onBack}
              disabled={isSubmitting}
            >
              Voltar
            </Button>
          )}
          
          <Button
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfoStep; 