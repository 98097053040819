import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import HistoryPage from './pages/HistoryPage';
import RecordPage from './pages/RecordPage';
import BatchMoodEntryPage from './pages/BatchMoodEntryPage';
import ContentPage from './pages/ContentPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import LandingPage from './pages/LandingPage';
import PlansPage from './pages/PlansPage';
import AboutPage from './pages/AboutPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import ChatPage from './pages/ChatPage';
import ChatSessionsPage from './pages/ChatSessionsPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { StripeProvider } from './contexts/StripeContext';
import { ChatProvider } from './contexts/ChatContext';
import ProtectedRoute from './components/ProtectedRoute';
import ScrollToTop from './components/ScrollToTop';
import { checkNotificationStatus, registerServiceWorker } from './infra/NotificationFacade';
import { Helmet } from 'react-helmet-async';
import { Loader2 } from 'lucide-react';

// Componente de roteamento que só é renderizado após a autenticação ser verificada
const AppRoutes: React.FC = () => {
  const { loading } = useAuth();
  const isLandingPage = window.location.pathname === '/';
  
  // Exibir um loader enquanto verifica a autenticação, exceto para a LandingPage
  if (loading && !isLandingPage) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-white">
        <Loader2 className="h-12 w-12 text-primary-500 animate-spin mb-4" />
        <p className="text-neutral-600 text-lg">Carregando...</p>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/planos" element={<PlansPage />} />
      <Route path="/sobre" element={<AboutPage />} />
      <Route path="/politica-de-privacidade" element={<PrivacyPolicyPage />} />
      <Route path="/termos-de-uso" element={<TermsOfUsePage />} />
      <Route path="/payment-success" element={<PaymentSuccessPage />} />
      <Route path="/payment-cancel" element={<PaymentCancelPage />} />
    
      <Route 
        path="/home" 
        element={
          <ProtectedRoute>
            <Layout>
              <HomePage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/historico" 
        element={
          <ProtectedRoute>
            <Layout>
              <HistoryPage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/registrar" 
        element={
          <ProtectedRoute>
            <Layout>
              <RecordPage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/registrar-lote" 
        element={
          <ProtectedRoute>
            <Layout>
              <BatchMoodEntryPage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      {/* Rotas de conteúdo abertas para SEO */}
      <Route 
        path="/conteudos" 
        element={
          <Layout>
            <ContentPage />
          </Layout>
        } 
      />
      <Route 
        path="/conteudos/:categoryId" 
        element={
          <Layout>
            <ContentPage />
          </Layout>
        } 
      />
      <Route 
        path="/conteudos/:categoryId/:subCategoryId" 
        element={
          <Layout>
            <ContentPage />
          </Layout>
        } 
      />
      <Route 
        path="/conteudos/:categoryId/:subCategoryId/:articleId" 
        element={
          <Layout>
            <ContentPage />
          </Layout>
        } 
      />
      <Route 
        path="/perfil" 
        element={
          <ProtectedRoute>
            <Layout>
              <ProfilePage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      {/* Novas rotas para o chat */}
      <Route 
        path="/chat" 
        element={
          <ProtectedRoute>
            <Layout>
              <ChatPage />
            </Layout>
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/chat/sessions" 
        element={
          <ProtectedRoute>
            <Layout>
              <ChatSessionsPage />
            </Layout>
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
};

function App() {
  // Inicializar service worker e verificar status de notificações ao carregar a aplicação
  useEffect(() => {
    const initNotifications = async () => {
      try {
        // Registrar service worker se ainda não estiver registrado
        await registerServiceWorker();
        
        // Verificar status de notificações
        const status = await checkNotificationStatus();
        console.log('Status inicial de notificações:', status);
      } catch (error) {
        console.error('Erro ao inicializar notificações:', error);
      }
    };
    
    initNotifications();
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="description" content="Meu Humor - Acompanhe seu humor e bem-estar mental" />
      </Helmet>
      <AuthProvider>
        <StripeProvider>
          <Router>
            <ScrollToTop />
            <ChatProvider>
              <AppRoutes />
            </ChatProvider>
          </Router>
        </StripeProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;