import React from 'react';
import { Link } from 'react-router-dom';
import { XCircle, ArrowLeft, Brain } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';

const PaymentCancelPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Pagamento Cancelado | Meu Humor</title>
        <meta name="description" content="Seu pagamento foi cancelado. Você pode tentar novamente quando quiser." />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white flex flex-col">
        {/* Header/Navigation */}
        <header className="py-3 sm:py-4 px-2 sm:px-4 md:px-8 max-w-7xl mx-auto w-full">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-1 sm:space-x-2">
              <div className="bg-primary-100 p-1.5 sm:p-2 rounded-full">
                <Brain className="h-4 w-4 sm:h-5 sm:w-5 text-primary-500" />
              </div>
              <h1 className="text-base sm:text-lg md:text-xl font-semibold text-neutral-800">Meu Humor</h1>
            </Link>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center px-4 py-12">
          <motion.div 
            className="bg-white rounded-xl shadow-medium p-8 max-w-md w-full text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex justify-center mb-6">
              <div className="bg-red-100 p-4 rounded-full">
                <XCircle className="h-12 w-12 text-red-500" />
              </div>
            </div>
            
            <h2 className="text-2xl font-bold text-neutral-800 mb-4">Pagamento Cancelado</h2>
            
            <p className="text-neutral-600 mb-8">
              Seu processo de pagamento foi cancelado. Não se preocupe, nenhum valor foi cobrado. 
              Você pode tentar novamente quando estiver pronto.
            </p>
            
            <div className="space-y-4">
              <Link to="/planos" className="btn btn-primary w-full flex items-center justify-center">
                <ArrowLeft className="h-4 w-4 mr-2" />
                Voltar para Planos
              </Link>
              
              <Link to="/home" className="btn btn-outline w-full">
                Ir para o Dashboard
              </Link>
            </div>
            
            <p className="mt-6 text-sm text-neutral-500">
              Precisa de ajuda? <Link to="/contato" className="text-primary-500 hover:underline">Entre em contato</Link> com nosso suporte.
            </p>
          </motion.div>
        </main>

        {/* Footer */}
        <footer className="py-6 px-4 border-t border-neutral-200">
          <div className="max-w-7xl mx-auto text-center text-neutral-500 text-sm">
            &copy; {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.
          </div>
        </footer>
      </div>
    </>
  );
};

export default PaymentCancelPage; 