import React, { createContext, useContext, useState, ReactNode } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { redirectToCheckout as stripeRedirectToCheckout } from '../services/stripeService';
import { useAuth } from './AuthContext';

// Carregue o Stripe com sua chave publicável (no nível do módulo para carregar apenas uma vez)
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface StripeContextProps {
  createCheckoutSession: (priceId: string) => Promise<{ error?: string }>;
  isLoading: boolean;
  error: string | null;
}

const StripeContext = createContext<StripeContextProps | undefined>(undefined);

export function useStripe() {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
}

interface StripeProviderProps {
  children: ReactNode;
}

export function StripeProvider({ children }: StripeProviderProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  /**
   * Cria uma sessão de checkout do Stripe e redireciona o usuário
   * @param priceId ID do preço do produto no Stripe
   */
  const createCheckoutSession = async (priceId: string): Promise<{ error?: string }> => {
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Criando sessão de checkout para:', priceId);
      
      if (!user || !user.id) {
        throw new Error('Usuário não autenticado');
      }
      
      // Usar o serviço Stripe para redirecionar ao checkout via backend
      const result = await stripeRedirectToCheckout({
        priceId,
        userId: user.id
      });
      
      if (result.error) {
        setError(result.error);
        return { error: result.error };
      }
      
      return {};
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao processar pagamento';
      console.error('Erro ao redirecionar para o checkout:', errorMessage);
      setError(errorMessage);
      return { error: errorMessage };
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    createCheckoutSession,
    isLoading,
    error,
  };

  return (
    <StripeContext.Provider value={value}>
      <Elements stripe={stripePromise}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
}
