// import { WeeklySummary } from '../../types';
import { LocalStorageCache } from '../utils/cacheUtils';
import type { MoodEntry } from '../../types';

export interface WeeklySummaryResponse {
  status?: string;
  summary: string;
  requestId: string;
  startDate: string;
  endDate: string;
}

export class WeeklySummaryService {
  private readonly apiUrl: string;
  private isPollingActive: boolean = false;
  private readonly cache: LocalStorageCache<WeeklySummaryResponse>;
  private readonly moodEntriesCache: LocalStorageCache<MoodEntry[]>;
  
  // Chaves para armazenar o cache
  private static readonly CACHE_KEY = 'weeklyRecommendationCache';
  private static readonly MOOD_ENTRIES_CACHE_KEY = 'moodEntriesStateCache';
  
  constructor() {
    this.apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
    // Inicializar o cache com TTL de 24 horas
    this.cache = new LocalStorageCache<WeeklySummaryResponse>(WeeklySummaryService.CACHE_KEY);
    // Cache para armazenar o estado dos registros de humor quando geramos um resumo
    this.moodEntriesCache = new LocalStorageCache<MoodEntry[]>(WeeklySummaryService.MOOD_ENTRIES_CACHE_KEY);
  }

  // Método para obter o token de autenticação
  private getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }
  
  /**
   * Gera uma assinatura única para um array de entradas de humor
   * Esta assinatura é usada para comparar se as entradas mudaram
   * @param entries Array de entradas de humor
   * @returns String representando a assinatura das entradas
   */
  private generateEntriesSignature(entries: MoodEntry[]): string {
    if (!entries || entries.length === 0) return '';
    
    // Ordenar entradas por timestamp (mais recente primeiro)
    const sortedEntries = [...entries].sort((a, b) => {
      return b.timestamp - a.timestamp;
    });
    
    // Criar uma string com os timestamps, scores e comprimento das notas para comparação
    return sortedEntries
      .map(entry => `${entry.timestamp}:${entry.score}:${entry.note?.length || 0}`)
      .join('|');
  }
  
  /**
   * Verifica se o cache deve ser invalidado baseado na comparação entre entradas antigas e novas
   * @param currentEntries Entradas de humor atuais
   * @returns true se o cache foi invalidado, false caso contrário
   */
  public shouldInvalidateCache(currentEntries: MoodEntry[]): boolean {
    if (!currentEntries || currentEntries.length === 0) return false;
    
    // Obter entradas anteriores do cache
    const previousEntries = this.moodEntriesCache.get();
    
    // Se não houver entradas anteriores, mas temos novas entradas, devemos invalidar
    if (!previousEntries && currentEntries.length > 0) {
      console.log('Sem registro anterior de entradas, invalidando cache...');
      this.cache.clear();
      // Armazenar novas entradas para comparação futura
      this.moodEntriesCache.save(currentEntries);
      return true;
    }
    
    // Comparar assinaturas para detectar mudanças
    const previousSignature = this.generateEntriesSignature(previousEntries || []);
    const currentSignature = this.generateEntriesSignature(currentEntries);
    
    if (previousSignature !== currentSignature) {
      console.log('Detectada mudança nos registros de humor, invalidando cache...');
      this.cache.clear();
      // Atualizar o cache de entradas com o estado atual
      this.moodEntriesCache.save(currentEntries);
      return true;
    }
    
    return false;
  }
  
  /**
   * Método legado para verificar se o cache deve ser invalidado com base na data de uma nova entrada
   * Mantido para compatibilidade, mas shouldInvalidateCache é preferível
   * @param entryDate Data da entrada mais recente
   * @returns true se o cache foi invalidado, false caso contrário
   */
  public invalidateCacheIfNewerEntry(entryDate: Date): boolean {
    const cachedData = this.cache.get();
    if (!cachedData) return false;
    
    const entryTimestamp = entryDate.getTime();
    // Se a nova entrada for mais recente que o período do cache, invalidá-lo
    const cacheEndDate = new Date(cachedData.endDate).getTime();
    
    if (entryTimestamp > cacheEndDate) {
      console.log('Nova entrada mais recente que o cache, invalidando...');
      this.cache.clear();
      // Limpar também o cache de entradas para forçar uma nova comparação
      this.moodEntriesCache.clear();
      return true;
    }
    
    return false;
  }

  /**
   * Obtém o resumo semanal, realizando polling se necessário
   * Verifica o cache primeiro antes de fazer requisição à API
   * @returns Resposta completa com o resumo semanal ou null se não for possível obter
   */
  async getWeeklySummary(): Promise<WeeklySummaryResponse | string | null> {
    // Verificar o cache primeiro
    const cachedData = this.cache.get();
    if (cachedData) {
      console.log('Usando resumo semanal do cache com ID:', cachedData.requestId);
      return cachedData;
    }
    
    // Evitar múltiplas chamadas simultâneas
    if (this.isPollingActive) {
      console.warn('Já existe uma solicitação de resumo semanal em andamento');
      throw new Error('Já existe um processamento assíncrono em andamento');
    }

    try {
      this.isPollingActive = true;
      const authToken = this.getAuthToken();
      
      if (!authToken) {
        this.isPollingActive = false;
        console.error('Erro ao obter resumo semanal: token não disponível');
        throw new Error('Erro ao obter resumo semanal: token não disponível');
      }
      
      console.log('Iniciando solicitação de resumo semanal via API...');
      
      const response = await fetch(`${this.apiUrl}/weekly-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      // Handle 202 Accepted status code - async processing started
      if (response.status === 202) {
        console.log('Servidor iniciou processamento assíncrono (202 Accepted)');
        const data = await response.json();
        
        // Verifica se temos pollUrl na resposta
        if (data.pollUrl) {
          console.log('Poll URL recebida, iniciando polling:', data.pollUrl);
          try {
            const result = await this.pollForResults(data.pollUrl);
            
            // Salvar resultado no cache se for uma resposta válida
            if (result && 
                typeof result === 'object' && 
                result.summary && 
                result.requestId && 
                result.startDate && 
                result.endDate) {
              this.cache.save(result);
            }
            
            return result;
          } finally {
            this.isPollingActive = false;
          }
        } else {
          this.isPollingActive = false;
          console.warn('Resposta 202 recebida, mas sem pollUrl');
          throw new Error('Servidor retornou 202 sem URL para polling');
        }
      }
      
      if (!response.ok) {
        this.isPollingActive = false;
        throw new Error(`Erro ao obter resumo semanal: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Resumo semanal obtido com sucesso (resposta direta)');
      
      // Salvar resultado no cache se for uma resposta válida
      if (data && 
          typeof data === 'object' && 
          data.summary && 
          data.requestId && 
          data.startDate && 
          data.endDate) {
        this.cache.save(data);
      }
      
      this.isPollingActive = false;
      return data; // Retorna o objeto completo, não apenas o campo summary
    } catch (error) {
      this.isPollingActive = false;
      console.error('Erro ao obter resumo semanal:', error);
      throw error;
    }
  }
  
  /**
   * Realiza polling em um endpoint para obter o resultado de uma operação assíncrona
   * @param pollUrl URL para polling do resultado
   * @param maxAttempts Número máximo de tentativas (padrão: 15)
   * @param delayMs Intervalo entre tentativas em milissegundos (padrão: 2000)
   */
  private async pollForResults(pollUrl: string, maxAttempts = 15, delayMs = 2000): Promise<WeeklySummaryResponse | null> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      throw new Error('Token de autenticação não disponível para polling');
    }
    
    // Função helper para aguardar um intervalo
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
    
    // Polling loop
    let attempts = 0;
    let lastError: Error | null = null;
    
    console.log(`Iniciando polling com máximo de ${maxAttempts} tentativas e intervalo de ${delayMs}ms`);
    
    while (attempts < maxAttempts) {
      attempts++;
      
      // Construir a URL completa para o polling
      const fullPollUrl = `${this.apiUrl}${pollUrl.startsWith('/') ? pollUrl : `/${pollUrl}`}`;
      console.log(`Tentativa ${attempts}/${maxAttempts} de polling para ${fullPollUrl}`);
      
      try {
        const response = await fetch(fullPollUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          }
        });
        
        // Se tiver erro HTTP
        if (!response.ok) {
          console.error(`Erro durante polling: ${response.status} ${response.statusText}`);
          lastError = new Error(`Erro ao obter resultado: ${response.statusText}`);
          
          // Para erros 4xx (cliente), parar as tentativas
          if (response.status >= 400 && response.status < 500) {
            throw lastError;
          }
          
          // Para erros 5xx (servidor), continuar tentando após delay
          await delay(delayMs);
          continue;
        }
        
        // Processar resposta
        const data = await response.json();
        console.log(`Resposta de polling (tentativa ${attempts}):`, data.status);
        
        // Verificar o status na resposta
        if (data.status === 'completed') {
          console.log('Polling concluído com sucesso, resumo disponível');
          return data; // Retorna o objeto completo
        } else if (data.status === 'processing') {
          // Ainda processando, continuar polling
          console.log('Status: processing, continuando polling...');
          await delay(delayMs);
          continue;
        } else if (data.status === 'error') {
          // Falha no processamento
          const errorMessage = data.message || 'Erro desconhecido durante o processamento do resumo semanal';
          console.error('Polling falhou:', errorMessage);
          throw new Error(errorMessage);
        } else {
          // Status desconhecido, tentar usar os dados disponíveis
          console.warn(`Status desconhecido: ${data.status}, tentando extrair dados`);
          return data;
        }
      } catch (error) {
        // Captura erros da requisição fetch ou do processamento da resposta
        const errorMessage = error instanceof Error ? error.message : 'Erro desconhecido';
        console.error(`Erro durante tentativa ${attempts} de polling:`, errorMessage);
        
        // Salvar o último erro para retornar se todas as tentativas falharem
        lastError = error instanceof Error ? error : new Error(errorMessage);
        
        // Se for a última tentativa, propagar o erro
        if (attempts >= maxAttempts) {
          throw lastError;
        }
        
        // Caso contrário, tentar novamente após delay
        await delay(delayMs);
      }
    }
    
    // Se chegamos aqui, é porque excedemos o número máximo de tentativas
    throw new Error(`Tempo esgotado após ${maxAttempts} tentativas de polling`);
  }
} 