import { MoodEntry, HealthRecord } from '../types';
import { getMoodService, getHealthRecordService } from './serviceLocator';

// Compatibilidade com código existente - MoodEntries
export const saveMoodEntries = async (entries: MoodEntry[]): Promise<void> => {
  const moodService = getMoodService();
  for (const entry of entries) {
    await moodService.addEntry(entry);
  }
};

export const getMoodEntries = async (): Promise<MoodEntry[]> => {
  return await getMoodService().getAllEntries();
};

export const addMoodEntry = async (entry: MoodEntry): Promise<void> => {
  await getMoodService().addEntry(entry);
};

export const addMoodEntryForDate = async (score: number, note: string, date: Date): Promise<void> => {
  await getMoodService().addEntryForDate(score, note, date);
};

export const getEntriesForLastDays = async (days: number): Promise<MoodEntry[]> => {
  return await getMoodService().getEntriesForLastDays(days);
};

export const getAverageMoodForDay = async (date: Date): Promise<number> => {
  return await getMoodService().getAverageMoodForDay(date);
};

export const updateMoodEntry = async (entry: MoodEntry): Promise<void> => {
  await getMoodService().updateEntry(entry);
};

export const deleteMoodEntry = async (entryId: string): Promise<void> => {
  await getMoodService().deleteEntry(entryId);
};

export const hasConcerningPattern = async (): Promise<boolean> => {
  return await getMoodService().hasConcerningPattern();
};

// Compatibilidade com código existente - HealthRecords
export const createHealthRecord = async (): Promise<HealthRecord | null> => {
  return await getHealthRecordService().createRecord();
};

export const getHealthRecords = async (limit = 2): Promise<HealthRecord[]> => {
  return await getHealthRecordService().getRecords(limit);
};

export const canGenerateHealthRecord = async (): Promise<boolean> => {
  return await getHealthRecordService().canGenerateRecord();
};

export const resetHealthRecord = async (recordId: string): Promise<HealthRecord | null> => {
  return await getHealthRecordService().resetRecord(recordId);
};