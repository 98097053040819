import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Lista de problemas de sono comuns
const sleepIssues = [
  'Dificuldade para adormecer',
  'Acordar durante a noite',
  'Acordar muito cedo',
  'Sono não reparador',
  'Pesadelos frequentes',
  'Ronco',
  'Apneia do sono',
  'Outro'
];

const SleepQualityStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [sleepQuality, setSleepQuality] = useState<string | undefined>(
    userData.sleepQuality
  );
  const [sleepHours, setSleepHours] = useState<number | undefined>(
    userData.sleepHoursPerNight
  );
  const [selectedIssues, setSelectedIssues] = useState<string[]>(
    userData.sleepIssues || []
  );
  const [otherIssue, setOtherIssue] = useState<string>('');
  
  const handleIssueToggle = (issue: string) => {
    setSelectedIssues(prev => {
      if (prev.includes(issue)) {
        return prev.filter(i => i !== issue);
      } else {
        return [...prev, issue];
      }
    });
  };
  
  const handleSubmit = () => {
    // Preparar a lista de problemas de sono
    const issues = [...selectedIssues.filter(i => i !== 'Outro')];
    
    // Adicionar o problema "Outro" se selecionado e especificado
    if (selectedIssues.includes('Outro') && otherIssue.trim()) {
      issues.push(otherIssue.trim());
    }
    
    onNext({
      sleepQuality: sleepQuality as 'ruim' | 'razoavel' | 'boa' | 'excelente' | undefined,
      sleepHoursPerNight: sleepHours,
      sleepIssues: issues.length > 0 ? issues : undefined
    });
  };
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Qualidade do Sono</h3>
        <p className="text-neutral-600 mb-4">
          O sono tem um impacto significativo na saúde mental. Estas informações nos ajudarão a personalizar suas recomendações.
        </p>
      </div>
      
      <div className="space-y-6">
        <div>
          <p className="font-medium text-neutral-700 mb-2">
            Como você classificaria a qualidade geral do seu sono?
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            {[
              { value: 'ruim', label: 'Ruim' },
              { value: 'razoavel', label: 'Razoável' },
              { value: 'boa', label: 'Boa' },
              { value: 'excelente', label: 'Excelente' }
            ].map((option) => (
              <div key={option.value} className="flex items-center">
                <input
                  type="radio"
                  id={`sleep-quality-${option.value}`}
                  name="sleep-quality"
                  value={option.value}
                  checked={sleepQuality === option.value}
                  onChange={() => setSleepQuality(option.value)}
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
                />
                <label htmlFor={`sleep-quality-${option.value}`} className="ml-2 text-neutral-700">
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        
        <div>
          <label htmlFor="sleep-hours" className="block font-medium text-neutral-700 mb-2">
            Em média, quantas horas você dorme por noite?
          </label>
          <input
            type="number"
            id="sleep-hours"
            min="1"
            max="24"
            value={sleepHours || ''}
            onChange={(e) => setSleepHours(e.target.value ? parseInt(e.target.value, 10) : undefined)}
            className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
            placeholder="Horas de sono"
          />
        </div>
        
        <div>
          <p className="font-medium text-neutral-700 mb-2">
            Você experimenta algum dos seguintes problemas de sono?
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {sleepIssues.map((issue) => (
              <div key={issue} className="flex items-center">
                <input
                  type="checkbox"
                  id={`issue-${issue}`}
                  checked={selectedIssues.includes(issue)}
                  onChange={() => handleIssueToggle(issue)}
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                />
                <label htmlFor={`issue-${issue}`} className="ml-2 text-neutral-700">
                  {issue}
                </label>
              </div>
            ))}
          </div>
          
          {selectedIssues.includes('Outro') && (
            <div className="mt-3">
              <label htmlFor="other-issue" className="block text-sm font-medium text-neutral-700 mb-1">
                Especifique:
              </label>
              <input
                type="text"
                id="other-issue"
                value={otherIssue}
                onChange={(e) => setOtherIssue(e.target.value)}
                className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                placeholder="Digite o problema de sono"
              />
            </div>
          )}
        </div>
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!sleepQuality || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SleepQualityStep; 