import { OnBoardingStatus, OnBoardingUserData, MoodEntry } from '../types';
import { getOnBoardingService } from './serviceLocator';
import { addMoodEntry } from './DatabaseFacade';

// Verificar se o usuário já viu o OnBoarding
export const hasSeenOnBoarding = async (): Promise<boolean> => {
  const onBoardingService = getOnBoardingService();
  const status = await onBoardingService.getOnBoardingStatus();
  return status.hasSeenOnBoarding;
};

// Marcar o OnBoarding como visualizado
export const markOnBoardingAsSeen = async (): Promise<void> => {
  const onBoardingService = getOnBoardingService();
  await onBoardingService.markOnBoardingAsSeen();
};

// Verificar se o OnBoarding obrigatório está completo
export const isRequiredOnBoardingComplete = async (): Promise<boolean> => {
  const onBoardingService = getOnBoardingService();
  return await onBoardingService.isRequiredOnBoardingComplete();
};

// Obter os passos pendentes do OnBoarding
export const getPendingOnBoardingSteps = async (): Promise<string[]> => {
  const onBoardingService = getOnBoardingService();
  return await onBoardingService.getPendingSteps();
};

// Obter os dados do usuário do OnBoarding
export const getOnBoardingUserData = async (): Promise<OnBoardingUserData | null> => {
  const onBoardingService = getOnBoardingService();
  return await onBoardingService.getUserData();
};

// Atualizar os dados do usuário e marcar o passo como concluído
export const updateOnBoardingUserData = async (
  stepId: string,
  data: Partial<OnBoardingUserData>
): Promise<OnBoardingUserData> => {
  const onBoardingService = getOnBoardingService();
  
  // Atualizar os dados do usuário
  const updatedData = await onBoardingService.updateUserData(data);
  
  // Marcar o passo como concluído
  await onBoardingService.completeStep(stepId);
  
  // Se for o passo de humor atual, registrar como entrada de humor
  if (stepId === 'current-mood' && data.currentMood !== undefined) {
    const moodEntry: MoodEntry = {
      id: `onboarding-mood-${Date.now()}`,
      score: data.currentMood,
      note: data.moodNote || '',
      timestamp: Date.now()
    };
    
    try {
      await addMoodEntry(moodEntry);
    } catch (error) {
      console.error('Erro ao registrar humor do OnBoarding:', error);
    }
  }
  
  return updatedData;
};

// Verificar se um passo específico foi concluído
export const isOnBoardingStepCompleted = async (stepId: string): Promise<boolean> => {
  const onBoardingService = getOnBoardingService();
  return await onBoardingService.isStepCompleted(stepId);
};

// Obter o status completo do OnBoarding
export const getOnBoardingStatus = async (): Promise<OnBoardingStatus> => {
  const onBoardingService = getOnBoardingService();
  return await onBoardingService.getOnBoardingStatus();
};

// Resetar o OnBoarding (para testes)
export const resetOnBoarding = async (): Promise<void> => {
  const onBoardingService = getOnBoardingService();
  await onBoardingService.resetOnBoarding();
};

// Atualizar o email de recuperação do usuário
export const updateUserRecoveryEmail = async (
  recoveryEmail: string
): Promise<OnBoardingUserData> => {
  const onBoardingService = getOnBoardingService();
  
  // Verificar se o email é válido
  if (!recoveryEmail || !recoveryEmail.trim()) {
    throw new Error('Email de recuperação inválido');
  }
  
  // Validar formato do email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(recoveryEmail)) {
    throw new Error('Formato de email inválido');
  }
  
  try {
    console.log(`Atualizando email de recuperação para: ${recoveryEmail}`);
    
    // Atualizar apenas o campo de email de recuperação
    const updatedData = await onBoardingService.updateUserData({
      recoveryEmail: recoveryEmail.trim()
    });
    
    return updatedData;
  } catch (error) {
    console.error('Erro ao atualizar email de recuperação:', error);
    throw new Error('Não foi possível atualizar o email de recuperação. Por favor, tente novamente.');
  }
}; 