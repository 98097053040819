import { MoodEntry } from '../../types';

export class MoodService {
  private readonly apiUrl: string;
  private userId?: string;

  constructor( ) {
    this.apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
  }

  // Método para atualizar o userId
  public setUserId(userId: string): void {
    this.userId = userId;
  }

  // Método para obter o userId atual ou usar fallback
  private getUserId(): string {
    // Se não temos userId, tentamos obter do localStorage
    if (!this.userId) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        this.userId = storedUserId;
      }
    }
    
    // Se ainda não temos userId, usamos o repositório local
    return this.userId || '';
  }

  // Método para obter o token de autenticação
  private getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  async getAllEntries(): Promise<MoodEntry[]> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      const response = await fetch(`${this.apiUrl}/mood-entries`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar entradas de humor: ${response.statusText}`);
      }

      const data = await response.json();
      return data as MoodEntry[];
    } catch (error) {
      console.error('Erro ao buscar entradas de humor:', error);
      return [];
    }
  }

  async addEntry(entry: MoodEntry): Promise<void> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Preparar o body da requisição
      const requestBody = {
        score: entry.score,
        note: entry.note || ''
      };
      
      // Fazer a chamada à API
      const response = await fetch(`${this.apiUrl}/mood-entries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestBody)
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao adicionar entrada de humor: ${response.statusText}`);
      }
      
      // Se a chamada à API for bem-sucedida, não precisamos fazer nada mais
      console.log('Entrada de humor adicionada com sucesso via API');
    } catch (error) {
      console.error('Erro ao adicionar entrada de humor via API:', error);
    }
  }

  async addEntryForDate(score: number, note: string, date: Date): Promise<void> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Criar uma cópia da data para não modificar o objeto original
      const adjustedDate = new Date(date);
      
      // Preparar o body da requisição
      const requestBody = {
        score,
        note: note || '',
        timestamp: adjustedDate.getTime()
      };
      
      // Fazer a chamada à API
      const response = await fetch(`${this.apiUrl}/mood-entries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestBody)
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao adicionar entrada de humor para data específica: ${response.statusText}`);
      }
      
      console.log('Entrada de humor para data específica adicionada com sucesso via API');
    } catch (error) {
      console.error('Erro ao adicionar entrada para data específica:', error);
      throw error;
    }
  }

  async getEntriesForLastDays(days: number): Promise<MoodEntry[]> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Calcular a data de início (hoje - dias)
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - days);
      
      // Formatar as datas no formato YYYY-MM-DD
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      
      const startDateStr = formatDate(startDate);
      const endDateStr = formatDate(endDate);
      
      // Construir a URL com os parâmetros de consulta
      const url = new URL(`${this.apiUrl}/mood-entries/date-range`);
      url.searchParams.append('startDate', startDateStr);
      url.searchParams.append('endDate', endDateStr);
      
      // Fazer a chamada à API
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao buscar entradas de humor por período: ${response.statusText}`);
      }
      
      const data = await response.json();
      return data as MoodEntry[];
    } catch (error) {
      console.error('Erro ao buscar entradas de humor por período:', error);
      return [];
    }
  }

  async getAverageMoodForDay(date: Date): Promise<number> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Formatar a data no formato YYYY-MM-DD
      const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      
      const dateStr = formatDate(date);
      
      // Construir a URL com os parâmetros de consulta
      const url = new URL(`${this.apiUrl}/mood-patterns/daily-average`);
      url.searchParams.append('date', dateStr);
      
      // Fazer a chamada à API
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao buscar média de humor diária: ${response.statusText}`);
      }
      
      const data = await response.json();
      return data.average;
    } catch (error) {
      console.error('Erro ao buscar média de humor diária:', error);
      return 0;
    }
  }

  async updateEntry(entry: MoodEntry): Promise<void> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Verificar se a entrada tem todos os campos necessários
      if (!entry.id || entry.score === undefined || entry.note === undefined) {
        console.error('Entrada incompleta:', entry);
        throw new Error('Entrada incompleta para atualização');
      }
      
      // Preparar o body da requisição
      const requestBody = {
        score: entry.score,
        note: entry.note || '',
        timestamp: entry.timestamp
      };
      
      // Fazer a chamada à API
      const response = await fetch(`${this.apiUrl}/mood-entries/${entry.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestBody)
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao atualizar entrada de humor: ${response.statusText}`);
      }
      
      console.log('Entrada de humor atualizada com sucesso via API');
    } catch (error) {
      console.error('Erro ao atualizar entrada de humor:', error);
      throw error;
    }
  }

  async deleteEntry(entryId: string): Promise<void> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Verificar se o ID da entrada é válido
      if (!entryId) {
        throw new Error('ID da entrada não fornecido para exclusão');
      }
      
      // Fazer a chamada à API
      const response = await fetch(`${this.apiUrl}/mood-entries/${entryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao excluir entrada de humor: ${response.statusText}`);
      }
      
      console.log('Entrada de humor excluída com sucesso via API');
    } catch (error) {
      console.error('Erro ao excluir entrada de humor:', error);
      throw error;
    }
  }

  async hasConcerningPattern(): Promise<boolean> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar entradas de humor: token não disponível');
      throw new Error('Erro ao buscar entradas de humor: token não disponível');
    }
    
    try {
      // Definir o número de dias para análise (padrão: 7)
      const days = 7;
      
      // Construir a URL com os parâmetros de consulta
      const url = new URL(`${this.apiUrl}/mood-patterns/concerning`);
      url.searchParams.append('days', days.toString());
      
      // Fazer a chamada à API
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao verificar padrões preocupantes: ${response.statusText}`);
      }
      
      const data = await response.json();
      return data.hasConcerningPattern;
    } catch (error) {
      console.error('Erro ao verificar padrões preocupantes:', error);
      throw error;
    }
  }
} 