import React, { useState, useEffect } from 'react';
import { Save, X } from 'lucide-react';
import { MoodEntry } from '../types';
import { getMoodEmoji, getMoodText } from '../infra/utils/moodUtils';
import FocusTextarea from './FocusTextarea';
import ReactMarkdown from 'react-markdown';
import { formatDatePtBR } from '../infra/utils/dateUtils';

interface MoodEditFormProps {
  entry: MoodEntry;
  initialValues: {
    date: string;
    time: string;
    note: string;
    score: number;
  };
  onSave: (data: {
    date: string;
    time: string;
    note: string;
    score: number;
  }) => Promise<void>;
  onCancel: () => void;
  isSaving: boolean;
}

export const MoodEditForm: React.FC<MoodEditFormProps> = ({
  entry,
  initialValues,
  onSave,
  onCancel,
  isSaving
}) => {
  const [date, setDate] = useState(initialValues.date);
  const [time, setTime] = useState(initialValues.time);
  const [note, setNote] = useState(initialValues.note);
  const [score, setScore] = useState(initialValues.score);

  useEffect(() => {
    setDate(initialValues.date);
    setTime(initialValues.time);
    setNote(initialValues.note);
    setScore(initialValues.score);
  }, [initialValues]);

  const handleMoodSelect = (newScore: number) => {
    setScore(newScore);
  };

  const handleSaveClick = async () => {
    await onSave({
      date,
      time,
      note,
      score
    });
  };

  return (
    <div className="edit-form">
      <div className="flex justify-between items-center mb-3">
        <div className="flex items-center">
          <span className="text-2xl mr-3">{getMoodEmoji(score !== -1 ? score : entry.score)}</span>
          <span className="text-neutral-700 font-medium">
            {formatDatePtBR(new Date(entry.timestamp), "HH:mm")}
          </span>
        </div>
        <div className="flex space-x-2">
          <button 
            onClick={handleSaveClick}
            className="p-2.5 bg-primary-100 text-primary-600 rounded-full hover:bg-primary-200 disabled:opacity-50 disabled:cursor-not-allowed touch-manipulation"
            aria-label="Salvar edição"
            disabled={isSaving}
          >
            {isSaving ? (
              <span className="flex items-center justify-center w-5 h-5">
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-600"></span>
              </span>
            ) : (
              <Save size={20} />
            )}
          </button>
          <button 
            onClick={onCancel}
            className="p-2.5 bg-neutral-100 text-neutral-600 rounded-full hover:bg-neutral-200 disabled:opacity-50 touch-manipulation"
            aria-label="Cancelar edição"
            disabled={isSaving}
          >
            <X size={20} />
          </button>
        </div>
      </div>

      <div className="mb-4 bg-neutral-50 p-3 rounded-lg transition-all">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex items-center">
            <label htmlFor="edit-date" className="text-sm text-neutral-600 w-12 flex-shrink-0">Data:</label>
            <input
              type="date"
              id="edit-date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="bg-white border border-neutral-200 rounded px-2 py-1 text-sm text-neutral-700 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 w-32 max-w-[180px]"
            />
          </div>
          <div className="flex items-center">
            <label htmlFor="edit-time" className="text-sm text-neutral-600 w-16 flex-shrink-0">Horário:</label>
            <input
              type="time"
              id="edit-time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="bg-white border border-neutral-200 rounded px-2 py-1 text-sm text-neutral-700 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 w-24"
            />
          </div>
        </div>
      </div>
      
      <div className="mb-4 bg-neutral-50 p-3 rounded-lg transition-all">
        <div className="text-sm text-neutral-600 mb-2">Como você estava se sentindo?</div>
        <div className="flex justify-between items-center">
          {[0, 1, 2, 3, 4, 5].map((moodScore) => (
            <button
              type="button"
              key={moodScore}
              className={`emoji-btn-small ${score === moodScore ? 'emoji-btn-selected' : ''}`}
              onClick={() => handleMoodSelect(moodScore)}
              aria-label={`Humor: ${getMoodText(moodScore)}`}
            >
              {getMoodEmoji(moodScore)}
            </button>
          ))}
        </div>
        {score !== -1 && (
          <p className="text-center text-neutral-600 text-xs mt-1">
            {getMoodText(score)}
          </p>
        )}
      </div>
      
      <FocusTextarea 
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="input mb-3 min-h-[80px] resize-none"
        placeholder="Como você está se sentindo?"
        autoFocus={false}
      />
      
      {entry.ai_analysis && (
        <div className="bg-neutral-50 p-3 rounded-lg text-sm text-neutral-700">
          <ReactMarkdown>{entry.ai_analysis}</ReactMarkdown>
        </div>
      )}
    </div>
  );
}; 