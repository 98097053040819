import React, { ReactNode, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Home, BarChart2, User, PlusCircle, LogOut, Calendar, BookOpen, Brain, MessageSquare, MoreHorizontal } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import GoogleAdsense from './GoogleAdsense';
import { adsenseConfig } from '../config/adsenseConfig';
import { useOnBoardingStore } from '../stores/onBoardingStore';
import { formatHashedEmail } from './utils/formatters';
import ChatWidget from './ChatAI/ChatWidget';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, signOut, currentPlan } = useAuth();
  const [showRegisterOptions, setShowRegisterOptions] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const isOnBoardingActive = useOnBoardingStore(state => state.isModalOpen);
  
  // Adicionar evento de clique no documento para fechar os menus
  useEffect(() => {
    const handleClickOutside = () => {
      setShowRegisterOptions(false);
      setShowMoreOptions(false);
    };
    
    if (showRegisterOptions || showMoreOptions) {
      document.addEventListener('click', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showRegisterOptions, showMoreOptions]);
  
  const isActive = (path: string) => {
    return location.pathname === path;
  };
  
  const shouldShowNavigation = !['/', '/login'].includes(location.pathname);
  
  // Verificar se deve mostrar a navegação inferior
  // Não mostrar quando o OnBoarding estiver ativo ou nas páginas de login/inicial
  const shouldShowBottomNavigation = shouldShowNavigation && !isOnBoardingActive;
  
  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };
  
  // Adicionar classe ao container principal quando o OnBoarding estiver ativo
  // para garantir que o conteúdo não fique escondido atrás da navegação
  const mainContainerClass = isOnBoardingActive 
    ? "flex-1 w-full mx-auto px-4 pb-8 lg:container-responsive" 
    : "flex-1 w-full mx-auto px-4 pb-20 lg:pb-8 lg:container-responsive";
  
  return (
    <>
      <div className="min-h-screen flex flex-col bg-neutral-50">
        {/* Header para todas as resoluções - Logo sempre visível */}
        {shouldShowNavigation && (
          <header className="bg-white border-b border-neutral-200 py-3 sticky top-0 z-10 w-full">
            <div className="container-responsive flex justify-between items-center w-full">
              <Link to="/home" className="text-xl font-semibold text-primary-600 flex items-center">
                <span className="bg-primary-100 p-2 rounded-full mr-2">
                  <Brain className="h-5 w-5 text-primary-500" />
                </span>
                Meu Humor
              </Link>
              
              {/* Navegação apenas visível em telas >= 1024px */}
              <nav className="hidden lg:flex items-center space-x-6">
                <Link 
                  to="/home" 
                  className={`flex items-center ${
                    isActive('/home') ? 'text-primary-600' : 'text-neutral-600 hover:text-primary-500'
                  }`}
                >
                  <Home size={20} className="mr-1" />
                  <span>Início</span>
                </Link>
                
                <Link 
                  to="/historico" 
                  className={`flex items-center ${
                    isActive('/historico') ? 'text-primary-600' : 'text-neutral-600 hover:text-primary-500'
                  }`}
                >
                  <BarChart2 size={20} className="mr-1" />
                  <span>Histórico</span>
                </Link>
                
                <Link 
                  to="/conteudos" 
                  className={`flex items-center ${
                    isActive('/conteudos') ? 'text-primary-600' : 'text-neutral-600 hover:text-primary-500'
                  }`}
                >
                  <BookOpen size={20} className="mr-1" />
                  <span>Conteúdos</span>
                </Link>
                
                <Link 
                  to="/chat/sessions" 
                  className={`flex items-center ${
                    location.pathname.startsWith('/chat') ? 'text-primary-600' : 'text-neutral-600 hover:text-primary-500'
                  }`}
                >
                  <MessageSquare size={20} className="mr-1" />
                  <span>Assistente IA</span>
                </Link>
                
                <div className="relative group">
                  <button 
                    className="flex items-center text-neutral-600 hover:text-primary-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRegisterOptions(!showRegisterOptions);
                    }}
                  >
                    <PlusCircle size={20} className="mr-1" />
                    <span>Registrar</span>
                  </button>
                  
                  {showRegisterOptions && (
                    <div className="absolute top-full right-0 mt-2 bg-white rounded-lg shadow-lg border border-neutral-200 w-48 overflow-hidden z-50">
                      <div 
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRegisterOptions(false);
                          setTimeout(() => navigate('/registrar'), 10);
                        }}
                      >
                        <PlusCircle size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Humor agora</span>
                      </div>
                      <div 
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRegisterOptions(false);
                          setTimeout(() => navigate('/registrar-lote'), 10);
                        }}
                      >
                        <Calendar size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Humores passados</span>
                      </div>
                    </div>
                  )}
                </div>
                
                <div className="relative group">
                  <Link 
                    to="/perfil" 
                    className={`flex items-center ${
                      isActive('/perfil') ? 'text-primary-600' : 'text-neutral-600 hover:text-primary-500'
                    }`}
                  >
                    <User size={20} className="mr-1" />
                    <span>{formatHashedEmail(user?.email)}</span>
                  </Link>
                </div>
              </nav>
            </div>
          </header>
        )}

        {/* Header mobile para a página de perfil */}
        {location.pathname === '/perfil' && user && (
          <header className="lg:hidden bg-white border-b border-neutral-200 py-4">
            <div className="max-w-md mx-auto px-4 flex justify-between items-center">
              <div>
                <h1 className="font-medium">Perfil</h1>
                <p className="text-sm text-neutral-500 truncate max-w-[200px]">{formatHashedEmail(user.email)}</p>
              </div>
              <button 
                onClick={handleLogout}
                className="flex items-center gap-1 text-red-500 text-sm"
              >
                <LogOut size={16} />
                <span>Sair</span>
              </button>
            </div>
          </header>
        )}
        
        {/* Header Ad - Somente exibido para usuários com plano basic */}
        {currentPlan === 'basic' && (
          <div className="w-full max-w-md lg:max-w-none mx-auto px-4 my-3">
            <GoogleAdsense 
              client={adsenseConfig.client}
              slot={adsenseConfig.slots.header}
              style={{ display: 'block', textAlign: 'center' }}
              className="mb-4"
            />
          </div>
        )}
        
        {/* Main content - Ajustando padding quando o OnBoarding estiver ativo */}
        <main className={mainContainerClass}>
          {children}
          
          {/* In-content Ad */}
          {currentPlan === 'basic' && (
            <div className="my-6">
              <GoogleAdsense 
                client={adsenseConfig.client}
                slot={adsenseConfig.slots.inContent}
                style={{ display: 'block', textAlign: 'center' }}
              />
            </div>
          )}
        </main>

        {/* Chat Widget - sempre presente mas só visível quando ativado */}
        {user && !location.pathname.startsWith('/chat') && (
          <ChatWidget />
        )}

        {/* Navegação mobile - Escondida em resoluções >= 1024px e quando o OnBoarding está ativo */}
        {shouldShowBottomNavigation && (
          <nav className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-neutral-200 py-2 z-20 shadow-md">
            <div className="max-w-md mx-auto px-4">
              <div className="flex justify-around items-center">
                <Link 
                  to="/home" 
                  className={`flex flex-col items-center p-2 ${
                    isActive('/home') ? 'text-primary-600' : 'text-neutral-500'
                  }`}
                >
                  <Home size={24} />
                  <span className="text-xs mt-1">Início</span>
                </Link>
                
                <Link 
                  to="/historico" 
                  className={`flex flex-col items-center p-2 ${
                    isActive('/historico') ? 'text-primary-600' : 'text-neutral-500'
                  }`}
                >
                  <BarChart2 size={24} />
                  <span className="text-xs mt-1">Histórico</span>
                </Link>
                
                <div className="relative">
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowRegisterOptions(!showRegisterOptions);
                    }}
                    className="flex flex-col items-center p-2 -mt-5"
                  >
                    <div className="bg-primary-500 text-white rounded-full p-3 shadow-md">
                      <PlusCircle size={24} />
                    </div>
                    <span className="text-xs mt-1 text-primary-600">Registrar</span>
                  </button>
                  
                  {/* Menu de opções de registro */}
                  {showRegisterOptions && (
                    <div className="absolute bottom-full mb-2 bg-white rounded-lg shadow-lg border border-neutral-200 w-48 overflow-hidden z-50">
                      <div 
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRegisterOptions(false);
                          setTimeout(() => navigate('/registrar'), 10);
                        }}
                      >
                        <PlusCircle size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Humor agora</span>
                      </div>
                      <div 
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRegisterOptions(false);
                          setTimeout(() => navigate('/registrar-lote'), 10);
                        }}
                      >
                        <Calendar size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Humores passados</span>
                      </div>
                    </div>
                  )}
                </div>
                
                <Link 
                  to="/conteudos" 
                  className={`flex flex-col items-center p-2 ${
                    isActive('/conteudos') ? 'text-primary-600' : 'text-neutral-500'
                  }`}
                >
                  <BookOpen size={24} />
                  <span className="text-xs mt-1">Conteúdos</span>
                </Link>
                
                {/* Novo botão de reticências para o menu de mais opções */}
                <div className="relative">
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowMoreOptions(!showMoreOptions);
                    }}
                    className={`flex flex-col items-center p-2 ${
                      (isActive('/perfil') || location.pathname.startsWith('/chat')) ? 'text-primary-600' : 'text-neutral-500'
                    }`}
                  >
                    <MoreHorizontal size={24} />
                    <span className="text-xs mt-1">Mais</span>
                  </button>
                  
                  {/* Submenu para Perfil e Assistente IA */}
                  {showMoreOptions && (
                    <div className="absolute bottom-full mb-2 right-0 bg-white rounded-lg shadow-lg border border-neutral-200 w-48 overflow-hidden z-50">
                      <Link 
                        to="/chat/sessions"
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMoreOptions(false);
                        }}
                      >
                        <MessageSquare size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Assistente IA</span>
                      </Link>
                      <Link 
                        to="/perfil"
                        className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowMoreOptions(false);
                        }}
                      >
                        <User size={18} className="mr-2 text-primary-500" />
                        <span className="text-sm">Perfil</span>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </nav>
        )}
      </div>
    </>
  );
};

export default Layout;