import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, MessageCircle, Brain } from 'lucide-react';
import { useChat } from '../../contexts/ChatContext';

/**
 * Botão flutuante para acessar o assistente de IA
 * Posicionado no canto da tela, adaptado para desktop e mobile
 */
const FloatingChatButton: React.FC = () => {
  const { openChat } = useChat();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [tooltipDismissed, setTooltipDismissed] = useState(false);
  const tooltipTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const pressTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const navigate = useNavigate();
  
  // Verificar se é a primeira vez que o usuário vê o botão
  useEffect(() => {
    const hasSeenChatTooltip = localStorage.getItem('hasSeenChatTooltip') === 'true';
    
    if (!hasSeenChatTooltip && !tooltipDismissed) {
      // Mostrar o tooltip após 2 segundos
      tooltipTimeout.current = setTimeout(() => {
        setShowTooltip(true);
        // Ocultar o tooltip após 8 segundos
        tooltipTimeout.current = setTimeout(() => {
          setShowTooltip(false);
          localStorage.setItem('hasSeenChatTooltip', 'true');
        }, 8000);
      }, 2000);
    }
    
    return () => {
      if (tooltipTimeout.current) {
        clearTimeout(tooltipTimeout.current);
      }
    };
  }, [tooltipDismissed]);
  
  const handleTooltipDismiss = () => {
    setShowTooltip(false);
    setTooltipDismissed(true);
    localStorage.setItem('hasSeenChatTooltip', 'true');
    
    if (tooltipTimeout.current) {
      clearTimeout(tooltipTimeout.current);
    }
  };
  
  const handleButtonPress = () => {
    // Iniciar timer quando o botão é pressionado
    pressTimer.current = setTimeout(() => {
      setShowOptions(true);
    }, 600); // 600ms para considerar pressionar e segurar
  };
  
  const handleButtonRelease = () => {
    // Limpar timer se o botão for solto antes do tempo
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
  };
  
  const handleButtonClick = () => {
    // Se as opções não estiverem mostrando, abre o chat
    // Se estiverem, apenas fecha as opções
    if (!showOptions) {
      openChat();
    } else {
      setShowOptions(false);
    }
  };
  
  const handleViewSessions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowOptions(false);
    navigate('/chat/sessions');
  };
  
  return (
    <div className="fixed right-4 bottom-24 z-40 lg:right-8 lg:bottom-8">
      {/* Tooltip para novos usuários */}
      {showTooltip && (
        <div className="absolute bottom-full mb-3 right-0 bg-white p-3 rounded-lg shadow-lg border border-neutral-200 w-64 animate-fade-in">
          <button 
            onClick={handleTooltipDismiss}
            className="absolute top-2 right-2 text-neutral-400 hover:text-neutral-600"
            aria-label="Fechar dica"
          >
            <X size={16} />
          </button>
          <p className="text-sm text-neutral-700 mb-2">
            <strong>Assistente IA</strong>
          </p>
          <p className="text-xs text-neutral-600">
            Clique aqui para conversar com o assistente. Ele pode ajudar a analisar seu humor e dar sugestões personalizadas.
          </p>
        </div>
      )}

      {/* Menu de opções quando pressionar e segurar */}
      {showOptions && (
        <div className="absolute bottom-full mb-3 right-0 bg-white rounded-lg shadow-lg border border-neutral-200 w-48 overflow-hidden animate-fade-in">
          <button 
            onClick={handleViewSessions}
            className="flex items-center p-3 w-full text-left hover:bg-neutral-50 text-neutral-700"
          >
            <MessageCircle size={18} className="mr-2 text-primary-500" />
            <span className="text-sm">Ver todas as conversas</span>
          </button>
        </div>
      )}

      {/* Botão flutuante */}
      <button
        onClick={handleButtonClick}
        onMouseDown={handleButtonPress}
        onMouseUp={handleButtonRelease}
        onTouchStart={handleButtonPress}
        onTouchEnd={handleButtonRelease}
        onMouseLeave={handleButtonRelease}
        className="bg-primary-500 text-white rounded-full p-3 shadow-lg hover:bg-primary-600 transition-all hover:scale-105 active:scale-95"
        aria-label="Abrir assistente IA"
      >
        <div className="relative">
          <Brain size={24} />
          {/* Pulsação ao redor do ícone */}
          <span className="absolute -inset-1 rounded-full animate-ping bg-primary-300 opacity-75"></span>
        </div>
      </button>
    </div>
  );
};

export default FloatingChatButton; 