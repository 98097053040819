// IDs de preço do Stripe para cada plano
// Estes devem ser os IDs de preço criados no dashboard do Stripe
export const STRIPE_PRICE_IDS = {
  essencial: import.meta.env.VITE_STRIPE_PRICE_ESSENCIAL || 'price_essencial',
  premium: import.meta.env.VITE_STRIPE_PRICE_PREMIUM || 'price_premium',
};

// URL base do backend
const BACKEND_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000';

interface CheckoutOptions {
  priceId: string;
  userId: string;
}

/**
 * Redireciona para o checkout do Stripe para o plano especificado
 * Esta implementação usa o backend para criar a sessão de checkout
 */
export async function redirectToCheckout({ 
  priceId,
  userId
}: CheckoutOptions): Promise<{ error?: string }> {
  try {
    console.log(`Criando sessão de checkout com ID de preço: ${priceId} para usuário: ${userId}`);
    
    // Chamar o endpoint do backend para criar a sessão de checkout
    const response = await fetch(`${BACKEND_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        userId
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Erro ao criar sessão de checkout');
    }

    const { url } = await response.json();
    
    // Redirecionar para a URL de checkout retornada pelo backend
    window.location.href = url;
    
    return {};
  } catch (error) {
    console.error('Erro ao redirecionar para checkout:', error);
    return { 
      error: error instanceof Error 
        ? error.message 
        : 'Ocorreu um erro ao processar o pagamento' 
    };
  }
}

/**
 * Handles subscription management for existing customers
 * Note: This requires a backend implementation to work properly
 */
export async function manageSubscription(): Promise<{ error?: string }> {
  try {
    // Solução temporária: redirecionar para a página de planos
    // Em produção, você precisará implementar um endpoint de backend para criar
    // sessões do portal do cliente Stripe
    alert('Gerenciamento de assinatura ainda não implementado. Entre em contato com o suporte.');
    window.location.href = '/planos';
    return {};
  } catch (error) {
    console.error('Erro ao gerenciar assinatura:', error);
    return { 
      error: error instanceof Error 
        ? error.message 
        : 'Ocorreu um erro ao acessar o portal de assinatura' 
    };
  }
}
