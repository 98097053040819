import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Componente que rola a página para o topo quando há uma mudança de rota.
 * Este componente deve ser colocado dentro do Router no App.tsx.
 */
const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Quando o pathname mudar, role para o topo da página
    window.scrollTo({
      top: 0,
      behavior: 'instant' // Comportamento instantâneo para evitar animações indesejadas
    });
  }, [pathname]);

  return null; // Este componente não renderiza nada
};

export default ScrollToTop; 