import React, { useEffect, useState } from 'react';
import { MessageSquare, ArrowLeft } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChatWidget from '../components/ChatAI/ChatWidget';
import { useChat } from '../contexts/ChatContext';

const ChatPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setCurrentSession } = useChat();
  const [sessionIdFromUrl, setSessionIdFromUrl] = useState<string | null>(null);
  
  // Lê os parâmetros da URL e configura a sessão correta
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('sessionId');
    
    // Se o sessionId mudou, então atualizamos o estado local para forçar uma recarga
    if (sessionId !== sessionIdFromUrl) {
      setSessionIdFromUrl(sessionId);
      
      if (sessionId) {
        // Se temos um ID de sessão, configuramos para exibir essa sessão específica
        // e adicionamos um timestamp para garantir que mesmo se o ID não mudar,
        // o contexto perceberá que é uma "nova" configuração
        console.log(`Configurando nova sessão: ${sessionId}`);
        setCurrentSession(sessionId);
      }
    }
    
    // Removida a chamada para openChat() pois isso causava a duplicação
    // do chat na interface quando renderizado na página dedicada
  }, [location.search, sessionIdFromUrl, setCurrentSession]);
  
  return (
    <div className="container-responsive py-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center">
          <button 
            onClick={() => navigate('/chat/sessions')}
            className="mr-4 p-2 rounded-full hover:bg-neutral-100 transition-colors"
            aria-label="Voltar para lista de sessões"
          >
            <ArrowLeft className="h-5 w-5 text-neutral-600" />
          </button>
          <h1 className="section-title mb-0">Conversa com Assistente</h1>
        </div>
        <div className="bg-primary-100 p-2 md:p-3 rounded-full">
          <MessageSquare className="h-5 w-5 md:h-6 md:w-6 text-primary-500" />
        </div>
      </div>
      
      <div className="card p-0 overflow-hidden h-[calc(100vh-200px)]">
        {/* Renderiza o ChatWidget em tela cheia */}
        <div className="h-full">
          <ChatWidget 
            fullscreen={true}
            key={sessionIdFromUrl || 'new'} // Forçar recriação do componente quando a sessão muda
          />
        </div>
      </div>
    </div>
  );
};

export default ChatPage; 