import { parseISO, setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * Cria um timestamp UTC a partir de uma data (YYYY-MM-DD) e hora (HH:mm)
 * @param dateStr - String de data no formato YYYY-MM-DD
 * @param timeStr - String de hora no formato HH:mm
 * @returns timestamp em milissegundos (UTC)
 */
export function createTimestampFromDateAndTime(dateStr: string, timeStr: string): number {
  // Parse da hora HH:mm
  const [hours, minutes] = timeStr.split(':').map(Number);
  
  // Cria uma data a partir da string YYYY-MM-DD
  let date = parseISO(dateStr);
  
  // Aplica horas e minutos, zerando segundos e milissegundos
  date = setHours(date, hours);
  date = setMinutes(date, minutes);
  date = setSeconds(date, 0);
  date = setMilliseconds(date, 0);
  
  // Retorna o timestamp UTC em milissegundos
  return date.getTime();
}

/**
 * Formata uma data para exibição em português do Brasil
 * @param date - Data a ser formatada
 * @param formatStr - String de formato do date-fns
 * @returns string formatada
 */
export function formatDatePtBR(date: Date | number, formatStr: string): string {
  return format(date, formatStr, { locale: ptBR });
}

/**
 * Extrai data (YYYY-MM-DD) e hora (HH:mm) de um timestamp
 * @param timestamp - Timestamp em milissegundos
 * @returns objeto com data e hora
 */
export function extractDateAndTimeFromTimestamp(timestamp: number): {
  date: string;
  time: string;
} {
  const date = new Date(timestamp);
  return {
    date: format(date, 'yyyy-MM-dd'),
    time: format(date, 'HH:mm')
  };
}

/**
 * Obtém o horário atual do cliente no formato HH:mm
 * @returns string no formato HH:mm
 */
export function getCurrentClientTime(): string {
  const now = new Date();
  return format(now, 'HH:mm');
}

/**
 * Formata uma data para exibição completa em português
 * Ex: "Segunda-feira, 12 de julho"
 * @param date - Data a ser formatada
 * @returns string formatada
 */
export function formatFullDatePtBR(date: Date | number): string {
  return formatDatePtBR(date, "EEEE, d 'de' MMMM");
}

/**
 * Formata uma data para exibição curta em português
 * Ex: "12 de julho, 2023"
 * @param date - Data a ser formatada
 * @returns string formatada
 */
export function formatShortDatePtBR(date: Date | number): string {
  return formatDatePtBR(date, "d 'de' MMMM, yyyy");
} 