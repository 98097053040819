import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Lista de tipos de tratamento comuns
const treatmentTypes = [
  'Terapia psicológica',
  'Medicação psiquiátrica',
  'Terapia em grupo',
  'Meditação/Mindfulness',
  'Exercícios físicos',
  'Terapias alternativas',
  'Outro'
];

const CurrentTreatmentStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [isReceivingTreatment, setIsReceivingTreatment] = useState<boolean | undefined>(
    userData.isReceivingTreatment
  );
  const [selectedTreatments, setSelectedTreatments] = useState<string[]>(
    userData.treatmentDetails?.split(',').map(item => item.trim()) || []
  );
  const [otherTreatment, setOtherTreatment] = useState<string>('');
  const [medications, setMedications] = useState<string>(
    userData.psychiatricMedications || ''
  );
  
  const handleTreatmentToggle = (treatment: string) => {
    setSelectedTreatments(prev => {
      if (prev.includes(treatment)) {
        return prev.filter(t => t !== treatment);
      } else {
        return [...prev, treatment];
      }
    });
  };
  
  const handleSubmit = () => {
    // Preparar os detalhes dos tratamentos
    let treatmentDetails = selectedTreatments.filter(t => t !== 'Outro').join(', ');
    
    // Adicionar o tratamento "Outro" se selecionado e especificado
    if (selectedTreatments.includes('Outro') && otherTreatment.trim()) {
      treatmentDetails += treatmentDetails ? `, ${otherTreatment.trim()}` : otherTreatment.trim();
    }
    
    onNext({
      isReceivingTreatment: isReceivingTreatment === true,
      treatmentDetails: treatmentDetails || undefined,
      psychiatricMedications: selectedTreatments.includes('Medicação psiquiátrica') ? medications.trim() : undefined
    });
  };
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Tratamento Atual</h3>
        <p className="text-neutral-600 mb-4">
          Estas informações nos ajudarão a personalizar sua experiência. Todas as informações são confidenciais.
        </p>
      </div>
      
      <div className="space-y-4">
        <div>
          <p className="font-medium text-neutral-700 mb-2">
            Você está atualmente recebendo algum tipo de tratamento para saúde mental?
          </p>
          <div className="flex space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="receiving-treatment-yes"
                name="receiving-treatment"
                checked={isReceivingTreatment === true}
                onChange={() => setIsReceivingTreatment(true)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="receiving-treatment-yes" className="ml-2 text-neutral-700">
                Sim
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="receiving-treatment-no"
                name="receiving-treatment"
                checked={isReceivingTreatment === false}
                onChange={() => setIsReceivingTreatment(false)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="receiving-treatment-no" className="ml-2 text-neutral-700">
                Não
              </label>
            </div>
          </div>
        </div>
        
        {isReceivingTreatment && (
          <div className="mt-4">
            <p className="font-medium text-neutral-700 mb-2">
              Selecione os tipos de tratamento que você está recebendo:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {treatmentTypes.map((treatment) => (
                <div key={treatment} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`treatment-${treatment}`}
                    checked={selectedTreatments.includes(treatment)}
                    onChange={() => handleTreatmentToggle(treatment)}
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                  />
                  <label htmlFor={`treatment-${treatment}`} className="ml-2 text-neutral-700">
                    {treatment}
                  </label>
                </div>
              ))}
            </div>
            
            {selectedTreatments.includes('Medicação psiquiátrica') && (
              <div className="mt-3">
                <label htmlFor="medications" className="block text-sm font-medium text-neutral-700 mb-1">
                  Quais medicamentos você está utilizando?
                </label>
                <textarea
                  id="medications"
                  value={medications}
                  onChange={(e) => setMedications(e.target.value)}
                  className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Ex: Fluoxetina 20mg, Clonazepam 0.5mg, etc."
                  rows={3}
                />
                <p className="mt-1 text-xs text-neutral-500">
                  Estas informações são confidenciais e nos ajudarão a personalizar sua experiência.
                </p>
              </div>
            )}
            
            {selectedTreatments.includes('Outro') && (
              <div className="mt-3">
                <label htmlFor="other-treatment" className="block text-sm font-medium text-neutral-700 mb-1">
                  Especifique:
                </label>
                <input
                  type="text"
                  id="other-treatment"
                  value={otherTreatment}
                  onChange={(e) => setOtherTreatment(e.target.value)}
                  className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Digite o tipo de tratamento"
                />
              </div>
            )}
          </div>
        )}
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="ghost"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={(isReceivingTreatment === undefined) || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentTreatmentStep; 