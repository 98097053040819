import { Category } from '../types';
import { Brain, Frown, Users, Utensils, Target } from 'lucide-react';

export const mentalHealthCategories: Category[] = [
  {
    id: 'depressao',
    title: 'Depressão',
    description: 'Informações sobre diferentes tipos de depressão, sintomas, tratamentos e estratégias de enfrentamento.',
    icon: 'Frown',
    subCategories: [
      {
        id: 'depressao-maior',
        title: 'Depressão Maior',
        description: 'Transtorno depressivo maior, seus sintomas, diagnóstico e tratamentos.',
        diseaseCode: {
          code: 'F32',
          description: 'Episódio depressivo',
          dsm5Criteria: [
            'Humor deprimido na maior parte do dia',
            'Interesse ou prazer acentuadamente diminuídos',
            'Perda ou ganho significativo de peso',
            'Insônia ou hipersonia',
            'Fadiga ou perda de energia',
            'Sentimentos de inutilidade ou culpa excessiva',
            'Capacidade diminuída de pensar ou concentrar-se',
            'Pensamentos recorrentes de morte ou suicídio',
            'Agitação ou retardo psicomotor'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F32',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'entendendo-depressao-maior',
            title: 'Entendendo a Depressão Maior',
            content: 'A depressão maior é caracterizada por humor persistentemente deprimido e perda de interesse em atividades que normalmente seriam prazerosas. Este transtorno afeta significativamente o funcionamento diário e a qualidade de vida. Os sintomas incluem alterações no sono, apetite, energia, concentração, e autoestima, além de pensamentos de morte ou suicídio. O diagnóstico requer a presença de pelo menos cinco destes sintomas por um período mínimo de duas semanas. A prevalência é alta, afetando cerca de 1 em 6 pessoas ao longo da vida. Causas incluem fatores genéticos, biológicos e ambientais. O tratamento envolve psicoterapia, como a terapia cognitivo-comportamental (TCC), e medicamentos antidepressivos, como os inibidores seletivos de recaptação de serotonina (ISRS). Estratégias de autocuidado, como exercícios físicos regulares e uma rotina de sono adequada, também são fundamentais. Um exercício prático útil é o diário de gratidão: reserve cinco minutos diários para anotar três coisas pelas quais você é grato e explique por que elas são significativas. Isso pode ajudar a focar em aspectos positivos e melhorar o humor ao longo do tempo.',
            diseaseCode: 'F32.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão', 'sintomas', 'diagnóstico', 'tratamento'],
            references: ['DSM-5', 'ICD-11', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'tratamento-depressao-maior',
            title: 'Tratamento da Depressão Maior',
            content: 'O tratamento da depressão maior pode incluir psicoterapia, medicamentos ou uma combinação de ambos. A terapia cognitivo-comportamental (TCC) é uma das abordagens mais eficazes, ajudando os pacientes a identificar e modificar padrões de pensamento negativos. Medicamentos antidepressivos, como os ISRS, são frequentemente prescritos para ajudar a equilibrar os neurotransmissores no cérebro. Em casos graves, a terapia eletroconvulsiva (ECT) pode ser considerada. É importante que o tratamento seja personalizado para atender às necessidades individuais do paciente, e o suporte familiar e social também desempenha um papel crucial na recuperação. Um exercício prático recomendado é o agendamento de atividades prazerosas: planeje uma atividade agradável por dia, mesmo que seja algo pequeno, como ler um livro ou caminhar, para ajudar a restaurar o prazer e a motivação.',
            diseaseCode: 'F32.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão', 'tratamento', 'TCC', 'medicamentos'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'autocuidado-depressao-maior',
            title: 'Estratégias de Autocuidado para Depressão Maior',
            content: 'O autocuidado é essencial no manejo da depressão maior, complementando tratamentos profissionais. Práticas como exercícios físicos regulares, uma alimentação equilibrada e um sono consistente podem aliviar sintomas. Técnicas de relaxamento, como meditação e respiração profunda, também ajudam a reduzir o estresse. Um exercício prático é a caminhada consciente: caminhe por 20 minutos ao ar livre, prestando atenção aos sons, cheiros e sensações ao seu redor, para melhorar o humor e a energia.',
            diseaseCode: 'F32.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão', 'autocuidado', 'exercício'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'impacto-depressao-trabalho',
            title: 'Impacto da Depressão Maior no Trabalho',
            content: 'A depressão maior pode afetar o desempenho profissional, causando dificuldades de concentração, fadiga e baixa motivação. Estratégias como estabelecer metas realistas, pedir apoio no trabalho e usar pausas para relaxamento podem ajudar. Um exercício prático é o planejamento diário: no início do dia, liste três tarefas prioritárias e divida-as em etapas menores, celebrando cada conclusão para manter a motivação.',
            diseaseCode: 'F32.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão', 'trabalho', 'produtividade'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['ansiedade', 'burnout']
      },
      {
        id: 'depressao-sazonal',
        title: 'Depressão Sazonal',
        description: 'Depressão que ocorre em padrões sazonais específicos.',
        diseaseCode: {
          code: 'F33',
          description: 'Transtorno depressivo recorrente, episódio atual leve',
          dsm5Criteria: [
            'Episódios recorrentes de depressão',
            'Padrão sazonal de ocorrência',
            'Remissão completa entre os episódios',
            'Predominância em meses com menos luz solar',
            'Sintomas atípicos como hipersonia e aumento do apetite'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F33',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm04',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'padroes-sazonais-depressao',
            title: 'Padrões Sazonais na Depressão',
            content: 'A depressão sazonal, também conhecida como Transtorno Afetivo Sazonal (TAS), é um tipo de depressão que ocorre em padrões sazonais específicos, geralmente durante os meses de outono e inverno quando há menos luz solar. Os sintomas incluem letargia, aumento do sono, ganho de peso e isolamento social. O tratamento pode incluir terapia de luz, psicoterapia e medicamentos antidepressivos. A suplementação de vitamina D também pode ser benéfica em alguns casos. A terapia de luz é particularmente eficaz, envolvendo a exposição diária a uma luz brilhante que imita a luz natural do sol. Um exercício prático é a caminhada matinal: exponha-se à luz natural pela manhã para ajudar a regular o relógio biológico e melhorar o humor. Comece com 10 minutos e aumente gradualmente.',
            diseaseCode: 'F33',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão sazonal', 'TAS', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'terapia-luz-depressao-sazonal',
            title: 'Terapia de Luz para Depressão Sazonal',
            content: 'A terapia de luz é um tratamento eficaz para a depressão sazonal, ajudando a compensar a redução da luz solar nos meses mais escuros. Envolve a exposição diária a uma luz de 10.000 lux por 20-30 minutos, geralmente pela manhã. Um exercício prático é incorporar a terapia de luz à rotina: use a caixa de luz enquanto lê ou toma café da manhã, mantendo uma distância de 30-60 cm, para regular o ritmo circadiano e melhorar o humor.',
            diseaseCode: 'F33',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão sazonal', 'terapia de luz', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'autocuidado-inverno-depressao-sazonal',
            title: 'Autocuidado no Inverno para Depressão Sazonal',
            content: 'Durante o inverno, o autocuidado pode ajudar a minimizar os sintomas da depressão sazonal. Atividades como manter uma rotina regular, praticar exercícios leves em casa e socializar, mesmo que virtualmente, são úteis. Um exercício prático é a "hora do sol": reserve 15 minutos diários para ficar perto de uma janela ensolarada ou sair ao ar livre, mesmo em dias frios, para absorver luz natural e aumentar os níveis de energia.',
            diseaseCode: 'F33',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão sazonal', 'autocuidado', 'inverno'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['ansiedade']
      },
      {
        id: 'depressao-pos-parto',
        title: 'Depressão Pós-Parto',
        description: 'Depressão que ocorre após o parto, afetando mães e, em alguns casos, pais.',
        diseaseCode: {
          code: 'F53.0',
          description: 'Transtornos mentais e comportamentais associados ao puerpério',
          dsm5Criteria: [
            'Início dos sintomas dentro de quatro semanas após o parto',
            'Humor deprimido ou perda de interesse ou prazer',
            'Alterações no sono e apetite',
            'Dificuldade em cuidar do bebê',
            'Sentimentos de inadequação ou culpa'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F53.0',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm04',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'desafios-tratamentos-depressao-pos-parto',
            title: 'Desafios e Tratamentos da Depressão Pós-Parto',
            content: 'A depressão pós-parto é um transtorno comum que afeta cerca de 1 em 7 mulheres após o parto. Os sintomas podem incluir tristeza profunda, ansiedade, irritabilidade e dificuldade em cuidar do bebê. O tratamento pode envolver psicoterapia, como a terapia interpessoal, e medicamentos antidepressivos seguros para uso durante a amamentação. O suporte familiar e grupos de apoio também são fundamentais para a recuperação. É importante diferenciar a depressão pós-parto do "baby blues", que é mais leve e temporário. Um exercício prático é o estabelecimento de uma rede de apoio: liste pessoas de confiança e atribua tarefas específicas, como ajudar com o bebê ou ouvir suas preocupações, para reduzir o isolamento e o estresse.',
            diseaseCode: 'F53.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão pós-parto', 'maternidade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'suporte-familiar-depressao-pos-parto',
            title: 'Suporte Familiar na Depressão Pós-Parto',
            content: 'O suporte familiar desempenha um papel vital na recuperação da depressão pós-parto, oferecendo apoio emocional e prático. Familiares podem ajudar com tarefas diárias ou simplesmente ouvir sem julgar. Um exercício prático é a "escuta ativa": peça a um familiar que ouça por 5 minutos enquanto você expressa seus sentimentos, sem interrupções, para aliviar a carga emocional e fortalecer os laços.',
            diseaseCode: 'F53.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão pós-parto', 'suporte', 'família'],
            references: ['Postpartum Support International', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'autocuidado-pos-parto',
            title: 'Autocuidado Após o Parto',
            content: 'O autocuidado é crucial para mães com depressão pós-parto, ajudando a restaurar a energia e o bem-estar. Pequenas ações, como tirar cochilos curtos, manter uma alimentação saudável e praticar respirações profundas, podem fazer diferença. Um exercício prático é o "momento para si": reserve 10 minutos diários para uma atividade relaxante, como tomar um chá ou ler, para recarregar as energias.',
            diseaseCode: 'F53.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão pós-parto', 'autocuidado', 'maternidade'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'depressao']
      },
      {
        id: 'distimia',
        title: 'Distimia',
        description: 'Transtorno depressivo persistente, caracterizado por humor deprimido crônico.',
        diseaseCode: {
          code: 'F34.1',
          description: 'Transtorno depressivo persistente (distimia)',
          dsm5Criteria: [
            'Humor deprimido na maior parte do dia, por mais dias do que não, por pelo menos dois anos',
            'Presença de dois ou mais dos seguintes: apetite alterado, sono alterado, baixa energia, baixa autoestima, dificuldade de concentração, sentimentos de desesperança',
            'Ausência de episódios maníacos ou hipomaníacos',
            'Sintomas causam sofrimento clinicamente significativo ou prejuízo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F34.1',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm04',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'vivendo-com-distimia',
            title: 'Vivendo com Distimia: Estratégias de Longo Prazo',
            content: 'A distimia, ou transtorno depressivo persistente, é caracterizada por um humor deprimido que dura pelo menos dois anos. Embora os sintomas sejam menos graves do que na depressão maior, a natureza crônica pode ser igualmente debilitante. O tratamento geralmente envolve uma combinação de psicoterapia e medicamentos. A terapia cognitivo-comportamental (TCC) pode ajudar a modificar padrões de pensamento negativos, enquanto os antidepressivos podem aliviar os sintomas. Estratégias de autocuidado, como exercício regular e uma dieta equilibrada, também são importantes para o manejo a longo prazo. Um exercício prático é a prática de mindfulness: reserve 10 minutos diários para meditar, focando na respiração e observando pensamentos sem julgamento, para reduzir a ruminação e melhorar o bem-estar emocional.',
            diseaseCode: 'F34.1',
            isPremium: true,
            requiredPlan: 'professional',
            tags: ['distimia', 'depressão crônica', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'relacoes-distimia',
            title: 'Distimia e Relações Interpessoais',
            content: 'A distimia pode dificultar as relações interpessoais devido à baixa energia e ao humor persistentemente deprimido. Estratégias como comunicar abertamente suas necessidades e buscar apoio podem fortalecer os vínculos. Um exercício prático é a "conversa planejada": agende um momento semanal com alguém de confiança para compartilhar como se sente, promovendo compreensão mútua e reduzindo o isolamento.',
            diseaseCode: 'F34.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['distimia', 'relacionamentos', 'suporte'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'rotina-distimia',
            title: 'Construindo uma Rotina com Distimia',
            content: 'Uma rotina estruturada pode ajudar a gerenciar a distimia, oferecendo estabilidade e propósito. Incluir atividades simples, como caminhadas ou hobbies, pode melhorar o humor ao longo do tempo. Um exercício prático é o "plano diário": crie uma lista com três tarefas pequenas para o dia, como tomar café da manhã ou arrumar a cama, e marque cada uma ao concluir para sentir progresso.',
            diseaseCode: 'F34.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['distimia', 'rotina', 'autocuidado'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['depressao', 'ansiedade']
      },
      {
        id: 'depressao-bipolar',
        title: 'Depressão Bipolar',
        description: 'Episódios depressivos no contexto do transtorno bipolar.',
        diseaseCode: {
          code: 'F31',
          description: 'Transtorno afetivo bipolar',
          dsm5Criteria: [
            'Episódios de mania ou hipomania',
            'Episódios depressivos',
            'Alterações no humor, energia e atividade',
            'Comprometimento significativo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F31',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm03',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'gerenciando-depressao-bipolar',
            title: 'Gerenciando a Depressão Bipolar',
            content: 'A depressão bipolar ocorre no transtorno bipolar, caracterizado por alternância entre episódios de mania/hipomania e depressão. Os sintomas depressivos incluem tristeza profunda, fadiga e perda de interesse, enquanto o tratamento exige cuidado para evitar induzir mania. Estabilizadores de humor (ex.: lítio) e psicoterapia, como a terapia cognitivo-comportamental (TCC), são eficazes. Um exercício prático é o monitoramento de humor: diariamente, classifique seu humor de 1 a 10 e anote eventos ou pensamentos que o influenciaram. Revise semanalmente para identificar padrões e gatilhos, e compartilhe com seu terapeuta para ajustar o tratamento.',
            diseaseCode: 'F31',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão bipolar', 'tratamento', 'monitoramento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'medicamentos-depressao-bipolar',
            title: 'Medicamentos para Depressão Bipolar',
            content: 'O tratamento medicamentoso da depressão bipolar requer equilíbrio para evitar episódios maníacos. Estabilizadores de humor, como lítio, e antipsicóticos atípicos são comumente usados. Um exercício prático é o "diário de medicação": anote diariamente os medicamentos tomados, horários e efeitos colaterais, revisando com seu médico para ajustes personalizados.',
            diseaseCode: 'F31',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão bipolar', 'medicamentos', 'tratamento'],
            references: ['NIMH', 'APA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'estabilidade-depressao-bipolar',
            title: 'Mantendo Estabilidade na Depressão Bipolar',
            content: 'Manter a estabilidade no transtorno bipolar envolve uma rotina consistente e estratégias para lidar com oscilações de humor. Sono regular, exercícios e apoio social são fundamentais. Um exercício prático é o "check-in diário": ao final do dia, avalie seu humor, sono e energia em uma escala de 1 a 5, ajustando atividades conforme necessário.',
            diseaseCode: 'F31',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão bipolar', 'estabilidade', 'autocuidado'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['depressao', 'ansiedade']
      },
      {
        id: 'depressao-psicotica',
        title: 'Depressão Psicótica',
        description: 'Depressão maior acompanhada de sintomas psicóticos, como delírios ou alucinações.',
        diseaseCode: {
          code: 'F32.3',
          description: 'Episódio depressivo grave com sintomas psicóticos',
          dsm5Criteria: [
            'Sintomas de depressão maior',
            'Presença de delírios ou alucinações',
            'Comprometimento significativo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F32.3',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm04',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'entendendo-depressao-psicotica',
            title: 'Entendendo a Depressão Psicótica',
            content: 'A depressão psicótica é uma forma grave de depressão maior que inclui sintomas psicóticos, como delírios (crenças falsas) ou alucinações (percepções irreais). Os delírios podem ser de culpa, ruína ou doença. O tratamento geralmente envolve uma combinação de antidepressivos e antipsicóticos, e, em alguns casos, terapia eletroconvulsiva (ECT). A psicoterapia pode ajudar a lidar com os sintomas depressivos, mas deve ser adaptada para não reforçar os delírios. Um exercício prático é a verificação da realidade: quando surgirem pensamentos delirantes, anote-os e, com a ajuda de um terapeuta, avalie evidências que os confirmem ou refutem, para gradualmente desafiar essas crenças.',
            diseaseCode: 'F32.3',
            isPremium: true,
            requiredPlan: 'professional',
            tags: ['depressão psicótica', 'tratamento', 'psicose'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '9'
          },
          {
            id: 'psicoterapia-depressao-psicotica',
            title: 'Psicoterapia para Depressão Psicótica',
            content: 'A psicoterapia na depressão psicótica exige adaptações para lidar com sintomas psicóticos sem reforçar delírios. A terapia cognitivo-comportamental (TCC) pode focar em reduzir a ansiedade e desafiar crenças distorcidas. Um exercício prático é a "lista de evidências": anote um pensamento delirante e liste fatos que o contradigam, revisando com um terapeuta para construir uma visão mais realista.',
            diseaseCode: 'F32.3',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['depressão psicótica', 'psicoterapia', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'suporte-depressao-psicotica',
            title: 'Suporte Social na Depressão Psicótica',
            content: 'O suporte social é essencial na depressão psicótica, ajudando a reduzir o isolamento e oferecer segurança. Familiares e amigos podem aprender a reconhecer sintomas e incentivar o tratamento. Um exercício prático é o "plano de apoio": crie uma lista de contatos confiáveis e defina como eles podem ajudar em momentos de crise, como acompanhar a consultas.',
            diseaseCode: 'F32.3',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['depressão psicótica', 'suporte', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['depressao', 'psicose']
      }
    ]
  },
  {
    id: 'ansiedade',
    title: 'Transtornos de Ansiedade',
    description: 'Diferentes tipos de transtornos de ansiedade, seus sintomas e tratamentos.',
    icon: 'Brain',
    subCategories: [
      {
        id: 'tag',
        title: 'Transtorno de Ansiedade Generalizada',
        description: 'TAG: sintomas, diagnóstico e estratégias de tratamento.',
        diseaseCode: {
          code: 'F41.1',
          description: 'Transtorno de ansiedade generalizada',
          dsm5Criteria: [
            'Ansiedade e preocupação excessivas na maioria dos dias',
            'Dificuldade em controlar a preocupação',
            'Inquietação ou sensação de estar com os nervos à flor da pele',
            'Fadiga fácil',
            'Dificuldade de concentração',
            'Irritabilidade',
            'Tensão muscular',
            'Perturbação do sono'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F41.1',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm05',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'compreendendo-tag',
            title: 'Compreendendo o TAG',
            content: 'O Transtorno de Ansiedade Generalizada é caracterizado por preocupação excessiva e persistente sobre várias áreas da vida cotidiana. Esta preocupação é difícil de controlar e vem acompanhada de sintomas físicos como tensão muscular, fadiga, irritabilidade, dificuldade de concentração e problemas de sono. Para ser diagnosticado, os sintomas devem estar presentes na maioria dos dias por pelo menos seis meses e causar sofrimento significativo ou prejuízo funcional. O tratamento pode incluir terapia cognitivo-comportamental (TCC), técnicas de relaxamento e medicamentos ansiolíticos ou antidepressivos. Um exercício prático é a respiração diafragmática: sente-se confortavelmente, inspire pelo nariz por 4 segundos expandindo o abdômen, e expire pela boca por 6 segundos. Repita por 5-10 minutos para reduzir a ansiedade física.',
            diseaseCode: 'F41.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['ansiedade', 'TAG', 'tratamento'],
            references: ['DSM-5', 'ICD-11', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'tecnicas-relaxamento-tag',
            title: 'Técnicas de Relaxamento para TAG',
            content: 'Técnicas de relaxamento são fundamentais para reduzir os sintomas físicos e mentais do TAG. Além da respiração diafragmática, práticas como yoga e relaxamento muscular progressivo podem ajudar. Um exercício prático é o relaxamento muscular: tensione e relaxe cada grupo muscular por 5 segundos, começando pelos pés e subindo até o rosto, para aliviar a tensão acumulada.',
            diseaseCode: 'F41.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['ansiedade', 'relaxamento', 'TAG'],
            references: ['NIMH', 'APA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'gerenciando-preocupacao-tag',
            title: 'Gerenciando a Preocupação no TAG',
            content: 'Controlar a preocupação excessiva é um desafio central no TAG. A TCC pode ensinar a identificar pensamentos irracionais e substituí-los por ideias realistas. Um exercício prático é o "tempo de preocupação": reserve 15 minutos diários para anotar todas as preocupações, adiando-as para esse momento, para evitar que dominem o dia.',
            diseaseCode: 'F41.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['ansiedade', 'preocupação', 'TAG'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['depressao', 'panico']
      },
      {
        id: 'transtorno-panico',
        title: 'Transtorno de Pânico',
        description: 'Ataques de pânico recorrentes e inesperados, seguidos de preocupação persistente com novos ataques.',
        diseaseCode: {
          code: 'F41.0',
          description: 'Transtorno de pânico',
          dsm5Criteria: [
            'Ataques de pânico recorrentes e inesperados',
            'Preocupação persistente com a ocorrência de novos ataques',
            'Mudança significativa no comportamento relacionada aos ataques',
            'Ausência de uso de substâncias ou condições médicas que expliquem os sintomas'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F41.0',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm05',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'gerenciando-ataques-panico',
            title: 'Gerenciando Ataques de Pânico',
            content: 'Os ataques de pânico são episódios súbitos de medo intenso que podem incluir palpitações, sudorese, tremores e sensação de falta de ar. No transtorno de pânico, esses ataques são recorrentes e acompanhados de preocupação constante com a possibilidade de novos ataques. O tratamento pode incluir terapia cognitivo-comportamental (TCC), que ajuda a identificar e modificar pensamentos catastróficos, e medicamentos como os ISRS ou benzodiazepínicos. Técnicas de respiração e relaxamento também são úteis para controlar os sintomas durante um ataque. Um exercício prático é a técnica 5-4-3-2-1: durante um ataque, identifique 5 coisas que vê, 4 que toca, 3 que ouve, 2 que cheira e 1 que saboreia, para se ancorar no presente.',
            diseaseCode: 'F41.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['pânico', 'ansiedade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'prevencao-ataques-panico',
            title: 'Prevenção de Ataques de Pânico',
            content: 'Prevenir ataques de pânico envolve reconhecer gatilhos como estresse ou cafeína e adotar estratégias de manejo, como exercícios regulares e sono adequado. Um exercício prático é o "diário de gatilhos": anote situações que precedem os ataques e planeje formas de evitá-las ou enfrentá-las, como respirar fundo antes de situações estressantes.',
            diseaseCode: 'F41.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['pânico', 'prevenção', 'ansiedade'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'recuperacao-pos-panico',
            title: 'Recuperação Após Ataques de Pânico',
            content: 'Após um ataque de pânico, é importante se recuperar física e emocionalmente. Técnicas de relaxamento e autocuidado ajudam a restaurar o equilíbrio. Um exercício prático é a "pausa restauradora": após um ataque, sente-se em um lugar calmo, beba água lentamente e respire fundo por 5 minutos para acalmar o corpo.',
            diseaseCode: 'F41.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['pânico', 'recuperação', 'autocuidado'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'tag']
      },
      {
        id: 'fobia-social',
        title: 'Fobia Social',
        description: 'Medo intenso e persistente de situações sociais ou de desempenho.',
        diseaseCode: {
          code: 'F40.1',
          description: 'Fobia social',
          dsm5Criteria: [
            'Medo ou ansiedade acentuados sobre uma ou mais situações sociais',
            'Evitação das situações sociais ou suportadas com medo ou ansiedade intensos',
            'O medo ou ansiedade é desproporcional ao perigo real',
            'Duração de seis meses ou mais',
            'Causa sofrimento clinicamente significativo ou prejuízo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F40.1',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm05',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'superando-fobia-social',
            title: 'Superando a Fobia Social',
            content: 'A fobia social, ou transtorno de ansiedade social, é caracterizada por um medo intenso de ser julgado ou avaliado negativamente em situações sociais. Isso pode levar à evitação de interações sociais, impactando significativamente a vida pessoal e profissional. O tratamento mais eficaz é a terapia cognitivo-comportamental (TCC), que inclui técnicas de exposição gradual às situações temidas e reestruturação cognitiva para desafiar crenças irracionais. Medicamentos como os ISRS também podem ser prescritos para ajudar a reduzir a ansiedade. Um exercício prático é a exposição gradual: comece com pequenas interações, como cumprimentar um vizinho, e aumente progressivamente para situações mais desafiadoras, como falar em público, registrando seu nível de ansiedade e progresso.',
            diseaseCode: 'F40.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['fobia social', 'ansiedade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'exposicao-gradual-fobia-social',
            title: 'Exposição Gradual para Fobia Social',
            content: 'A exposição gradual é uma técnica eficaz para enfrentar o medo de situações sociais. Comece com interações simples e avance para cenários mais complexos, monitorando a ansiedade. Um exercício prático é a "escada de exposição": liste 10 situações sociais temidas, ordene-as do menos ao mais assustador e enfrente uma por vez, usando respiração lenta para se acalmar.',
            diseaseCode: 'F40.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['fobia social', 'exposição', 'ansiedade'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'confianca-fobia-social',
            title: 'Construindo Confiança na Fobia Social',
            content: 'Aumentar a confiança social é essencial para superar a fobia social. Pequenos sucessos em interações podem reforçar a autoestima. Um exercício prático é o "desafio diário": escolha uma interação simples por dia, como perguntar algo a um colega, e anote como se sentiu após, celebrando cada passo dado.',
            diseaseCode: 'F40.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['fobia social', 'confiança', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'personalidade']
      },
      {
        id: 'tept',
        title: 'Transtorno de Estresse Pós-Traumático',
        description: 'Resposta a eventos traumáticos com sintomas de revivência e hipervigilância.',
        diseaseCode: {
          code: 'F43.1',
          description: 'Transtorno de estresse pós-traumático',
          dsm5Criteria: [
            'Exposição a evento traumático',
            'Sintomas de revivência (flashbacks, pesadelos)',
            'Evitação de estímulos relacionados ao trauma',
            'Alterações negativas no humor e cognição',
            'Hipervigilância ou resposta de sobressalto exagerada'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F43.1',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm07',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'tratamento-tept',
            title: 'Tratamento do TEPT',
            content: 'O TEPT surge após traumas, com sintomas como flashbacks, evitação e hipervigilância. Terapias como exposição e EMDR (Dessensibilização e Reprocessamento por Movimentos Oculares), além de inibidores seletivos de recaptação de serotonina (ISRS), são recomendadas. Um exercício prático é a técnica de grounding: durante um flashback, identifique 5 coisas que vê, 4 que toca, 3 que ouve, 2 que cheira e 1 que saboreia, para se ancorar no presente e reduzir a intensidade dos sintomas.',
            diseaseCode: 'F43.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TEPT', 'trauma', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'emdr-tept',
            title: 'EMDR para TEPT',
            content: 'O EMDR é uma terapia eficaz para o TEPT, ajudando a processar memórias traumáticas por meio de movimentos oculares bilaterais. É conduzida por profissionais treinados e pode reduzir a carga emocional dos traumas. Um exercício prático complementar é a "caixa segura": visualize uma caixa onde guarda memórias difíceis, fechando-a mentalmente até a próxima sessão de terapia.',
            diseaseCode: 'F43.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TEPT', 'EMDR', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'autocuidado-tept',
            title: 'Autocuidado no TEPT',
            content: 'O autocuidado no TEPT ajuda a gerenciar sintomas como hipervigilância e ansiedade. Técnicas como meditação e atividades prazerosas podem promover bem-estar. Um exercício prático é o "espaço seguro": crie mentalmente um lugar tranquilo e seguro, descrevendo-o em detalhes, e visite-o em momentos de estresse para se acalmar.',
            diseaseCode: 'F43.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TEPT', 'autocuidado', 'trauma'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'depressao']
      },
      {
        id: 'fobias-especificas',
        title: 'Fobias Específicas',
        description: 'Medo intenso e irracional de objetos ou situações específicas.',
        diseaseCode: {
          code: 'F40.2',
          description: 'Fobias específicas',
          dsm5Criteria: [
            'Medo ou ansiedade acentuados sobre um objeto ou situação específica',
            'Evitação do objeto ou situação fóbica',
            'O medo ou ansiedade é desproporcional ao perigo real',
            'Duração de seis meses ou mais',
            'Causa sofrimento clinicamente significativo ou prejuízo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F40.2',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm05',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'superando-fobias-especificas',
            title: 'Superando Fobias Específicas',
            content: 'Fobias específicas são caracterizadas por um medo intenso e irracional de objetos ou situações, como alturas, animais ou voar. O tratamento mais eficaz é a terapia de exposição, que envolve enfrentar gradualmente o objeto ou situação temida em um ambiente controlado. A terapia cognitivo-comportamental (TCC) também pode ajudar a desafiar crenças irracionais sobre o objeto fóbico. Um exercício prático é a hierarquia de exposição: liste situações relacionadas à sua fobia em ordem de intensidade, comece pela menos assustadora e avance gradualmente, praticando técnicas de relaxamento para gerenciar a ansiedade.',
            diseaseCode: 'F40.2',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['fobias', 'ansiedade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'terapia-exposicao-fobias',
            title: 'Terapia de Exposição para Fobias Específicas',
            content: 'A terapia de exposição ajuda a reduzir o medo enfrentando-o gradualmente. Por exemplo, para uma fobia de cães, comece olhando fotos e avance até interagir com um cão. Um exercício prático é a "exposição controlada": passe 5 minutos diários enfrentando um nível baixo da sua fobia, como ver um vídeo, e aumente o desafio semanalmente.',
            diseaseCode: 'F40.2',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['fobias', 'exposição', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'enfrentando-medo-fobias',
            title: 'Enfrentando o Medo nas Fobias Específicas',
            content: 'Enfrentar o medo é essencial para superar fobias específicas. Além da exposição, técnicas cognitivas ajudam a mudar percepções. Um exercício prático é o "diálogo com o medo": escreva o que sua fobia "diz" (ex.: "cobras são perigosas") e responda com fatos (ex.: "a maioria não é venenosa"), revisando diariamente.',
            diseaseCode: 'F40.2',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['fobias', 'medo', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade']
      }
    ]
  },
  {
    id: 'personalidade',
    title: 'Transtornos de Personalidade',
    description: 'Informações sobre diferentes transtornos de personalidade e suas características.',
    icon: 'Users',
    subCategories: [
      {
        id: 'borderline',
        title: 'Transtorno de Personalidade Borderline',
        description: 'TPB: características, diagnóstico e abordagens terapêuticas.',
        diseaseCode: {
          code: 'F60.3',
          description: 'Transtorno de personalidade emocionalmente instável',
          dsm5Criteria: [
            'Esforços desesperados para evitar abandono real ou imaginado',
            'Padrão de relacionamentos interpessoais instáveis e intensos',
            'Perturbação da identidade: autoimagem ou sentido de self acentuada e persistentemente instável',
            'Impulsividade em pelo menos duas áreas potencialmente autodestrutivas',
            'Comportamento, gestos ou ameaças suicidas recorrentes ou comportamento automutilante',
            'Instabilidade afetiva devida a uma acentuada reatividade do humor',
            'Sentimentos crônicos de vazio',
            'Raiva inadequada e intensa ou dificuldade em controlá-la',
            'Ideação paranóide transitiva relacionada a estresse ou sintomas dissociativos graves'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F60.3',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm18',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'visao-geral-tpb',
            title: 'Visão Geral do TPB',
            content: 'O Transtorno de Personalidade Borderline é caracterizado por instabilidade significativa nos relacionamentos interpessoais, autoimagem e afetos, além de impulsividade marcante. Pessoas com TPB frequentemente apresentam medo intenso de abandono, relacionamentos instáveis, identidade perturbada, comportamentos autodestrutivos, sentimentos crônicos de vazio e dificuldade em regular emoções. A terapia comportamental dialética (TCD) tem se mostrado particularmente eficaz no tratamento deste transtorno, ajudando os pacientes a desenvolver habilidades de regulação emocional e tolerância ao sofrimento. Um exercício prático é a técnica STOP: quando sentir uma emoção intensa, pare (Stop), observe (Take a step back), pense (Observe), e prossiga (Proceed mindfully) para evitar reações impulsivas.',
            diseaseCode: 'F60.3',
            isPremium: true,
            requiredPlan: 'professional',
            tags: ['borderline', 'TPB', 'personalidade'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '10'
          },
          {
            id: 'regulacao-emocional-tpb',
            title: 'Regulação Emocional no TPB',
            content: 'A regulação emocional é um desafio central no TPB. A TCD oferece técnicas como "ação oposta", onde se age contra o impulso emocional, como sorrir quando triste. Um exercício prático é a "onda emocional": observe uma emoção intensa sem reagir por 5 minutos, deixando-a passar como uma onda, para reduzir impulsividade.',
            diseaseCode: 'F60.3',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['borderline', 'emoções', 'TCD'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'relacionamentos-tpb',
            title: 'Relacionamentos e TPB',
            content: 'Os relacionamentos no TPB podem ser intensos e instáveis devido ao medo de abandono. Melhorar a comunicação e estabelecer limites saudáveis são passos importantes. Um exercício prático é o "diálogo estruturado": pratique expressar uma necessidade a um ente querido em 3 frases curtas, promovendo clareza e conexão.',
            diseaseCode: 'F60.3',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['borderline', 'relacionamentos', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['ansiedade', 'depressao']
      },
      {
        id: 'narcisista',
        title: 'Transtorno de Personalidade Narcisista',
        description: 'Caracterizado por grandiosidade, necessidade de admiração e falta de empatia.',
        diseaseCode: {
          code: 'F60.8',
          description: 'Outros transtornos específicos da personalidade',
          dsm5Criteria: [
            'Sentimento grandioso de importância',
            'Fantasias de sucesso ilimitado, poder, brilho, beleza ou amor ideal',
            'Crença de ser especial e único',
            'Necessidade de admiração excessiva',
            'Sentimento de merecimento',
            'Exploração interpessoal',
            'Falta de empatia',
            'Inveja dos outros ou crença de que os outros o invejam',
            'Comportamentos ou atitudes arrogantes ou soberbas'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F60.8',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm18',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'tratamento-narcisista',
            title: 'Tratando o Transtorno de Personalidade Narcisista',
            content: 'O tratamento do Transtorno de Personalidade Narcisista é desafiador devido à falta de insight e à resistência ao tratamento. Abordagens psicoterapêuticas, como a terapia psicodinâmica e a terapia cognitivo-comportamental, podem ajudar a explorar as vulnerabilidades subjacentes e a desenvolver empatia. O foco está em melhorar as relações interpessoais e reduzir comportamentos manipulativos. Medicamentos podem ser usados para tratar sintomas comórbidos, como depressão ou ansiedade. Um exercício prático é a prática de empatia: diariamente, tente se colocar no lugar de outra pessoa, imaginando como ela se sente em uma situação específica, para desenvolver a capacidade de compreender emoções alheias.',
            diseaseCode: 'F60.8',
            isPremium: true,
            requiredPlan: 'professional',
            tags: ['narcisismo', 'personalidade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '9'
          },
          {
            id: 'empatia-narcisista',
            title: 'Desenvolvendo Empatia no Transtorno Narcisista',
            content: 'A falta de empatia é uma característica marcante do transtorno narcisista. Exercícios de perspectiva podem ajudar a melhorar essa habilidade. Um exercício prático é o "jogo da perspectiva": escolha uma pessoa e escreva como ela pode se sentir em uma situação recente, revisando com um terapeuta para aprofundar a compreensão.',
            diseaseCode: 'F60.8',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['narcisismo', 'empatia', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'relacoes-narcisista',
            title: 'Relações e o Transtorno Narcisista',
            content: 'As relações no transtorno narcisista podem ser marcadas por exploração e falta de reciprocidade. Melhorar a escuta ativa e reduzir a autocomiseração são passos úteis. Um exercício prático é a "escuta consciente": durante uma conversa, foque apenas em ouvir por 2 minutos sem interromper, anotando o que aprendeu sobre o outro.',
            diseaseCode: 'F60.8',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['narcisismo', 'relacionamentos', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['personalidade', 'depressao']
      },
      {
        id: 'esquiva',
        title: 'Transtorno de Personalidade Esquiva',
        description: 'Caracterizado por inibição social, sentimentos de inadequação e hipersensibilidade à rejeição.',
        diseaseCode: {
          code: 'F60.6',
          description: 'Transtorno de personalidade ansiosa (esquiva)',
          dsm5Criteria: [
            'Evitação de atividades ocupacionais que envolvam contato interpessoal significativo',
            'Relutância em se envolver com pessoas a menos que tenha certeza de ser aceito',
            'Restrição em relacionamentos íntimos por medo de ser envergonhado ou ridicularizado',
            'Preocupação com críticas ou rejeição em situações sociais',
            'Inibição em novas situações interpessoais',
            'Visão de si mesmo como socialmente inepto, pessoalmente desinteressante ou inferior aos outros',
            'Relutância em assumir riscos pessoais ou se engajar em novas atividades'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F60.6',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm18',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'diferenciando-esquiva-ansiedade-social',
            title: 'Diferenciando Esquiva e Ansiedade Social',
            content: 'Embora o Transtorno de Personalidade Esquiva e a Fobia Social compartilhem sintomas de ansiedade em situações sociais, eles diferem em alguns aspectos. O transtorno de personalidade esquiva é mais pervasivo e envolve uma visão negativa de si mesmo e dos outros, enquanto a fobia social é mais focada no medo de situações específicas. O tratamento para o transtorno esquivo pode incluir terapia cognitivo-comportamental para desafiar crenças negativas e técnicas de exposição para aumentar a confiança social. Um exercício prático é a afirmação positiva: diariamente, escreva e repita três qualidades positivas sobre si mesmo para melhorar a autoestima e reduzir a autocrítica.',
            diseaseCode: 'F60.6',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['esquiva', 'personalidade', 'ansiedade social'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'confianca-social-esquiva',
            title: 'Construindo Confiança Social no Transtorno Esquivo',
            content: 'Aumentar a confiança social é essencial para superar a inibição do transtorno esquivo. Pequenas interações diárias podem ajudar. Um exercício prático é o "desafio social": inicie uma conversa curta por dia, como perguntar as horas, e anote seu progresso para reforçar a autoconfiança.',
            diseaseCode: 'F60.6',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['esquiva', 'confiança', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'superando-rejeicao-esquiva',
            title: 'Superando o Medo de Rejeição',
            content: 'O medo de rejeição no transtorno esquivo pode ser enfrentado com exposição gradual e reestruturação cognitiva. Um exercício prático é o "teste de rejeição": peça algo pequeno que possa ser recusado (ex.: um desconto em uma loja) e observe que a rejeição não define seu valor.',
            diseaseCode: 'F60.6',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['esquiva', 'rejeição', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'personalidade']
      },
      {
        id: 'obsessivo-compulsiva',
        title: 'Transtorno de Personalidade Obsessivo-Compulsiva',
        description: 'Caracterizado por perfeccionismo, rigidez e necessidade de controle.',
        diseaseCode: {
          code: 'F60.5',
          description: 'Transtorno de personalidade obsessivo-compulsiva',
          dsm5Criteria: [
            'Preocupação com detalhes, regras, listas, ordem, organização ou horários',
            'Perfeccionismo que interfere na conclusão de tarefas',
            'Dedicação excessiva ao trabalho e produtividade',
            'Inflexibilidade sobre moralidade, ética ou valores',
            'Incapacidade de descartar objetos sem valor',
            'Relutância em delegar tarefas ou trabalhar em equipe',
            'Rigidez e teimosia'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F60.5',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm18',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'gerenciando-ocpd',
            title: 'Gerenciando o Transtorno de Personalidade Obsessivo-Compulsiva',
            content: 'O Transtorno de Personalidade Obsessivo-Compulsiva é marcado por perfeccionismo extremo, rigidez e necessidade de controle, o que pode levar a dificuldades interpessoais e ineficiência. O tratamento pode incluir psicoterapia focada na flexibilidade cognitiva e comportamental, ajudando a pessoa a aceitar imperfeições e delegar tarefas. Um exercício prático é a prática de aceitação de imperfeições: defina uma tarefa com um padrão "bom o suficiente" em vez de perfeito, e observe como isso afeta sua ansiedade e produtividade. Reflita sobre os benefícios de ser mais flexível.',
            diseaseCode: 'F60.5',
            isPremium: true,
            requiredPlan: 'professional',
            tags: ['OCPD', 'personalidade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'flexibilidade-ocpd',
            title: 'Desenvolvendo Flexibilidade no OCPD',
            content: 'A rigidez no OCPD pode ser reduzida com práticas que promovem flexibilidade. Um exercício prático é o "experimento de mudança": por um dia, altere uma rotina fixa (ex.: deixe a cama desarrumada) e anote como se sentiu, explorando os benefícios da adaptação.',
            diseaseCode: 'F60.5',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['OCPD', 'flexibilidade', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'trabalho-ocpd',
            title: 'OCPD e Produtividade no Trabalho',
            content: 'O perfeccionismo no OCPD pode prejudicar a produtividade. Aprender a priorizar e delegar é essencial. Um exercício prático é o "limite de tempo": defina um prazo curto para uma tarefa e conclua-a sem revisões excessivas, avaliando o resultado para aceitar imperfeições.',
            diseaseCode: 'F60.5',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['OCPD', 'trabalho', 'produtividade'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['personalidade', 'ansiedade']
      },
      {
        id: 'dependente',
        title: 'Transtorno de Personalidade Dependente',
        description: 'Caracterizado por necessidade excessiva de cuidado e medo de separação.',
        diseaseCode: {
          code: 'F60.7',
          description: 'Transtorno de personalidade dependente',
          dsm5Criteria: [
            'Dificuldade em tomar decisões cotidianas sem aconselhamento excessivo',
            'Necessidade de que outros assumam a responsabilidade por áreas importantes da vida',
            'Dificuldade em expressar desacordo por medo de perder apoio',
            'Dificuldade em iniciar projetos ou fazer coisas por conta própria',
            'Ir a extremos para obter cuidado e apoio',
            'Sentir-se desconfortável ou desamparado quando sozinho',
            'Buscar urgentemente outro relacionamento quando um termina'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F60.7',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm18',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'construindo-independencia-dependente',
            title: 'Construindo Independência no Transtorno de Personalidade Dependente',
            content: 'O Transtorno de Personalidade Dependente é caracterizado por uma necessidade excessiva de ser cuidado, levando a comportamentos submissos e medo de separação. O tratamento pode incluir psicoterapia para desenvolver autonomia e assertividade, ajudando a pessoa a tomar decisões e expressar suas necessidades. Um exercício prático é a tomada de decisões pequenas: comece tomando decisões diárias simples, como escolher o que comer ou vestir, e gradualmente avance para decisões mais significativas, registrando seu progresso e sentimentos.',
            diseaseCode: 'F60.7',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['dependência', 'personalidade', 'tratamento'],
            references: ['DSM-5', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'assertividade-dependente',
            title: 'Desenvolvendo Assertividade no Transtorno Dependente',
            content: 'A assertividade ajuda a superar a submissão no transtorno dependente. Praticar a expressão de opiniões é um passo importante. Um exercício prático é o "treino de não": diga "não" a um pedido simples uma vez por dia, anotando como se sentiu, para construir confiança na autonomia.',
            diseaseCode: 'F60.7',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['dependência', 'assertividade', 'tratamento'],
            references: ['APA', 'NIMH'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'autoestima-dependente',
            title: 'Fortalecendo a Autoestima no Transtorno Dependente',
            content: 'A baixa autoestima no transtorno dependente pode ser melhorada com práticas de autovalorização. Um exercício prático é o "diário de conquistas": anote uma conquista diária, por menor que seja, como cozinhar algo novo, para reconhecer seu valor independentemente dos outros.',
            diseaseCode: 'F60.7',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['dependência', 'autoestima', 'tratamento'],
            references: ['NIMH', 'Mayo Clinic'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['personalidade', 'ansiedade']
      }
    ]
  },
  {
    id: 'alimentares',
    title: 'Transtornos Alimentares',
    description: 'Diferentes tipos de transtornos alimentares, sintomas e tratamentos.',
    icon: 'Utensils',
    subCategories: [
      {
        id: 'anorexia',
        title: 'Anorexia Nervosa',
        description: 'Características, riscos e tratamento da anorexia nervosa.',
        diseaseCode: {
          code: 'F50.0',
          description: 'Anorexia nervosa',
          dsm5Criteria: [
            'Restrição da ingesta calórica em relação às necessidades',
            'Medo intenso de ganhar peso ou de engordar',
            'Perturbação no modo como o próprio peso ou a forma corporal são vivenciados',
            'Influência indevida do peso ou da forma corporal na autoavaliação',
            'Ausência de reconhecimento da gravidade do baixo peso corporal atual'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F50.0',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm10',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'tratamento-anorexia',
            title: 'Tratamento da Anorexia',
            content: 'O tratamento da anorexia nervosa requer uma abordagem multidisciplinar que aborda tanto os aspectos físicos quanto psicológicos do transtorno. A equipe de tratamento geralmente inclui médicos, nutricionistas, psicólogos e psiquiatras. As intervenções podem incluir restauração do peso, psicoterapia (como terapia cognitivo-comportamental ou terapia familiar), educação nutricional e, em alguns casos, medicação. A hospitalização pode ser necessária para casos graves com complicações médicas significativas. O prognóstico melhora com intervenção precoce e suporte contínuo. Um exercício prático é a aceitação corporal: liste três funções do seu corpo que você aprecia (ex.: "Meus olhos me permitem ver o mundo") e escreva uma carta de gratidão ao seu corpo, lendo-a em voz alta semanalmente para reforçar uma visão positiva.',
            diseaseCode: 'F50.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['anorexia', 'tratamento', 'alimentação'],
            references: ['DSM-5', 'ICD-11', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '9'
          },
          {
            id: 'nutricao-anorexia',
            title: 'Nutrição e Recuperação na Anorexia',
            content: 'A nutrição é essencial na recuperação da anorexia, ajudando a restaurar o peso e a saúde. Um plano alimentar estruturado com um nutricionista pode promover uma relação saudável com a comida. Um exercício prático é o "desafio alimentar": experimente um alimento temido por semana, anotando sentimentos e progressos para discutir em terapia.',
            diseaseCode: 'F50.0',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['anorexia', 'nutrição', 'recuperação'],
            references: ['NEDA', 'APA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'autoimagem-anorexia',
            title: 'Melhorando a Autoimagem na Anorexia',
            content: 'A distorção da autoimagem é comum na anorexia. Técnicas de TCC ajudam a desafiar pensamentos negativos sobre o corpo. Um exercício prático é o "espelho positivo": olhe-se no espelho por 2 minutos, focando em algo que aprecia, como seus olhos, para construir uma percepção mais saudável.',
            diseaseCode: 'F50.0',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['anorexia', 'autoimagem', 'tratamento'],
            references: ['NIMH', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'depressao']
      },
      {
        id: 'bulimia',
        title: 'Bulimia Nervosa',
        description: 'Caracterizada por episódios de compulsão alimentar seguidos de comportamentos compensatórios.',
        diseaseCode: {
          code: 'F50.2',
          description: 'Bulimia nervosa',
          dsm5Criteria: [
            'Episódios recorrentes de compulsão alimentar',
            'Comportamentos compensatórios recorrentes para prevenir ganho de peso',
            'Autoavaliação indevidamente influenciada pela forma e peso corporal',
            'Os episódios ocorrem, em média, pelo menos uma vez por semana durante três meses'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F50.2',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm10',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'riscos-tratamentos-bulimia',
            title: 'Riscos e Tratamentos da Bulimia',
            content: 'A bulimia nervosa é um transtorno alimentar grave que pode levar a complicações médicas sérias, como desequilíbrios eletrolíticos, problemas gastrointestinais e danos dentários. O tratamento geralmente envolve psicoterapia, como a terapia cognitivo-comportamental (TCC), que ajuda a interromper o ciclo de compulsão e purgação, e a desenvolver uma relação mais saudável com a comida. Medicamentos, como os ISRS, podem ser usados para tratar sintomas comórbidos de depressão ou ansiedade. O suporte nutricional também é essencial para estabelecer padrões alimentares regulares. Um exercício prático é o diário alimentar: registre o que come, quando e como se sente antes e depois, para identificar gatilhos emocionais e padrões de comportamento, discutindo-os com um terapeuta ou nutricionista.',
            diseaseCode: 'F50.2',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['bulimia', 'tratamento', 'alimentação'],
            references: ['DSM-5', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'interrompendo-ciclo-bulimia',
            title: 'Interrompendo o Ciclo da Bulimia',
            content: 'Quebrar o ciclo de compulsão e purgação é essencial no tratamento da bulimia. Estratégias incluem identificar gatilhos emocionais e substituir comportamentos compensatórios por ações saudáveis. Um exercício prático é a "pausa consciente": antes de uma compulsão, espere 5 minutos e pratique respiração profunda para reduzir o impulso.',
            diseaseCode: 'F50.2',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['bulimia', 'compulsão', 'tratamento'],
            references: ['APA', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'saude-bucal-bulimia',
            title: 'Saúde Bucal e Bulimia',
            content: 'A bulimia pode causar danos dentários devido à purgação. Cuidados como enxaguar a boca com água após episódios e evitar escovação imediata ajudam a proteger os dentes. Um exercício prático é o "cuidado pós-purging": após um episódio, beba água e espere 30 minutos antes de escovar, para minimizar erosão.',
            diseaseCode: 'F50.2',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['bulimia', 'saúde bucal', 'tratamento'],
            references: ['NIMH', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade', 'depressao']
      },
      {
        id: 'compulsao-alimentar',
        title: 'Transtorno da Compulsão Alimentar Periódica',
        description: 'Episódios recorrentes de compulsão alimentar sem comportamentos compensatórios.',
        diseaseCode: {
          code: 'F50.8',
          description: 'Outros transtornos alimentares',
          dsm5Criteria: [
            'Episódios recorrentes de compulsão alimentar',
            'Sensação de falta de controle durante os episódios',
            'Angústia em relação à compulsão alimentar',
            'Ausência de comportamentos compensatórios regulares',
            'Os episódios ocorrem, em média, pelo menos uma vez por semana durante três meses'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F50.8',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm10',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'manejo-compulsao-alimentar',
            title: 'Manejo da Compulsão Alimentar Periódica',
            content: 'O Transtorno da Compulsão Alimentar Periódica é o transtorno alimentar mais comum e está associado a obesidade e outros problemas de saúde. O tratamento pode incluir psicoterapia, como a terapia cognitivo-comportamental (TCC), que ajuda a identificar gatilhos emocionais e a desenvolver estratégias de enfrentamento. Medicamentos, como os ISRS ou o lisdexanfetamina, podem ser prescritos para reduzir a frequência dos episódios. O suporte nutricional e grupos de apoio também são importantes para promover uma relação saudável com a comida. Um exercício prático é a alimentação consciente: durante as refeições, coma devagar, saboreie cada mordida e preste atenção aos sinais de saciedade do corpo, evitando distrações como TV ou celular.',
            diseaseCode: 'F50.8',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['compulsão alimentar', 'tratamento', 'alimentação'],
            references: ['DSM-5', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'mindfulness-compulsao-alimentar',
            title: 'Mindfulness na Compulsão Alimentar',
            content: 'A prática de mindfulness pode reduzir episódios de compulsão ao aumentar a consciência dos sinais de fome e saciedade. Um exercício prático é o "momento mindful": antes de comer, pare por 1 minuto, respire fundo e avalie se está com fome física ou emocional, ajustando sua escolha alimentar.',
            diseaseCode: 'F50.8',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['compulsão alimentar', 'mindfulness', 'tratamento'],
            references: ['APA', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'gatilhos-compulsao-alimentar',
            title: 'Identificando Gatilhos na Compulsão Alimentar',
            content: 'Reconhecer gatilhos emocionais ou situacionais é crucial para controlar a compulsão alimentar. Um exercício prático é o "mapa de gatilhos": anote por uma semana quando ocorrem episódios, o que sentiu antes e o que estava acontecendo, para planejar estratégias com um terapeuta.',
            diseaseCode: 'F50.8',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['compulsão alimentar', 'gatilhos', 'tratamento'],
            references: ['NIMH', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['depressao', 'ansiedade']
      },
      {
        id: 'ortorexia',
        title: 'Ortorexia',
        description: 'Obsessão por alimentos saudáveis levando a restrições extremas.',
        diseaseCode: {
          code: 'F50.9',
          description: 'Transtorno alimentar não especificado',
          dsm5Criteria: [
            'Preocupação excessiva com a qualidade e pureza dos alimentos',
            'Restrições alimentares severas baseadas em percepções de "saúde"',
            'Comprometimento significativo no funcionamento social ou ocupacional',
            'Ausência de preocupação com o peso ou forma corporal'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F50.9',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm10',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'tratamento-ortorexia',
            title: 'Tratamento da Ortorexia',
            content: 'A ortorexia é uma obsessão por alimentos "puros" ou "saudáveis", levando a restrições extremas que podem causar desnutrição e isolamento social. Embora não seja oficialmente reconhecida no DSM-5, é tratada como um transtorno alimentar. O tratamento envolve psicoterapia para desafiar crenças irracionais sobre alimentos e educação nutricional para promover uma dieta equilibrada. Um exercício prático é a alimentação intuitiva: pratique comer quando estiver com fome e parar quando estiver satisfeito, experimentando uma variedade de alimentos sem culpa, para reconectar-se aos sinais naturais do corpo.',
            diseaseCode: 'F50.9',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['ortorexia', 'tratamento', 'alimentação'],
            references: ['DSM-5', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'equilibrio-ortorexia',
            title: 'Equilíbrio Alimentar na Ortorexia',
            content: 'Superar a ortorexia envolve aceitar uma dieta variada sem obsessão por "pureza". Um exercício prático é o "dia flexível": uma vez por semana, coma algo que normalmente evitaria, como um doce, e reflita sobre como se sentiu, promovendo flexibilidade alimentar.',
            diseaseCode: 'F50.9',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['ortorexia', 'equilíbrio', 'alimentação'],
            references: ['APA', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'socializacao-ortorexia',
            title: 'Ortorexia e Socialização',
            content: 'A ortorexia pode levar ao isolamento social devido a restrições alimentares. Participar de eventos com comida pode ajudar a reintegrar-se. Um exercício prático é o "almoço social": aceite um convite para comer fora, escolhendo algo do cardápio sem julgamento, para reconstruir conexões.',
            diseaseCode: 'F50.9',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['ortorexia', 'socialização', 'tratamento'],
            references: ['NIMH', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['alimentares', 'ansiedade']
      },
      {
        id: 'ruminacao',
        title: 'Transtorno da Ruminação',
        description: 'Regurgitação repetida de alimentos sem causa médica aparente.',
        diseaseCode: {
          code: 'F98.21',
          description: 'Transtorno da ruminação',
          dsm5Criteria: [
            'Regurgitação repetida de alimentos por pelo menos um mês',
            'A regurgitação não é atribuível a uma condição médica',
            'Não ocorre exclusivamente durante outro transtorno alimentar',
            'Causa sofrimento ou prejuízo no funcionamento'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F98.21',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm10',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'manejo-ruminacao',
            title: 'Manejo do Transtorno da Ruminação',
            content: 'O Transtorno da Ruminação envolve a regurgitação repetida de alimentos, que são então re-mastigados, engolidos novamente ou cuspidos. O tratamento pode incluir terapia comportamental para modificar o comportamento de ruminação e técnicas de relaxamento para reduzir a ansiedade associada. Um exercício prático é a distração após as refeições: após comer, envolva-se em uma atividade prazerosa ou relaxante, como ler ou ouvir música, para evitar a ruminação e quebrar o ciclo do comportamento.',
            diseaseCode: 'F98.21',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['ruminação', 'tratamento', 'alimentação'],
            references: ['DSM-5', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'terapia-comportamental-ruminacao',
            title: 'Terapia Comportamental para Ruminação',
            content: 'A terapia comportamental, como a reversão de hábitos, ensina a substituir a ruminação por ações incompatíveis, como respirar fundo. Um exercício prático é o "treino de respiração": após comer, pratique respirações profundas por 5 minutos, focando em manter a comida no estômago.',
            diseaseCode: 'F98.21',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['ruminação', 'comportamento', 'tratamento'],
            references: ['APA', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'relaxamento-ruminacao',
            title: 'Relaxamento e Ruminação',
            content: 'Reduzir a ansiedade pode diminuir a ruminação. Técnicas de relaxamento pós-refeição são eficazes. Um exercício prático é o "relaxamento guiado": ouça uma gravação de 10 minutos de relaxamento após comer, focando em sensações corporais para evitar o comportamento.',
            diseaseCode: 'F98.21',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['ruminação', 'relaxamento', 'tratamento'],
            references: ['NIMH', 'NEDA'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['alimentares', 'ansiedade']
      }
    ]
  },
  {
    id: 'toc',
    title: 'TOC',
    description: 'Transtorno Obsessivo-Compulsivo: sintomas, tipos e tratamentos.',
    icon: 'Target',
    subCategories: [
      {
        id: 'toc-puro',
        title: 'TOC Predominantemente Obsessivo',
        description: 'TOC com predominância de obsessões mentais.',
        diseaseCode: {
          code: 'F42',
          description: 'Transtorno obsessivo-compulsivo',
          dsm5Criteria: [
            'Presença de obsessões, compulsões ou ambas',
            'As obsessões ou compulsões consomem tempo ou causam sofrimento clinicamente significativo',
            'Os sintomas obsessivo-compulsivos não são atribuíveis aos efeitos fisiológicos de uma substância',
            'Predominância de obsessões sobre compulsões',
            'Insight bom ou razoável: o indivíduo reconhece que as crenças do transtorno obsessivo-compulsivo são definitiva ou provavelmente não verdadeiras'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F42',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm06',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'visao-geral-toc-puro',
            title: 'TOC Predominantemente Obsessivo',
            content: 'Este subtipo de TOC é caracterizado por obsessões intensas sem compulsões visíveis, embora compulsões mentais frequentemente estejam presentes. As obsessões podem incluir pensamentos intrusivos sobre temas como contaminação, dano, sexualidade, religião ou moralidade. Pessoas com TOC predominantemente obsessivo geralmente realizam rituais mentais como contagem, repetição de frases ou orações, ou revisão mental de eventos passados. O tratamento mais eficaz é a Exposição e Prevenção de Resposta (EPR), frequentemente combinada com medicamentos inibidores seletivos de recaptação de serotonina (ISRS). Um exercício prático é a exposição imaginária: escreva um script detalhado da sua obsessão mais temida, leia-o em voz alta ou grave e ouça, permanecendo com o desconforto sem realizar rituais mentais, até que a ansiedade diminua pela metade. Repita diariamente.',
            diseaseCode: 'F42',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TOC', 'obsessões', 'tratamento'],
            references: ['DSM-5', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'exposicao-imaginaria-toc-puro',
            title: 'Exposição Imaginária no TOC Puro',
            content: 'A exposição imaginária é uma técnica poderosa para enfrentar obsessões mentais, reduzindo sua intensidade. Um exercício prático é o "script diário": escreva uma obsessão detalhada, leia-a por 10 minutos sem rituais, e repita até que a ansiedade caia, promovendo dessensibilização.',
            diseaseCode: 'F42',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TOC', 'exposição', 'tratamento'],
            references: ['APA', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'lidando-obsessoes-toc-puro',
            title: 'Lidando com Obsessões no TOC Puro',
            content: 'Gerenciar obsessões mentais requer estratégias para evitar rituais internos. Um exercício prático é o "adiamento": quando uma obsessão surgir, diga a si mesmo "pensarei nisso depois" e foque em outra atividade por 15 minutos, reduzindo o poder do pensamento.',
            diseaseCode: 'F42',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TOC', 'obsessões', 'tratamento'],
            references: ['NIMH', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['ansiedade']
      },
      {
        id: 'toc-compulsivo',
        title: 'TOC com Compulsões Predominantes',
        description: 'TOC com predominância de comportamentos compulsivos visíveis.',
        diseaseCode: {
          code: 'F42',
          description: 'Transtorno obsessivo-compulsivo',
          dsm5Criteria: [
            'Presença de obsessões, compulsões ou ambas',
            'As compulsões são comportamentos repetitivos ou atos mentais que o indivíduo se sente compelido a realizar',
            'As compulsões visam reduzir a ansiedade ou prevenir algum evento temido',
            'Os sintomas consomem tempo ou causam sofrimento clinicamente significativo'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F42',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm06',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'entendendo-compulsoes-toc',
            title: 'Entendendo as Compulsões no TOC',
            content: 'As compulsões no TOC são comportamentos repetitivos, como lavar as mãos, verificar, ordenar ou contar, que a pessoa sente-se compelida a realizar em resposta a uma obsessão ou de acordo com regras rígidas. Essas ações visam reduzir a ansiedade ou prevenir um evento temido, mas proporcionam apenas alívio temporário. O tratamento envolve a Exposição e Prevenção de Resposta (EPR), que ajuda a pessoa a enfrentar suas obsessões sem recorrer às compulsões, e medicamentos como os ISRS. Um exercício prático é a prevenção de resposta: identifique uma compulsão comum, como verificar o fogão, e gradualmente reduza o número de verificações, tolerando a ansiedade até que ela diminua naturalmente.',
            diseaseCode: 'F42',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TOC', 'compulsões', 'tratamento'],
            references: ['DSM-5', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'prevencao-resposta-toc-compulsivo',
            title: 'Prevenção de Resposta no TOC Compulsivo',
            content: 'A prevenção de resposta ajuda a quebrar o ciclo das compulsões no TOC. Um exercício prático é o "limite único": para uma compulsão como lavar as mãos, faça-o apenas uma vez e resista a repetir, anotando a redução da ansiedade ao longo do tempo.',
            diseaseCode: 'F42',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TOC', 'compulsões', 'tratamento'],
            references: ['APA', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'exposicao-toc-compulsivo',
            title: 'Exposição no TOC Compulsivo',
            content: 'A exposição enfrenta as obsessões que alimentam compulsões, como tocar algo "contaminado" sem lavar as mãos depois. Um exercício prático é o "desafio de exposição": toque um objeto temido e espere 10 minutos antes de qualquer ação, observando a queda da ansiedade.',
            diseaseCode: 'F42',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TOC', 'exposição', 'tratamento'],
            references: ['NIMH', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          }
        ],
        relatedCategories: ['ansiedade', 'toc']
      },
      {
        id: 'dismorfico-corporal',
        title: 'Transtorno Dismórfico Corporal',
        description: 'Preocupação excessiva com defeitos percebidos na aparência física.',
        diseaseCode: {
          code: 'F45.22',
          description: 'Transtorno dismórfico corporal',
          dsm5Criteria: [
            'Preocupação com um ou mais defeitos percebidos na aparência física',
            'Comportamentos repetitivos (por exemplo, verificar no espelho) ou atos mentais em resposta às preocupações com a aparência',
            'A preocupação causa sofrimento clinicamente significativo ou prejuízo no funcionamento',
            'A preocupação não é melhor explicada por outro transtorno mental'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F45.22',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm06',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'semelhancas-dismorfia-toc',
            title: 'TOC e Dismorfia Corporal: Semelhanças e Diferenças',
            content: 'O Transtorno Dismórfico Corporal (TDC) compartilha características com o TOC, como pensamentos obsessivos e comportamentos compulsivos. No TDC, as obsessões estão focadas em defeitos percebidos na aparência, e as compulsões podem incluir verificar no espelho ou buscar reasssurance. O tratamento pode incluir terapia cognitivo-comportamental com foco em exposição e prevenção de resposta, e medicamentos como os ISRS. É importante diferenciar o TDC de preocupações normais com a aparência ou de outros transtornos, como a anorexia nervosa. Um exercício prático é a redução do tempo no espelho: limite o tempo gasto verificando sua aparência, começando com pequenas reduções e aumentando gradualmente, para diminuir a dependência desse comportamento compulsivo.',
            diseaseCode: 'F45.22',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TDC', 'TOC', 'tratamento'],
            references: ['DSM-5', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '8'
          },
          {
            id: 'tratamento-dismorfia-corporal',
            title: 'Tratamento do Transtorno Dismórfico Corporal',
            content: 'O tratamento do TDC combina TCC com exposição e prevenção de resposta para reduzir obsessões com a aparência. Um exercício prático é o "tempo limitado no espelho": use um temporizador para 5 minutos diários de verificação, evitando excessos, e anote os sentimentos para discutir em terapia.',
            diseaseCode: 'F45.22',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['TDC', 'tratamento', 'TCC'],
            references: ['APA', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'autoimagem-dismorfia',
            title: 'Reconstruindo a Autoimagem no TDC',
            content: 'Melhorar a autoimagem no TDC envolve desafiar percepções distorcidas. Um exercício prático é o "foco positivo": por 5 minutos, descreva em voz alta aspectos do seu corpo que funcionam bem (ex.: "minhas pernas me levam aonde quero"), reforçando uma visão funcional.',
            diseaseCode: 'F45.22',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['TDC', 'autoimagem', 'tratamento'],
            references: ['NIMH', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['toc', 'ansiedade']
      },
      {
        id: 'tricotilomania',
        title: 'Tricotilomania',
        description: 'Impulso de arrancar os próprios cabelos.',
        diseaseCode: {
          code: 'F63.3',
          description: 'Tricotilomania',
          dsm5Criteria: [
            'Arrancamento recorrente de cabelos, resultando em perda de cabelo',
            'Tentativas repetidas de diminuir ou parar o arrancamento',
            'O arrancamento causa sofrimento clinicamente significativo ou prejuízo no funcionamento',
            'Não é melhor explicado por outro transtorno mental ou condição médica'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/F63.3',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm06',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'tratamento-tricotilomania',
            title: 'Tratamento da Tricotilomania',
            content: 'A tricotilomania é caracterizada pelo impulso irresistível de arrancar os próprios cabelos, resultando em perda de cabelo e angústia. O tratamento pode incluir terapia comportamental, como a reversão de hábitos, que ensina a pessoa a identificar gatilhos e substituir o comportamento por ações incompatíveis, como apertar uma bola de estresse. Medicamentos, como os ISRS, também podem ser usados. Um exercício prático é a técnica de substituição: quando sentir o impulso de arrancar cabelos, aperte uma bola de estresse ou pratique uma atividade manual, como tricô, para ocupar as mãos e redirecionar o comportamento.',
            diseaseCode: 'F63.3',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['tricotilomania', 'TOC', 'tratamento'],
            references: ['DSM-5', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '7'
          },
          {
            id: 'reversao-habitos-tricotilomania',
            title: 'Reversão de Hábitos na Tricotilomania',
            content: 'A reversão de hábitos é uma técnica comportamental que substitui o arrancamento por ações alternativas. Um exercício prático é o "substituto manual": mantenha um objeto (ex.: bola antiestresse) nas mãos em momentos de risco, usando-o por 5 minutos para evitar o impulso.',
            diseaseCode: 'F63.3',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['tricotilomania', 'hábitos', 'tratamento'],
            references: ['APA', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'autocontrole-tricotilomania',
            title: 'Autocontrole na Tricotilomania',
            content: 'Desenvolver autocontrole ajuda a reduzir o arrancamento de cabelos. Um exercício prático é o "diário de impulsos": anote quando sente o impulso, o que o desencadeou e como resistiu, revisando semanalmente para identificar padrões e reforçar estratégias.',
            diseaseCode: 'F63.3',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['tricotilomania', 'autocontrole', 'tratamento'],
            references: ['NIMH', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['toc', 'ansiedade']
      },
      {
        id: 'escoriacao',
        title: 'Transtorno de Escoriação',
        description: 'Cutucar repetitivo da pele resultando em lesões.',
        diseaseCode: {
          code: 'L98.1',
          description: 'Dermatite factícia',
          dsm5Criteria: [
            'Cutucar recorrente da pele, resultando em lesões',
            'Tentativas repetidas de diminuir ou parar o cutucar',
            'O cutucar causa sofrimento clinicamente significativo ou prejuízo no funcionamento',
            'Não é melhor explicado por outro transtorno mental ou condição médica'
          ],
          references: {
            icd10: 'https://icd.who.int/browse10/2019/en#/L98.1',
            dsm5: 'https://doi.org/10.1176/appi.books.9780890425596.dsm06',
            who: 'https://www.who.int/classifications/icd/en/'
          }
        },
        articles: [
          {
            id: 'manejo-escoriacao',
            title: 'Manejo do Transtorno de Escoriação',
            content: 'O Transtorno de Escoriação envolve o cutucar repetitivo da pele, causando lesões e angústia. O tratamento pode incluir terapia comportamental, como a reversão de hábitos, e medicamentos, como os ISRS. Um exercício prático é o uso de barreiras físicas: use luvas ou adesivos nas áreas mais afetadas para dificultar o cutucar, ajudando a quebrar o ciclo do comportamento enquanto trabalha em estratégias de longo prazo com um terapeuta.',
            diseaseCode: 'L98.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['escoriação', 'TOC', 'tratamento'],
            references: ['DSM-5', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'reduzindo-cutucar-escoriacao',
            title: 'Reduzindo o Cutucar na Escoriação',
            content: 'Reduzir o cutucar requer substituir o comportamento por alternativas saudáveis. Um exercício prático é o "minuto de pausa": ao sentir o impulso, pare por 1 minuto, respire fundo e use um creme hidratante nas mãos, redirecionando a energia.',
            diseaseCode: 'L98.1',
            isPremium: true,
            requiredPlan: 'premium',
            tags: ['escoriação', 'redução', 'tratamento'],
            references: ['APA', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          },
          {
            id: 'cuidado-pele-escoriacao',
            title: 'Cuidado com a Pele na Escoriação',
            content: 'Cuidar da pele pode ajudar a diminuir o desejo de cutucar e promover a cura. Um exercício prático é o "ritual de cuidado": aplique um creme calmante nas áreas afetadas duas vezes ao dia, focando na sensação para evitar o comportamento.',
            diseaseCode: 'L98.1',
            isPremium: false,
            requiredPlan: 'basic',
            tags: ['escoriação', 'pele', 'tratamento'],
            references: ['NIMH', 'IOCDF'],
            lastUpdated: '2025-03-06',
            expertReviewed: true,
            readingTime: '6'
          }
        ],
        relatedCategories: ['toc', 'ansiedade']
      }
    ]
  }
];