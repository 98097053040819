import React, { useState } from 'react';
import { User, Settings, Shield, HelpCircle, LogOut, FileText } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import OnBoardingModal from '../components/onboarding/OnBoardingModal';
import { useOnBoardingStore } from '../stores/onBoardingStore';
import RecoveryEmailManager from '../components/profile/RecoveryEmailManager';
import { formatHashedEmail } from '../components/utils/formatters';

const ProfilePage: React.FC = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const [isOnBoardingOpen, setIsOnBoardingOpen] = useState(false);
  const { setModalOpen, setCurrentStep } = useOnBoardingStore();
  
  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };
  
  const handleOpenOnBoarding = () => {
    setCurrentStep(0);
    setIsOnBoardingOpen(true);
    setModalOpen(true);
  };
  
  const handleCloseOnBoarding = () => {
    setIsOnBoardingOpen(false);
    setModalOpen(false);
    setCurrentStep(0);
  };
  
  const userEmail = user?.email || 'Usuário sem email';
  const userName = user?.user_metadata?.name || formatHashedEmail(userEmail);
  
  return (
    <div className="py-6">
      <h1 className="text-2xl font-semibold text-neutral-800 mb-6">Perfil</h1>
      
      <div className="card mb-6 flex items-center">
        <div className="bg-primary-100 p-4 rounded-full mr-4">
          <User size={32} className="text-primary-600" />
        </div>
        <div>
          <h2 className="font-medium text-neutral-800">{userName}</h2>
          <p className="text-sm text-neutral-500 truncate max-w-[250px]">{formatHashedEmail(userEmail)}</p>
        </div>
      </div>
      
      {/* Componente de gerenciamento de email de recuperação */}
      {user?.user_metadata?.is_anonymous && (
        <RecoveryEmailManager />
      )}
      
      <div className="card mb-6">
        <h3 className="text-lg font-medium text-neutral-700 mb-4">Configurações</h3>
        
        <div className="space-y-4">
          <div 
            className="flex items-center justify-between p-2 hover:bg-neutral-50 rounded-lg cursor-pointer"
            onClick={handleOpenOnBoarding}
          >
            <div className="flex items-center">
              <div className="bg-neutral-100 p-2 rounded-full mr-3">
                <FileText size={20} className="text-neutral-600" />
              </div>
              <span>Revisar Informações de Perfil</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-neutral-400">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
          
          <div className="flex items-center justify-between p-2 hover:bg-neutral-50 rounded-lg cursor-pointer">
            <div className="flex items-center">
              <div className="bg-neutral-100 p-2 rounded-full mr-3">
                <Settings size={20} className="text-neutral-600" />
              </div>
              <span>Preferências</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-neutral-400">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
          
          <Link 
            to="/politica-de-privacidade" 
            className="flex items-center justify-between p-2 hover:bg-neutral-50 rounded-lg cursor-pointer"
          >
            <div className="flex items-center">
              <div className="bg-neutral-100 p-2 rounded-full mr-3">
                <Shield size={20} className="text-neutral-600" />
              </div>
              <span>Privacidade e Segurança</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-neutral-400">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </Link>
          
          <div className="flex items-center justify-between p-2 hover:bg-neutral-50 rounded-lg cursor-pointer">
            <div className="flex items-center">
              <div className="bg-neutral-100 p-2 rounded-full mr-3">
                <HelpCircle size={20} className="text-neutral-600" />
              </div>
              <span>Ajuda e Suporte</span>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-neutral-400">
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </div>
        </div>
      </div>
      
      <div className="card">
        <div 
          onClick={handleLogout}
          className="flex items-center text-red-500 p-2 hover:bg-red-50 rounded-lg cursor-pointer"
        >
          <div className="bg-red-100 p-2 rounded-full mr-3">
            <LogOut size={20} className="text-red-500" />
          </div>
          <span>Sair</span>
        </div>
      </div>
      
      <p className="text-center text-xs text-neutral-500 mt-8">
        Meu Humor v0.1.0
      </p>
      
      {/* Modal de OnBoarding */}
      <OnBoardingModal 
        isOpen={isOnBoardingOpen} 
        onClose={handleCloseOnBoarding} 
        isRequired={false}
        startWithPendingSteps={false}
        isReview={true}
      />
    </div>
  );
};

export default ProfilePage;