import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import MoodSelector from './MoodSelector';
import { addMoodEntry } from '../infra/DatabaseFacade';
import { MoodEntry } from '../types';

interface MoodEntryFormProps {
  onComplete: () => void;
}

const MoodEntryForm: React.FC<MoodEntryFormProps> = ({ onComplete }) => {
  const [selectedMood, setSelectedMood] = useState<number>(-1);
  const [note, setNote] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (selectedMood === -1) {
      return;
    }
    
    setIsSubmitting(true);
    
    const newEntry: MoodEntry = {
      id: uuidv4(),
      timestamp: Date.now(),
      score: selectedMood,
      note: note.trim()
    };
    
    try {    
      // Save the entry
      addMoodEntry(newEntry);
      
      // Reset form and notify parent
      setSelectedMood(-1);
      setNote('');
      onComplete();
    } catch (error) {
      console.error('Error saving mood entry:', error);
      // Save without analysis in case of error
      addMoodEntry(newEntry);
      onComplete();
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="w-full">
      <MoodSelector selectedMood={selectedMood} onSelect={setSelectedMood} />
      
      {selectedMood !== -1 && (
        <>
          <div className="mt-4 mb-6">
            <label htmlFor="note" className="block text-sm font-medium text-neutral-700 mb-1">
              Quer adicionar algum detalhe? (opcional)
            </label>
            <textarea
              id="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              maxLength={255}
              placeholder="Ex: Estou ansioso com uma reunião..."
              className="input h-24 resize-none"
            />
            <p className="text-xs text-neutral-500 text-right mt-1">
              {note.length}/255 caracteres
            </p>
          </div>
          
          <button
            type="submit"
            disabled={isSubmitting}
            className="btn btn-primary w-full"
          >
            {isSubmitting ? 'Salvando...' : 'Salvar registro'}
          </button>
        </>
      )}
    </form>
  );
};

export default MoodEntryForm;