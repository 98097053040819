/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useRef } from 'react';
import { getMoodEntries, updateMoodEntry, deleteMoodEntry, addMoodEntryForDate, createHealthRecord, getHealthRecords, canGenerateHealthRecord, resetHealthRecord } from '../infra/DatabaseFacade';
import { Download, Edit2, Trash2, AlertCircle, Plus, FileText, ChevronDown, ChevronUp, RefreshCw, Loader2, Brain } from 'lucide-react';
import type { MoodEntry, HealthRecord } from '../types';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { getMoodEmoji } from '../infra/utils/moodUtils';
import ReactMarkdown from 'react-markdown';
import { 
  createTimestampFromDateAndTime,
  formatDatePtBR,
  extractDateAndTimeFromTimestamp,
  getCurrentClientTime,
  formatFullDatePtBR,
  formatShortDatePtBR
} from '../infra/utils/dateUtils';
import { MoodEditForm } from '../components/MoodEditForm';
import { MoodAddForm } from '../components/MoodAddForm';
import QuickActions from '../components/QuickActions';
import { useAuth } from '../contexts/AuthContext';
import FloatingChatButton from '../components/ChatAI/FloatingChatButton';

// Interfaces para processamento de markdown
interface MarkdownNode {
  type: 'heading' | 'paragraph' | 'bold' | 'italic' | 'list-item' | 'list' | 'sublist-header' | 'sublist';
  content: string;
  level?: number; // Para headings (h1, h2, h3, etc.)
  children?: MarkdownNode[];
  isSubHeader?: boolean; // Para itens de lista que funcionam como cabeçalhos
}

const HistoryPage: React.FC = () => {
  const [entries, setEntries] = useState<MoodEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingRecord, setIsGeneratingRecord] = useState(false);
  const [isResettingRecord, setIsResettingRecord] = useState(false);
  const [resetRecordId, setResetRecordId] = useState<string | null>(null);
  const [resetError, setResetError] = useState<string | null>(null);
  const [healthRecord, setHealthRecord] = useState<string | null>(null);
  const [healthRecords, setHealthRecords] = useState<HealthRecord[]>([]);
  const [canGenerate, setCanGenerate] = useState(false);
  const [viewingRecordId, setViewingRecordId] = useState<string | null>(null);
  const [expandedRecords, setExpandedRecords] = useState<Record<string, boolean>>({});
  const [editingEntryId, setEditingEntryId] = useState<string | null>(null);
  const [editingMoodScore, setEditingMoodScore] = useState(-1);
  const [editingNoteValue, setEditingNoteValue] = useState('');
  const [editingTime, setEditingTime] = useState('');
  const [editingDate, setEditingDate] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmId, setDeleteConfirmId] = useState<string | null>(null);
  const [addingForDate, setAddingForDate] = useState<string | null>(null);
  const [newEntryTime, setNewEntryTime] = useState('12:00');
  const [visibleDatesCount, setVisibleDatesCount] = useState(5);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [expandedAnalysis, setExpandedAnalysis] = useState<Record<string, boolean>>({});
  const loaderRef = useRef<HTMLDivElement>(null);
  const dataLoadedRef = useRef(false);
  const { user } = useAuth();

  useEffect(() => {
    if (dataLoadedRef.current) return;
    
    const fetchData = async () => {
      try {
        dataLoadedRef.current = true;
        
        console.log('Carregando entradas de humor');
        const data = await getMoodEntries();
        setEntries(data);
        
        const records = await getHealthRecords();
        setHealthRecords(records);
        
        setExpandedRecords({});
        
        if (records.length > 0) {
          const mostRecentRecord = records[0];
          setViewingRecordId(mostRecentRecord.id);
          setHealthRecord(mostRecentRecord.content);
        }
        
        const canGenerateNew = await canGenerateHealthRecord();
        setCanGenerate(canGenerateNew);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
        setIsLoading(false);
      }
    };

    fetchData();
    
    return () => {
    };
  }, []);
  
  useEffect(() => {
    if (!('IntersectionObserver' in window)) return;
    
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && !isLoadingMore) {
        loadMoreItems();
      }
    };
    
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    });
    
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [isLoadingMore]);
  
  const loadMoreItems = () => {
    setIsLoadingMore(true);
    
    setTimeout(() => {
      setVisibleDatesCount(prev => prev + 3);
      setIsLoadingMore(false);
    }, 500);
  };

  const handleGenerateRecord = async () => {
    if (entries.length === 0) return;

    setIsGeneratingRecord(true);
    try {
      const newRecord = await createHealthRecord();
      
      if (newRecord) {
        const records = await getHealthRecords();
        setHealthRecords(records);
        
        setCanGenerate(false);
        
        setViewingRecordId(newRecord.id);
        setHealthRecord(newRecord.content);
      } else {
        if (healthRecords.length > 0) {
          setViewingRecordId(healthRecords[0].id);
          setHealthRecord(healthRecords[0].content);
        }
      }
    } catch (error) {
      console.error('Erro ao gerar prontuário:', error);
    } finally {
      setIsGeneratingRecord(false);
    }
  };

  const handleEditClick = (mood: MoodEntry) => {
    setEditingEntryId(mood.id);
    setEditingMoodScore(mood.score);
    setEditingNoteValue(mood.note || '');
    
    // Usar função utilitária para extrair data e hora
    const { date, time } = extractDateAndTimeFromTimestamp(mood.timestamp);
    setEditingDate(date);
    setEditingTime(time);
  };

  const handleCancelEdit = () => {
    setEditingEntryId(null);
    setEditingMoodScore(-1);
    setEditingNoteValue('');
    setEditingTime('');
    setEditingDate('');
  };

  const handleDeleteClick = (entryId: string) => {
    setDeleteConfirmId(entryId);
  };

  const handleConfirmDelete = async (entryId: string) => {
    if (isDeleting) return;
    
    setIsDeleting(true);
    
    try {
      await deleteMoodEntry(entryId);
      const updatedEntries = await getMoodEntries();
      setEntries(updatedEntries);
    } catch (error) {
      console.error('Error deleting mood entry:', error);
    } finally {
      setIsDeleting(false);
      setDeleteConfirmId(null);
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmId(null);
  };
  
  const toggleRecordExpansion = (recordId: string) => {
    setExpandedRecords(prev => ({
      ...prev,
      [recordId]: !prev[recordId]
    }));
  };
  
  const handleResetRecord = async (recordId: string) => {
    if (isResettingRecord) return;
    
    // Não permitir atualização se um novo prontuário pode ser gerado
    if (canGenerate) {
      setResetError('Não é possível atualizar o prontuário quando um novo pode ser gerado. Por favor, gere um novo prontuário.');
      
      setTimeout(() => {
        setResetError(null);
      }, 5000);
      
      return;
    }
    
    setIsResettingRecord(true);
    setResetRecordId(recordId);
    setResetError(null);
    
    try {
      const updatedRecord = await resetHealthRecord(recordId);
      
      if (updatedRecord) {
        const records = await getHealthRecords();
        setHealthRecords(records);
        
        if (viewingRecordId === recordId) {
          setHealthRecord(updatedRecord.content);
        }
        
        setExpandedRecords(prev => ({
          ...prev,
          [recordId]: true
        }));
      }
    } catch (error: any) {
      console.error('Erro ao resetar prontuário:', error);
      
      if (error.message && error.message.includes('Apenas o prontuário mais recente')) {
        setResetError('Apenas o prontuário mais recente pode ser atualizado.');
        
        setTimeout(() => {
          setResetError(null);
        }, 5000);
      }
    } finally {
      setIsResettingRecord(false);
      setResetRecordId(null);
    }
  };
  
  // Função para analisar o markdown e criar uma estrutura mais fiel
  const parseMarkdown = (markdown: string): MarkdownNode[] => {
    if (!markdown) return [];
    
    const result: MarkdownNode[] = [];
    const lines = markdown.split('\n');
    let currentList: MarkdownNode | null = null;
    let currentSublist: MarkdownNode | null = null;
    
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();
      
      if (!line) continue; // Ignorar linhas vazias
      
      // Heading (h1 to h6)
      if (line.startsWith('#')) {
        const match = line.match(/^(#{1,6})\s+(.+)$/);
        if (match) {
          const level = match[1].length;
          const content = match[2].trim();
          result.push({
            type: 'heading',
            content,
            level
          });
          continue;
        }
      }
      
      // Lista não ordenada
      if (line.match(/^[\*\-\•]\s+(.+)$/)) {
        const content = line.replace(/^[\*\-\•]\s+/, '').trim();
        
        // Verificar se é um cabeçalho de sublista (terminado com ":" ou padrões específicos)
        const isSublistHeader = content.endsWith(':') || 
                               content.match(/^(Gatilhos Positivos|Gatilhos Negativos):/) != null;
        
        if (!currentList) {
          // Iniciar uma nova lista principal
          currentList = {
            type: 'list',
            content: '',
            children: []
          };
          result.push(currentList);
        }
        
        if (isSublistHeader) {
          // Item é um cabeçalho de sublista
          currentSublist = {
            type: 'sublist',
            content: content,
            children: [],
            isSubHeader: true
          };
          
          currentList.children?.push(currentSublist);
        } else if (currentSublist && isProbableSublistItem(content, i, lines)) {
          // Se parece ser um item de sublista (indentação, contexto, etc.)
          // adicionar ao sublist atual
          currentSublist.children?.push({
            type: 'list-item',
            content
          });
        } else {
          // Item normal de lista
          currentList.children?.push({
            type: 'list-item',
            content,
            isSubHeader: isSublistHeader
          });
          
          // Se não for um cabeçalho de sublista, resetar o rastreamento de sublista
          if (!isSublistHeader) {
            currentSublist = null;
          }
        }
        continue;
      } else {
        // Se não for item de lista, resetar o rastreamento de lista/sublista
        currentList = null;
        currentSublist = null;
      }
      
      // Formatos inline (bold, italic) dentro de parágrafos normais
      const paragraph: MarkdownNode = {
        type: 'paragraph',
        content: line
      };
      
      result.push(paragraph);
    }
    
    return result;
  };
  
  // Função auxiliar para determinar se um item provavelmente pertence a uma sublista
  const isProbableSublistItem = (content: string, index: number, lines: string[]): boolean => {
    // Verificar se o conteúdo é uma descrição/explicação de um item anterior
    // que terminou com dois pontos
    
    // Verificar se há uma linha anterior terminando com ":"
    if (index > 0) {
      const prevLine = lines[index - 1].trim().replace(/^[\*\-\•]\s+/, '').trim();
      
      // Se a linha anterior é um cabeçalho de grupo (termina com ":")
      if (prevLine.endsWith(':')) {
        return true;
      }
      
      // Verificar por padrões específicos relacionados a "Gatilhos"
      if (prevLine.match(/^(Gatilhos Positivos|Gatilhos Negativos):/)) {
        return true;
      }
      
      // Verificar outros padrões de cabeçalho conhecidos
      if (prevLine.includes("Interação com") || 
          prevLine.includes("Apoio incondicional") ||
          prevLine.includes("Atividades prazerosas") ||
          prevLine.includes("Problemas com")) {
        return true;
      }
    }
    
    return false;
  };
  
  // Função para renderizar elementos markdown no PDF
  const renderMarkdownToPDF = (pdf: jsPDF, nodes: MarkdownNode[], startY: number): number => {
    let yPosition = startY;
    const margin = 20; // Margem lateral
    const pageWidth = 210; // Largura da página A4 em mm
    const textWidth = pageWidth - (margin * 2); // Largura disponível para texto
    
    for (const node of nodes) {
      // Verificar se precisamos adicionar uma nova página
      if (yPosition > 270) {
        pdf.addPage();
        yPosition = 20;
      }
      
      switch (node.type) {
        case 'heading': {
          // Configurar estilo de acordo com o nível do cabeçalho
          pdf.setFont('helvetica', 'bold');
          const fontSize = (node.level ?? 1) === 1 ? 18 : 
                         (node.level ?? 1) === 2 ? 16 : 
                         (node.level ?? 1) === 3 ? 14 : 12;
          pdf.setFontSize(fontSize);
          
          // Cor especial para cabeçalhos principais
          if ((node.level ?? 1) === 1) {
            pdf.setTextColor(79, 70, 229); // Cor primária para h1
          } else {
            pdf.setTextColor(55, 65, 81); // Cor neutra para outros cabeçalhos
          }
          
          const lines = pdf.splitTextToSize(node.content, textWidth);
          pdf.text(lines, margin, yPosition);
          
          // Adicionar espaço após o cabeçalho, proporcional à sua importância
          const spacing = (node.level ?? 1) === 1 ? 10 : 
                         (node.level ?? 1) === 2 ? 8 : 
                         (node.level ?? 1) === 3 ? 6 : 5;
          yPosition += (lines.length * 7) + spacing;
          
          // Adicionar sublinhado para cabeçalhos h1 e h2
          if ((node.level ?? 1) <= 2) {
            const lineY = yPosition - (spacing / 2);
            pdf.setDrawColor((node.level ?? 1) === 1 ? 79 : 180, (node.level ?? 1) === 1 ? 70 : 180, (node.level ?? 1) === 1 ? 229 : 200);
            pdf.setLineWidth((node.level ?? 1) === 1 ? 0.5 : 0.3);
            pdf.line(margin, lineY, margin + textWidth, lineY);
            yPosition += 2; // Espaço adicional após a linha
          }
          break;
        }
        
        case 'paragraph': {
          // Restaurar configuração padrão para parágrafos
          pdf.setFont('helvetica', 'normal');
          pdf.setFontSize(12);
          pdf.setTextColor(55, 65, 81);
          
          // Processar formatação inline (negrito e itálico)
          const textWithBold = node.content.match(/\*\*([^*]+)\*\*/g);
          const textWithItalic = node.content.match(/\*([^*]+)\*/g);
          
          if (textWithBold || textWithItalic) {
            // Texto com formatação inline
            let processedText = node.content;
            let lastIndex = 0;
            let segments = [];
            
            // Handle bold text
            const boldPattern = /\*\*([^*]+)\*\*/g;
            let boldMatch;
            
            while ((boldMatch = boldPattern.exec(node.content)) !== null) {
              if (boldMatch.index > lastIndex) {
                // Texto normal antes do negrito
                segments.push({
                  text: node.content.substring(lastIndex, boldMatch.index),
                  style: 'normal'
                });
              }
              
              // Texto em negrito
              segments.push({
                text: boldMatch[1],
                style: 'bold'
              });
              
              lastIndex = boldMatch.index + boldMatch[0].length;
            }
            
            // Texto restante após o último negrito
            if (lastIndex < node.content.length) {
              segments.push({
                text: node.content.substring(lastIndex),
                style: 'normal'
              });
            }
            
            // Imprimir segmentos com seus respectivos estilos
            let lineY = yPosition;
            let currentX = margin;
            
            for (const segment of segments) {
              pdf.setFont('helvetica', segment.style);
              const segmentWidth = pdf.getTextWidth(segment.text);
              
              // Verificar quebra de linha
              if (currentX + segmentWidth > margin + textWidth) {
                currentX = margin;
                lineY += 7; // Nova linha
                
                // Verificar se precisamos de nova página
                if (lineY > 270) {
                  pdf.addPage();
                  lineY = 20;
                }
              }
              
              pdf.text(segment.text, currentX, lineY);
              currentX += segmentWidth;
            }
            
            yPosition = lineY + 7; // Avançar para próxima linha após o parágrafo
          } else {
            // Texto simples sem formatação inline
            const lines = pdf.splitTextToSize(node.content, textWidth);
            pdf.text(lines, margin, yPosition);
            yPosition += (lines.length * 7) + 4; // Espaçamento padrão entre parágrafos
          }
          break;
        }
        
        case 'list': {
          pdf.setFont('helvetica', 'normal');
          pdf.setFontSize(12);
          pdf.setTextColor(55, 65, 81);
          
          // Adicionar espaço antes da lista
          yPosition += 2;
          
          // Renderizar itens da lista
          if (node.children && node.children.length > 0) {
            for (const item of node.children) {
              // Verificar se precisamos de nova página
              if (yPosition > 270) {
                pdf.addPage();
                yPosition = 20;
              }
              
              if (item.type === 'sublist') {
                // É um cabeçalho de sublista
                pdf.setFont('helvetica', 'bold');
                pdf.setTextColor(55, 65, 81);
                
                // Marcador de lista para o cabeçalho
                pdf.text('•', margin, yPosition);
                
                // Texto do cabeçalho com indentação
                const headerLines = pdf.splitTextToSize(item.content, textWidth - 8);
                pdf.text(headerLines, margin + 8, yPosition);
                
                yPosition += (headerLines.length * 7) + 3;
                
                // Renderizar itens da sublista
                if (item.children && item.children.length > 0) {
                  pdf.setFont('helvetica', 'normal');
                  
                  for (const subItem of item.children) {
                    // Verificar se precisamos de nova página
                    if (yPosition > 270) {
                      pdf.addPage();
                      yPosition = 20;
                    }
                    
                    // Marcador de sublista com indentação adicional
                    pdf.text('-', margin + 8, yPosition);
                    
                    // Texto do item com indentação adicional
                    const subItemLines = pdf.splitTextToSize(subItem.content, textWidth - 16);
                    pdf.text(subItemLines, margin + 16, yPosition);
                    
                    yPosition += (subItemLines.length * 7) + 2;
                  }
                  
                  // Espaçamento após a sublista
                  yPosition += 2;
                }
              } else if (item.type === 'list-item') {
                // Verificar se é um cabeçalho de grupo (termina com ":")
                if (item.isSubHeader) {
                  pdf.setFont('helvetica', 'bold');
                } else {
                  pdf.setFont('helvetica', 'normal');
                }
                
                // Marcador de lista
                pdf.text('•', margin, yPosition);
                
                // Texto do item com indentação
                const itemLines = pdf.splitTextToSize(item.content, textWidth - 8);
                pdf.text(itemLines, margin + 8, yPosition);
                
                yPosition += (itemLines.length * 7) + 2; // Espaçamento menor entre itens de lista
                
                // Se for um cabeçalho de grupo, adicionar espaço extra
                if (item.isSubHeader) {
                  yPosition += 1;
                }
              }
            }
            
            // Espaço adicional após a lista
            yPosition += 2;
          }
          break;
        }
        
        default:
          // Para outros tipos de nós que possam ser adicionados no futuro
          break;
      }
    }
    
    return yPosition;
  };
  
  const handleExportPDF = async () => {
    if (!healthRecord) return;
    
    try {
      // Configuração inicial do PDF
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
      
      // Cabeçalho do documento
      pdf.setFont('helvetica', 'bold');
      pdf.setTextColor(79, 70, 229);
      pdf.setFontSize(24);
      pdf.text('Prontuário de Saúde Mental', 20, 20);
      
      pdf.setDrawColor(79, 70, 229);
      pdf.setLineWidth(0.5);
      pdf.line(20, 25, 190, 25);
      
      // Informações de data e fonte
      pdf.setFont('helvetica', 'normal');
      pdf.setFontSize(12);
      pdf.setTextColor(107, 114, 128);
      const currentDate = format(new Date(), "d 'de' MMMM, yyyy", { locale: ptBR });
      pdf.text(`Data: ${currentDate}`, 190, 15, { align: 'right' });
      pdf.text('Meu Humor App', 190, 20, { align: 'right' });
      
      // Processar o conteúdo markdown
      const parsedMarkdown = parseMarkdown(healthRecord);
      
      // Renderizar o conteúdo no PDF
      let finalYPosition = renderMarkdownToPDF(pdf, parsedMarkdown, 40);
      
      // Adicionar rodapé se necessário
      if (finalYPosition > 250) {
        pdf.addPage();
        finalYPosition = 20;
      }
      
      // Linha divisória para o rodapé
      pdf.setDrawColor(229, 231, 235);
      pdf.line(20, finalYPosition + 10, 190, finalYPosition + 10);
      
      // Texto do rodapé
      pdf.setTextColor(156, 163, 175);
      pdf.setFontSize(10);
      pdf.setFont('helvetica', 'normal');
      pdf.text('Este documento foi gerado automaticamente pelo aplicativo Meu Humor.', 105, finalYPosition + 20, { align: 'center' });
      pdf.text('As informações contidas neste prontuário são baseadas nos registros de humor do usuário.', 105, finalYPosition + 25, { align: 'center' });
      pdf.text('Recomenda-se compartilhar este documento com profissionais de saúde mental qualificados.', 105, finalYPosition + 30, { align: 'center' });
      
      // Adicionar número de página em todas as páginas
      const pageCount = pdf.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        pdf.setPage(i);
        pdf.setFontSize(10);
        pdf.setTextColor(156, 163, 175);
        pdf.text(`Página ${i} de ${pageCount}`, 105, 287, { align: 'center' });
      }
      
      // Salvar o arquivo
      pdf.save(`prontuario-${new Date().toISOString().split('T')[0]}.pdf`);
      
    } catch (error) {
      console.error('Erro ao exportar prontuário:', error);
    }
  };

  const toggleAnalysisExpansion = (entryId: string) => {
    setExpandedAnalysis(prev => ({
      ...prev,
      [entryId]: !prev[entryId]
    }));
  };

  const truncateAnalysis = (text: string, maxLength = 120) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const CustomMoodEntryList = () => {
    const entriesByDate: Record<string, MoodEntry[]> = {};
    
    entries.forEach(entry => {
      const date = format(new Date(entry.timestamp), 'yyyy-MM-dd 03:00');
      
      if (!entriesByDate[date]) {
        entriesByDate[date] = [];
      }
      entriesByDate[date].push(entry);
    });
    
    const sortedDates = Object.keys(entriesByDate).sort((a, b) => b.localeCompare(a));
    
    const visibleDates = sortedDates.slice(0, visibleDatesCount);
    
    const hasMoreDates = sortedDates.length > visibleDatesCount;
    
    return (
      <div className="space-y-6">
        {visibleDates.length === 0 && (
          <div className="text-center py-6">
            <p className="text-neutral-500">Nenhum registro de humor encontrado.</p>
            <p className="text-sm text-neutral-400 mt-2">Registre seu humor para começar a ver seu histórico aqui.</p>
          </div>
        )}
        
        {visibleDates.map(date => (
          <div key={date}>
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-sm md:text-base font-medium text-neutral-500">
                {formatFullDatePtBR(new Date(date))}
              </h3>
              <button
                onClick={() => {
                  // Usar função utilitária para obter horário atual
                  const currentTime = getCurrentClientTime();
                  setNewEntryTime(currentTime);
                  setAddingForDate(date);
                }}
                className="text-primary-600 hover:text-primary-700 text-sm flex items-center gap-1 transition-colors"
                aria-label="Adicionar humor para este dia"
              >
                <Plus size={16} />
                <span>Adicionar Humor</span>
              </button>
            </div>
            
            <div className="space-y-3">
              {addingForDate === date && (
                isSaving ? (
                  <div className="card relative p-6 flex flex-col items-center justify-center">
                    <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-3" />
                    <p className="text-neutral-600 text-center">Salvando registro...</p>
                  </div>
                ) : (
                  <MoodAddForm
                    date={date}
                    formattedDate={formatFullDatePtBR(new Date(date))}
                    initialTime={newEntryTime}
                    onSave={async (data) => {
                      setIsSaving(true);
                      try {
                        const [hours, minutes] = data.time.split(':').map(Number);
                        const entryDate = new Date(date);
                        entryDate.setHours(hours, minutes, 0, 0);
                        
                        await addMoodEntryForDate(
                          data.score,
                          data.note.trim(),
                          entryDate
                        );
                        
                        const updatedEntries = await getMoodEntries();
                        setEntries(updatedEntries);
                        
                        setAddingForDate(null);
                        setIsSaving(false);
                      } catch (error) {
                        console.error('Erro ao adicionar entrada para data específica:', error);
                        setIsSaving(false);
                      }
                    }}
                    onCancel={() => {
                      setAddingForDate(null);
                    }}
                    isSaving={isSaving}
                  />
                )
              )}
              
              <div className="grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
                {entriesByDate[date].sort((e1, e2) => String(e2.timestamp).localeCompare(String(e1.timestamp))).map(entry => (
                  <div key={entry.id} className={`card relative ${editingEntryId === entry.id ? 'ring-2 ring-primary-300' : ''} ${deleteConfirmId === entry.id ? 'ring-2 ring-red-300' : ''}`}>
                    {deleteConfirmId === entry.id ? (
                      <div className="delete-confirm p-2">
                        <div className="flex items-center mb-4 text-red-600">
                          <AlertCircle size={20} className="mr-2" />
                          <span className="font-medium">Confirmar exclusão?</span>
                        </div>
                        <p className="text-neutral-600 mb-4 text-sm">
                          Esta ação não pode ser desfeita. Deseja realmente excluir este registro de humor?
                        </p>
                        <div className="flex space-x-3">
                          <button
                            onClick={() => handleConfirmDelete(entry.id)}
                            className="btn btn-sm bg-red-500 text-white hover:bg-red-600 flex items-center disabled:opacity-50"
                            disabled={isDeleting}
                          >
                            {isDeleting ? (
                              <span className="flex items-center">
                                <span className="animate-spin rounded-full h-3 w-3 border-b-2 border-white mr-2"></span>
                                Excluindo...
                              </span>
                            ) : (
                              <>
                                <Trash2 size={16} className="mr-1" />
                                Excluir
                              </>
                            )}
                          </button>
                          <button
                            onClick={handleCancelDelete}
                            className="btn btn-sm btn-outline disabled:opacity-50"
                            disabled={isDeleting}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                    ) : editingEntryId === entry.id ? (
                      <MoodEditForm
                        entry={entry}
                        initialValues={{
                          date: editingDate,
                          time: editingTime,
                          note: editingNoteValue,
                          score: editingMoodScore
                        }}
                        onSave={async (data) => {
                          const timestamp = createTimestampFromDateAndTime(data.date, data.time);
                          
                          console.log('editingDate:', data.date);
                          console.log('editingTime:', data.time);
                          console.log('timestamp:', timestamp);
                          console.log('local date from timestamp:', new Date(timestamp));
                          
                          const updatedEntry = { 
                            ...entry,
                            note: data.note,
                            score: data.score,
                            timestamp: timestamp
                          };

                          await updateMoodEntry(updatedEntry);
                          const updatedEntries = await getMoodEntries();
                          setEntries(updatedEntries);
                          
                          setEditingEntryId(null);
                          setEditingMoodScore(-1);
                          setEditingNoteValue('');
                          setEditingTime('');
                          setEditingDate('');
                          setIsSaving(false);
                        }}
                        onCancel={handleCancelEdit}
                        isSaving={isSaving}
                      />
                    ) : (
                      <>
                        <div className="absolute top-3 right-3 p-2 flex space-x-2">
                          <button 
                            className="edit-button text-neutral-400 hover:text-primary-500 transition-colors p-2 touch-manipulation"
                            onClick={() => handleEditClick(entry)}
                            aria-label="Editar registro"
                          >
                            <Edit2 size={20} />
                          </button>
                          <button 
                            className="delete-button text-neutral-400 hover:text-red-500 transition-colors p-2 touch-manipulation"
                            onClick={() => handleDeleteClick(entry.id)}
                            aria-label="Excluir registro"
                          >
                            <Trash2 size={20} />
                          </button>
                        </div>
                        <div className="flex items-center mb-2">
                          <span className="text-2xl md:text-3xl mr-3">{getMoodEmoji(entry.score)}</span>
                          <span className="text-neutral-700 font-medium">
                            {formatDatePtBR(new Date(entry.timestamp), "HH:mm")}
                          </span>
                        </div>
                        
                        {entry.note && (
                          <p className="text-neutral-600 mb-3">{entry.note}</p>
                        )}
                        
                        {/* Bloco de análise de IA */}
                        {entry.ai_analysis !== undefined && (
                          <div className="bg-neutral-50 p-3 rounded-lg text-sm text-neutral-700 relative border-l-2 border-indigo-300">
                            <div className="flex items-center mb-2">
                              <Brain size={14} className="text-indigo-500 mr-1.5" />
                              <span className="text-xs text-indigo-500 font-medium">Análise complementar por IA</span>
                            </div>
                            
                            {entry.ai_analysis ? (
                              <>
                                {expandedAnalysis[entry.id] ? (
                                  <>
                                    <ReactMarkdown>{entry.ai_analysis}</ReactMarkdown>
                                    <button 
                                      onClick={() => toggleAnalysisExpansion(entry.id)}
                                      className="text-primary-500 hover:text-primary-600 text-xs font-medium mt-2 flex items-center"
                                    >
                                      <ChevronUp size={14} className="mr-1" />
                                      <span>Mostrar menos</span>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <ReactMarkdown>
                                      {truncateAnalysis(entry.ai_analysis)}
                                    </ReactMarkdown>
                                    {entry.ai_analysis.length > 120 && (
                                      <button 
                                        onClick={() => toggleAnalysisExpansion(entry.id)}
                                        className="text-primary-500 hover:text-primary-600 text-xs font-medium mt-2 flex items-center"
                                      >
                                        <ChevronDown size={14} className="mr-1" />
                                        <span>Mostrar mais</span>
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <div className="flex items-center text-neutral-500 py-1">
                                <Loader2 size={14} className="text-indigo-400 mr-2 animate-spin" />
                                <p className="text-xs">
                                  Análise detalhada em processamento, fique atento para atualizações em breve.
                                  <span className="block mt-1 text-neutral-400">
                                    Atualize a página para verificar se já está disponível.
                                  </span>
                                </p>
                              </div>
                            )}
                            
                            <div className="flex items-center mt-1 justify-end">
                              <span className="text-neutral-400 text-xs italic">
                                Utilizado para gerar prontuários mais detalhados
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
                
                {/* Card de adicionar humor para desktop */}
                <div 
                  className="hidden xl:flex card border-2 border-dashed border-neutral-200 hover:border-primary-300 transition-colors cursor-pointer items-center justify-center min-h-[295px]"
                  onClick={() => {
                    const currentTime = getCurrentClientTime();
                    setNewEntryTime(currentTime);
                    setAddingForDate(date);
                  }}
                >
                  <div className="flex flex-col items-center text-neutral-500 hover:text-primary-600 transition-colors">
                    <Plus size={24} className="mb-2" />
                    <span className="text-sm font-medium">Adicionar Humor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        
        {hasMoreDates && (
          <div ref={loaderRef} className="py-4 text-center">
            {isLoadingMore ? (
              <div className="flex justify-center items-center space-x-2">
                <Loader2 className="h-5 w-5 text-primary-500 animate-spin" />
                <span className="text-neutral-500 text-sm">Carregando mais registros...</span>
              </div>
            ) : (
              <button 
                onClick={loadMoreItems}
                className="text-primary-500 hover:text-primary-600 text-sm font-medium flex items-center justify-center mx-auto"
              >
                <span>Carregar mais registros</span>
                <ChevronDown size={16} className="ml-1" />
              </button>
            )}
          </div>
        )}
        
        {!hasMoreDates && visibleDates.length > 0 && visibleDates.length === sortedDates.length && (
          <div className="text-center py-4">
            <p className="text-sm text-neutral-400">Todos os registros foram carregados</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container-responsive py-6">
      <div className="flex items-center justify-between mb-6">
        <h1 className="section-title">Histórico de Entradas</h1>
        <button
          onClick={handleExportPDF}
          className="btn btn-sm bg-primary-100 hover:bg-primary-200 text-primary-700 flex items-center"
          disabled={entries.length === 0}
        >
          <Download size={16} className="mr-2" />
          Exportar
        </button>
      </div>

      {isLoading ? (
        <div className="text-center py-6">
          <div className="flex flex-col items-center justify-center">
            <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-2" />
            <p className="text-neutral-600">Carregando histórico...</p>
          </div>
        </div>
      ) : (
        <>
          <div className="xl:grid xl:grid-cols-12 xl:gap-6 2xl:gap-8 w-full">
            {/* Coluna principal para conteúdo em telas maiores */}
            <div className="xl:col-span-8 w-full">
              {entries.length > 0 && (
                <div className="mb-6">
                  <button
                    onClick={handleGenerateRecord}
                    disabled={isGeneratingRecord || !canGenerate}
                    className="btn btn-outline w-full xl:w-auto flex items-center justify-center"
                  >
                    <Download size={18} className="mr-2" />
                    {isGeneratingRecord ? 'Gerando prontuário...' : 
                    !canGenerate ? 'Prontuário já gerado recentemente' : 'Gerar prontuário detalhado'}
                  </button>
                  <p className="text-xs text-neutral-500 mt-2 text-center xl:text-left">
                    {!canGenerate ? 
                      'Um novo prontuário pode ser gerado após 7 dias do último.' : 
                      'Este prontuário pode ser compartilhado com profissionais de saúde mental.'}
                  </p>
                </div>
              )}
              
              {resetError && (
                <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg text-red-600 flex items-center">
                  <AlertCircle size={18} className="mr-2" />
                  {resetError}
                </div>
              )}
              
              {healthRecords.length > 0 && (
                <div className="mb-6">
                  <h2 className="text-lg xl:text-xl font-medium text-neutral-700 mb-3">Prontuários disponíveis</h2>
                  <div className="space-y-2">
                    {healthRecords.map((record, index) => {
                      const isExpanded = expandedRecords[record.id] || false;
                      const recordDate = new Date(record.timestamp);
                      return (
                        <div key={record.id} className="border rounded-lg overflow-hidden">
                          <div 
                            onClick={() => toggleRecordExpansion(record.id)}
                            className={`p-3 xl:p-4 flex justify-between items-center cursor-pointer ${isExpanded ? 'border-primary-500 bg-primary-50' : 'border-neutral-200'}`}
                          >
                            <div className="flex items-center">
                              {isExpanded ? 
                                <ChevronUp size={18} className="mr-2 text-primary-500" /> : 
                                <ChevronDown size={18} className="mr-2 text-neutral-500" />
                              }
                              <div>
                                <p className="font-medium xl:text-lg">
                                  Prontuário de {formatShortDatePtBR(recordDate)}
                                </p>
                                <p className="text-xs xl:text-sm text-neutral-500">
                                  Gerado às {formatDatePtBR(recordDate, "HH:mm")}
                                </p>
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              {index === 0 ? (
                                <button 
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleResetRecord(record.id);
                                  }}
                                  disabled={isResettingRecord || canGenerate}
                                  className={`p-3 touch-manipulation ${isResettingRecord && resetRecordId === record.id ? 'text-primary-500 animate-spin' : canGenerate ? 'text-neutral-300 cursor-not-allowed' : 'text-neutral-600 hover:text-primary-500'}`}
                                  aria-label="Resetar prontuário"
                                  title={canGenerate ? "Gere um novo prontuário em vez de atualizar o existente" : "Atualizar prontuário com novos dados (não cria um novo registro)"}
                                >
                                  <RefreshCw size={20} />
                                </button>
                              ) : (
                                <button 
                                  disabled={true}
                                  className="p-3 text-neutral-300 cursor-not-allowed touch-manipulation"
                                  aria-label="Não é possível resetar este prontuário"
                                  title="Apenas o prontuário mais recente pode ser atualizado"
                                >
                                  <RefreshCw size={20} />
                                </button>
                              )}
                              <button 
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setHealthRecord(record.content);
                                  handleExportPDF();
                                }}
                                className="p-3 text-neutral-600 hover:text-primary-500 touch-manipulation"
                                aria-label="Exportar prontuário como PDF"
                              >
                                <FileText size={20} />
                              </button>
                            </div>
                          </div>
                          
                          {isExpanded && (
                            <div className="p-4 xl:p-6 border-t border-neutral-200">
                              <div className="prose prose-sm xl:prose max-w-none">
                                <ReactMarkdown>{record.content}</ReactMarkdown>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <CustomMoodEntryList />
            </div>
            
            {/* Coluna lateral para ações em telas maiores */}
            <div className="xl:col-span-4 w-full mt-8 xl:mt-0">
              <QuickActions />
            </div>
          </div>
          
          {/* Adiciona o botão de chat flutuante */}
          {user && <FloatingChatButton />}
        </>
      )}
    </div>
  );
};

export default HistoryPage;