/**
 * Interface para item cacheado com timestamp
 */
interface CachedItem<T> {
  data: T;
  timestamp: number;
}

/**
 * Classe utilitária para gerenciar cache no localStorage com TTL
 */
export class LocalStorageCache<T> {
  private readonly cacheKey: string;
  private readonly ttlMs: number;

  /**
   * @param cacheKey Chave única para identificar o cache no localStorage
   * @param ttlMs Tempo de vida do cache em milissegundos (padrão: 24 horas)
   */
  constructor(cacheKey: string, ttlMs: number = 24 * 60 * 60 * 1000) {
    this.cacheKey = cacheKey;
    this.ttlMs = ttlMs;
  }

  /**
   * Salva dados no cache
   * @param data Dados a serem armazenados no cache
   */
  save(data: T): void {
    try {
      const cacheItem: CachedItem<T> = {
        data,
        timestamp: Date.now()
      };
      localStorage.setItem(this.cacheKey, JSON.stringify(cacheItem));
      console.log(`Cache salvo em: ${this.cacheKey}`);
    } catch (error) {
      console.error(`Erro ao salvar cache (${this.cacheKey}):`, error);
    }
  }

  /**
   * Recupera dados do cache se estiverem válidos
   * @returns Dados cacheados ou null se não existir cache válido
   */
  get(): T | null {
    try {
      const cachedData = localStorage.getItem(this.cacheKey);
      if (!cachedData) return null;
      
      const parsedData = JSON.parse(cachedData) as CachedItem<T>;
      
      // Verificar se o cache ainda é válido
      if (this.isValid(parsedData.timestamp)) {
        console.log(`Cache encontrado e válido para: ${this.cacheKey}`);
        return parsedData.data;
      } else {
        console.log(`Cache expirado para: ${this.cacheKey}`);
        this.clear();
        return null;
      }
    } catch (error) {
      console.error(`Erro ao recuperar cache (${this.cacheKey}):`, error);
      this.clear();
      return null;
    }
  }

  /**
   * Verifica se o timestamp do cache ainda é válido
   * @param timestamp Timestamp de quando o cache foi criado
   * @returns Verdadeiro se o cache ainda for válido
   */
  private isValid(timestamp: number): boolean {
    const now = Date.now();
    return (now - timestamp) < this.ttlMs;
  }

  /**
   * Limpa o cache
   */
  clear(): void {
    localStorage.removeItem(this.cacheKey);
    console.log(`Cache limpo: ${this.cacheKey}`);
  }
} 