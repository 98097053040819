import React, { useState } from 'react';
import { OnBoardingUserData } from '../../../types';
import { motion } from 'framer-motion';
import { Heart, Brain, Smile, BookOpen, Coffee, Edit3, Moon, Users, Award, Zap } from 'lucide-react';
import { StepProps } from './StepProps';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';

type MotivationOption = {
  value: string;
  label: string;
  description: string;
  icon: React.ElementType;
};

// Lista unificada de todas as opções de motivação/objetivos
const allOptions: MotivationOption[] = [
  { 
    value: 'melhorar-humor', 
    label: 'Melhorar meu humor', 
    description: 'Quero me sentir melhor no dia a dia',
    icon: Smile 
  },
  { 
    value: 'gerenciar-estresse', 
    label: 'Gerenciar estresse', 
    description: 'Preciso lidar melhor com situações estressantes',
    icon: Brain 
  },
  { 
    value: 'acompanhar-emocoes', 
    label: 'Acompanhar emoções', 
    description: 'Quero entender melhor meus padrões emocionais',
    icon: Heart 
  },
  { 
    value: 'recomendacao-profissional', 
    label: 'Recomendação profissional', 
    description: 'Um profissional de saúde me recomendou',
    icon: BookOpen 
  },
  { 
    value: 'reduzir-ansiedade', 
    label: 'Reduzir sintomas de ansiedade',
    description: 'Quero diminuir minha ansiedade no dia a dia',
    icon: Zap
  },
  { 
    value: 'habilidades-enfrentamento', 
    label: 'Desenvolver habilidades',
    description: 'Quero melhorar minhas habilidades de enfrentamento',
    icon: Award
  },
  { 
    value: 'melhorar-relacionamentos', 
    label: 'Melhorar relacionamentos',
    description: 'Quero ter relações mais saudáveis com outras pessoas',
    icon: Users
  },
  { 
    value: 'aumentar-autoestima', 
    label: 'Aumentar autoestima',
    description: 'Quero me sentir melhor comigo mesmo',
    icon: Award
  },
  { 
    value: 'melhorar-sono', 
    label: 'Melhorar qualidade do sono',
    description: 'Quero dormir melhor e ter mais disposição',
    icon: Moon
  },
  { 
    value: 'desenvolver-habitos', 
    label: 'Desenvolver hábitos saudáveis',
    description: 'Quero criar rotinas que melhorem meu bem-estar',
    icon: Award
  },
  { 
    value: 'curiosidade', 
    label: 'Curiosidade', 
    description: 'Estou apenas explorando o aplicativo',
    icon: Coffee 
  },
  { 
    value: 'outro', 
    label: 'Outro motivo', 
    description: 'Tenho um motivo diferente dos listados',
    icon: Edit3 
  }
];

const MotivationStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  // Converter motivações e objetivos existentes para um único array
  const existingSelections = [
    ...(Array.isArray(userData.motivation) 
      ? userData.motivation as string[] 
      : userData.motivation ? [userData.motivation as string] : []),
    ...(userData.goals || [])
  ];

  const [selectedOptions, setSelectedOptions] = useState<string[]>(existingSelections);
  const [otherOption, setOtherOption] = useState<string>(userData.motivationOther || '');
  const [additionalInfo, setAdditionalInfo] = useState<string>(userData.additionalInfo || '');
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(!!userData.additionalInfo);
  
  const handleOptionToggle = (option: string) => {
    setSelectedOptions(prev => {
      if (prev.includes(option)) {
        return prev.filter(o => o !== option);
      } else {
        // Não há mais limite de seleções
        return [...prev, option];
      }
    });
  };
  
  const handleSubmit = () => {
    if (selectedOptions.length > 0) {
      // Criamos um objeto com os dados para enviar
      const data: Partial<OnBoardingUserData> = {};
      
      // Atribuímos as opções selecionadas como motivações
      // Como a interface espera um tipo específico, usamos type assertion
      data.motivation = selectedOptions.length === 1 && ['melhorar-humor', 'gerenciar-estresse', 'acompanhar-emocoes', 'recomendacao-profissional', 'curiosidade', 'outro'].includes(selectedOptions[0])
        ? selectedOptions[0] as 'melhorar-humor' | 'gerenciar-estresse' | 'acompanhar-emocoes' | 'recomendacao-profissional' | 'curiosidade' | 'outro'
        : undefined; // Se não for um dos valores esperados ou for múltiplo, não atribuímos
      
      // Armazenamos todas as opções selecionadas no campo goals
      data.goals = selectedOptions;
      
      if (selectedOptions.includes('outro') && otherOption) {
        data.motivationOther = otherOption;
      }
      
      if (showAdditionalInfo && additionalInfo.trim()) {
        data.additionalInfo = additionalInfo.trim();
      }
      
      onNext(data);
    }
  };
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          O que te trouxe ao Meu Humor?
        </h3>
        <p className="text-neutral-600">
          Selecione os motivos principais para você usar este aplicativo
        </p>
      </div>
      
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {allOptions.map((option) => {
            const Icon = option.icon;
            return (
              <motion.button
                key={option.value}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => handleOptionToggle(option.value)}
                className={`flex flex-col items-start p-4 rounded-lg border-2 text-left transition-colors ${
                  selectedOptions.includes(option.value)
                    ? 'border-primary-500 bg-primary-50'
                    : 'border-neutral-200 hover:border-neutral-300'
                }`}
              >
                <div className="flex items-center mb-2">
                  <div className={`p-2 rounded-full ${
                    selectedOptions.includes(option.value)
                      ? 'bg-primary-100 text-primary-600'
                      : 'bg-neutral-100 text-neutral-600'
                  }`}>
                    <Icon size={18} />
                  </div>
                  <span className="ml-2 font-medium">{option.label}</span>
                </div>
                <p className="text-sm text-neutral-600">{option.description}</p>
              </motion.button>
            );
          })}
        </div>
        
        {selectedOptions.includes('outro') && (
          <div className="mt-4 p-4 border-2 border-primary-500 rounded-lg bg-primary-50">
            <label htmlFor="otherOption" className="block text-sm font-medium text-neutral-700 mb-1">
              Qual é o seu motivo?
            </label>
            <textarea
              id="otherOption"
              value={otherOption}
              onChange={(e) => setOtherOption(e.target.value)}
              className="block w-full px-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
              rows={3}
              placeholder="Descreva seu motivo para usar o aplicativo..."
            />
          </div>
        )}
        
        {selectedOptions.length > 0 && (
          <div className="mt-4">
            <button
              type="button"
              onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
              className="text-primary-600 hover:text-primary-700 text-sm font-medium flex items-center"
            >
              {showAdditionalInfo ? 'Ocultar informações adicionais' : 'Adicionar informações adicionais'}
            </button>
            
            {showAdditionalInfo && (
              <div className="mt-3 p-4 border-2 border-primary-500 rounded-lg bg-primary-50">
                <label htmlFor="additional-info" className="block text-sm font-medium text-neutral-700 mb-1">
                  Há algo mais que você gostaria de compartilhar conosco?
                </label>
                <textarea
                  id="additional-info"
                  value={additionalInfo}
                  onChange={(e) => setAdditionalInfo(e.target.value)}
                  className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                  rows={3}
                  placeholder="Compartilhe informações adicionais que possam nos ajudar a personalizar sua experiência"
                />
              </div>
            )}
          </div>
        )}
        
        <div className="pt-4 flex justify-between items-center">
          {showBackButton && (
            <Button
              type="button"
              variant="ghost"
              onClick={onBack}
              disabled={isSubmitting}
            >
              Voltar
            </Button>
          )}
          
          <div className="space-x-2">
            <Button
              type="button"
              variant="ghost"
              onClick={onSkip}
              disabled={isSubmitting}
            >
              Pular
            </Button>
            
            <Button
              type="button"
              onClick={handleSubmit}
              disabled={selectedOptions.length === 0 || (selectedOptions.includes('outro') && !otherOption) || isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processando
                </>
              ) : (
                'Continuar'
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotivationStep; 