import React, { useEffect, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface GoogleAdsenseProps {
  client: string;
  slot: string;
  format?: 'auto' | 'rectangle' | 'horizontal' | 'vertical';
  responsive?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

// Global script loading state to prevent multiple script loads
let isScriptLoaded = false;

const GoogleAdsense: React.FC<GoogleAdsenseProps> = ({
  client,
  slot,
  format = 'auto',
  responsive = true,
  style = {},
  className = '',
}) => {
  // Obter o plano atual do usuário do contexto de autenticação
  const { currentPlan } = useAuth();
  const adRef = useRef<HTMLDivElement>(null);
  const isAdPushed = useRef<boolean>(false);
  
  // Não renderizar anúncios para usuários com planos diferentes de "basic"
  if (currentPlan !== 'basic') {
    return null;
  }

  useEffect(() => {
    // Load AdSense script if it hasn't been loaded yet
    if (!isScriptLoaded && window.adsbygoogle === undefined) {
      isScriptLoaded = true;
      const script = document.createElement('script');
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${client}`;
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    }

    // Only push the ad if it hasn't been pushed yet
    const pushAd = () => {
      if (!isAdPushed.current && adRef.current) {
        try {
          // Mark as pushed before the actual push to prevent double-pushing
          isAdPushed.current = true;
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
          console.error('AdSense error:', error);
        }
      }
    };

    // Wait for AdSense to be available
    if (window.adsbygoogle) {
      pushAd();
    } else {
      // If AdSense isn't available yet, wait for it
      const interval = setInterval(() => {
        if (window.adsbygoogle) {
          pushAd();
          clearInterval(interval);
        }
      }, 200);
      
      // Clean up interval
      return () => clearInterval(interval);
    }
  }, [client]);

  return (
    <div className={`google-adsense ${className}`} ref={adRef}>
      <ins
        className="adsbygoogle"
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-layout-key="-gw-3+1f-3d+2z"
        data-ad-format={format}
        data-full-width-responsive={responsive ? 'true' : 'false'}
      />
    </div>
  );
};

export default GoogleAdsense;

// Add this to global.d.ts or create a types file
declare global {
  interface Window {
    adsbygoogle: any[];
  }
}
