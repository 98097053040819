import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircle, ArrowRight, Brain, Loader2 } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';

const PaymentSuccessPage: React.FC = () => {
  const { user, refreshUser } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const hasCheckedRef = useRef(false);
  
  useEffect(() => {
    // Se não temos usuário, não precisamos verificar
    if (!user) {
      setIsChecking(false);
      return;
    }
    
    // Se já verificamos, não precisamos verificar novamente
    if (hasCheckedRef.current) {
      return;
    }
    
    // Marcamos que já executamos a verificação
    hasCheckedRef.current = true;

    setTimeout(() => {
      setIsChecking(false);
    }, 7000)
    
    const checkPaymentStatus = async () => {
      try {
        // Tentamos atualizar os dados do usuário para ver se o webhook já processou
        await refreshUser();
      } catch (error) {
        console.error('Erro ao atualizar dados do usuário:', error);
      }
    };
    
    // Executa a verificação
    checkPaymentStatus(); 
  }, [user, refreshUser]);

  return (
    <>
      <Helmet>
        <title>Pagamento Confirmado | Meu Humor</title>
        <meta name="description" content="Seu pagamento foi confirmado com sucesso. Aproveite seu novo plano!" />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white flex flex-col">
        {/* Header/Navigation */}
        <header className="py-3 sm:py-4 px-2 sm:px-4 md:px-8 max-w-7xl mx-auto w-full">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-1 sm:space-x-2">
              <div className="bg-primary-100 p-1.5 sm:p-2 rounded-full">
                <Brain className="h-4 w-4 sm:h-5 sm:w-5 text-primary-500" />
              </div>
              <h1 className="text-base sm:text-lg md:text-xl font-semibold text-neutral-800">Meu Humor</h1>
            </Link>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center px-4 py-12">
          <motion.div 
            className="bg-white rounded-xl shadow-medium p-8 max-w-md w-full text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {isChecking ? (
              <>
                <div className="flex justify-center mb-6">
                  <Loader2 className="h-12 w-12 text-primary-500 animate-spin" />
                </div>
                <h2 className="text-2xl font-bold text-neutral-800 mb-4">Processando seu pagamento</h2>
                <p className="text-neutral-600 mb-8">
                  Estamos confirmando seu pagamento. Isso pode levar alguns instantes...
                </p>
              </>
            ) : (
              <>
                <div className="flex justify-center mb-6">
                  <div className="bg-green-100 p-4 rounded-full">
                    <CheckCircle className="h-12 w-12 text-green-500" />
                  </div>
                </div>
                
                <h2 className="text-2xl font-bold text-neutral-800 mb-4">Pagamento Confirmado!</h2>
                
                <p className="text-neutral-600 mb-8">
                  Obrigado por assinar! 
                  Seu pagamento foi processado com sucesso e seu plano será ativado em breve.
                </p>
                
                <div className="space-y-4">
                  <Link to="/home" className="btn btn-primary w-full flex items-center justify-center">
                    Ir para o Dashboard
                    <ArrowRight className="h-4 w-4 ml-2" />
                  </Link>
                  
                  <p className="text-sm text-neutral-500 mt-4">
                    Pode levar alguns minutos para que todos os recursos do seu novo plano sejam ativados.
                    Se após 10 minutos você ainda não tiver acesso, por favor entre em contato com nosso suporte.
                  </p>
                </div>
              </>
            )}
          </motion.div>
        </main>

        {/* Footer */}
        <footer className="py-6 px-4 border-t border-neutral-200">
          <div className="max-w-7xl mx-auto text-center text-neutral-500 text-sm">
            &copy; {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.
          </div>
        </footer>
      </div>
    </>
  );
};

export default PaymentSuccessPage;
