import React, { useState } from 'react';
import { OnBoardingUserData } from '../../../types';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';

interface CurrentMoodStepProps {
  userData: Partial<OnBoardingUserData>;
  onNext: (data: Partial<OnBoardingUserData>) => void;
  onSkip: () => void;
  onBack: () => void;
  isSubmitting: boolean;
  showBackButton?: boolean;
}

// Opções de humor - agora de 0 a 5, igual ao MoodAddForm
const moodOptions = [
  { value: 0, label: 'Muito mal', emoji: '😭', color: 'bg-red-500' },
  { value: 1, label: 'Mal', emoji: '😔', color: 'bg-orange-500' },
  { value: 2, label: 'Regular', emoji: '😐', color: 'bg-yellow-500' },
  { value: 3, label: 'Bem', emoji: '🙂', color: 'bg-lime-500' },
  { value: 4, label: 'Muito bem', emoji: '😄', color: 'bg-green-500' },
  { value: 5, label: 'Excelente', emoji: '🤩', color: 'bg-emerald-500' }
];

const CurrentMoodStep: React.FC<CurrentMoodStepProps> = ({
  userData,
  onNext,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [selectedMood, setSelectedMood] = useState<number | null>(
    userData.currentMood !== undefined ? userData.currentMood : null
  );
  const [moodNote, setMoodNote] = useState(userData.moodNote || '');
  
  const handleMoodSelect = (value: number) => {
    setSelectedMood(value);
  };
  
  const handleSubmit = () => {
    if (selectedMood !== null) {
      onNext({ 
        currentMood: selectedMood,
        moodNote: moodNote.trim() || undefined
      });
    }
  };
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          Como você está se sentindo agora?
        </h3>
        <p className="text-neutral-600">
          Selecione a opção que melhor representa seu humor atual
        </p>
      </div>
      
      {/* Grid responsivo: 2 colunas em mobile, 3 em tablet, 6 em desktop */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-2 mb-6">
        {moodOptions.map((option) => (
          <div 
            key={option.value}
            onClick={() => handleMoodSelect(option.value)}
            className={`flex flex-col items-center p-3 rounded-lg cursor-pointer transition-all ${
              selectedMood === option.value 
                ? `ring-2 ring-primary-500 ${option.color} text-white` 
                : 'bg-white hover:bg-neutral-50 border border-neutral-200'
            }`}
          >
            <span className="text-2xl mb-1">{option.emoji}</span>
            <span className="text-xs font-medium">{option.label}</span>
          </div>
        ))}
      </div>
      
      {selectedMood !== null && (
        <div className="mb-6">
          <label htmlFor="mood-note" className="block text-sm font-medium text-neutral-700 mb-2">
            Descreva como você está se sentindo (opcional)
          </label>
          <textarea
            id="mood-note"
            value={moodNote}
            onChange={(e) => setMoodNote(e.target.value)}
            placeholder="O que está influenciando seu humor atual?"
            className="w-full min-h-[100px] p-3 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
            maxLength={255}
          />
          <div className="text-right mt-1">
            <span className="text-xs text-neutral-500">
              {moodNote.length}/255 caracteres
            </span>
          </div>
        </div>
      )}
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={selectedMood === null || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentMoodStep; 