/**
 * Utilitários para formatação de dados na interface
 */

/**
 * Formata um email hasheado para exibição na interface, abreviando o hash
 * para evitar problemas de layout
 * 
 * @param email Email a ser formatado
 * @returns Email formatado de forma abreviada
 */
export const formatHashedEmail = (email: string | undefined): string => {
  if (!email) return 'Usuário';
  
  // Se for um email hasheado no formato anon_[hash]@hashed.com
  if (email.startsWith('anon_') && email.includes('@hashed.com')) {
    // Extrair apenas os primeiros 8 caracteres do hash após "anon_"
    const hash = email.substring(5, email.indexOf('@'));
    return `anon_${hash.substring(0, 8)}...`;
  }
  
  // Para outros formatos, usar a parte antes do @
  return email.split('@')[0];
};

/**
 * Formata um timestamp para uma data legível
 * 
 * @param timestamp Timestamp em milissegundos
 * @returns Data formatada no padrão DD/MM/YYYY
 */
export const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('pt-BR');
};

/**
 * Formata uma string muito longa, truncando-a com reticências
 * 
 * @param text Texto a ser truncado
 * @param maxLength Comprimento máximo antes de truncar
 * @returns Texto truncado com reticências
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
}; 