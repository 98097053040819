import { ChatAIResponse, ChatSessionsResponse, ChatSessionMessagesResponse } from '../../types';

interface FetchError extends Error {
  name: string;
}

// Interface para a resposta inicial do chat que contém o messageId e pollUrl
interface ChatRequestResponse {
  status: 'accepted';
  message: string;
  messageId: string;
  sessionId: string;
  pollUrl: string;
}

// Interface para o status da resposta durante o polling
interface ChatPollResponse {
  status: 'pending' | 'processing' | 'completed' | 'failed';
  messageId: string;
  response?: string;
  error?: string;
}

export class ChatAIService {
  private readonly apiUrl: string;
  private userId?: string;
  private readonly requestTimeout: number = 30000; // 30 segundos de timeout para requisições de IA
  private readonly pollingInterval: number = 1000; // 1 segundo entre cada tentativa de polling
  private readonly maxPollingAttempts: number = 60; // Máximo de 30 tentativas (30 segundos)

  constructor() {
    this.apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
  }

  // Método para atualizar o userId
  public setUserId(userId: string): void {
    this.userId = userId;
  }

  // Método para obter o userId atual ou usar fallback
  private getUserId(): string {
    // Se não temos userId, tentamos obter do localStorage
    if (!this.userId) {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        this.userId = storedUserId;
      }
    }
    
    // Se ainda não temos userId, usamos o repositório local
    return this.userId || '';
  }

  // Método para obter o token de autenticação
  private getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  /**
   * Envia uma mensagem para a API do chat e inicia o processo de polling para obter a resposta
   * @param message - Mensagem do usuário para enviar ao chatbot
   * @param sessionId - ID opcional da sessão existente para continuar a conversa
   * @param createNewSession - Flag para indicar se deve criar uma nova sessão
   * @param extraAttempts - Número adicional de tentativas para o polling (para mensagens reenviadas)
   * @returns Resposta da API de chat após processamento
   */
  async sendMessage(message: string, sessionId?: string, createNewSession?: boolean, extraAttempts: number = 0): Promise<ChatAIResponse> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao enviar mensagem para o chat: token não disponível');
      return {
        answer: 'Desculpe, não foi possível autenticar sua solicitação. Por favor, faça login novamente.'
      };
    }
    
    try {
      // Primeira etapa: Enviar a mensagem para a API
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout);
      
      // Prepara os dados para envio
      const requestData: { message: string; sessionId?: string; newSession?: boolean } = { message };
      
      // Adiciona sessionId ou createNewSession se fornecidos
      if (sessionId) {
        requestData.sessionId = sessionId;
      }
      
      if (createNewSession) {
        requestData.newSession = true;
      }
      
      const response = await fetch(`${this.apiUrl}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(requestData),
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        throw new Error(`Erro ao enviar mensagem para o chat: ${response.statusText}`);
      }
      
      // Obter a resposta inicial com o pollUrl
      const chatRequest = await response.json() as ChatRequestResponse;
      
      // Segunda etapa: Fazer polling para obter a resposta final
      // Passamos o número de tentativas extras para o método de polling
      const finalResponse = await this.pollForResponse(chatRequest.pollUrl, authToken, extraAttempts);
      
      return {
        answer: finalResponse.response || 'Desculpe, ocorreu um erro ao processar sua solicitação.'
      };
    } catch (error: unknown) {
      console.error('Erro ao enviar mensagem para o chat:', error);
      const fetchError = error as FetchError;
      if (fetchError.name === 'AbortError') {
        return {
          answer: 'A solicitação demorou muito tempo. Por favor, tente novamente ou formule sua mensagem de forma mais concisa.'
        };
      }
      return {
        answer: 'Desculpe, houve um problema ao processar sua mensagem. Por favor, tente novamente.'
      };
    }
  }

  /**
   * Realiza o polling para obter a resposta processada da IA
   * @param pollUrl - URL para verificar o status da resposta
   * @param authToken - Token de autenticação para as requisições
   * @param extraAttempts - Número adicional de tentativas para o polling (para mensagens reenviadas)
   * @returns Resposta final da API após processamento
   */
  private async pollForResponse(pollUrl: string, authToken: string, extraAttempts: number = 0): Promise<ChatPollResponse> {
    let attempts = 0;
    
    while (attempts < this.maxPollingAttempts + extraAttempts) {
      try {
        // Aguarda o intervalo de polling antes de tentar novamente (exceto na primeira tentativa)
        if (attempts > 0) {
          await new Promise(resolve => setTimeout(resolve, this.pollingInterval));
        }
        
        const fullPollUrl = `${this.apiUrl}${pollUrl.startsWith('/') ? pollUrl : `/${pollUrl}`}`;
        const response = await fetch(fullPollUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        
        if (!response.ok) {
          throw new Error(`Erro ao verificar status da resposta: ${response.statusText}`);
        }
        
        const pollResponse = await response.json() as ChatPollResponse;
        
        // Se o status for "completed", retorna a resposta final
        if (pollResponse.status === 'completed') {
          return pollResponse;
        }
        
        // Se o status for "failed", lança um erro
        if (pollResponse.status === 'failed') {
          throw new Error(pollResponse.error || 'Falha ao processar a mensagem');
        }
        
        // Incrementa o contador de tentativas e continua o polling
        attempts++;
      } catch (error) {
        console.error('Erro durante o polling:', error);
        throw error;
      }
    }
    
    // Retorna um erro se exceder o número máximo de tentativas
    return {
      status: 'failed',
      messageId: '',
      error: 'Tempo limite excedido ao aguardar resposta'
    };
  }

  /**
   * Obtém todas as sessões de chat do usuário
   * @returns Lista de sessões de chat
   */
  async getSessions(): Promise<ChatSessionsResponse> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao obter sessões: token não disponível');
      throw new Error('Não foi possível autenticar sua solicitação');
    }
    
    try {
      const response = await fetch(`${this.apiUrl}/chat/sessions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao obter sessões: ${response.statusText}`);
      }
      
      return await response.json() as ChatSessionsResponse;
    } catch (error) {
      console.error('Erro ao obter sessões de chat:', error);
      throw error;
    }
  }

  /**
   * Obtém todas as mensagens de uma sessão específica
   * @param sessionId - ID da sessão para buscar as mensagens
   * @returns Lista de mensagens da sessão
   */
  async getSessionMessages(sessionId: string): Promise<ChatSessionMessagesResponse> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao obter mensagens da sessão: token não disponível');
      throw new Error('Não foi possível autenticar sua solicitação');
    }
    
    try {
      const response = await fetch(`${this.apiUrl}/chat/session/${sessionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao obter mensagens da sessão: ${response.statusText}`);
      }
      
      return await response.json() as ChatSessionMessagesResponse;
    } catch (error) {
      console.error('Erro ao obter mensagens da sessão:', error);
      throw error;
    }
  }

  /**
   * Analisa o humor a partir de uma mensagem de texto
   * @param message - Mensagem do usuário para analisar
   * @returns Análise de humor ou null se não for possível analisar
   */
  async analyzeMood(message: string): Promise<ChatAIResponse | null> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao analisar humor: token não disponível');
      return null;
    }
    
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout);
      
      const response = await fetch(`${this.apiUrl}/analyze-mood`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ message }),
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        throw new Error(`Erro ao analisar humor: ${response.statusText}`);
      }
      
      return await response.json();
    } catch (error: unknown) {
      console.error('Erro ao analisar humor:', error);
      const fetchError = error as FetchError;
      if (fetchError.name === 'AbortError') {
        return {
          answer: 'A análise de humor demorou muito tempo. Por favor, tente novamente com uma descrição mais curta.'
        };
      }
      return null;
    }
  }

  /**
   * Busca dados do histórico de humor através do chat
   * @param query - Consulta para buscar no histórico
   * @returns Resposta da IA com os dados encontrados
   */
  async searchMoodHistory(query: string): Promise<ChatAIResponse> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar histórico: token não disponível');
      return {
        answer: 'Desculpe, não foi possível autenticar sua solicitação para buscar seu histórico.'
      };
    }
    
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout);
      
      const response = await fetch(`${this.apiUrl}/chat/search-history`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ query }),
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        throw new Error(`Erro ao buscar histórico: ${response.statusText}`);
      }
      
      return await response.json();
    } catch (error: unknown) {
      console.error('Erro ao buscar histórico de humor:', error);
      const fetchError = error as FetchError;
      if (fetchError.name === 'AbortError') {
        return {
          answer: 'A busca no histórico demorou muito tempo. Por favor, tente uma consulta mais específica.'
        };
      }
      return {
        answer: 'Desculpe, não foi possível buscar seu histórico neste momento.'
      };
    }
  }

  /**
   * Recebe sugestões personalizadas da IA
   * @returns Resposta da IA com sugestões baseadas no histórico
   */
  async getSuggestions(): Promise<ChatAIResponse> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao obter sugestões: token não disponível');
      return {
        answer: 'Desculpe, não foi possível autenticar sua solicitação para obter sugestões.'
      };
    }
    
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.requestTimeout);
      
      const response = await fetch(`${this.apiUrl}/chat/suggestions`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        throw new Error(`Erro ao obter sugestões: ${response.statusText}`);
      }
      
      return await response.json();
    } catch (error: unknown) {
      console.error('Erro ao obter sugestões da IA:', error);
      const fetchError = error as FetchError;
      if (fetchError.name === 'AbortError') {
        return {
          answer: 'A geração de sugestões demorou muito tempo. Por favor, tente novamente mais tarde.'
        };
      }
      return {
        answer: 'Desculpe, não foi possível gerar sugestões neste momento.'
      };
    }
  }
} 