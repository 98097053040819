import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Chaves para o localStorage
const STORAGE_KEYS = {
  NOTIFICATION_PERMISSION: 'notification_permission_dismissed',
  NOTIFICATION_MOCK: 'notification_mock_dismissed',
  SAFARI_IOS_INSTRUCTIONS: 'safari_ios_instructions_dismissed'
};

// Função para verificar se uma notificação deve ser ocultada
export function shouldHideNotification(key: string): boolean {
  try {
    const dismissedTime = localStorage.getItem(key);
    
    if (!dismissedTime) {
      return false;
    }
    
    const dismissedAt = parseInt(dismissedTime, 10);
    const currentTime = Date.now();
    const twoHoursInMs = 2 * 60 * 60 * 1000; // 2 horas em milissegundos
    
    // Retorna true se ainda não passaram 2 horas desde que foi fechado
    return currentTime - dismissedAt < twoHoursInMs;
  } catch (error) {
    console.error('Erro ao verificar localStorage:', error);
    return false;
  }
}

// Função para marcar uma notificação como fechada
export function markNotificationAsDismissed(key: string): void {
  try {
    localStorage.setItem(key, Date.now().toString());
  } catch (error) {
    console.error('Erro ao salvar no localStorage:', error);
  }
}

// Exportar as chaves de armazenamento
export { STORAGE_KEYS }; 