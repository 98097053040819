import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Lista de condições de saúde mental comuns
const mentalHealthConditions = [
  'Depressão',
  'Ansiedade',
  'Transtorno Bipolar',
  'Transtorno Obsessivo-Compulsivo (TOC)',
  'Transtorno de Estresse Pós-Traumático (TEPT)',
  'Transtorno de Déficit de Atenção e Hiperatividade (TDAH)',
  'Esquizofrenia',
  'Transtorno de Personalidade',
  'Transtorno Alimentar',
  'Outro'
];

const MentalHealthHistoryStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [hasMentalHealthCondition, setHasMentalHealthCondition] = useState<boolean | undefined>(
    userData.hasMentalHealthCondition
  );
  const [selectedConditions, setSelectedConditions] = useState<string[]>(
    userData.mentalHealthConditionDetails?.split(',').map(item => item.trim()) || []
  );
  const [otherCondition, setOtherCondition] = useState<string>('');
  
  const handleConditionToggle = (condition: string) => {
    setSelectedConditions(prev => {
      if (prev.includes(condition)) {
        return prev.filter(c => c !== condition);
      } else {
        return [...prev, condition];
      }
    });
  };
  
  const handleSubmit = () => {
    // Preparar os detalhes das condições
    let conditionDetails = selectedConditions.filter(c => c !== 'Outro').join(', ');
    
    // Adicionar a condição "Outro" se selecionada e especificada
    if (selectedConditions.includes('Outro') && otherCondition.trim()) {
      conditionDetails += conditionDetails ? `, ${otherCondition.trim()}` : otherCondition.trim();
    }
    
    onNext({
      hasMentalHealthCondition: hasMentalHealthCondition === true,
      mentalHealthConditionDetails: conditionDetails || undefined
    });
  };
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Histórico de Saúde Mental</h3>
        <p className="text-neutral-600 mb-4">
          Estas informações nos ajudarão a personalizar sua experiência. Todas as informações são confidenciais.
        </p>
      </div>
      
      <div className="space-y-4">
        <div>
          <p className="font-medium text-neutral-700 mb-2">
            Você já foi diagnosticado com alguma condição de saúde mental?
          </p>
          <div className="flex space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="has-condition-yes"
                name="has-condition"
                checked={hasMentalHealthCondition === true}
                onChange={() => setHasMentalHealthCondition(true)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="has-condition-yes" className="ml-2 text-neutral-700">
                Sim
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="has-condition-no"
                name="has-condition"
                checked={hasMentalHealthCondition === false}
                onChange={() => setHasMentalHealthCondition(false)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="has-condition-no" className="ml-2 text-neutral-700">
                Não
              </label>
            </div>
          </div>
        </div>
        
        {hasMentalHealthCondition && (
          <div className="mt-4">
            <p className="font-medium text-neutral-700 mb-2">
              Selecione as condições que se aplicam a você:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {mentalHealthConditions.map((condition) => (
                <div key={condition} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`condition-${condition}`}
                    checked={selectedConditions.includes(condition)}
                    onChange={() => handleConditionToggle(condition)}
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                  />
                  <label htmlFor={`condition-${condition}`} className="ml-2 text-neutral-700">
                    {condition}
                  </label>
                </div>
              ))}
            </div>
            
            {selectedConditions.includes('Outro') && (
              <div className="mt-3">
                <label htmlFor="other-condition" className="block text-sm font-medium text-neutral-700 mb-1">
                  Especifique:
                </label>
                <input
                  type="text"
                  id="other-condition"
                  value={otherCondition}
                  onChange={(e) => setOtherCondition(e.target.value)}
                  className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Digite a condição"
                />
              </div>
            )}
          </div>
        )}
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={(hasMentalHealthCondition === undefined) || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MentalHealthHistoryStep; 