import { HealthRecord } from '../../types';

export class HealthRecordService {
  private readonly apiUrl: string;

  constructor() {
    this.apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
  }

  // Método para obter o token de autenticação
  private getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  async createRecord(): Promise<HealthRecord | null> {
    try {
      const authToken = this.getAuthToken();
      
      if (!authToken) {
        console.error('Erro ao criar prontuário: token não disponível');
        throw new Error('Erro ao criar prontuário: token não disponível');
      }
      
      console.log('Iniciando criação de prontuário via API...');
      
      const response = await fetch(`${this.apiUrl}/health-records`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao criar prontuário: ${response.statusText}`);
      }
      
      const record = await response.json();
      console.log('Prontuário criado com sucesso:', record);
      
      return record;
    } catch (error) {
      console.error('Erro ao criar prontuário:', error);
      throw error;
    }
  }

  async getRecords(limit = 2): Promise<HealthRecord[]> {
    try {
      const authToken = this.getAuthToken();
      
      if (!authToken) {
        console.error('Erro ao buscar prontuários: token não disponível');
        throw new Error('Erro ao buscar prontuários: token não disponível');
      }
      
      console.log('Buscando prontuários via API...');
      
      // Construir a URL com os parâmetros de consulta
      const url = new URL(`${this.apiUrl}/health-records`);
      url.searchParams.append('limit', limit.toString());
      
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao buscar prontuários: ${response.statusText}`);
      }
      
      const records = await response.json();
      console.log('Prontuários obtidos com sucesso:', records);
      
      return records;
    } catch (error) {
      console.error('Erro ao buscar prontuários:', error);
      throw error;
    }
  }

  async canGenerateRecord(): Promise<boolean> {
    try {
      const authToken = this.getAuthToken();
      
      if (!authToken) {
        console.error('Erro ao verificar elegibilidade para prontuário: token não disponível');
        throw new Error('Erro ao verificar elegibilidade para prontuário: token não disponível');
      }
      
      console.log('Verificando elegibilidade para prontuário via API...');
      
      const response = await fetch(`${this.apiUrl}/health-records/can-generate`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao verificar elegibilidade para prontuário: ${response.statusText}`);
      }
      
      const data = await response.json();
      console.log('Verificação de elegibilidade concluída:', data);
      
      return data.canGenerate;
    } catch (error) {
      console.error('Erro ao verificar elegibilidade para prontuário:', error);
      throw error;
    }
  }

  async resetRecord(recordId: string): Promise<HealthRecord | null> {
    try {
      const authToken = this.getAuthToken();
      
      if (!authToken) {
        console.error('Erro ao atualizar prontuário: token não disponível');
        throw new Error('Erro ao atualizar prontuário: token não disponível');
      }
      
      if (!recordId) {
        console.error('Erro ao atualizar prontuário: ID não fornecido');
        throw new Error('Erro ao atualizar prontuário: ID não fornecido');
      }
      
      console.log('Iniciando atualização de prontuário via API:', recordId);
      
      const response = await fetch(`${this.apiUrl}/health-records/${recordId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao atualizar prontuário: ${response.statusText}`);
      }
      
      const updatedRecord = await response.json();
      console.log('Prontuário atualizado com sucesso:', updatedRecord);
      
      return updatedRecord;
    } catch (error) {
      console.error('Erro ao atualizar prontuário:', error);
      throw error;
    }
  }
} 