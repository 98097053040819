import React from 'react';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ChatTrigger from './ChatAI/ChatTrigger';
import { useLocation } from 'react-router-dom';

interface QuickActionsProps {
  className?: string;
}

const QuickActions: React.FC<QuickActionsProps> = ({ className = '' }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/home';
  
  return (
    <div className={`sidebar-card space-y-4 w-full ${className}`}>
      <h3 className="text-lg font-medium text-neutral-700 mb-4">Ações rápidas</h3>
      <div className="flex flex-col space-y-4">
        {/* Card de assistente IA - customizado de acordo com a página */}
        <ChatTrigger
          variant="card"
          label="Conversar com Assistente IA"
          message={isHomePage ? "Como estou me sentindo hoje?" : "Analisar meu histórico de humor"}
          className="w-full"
        />
        <button
          onClick={() => navigate('/registrar-lote')}
          className="btn btn-outline btn-action w-full text-sm"
        >
          <Plus size={16} className="btn-action-icon" />
          <span>Humores passados</span>
        </button>
        <button
          onClick={() => navigate('/registrar')}
          className="btn btn-primary btn-action w-full text-sm"
        >
          <Plus size={16} className="btn-action-icon" />
          <span>Humor agora</span>
        </button>
      </div>
      
      {/* Componente adicional visível em todas as telas */}
      <div className="mt-6">
        <h3 className="text-lg font-medium text-neutral-700 mb-3">Dicas de uso</h3>
        <p className="text-neutral-600 text-sm">
          Você pode exportar seus prontuários como PDF para compartilhar com profissionais de saúde mental.
          Registre seu humor regularmente para obter análises mais precisas.
        </p>
      </div>
      
      {/* Componente adicional para desktop */}
      <div className="mt-6 p-4 bg-primary-50 rounded-xl">
        <h3 className="text-lg font-medium text-primary-700 mb-3">Benefícios do registro</h3>
        <ul className="space-y-2 text-sm text-neutral-600">
          <li className="flex items-start">
            <span className="text-primary-500 mr-2">•</span>
            <span>Identifica padrões emocionais</span>
          </li>
          <li className="flex items-start">
            <span className="text-primary-500 mr-2">•</span>
            <span>Ajuda a reconhecer gatilhos</span>
          </li>
          <li className="flex items-start">
            <span className="text-primary-500 mr-2">•</span>
            <span>Facilita a comunicação com profissionais</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickActions; 