import { OnBoardingStatus, OnBoardingUserData, MoodEntry } from '../../types';

class OnBoardingService {
  private readonly apiUrl: string;

  constructor() {
    this.apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
  }

  // Método para obter o token de autenticação
  private getAuthToken(): string | null {
    return localStorage.getItem('authToken');
  }

  // Obter o status do OnBoarding da API
  async getOnBoardingStatus(): Promise<OnBoardingStatus> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao buscar status do OnBoarding: token não disponível');
      // Retornar status padrão em caso de erro
      return {
        hasSeenOnBoarding: false,
        completedSteps: [],
        lastUpdated: Date.now()
      };
    }
    
    try {
      const response = await fetch(`${this.apiUrl}/onboarding/status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error(`Erro ao buscar status do OnBoarding: ${response.statusText}`);
      }

      const data = await response.json();
      return data as OnBoardingStatus;
    } catch (error) {
      console.error('Erro ao obter status do OnBoarding da API:', error);
      // Retornar status padrão em caso de erro
      return {
        hasSeenOnBoarding: false,
        completedSteps: [],
        lastUpdated: Date.now()
      };
    }
  }

  // Atualizar o status do OnBoarding via API
  async updateOnBoardingStatus(status: Partial<OnBoardingStatus>): Promise<OnBoardingStatus> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao atualizar status do OnBoarding: token não disponível');
      throw new Error('Erro ao atualizar status do OnBoarding: token não disponível');
    }
    
    try {
      // Obter o status atual para combinar com as atualizações
      const currentStatus = await this.getOnBoardingStatus();
      
      // Preparar o corpo da requisição com os dados atualizados
      const updatedStatus: Partial<OnBoardingStatus> = {
        hasSeenOnBoarding: status.hasSeenOnBoarding !== undefined ? status.hasSeenOnBoarding : currentStatus.hasSeenOnBoarding,
        completedSteps: status.completedSteps || currentStatus.completedSteps,
        lastCompletedStep: status.lastCompletedStep || currentStatus.lastCompletedStep
      };
      
      // Fazer a chamada à API
      const response = await fetch(`${this.apiUrl}/onboarding/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(updatedStatus)
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao atualizar status do OnBoarding: ${response.statusText}`);
      }
      
      // Obter a resposta atualizada da API
      const data = await response.json();
      return data as OnBoardingStatus;
    } catch (error) {
      console.error('Erro ao atualizar status do OnBoarding na API:', error);
      throw error;
    }
  }

  // Marcar o OnBoarding como visualizado usando a API diretamente
  async markOnBoardingAsSeen(): Promise<OnBoardingStatus> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao marcar OnBoarding como visualizado: token não disponível');
      throw new Error('Erro ao marcar OnBoarding como visualizado: token não disponível');
    }
    
    try {
      // Chamar o endpoint específico para marcar como visualizado
      const response = await fetch(`${this.apiUrl}/onboarding/seen`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao marcar OnBoarding como visualizado: ${response.statusText}`);
      }
      
      // Obter a resposta atualizada da API
      const data = await response.json();
      return data as OnBoardingStatus;
    } catch (error) {
      console.error('Erro ao marcar OnBoarding como visualizado na API:', error);
      throw error;
    }
  }

  // Marcar um passo como concluído usando a API diretamente
  async completeStep(stepId: string): Promise<OnBoardingStatus> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao marcar passo como concluído: token não disponível');
      throw new Error('Erro ao marcar passo como concluído: token não disponível');
    }
    
    try {
      // Chamar o endpoint específico para marcar um passo como concluído
      const response = await fetch(`${this.apiUrl}/onboarding/steps/${stepId}/complete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao marcar passo como concluído: ${response.statusText}`);
      }
      
      // Obter a resposta atualizada da API
      const data = await response.json();
      return data as OnBoardingStatus;
    } catch (error) {
      console.error('Erro ao marcar passo como concluído na API:', error);
      throw error;
    }
  }

  // Verificar se um passo foi concluído usando a API diretamente
  async isStepCompleted(stepId: string): Promise<boolean> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao verificar conclusão do passo: token não disponível');
      return false; // Retorna false em caso de erro com o token
    }
    
    try {
      // Chamar o endpoint específico para verificar se um passo foi concluído
      const response = await fetch(`${this.apiUrl}/onboarding/steps/${stepId}/completed`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao verificar conclusão do passo: ${response.statusText}`);
      }
      
      // Obter a resposta da API
      const data = await response.json();
      return data.isCompleted; // Retorna o valor booleano da propriedade isCompleted
    } catch (error) {
      console.error('Erro ao verificar conclusão do passo na API:', error);
      return false; // Retorna false em caso de erro na chamada à API
    }
  }

  // Obter os dados do usuário do OnBoarding usando a API diretamente
  async getUserData(): Promise<OnBoardingUserData | null> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao obter dados do usuário do OnBoarding: token não disponível');
      return null; // Retorna null em caso de erro com o token
    }
    
    try {
      // Chamar o endpoint específico para obter os dados do usuário
      const response = await fetch(`${this.apiUrl}/onboarding/user-data`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao obter dados do usuário do OnBoarding: ${response.statusText}`);
      }
      
      // Obter a resposta da API
      const data = await response.json();
      return data as OnBoardingUserData;
    } catch (error) {
      console.error('Erro ao obter dados do usuário do OnBoarding da API:', error);
      return null; // Retorna null em caso de erro na chamada à API
    }
  }

  // Atualizar os dados do usuário do OnBoarding usando a API diretamente
  async updateUserData(data: Partial<OnBoardingUserData>): Promise<OnBoardingUserData> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao atualizar dados do usuário do OnBoarding: token não disponível');
      throw new Error('Erro ao atualizar dados do usuário do OnBoarding: token não disponível');
    }
    
    try {
      // Obter os dados atuais do usuário para combinar com as atualizações
      const currentData = await this.getUserData();
      
      // Verificar se há campos que devem ser removidos (undefined ou null)
      const fieldsToRemove = Object.keys(data).filter(key => 
        data[key as keyof Partial<OnBoardingUserData>] === undefined || 
        data[key as keyof Partial<OnBoardingUserData>] === null
      );
      
      // Preparar os dados para envio: primeiro mesclar com os dados atuais
      const combinedData: Partial<OnBoardingUserData> = {
        ...(currentData || {}),
        ...data
      };
      
      // Depois remover explicitamente os campos que devem ser excluídos
      fieldsToRemove.forEach(field => {
        console.log(`OnBoardingService - Removendo campo ${field} do objeto de dados`);
        delete combinedData[field as keyof OnBoardingUserData];
      });
      
      console.log('OnBoardingService - Dados a serem enviados:', JSON.stringify(combinedData, null, 2));
      
      // Chamar o endpoint específico para atualizar os dados do usuário
      const response = await fetch(`${this.apiUrl}/onboarding/user-data`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify(combinedData)
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao atualizar dados do usuário do OnBoarding: ${response.statusText}`);
      }
      
      // Obter a resposta atualizada da API
      const responseData = await response.json();
      return responseData as OnBoardingUserData;
    } catch (error) {
      console.error('Erro ao atualizar dados do usuário do OnBoarding na API:', error);
      throw error;
    }
  }

  // Verificar se o OnBoarding está completo (todos os passos obrigatórios) usando a API diretamente
  async isRequiredOnBoardingComplete(): Promise<boolean> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao verificar completude do OnBoarding: token não disponível');
      return false; // Retorna false em caso de erro com o token
    }
    
    try {
      // Chamar o endpoint específico para verificar se o onboarding está completo
      const response = await fetch(`${this.apiUrl}/onboarding/is-complete`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao verificar completude do OnBoarding: ${response.statusText}`);
      }
      
      // Obter a resposta da API
      const data = await response.json();
      return data.isComplete; // Retorna o valor booleano da propriedade isComplete
    } catch (error) {
      console.error('Erro ao verificar completude do OnBoarding na API:', error);
      return false; // Retorna false em caso de erro na chamada à API
    }
  }

  // Obter os passos pendentes (não concluídos) usando a API diretamente
  async getPendingSteps(): Promise<string[]> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao obter passos pendentes: token não disponível');
      return []; // Retorna um array vazio em caso de erro com o token
    }
    
    try {
      // Chamar o endpoint específico para obter os passos pendentes
      const response = await fetch(`${this.apiUrl}/onboarding/pending-steps`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao obter passos pendentes: ${response.statusText}`);
      }
      
      // Obter a resposta da API
      const data = await response.json();
      return data.pendingSteps || []; // Retorna o array de passos pendentes
    } catch (error) {
      console.error('Erro ao obter passos pendentes da API:', error);
      return []; // Retorna um array vazio em caso de erro na chamada à API
    }
  }

  // Converter dados do OnBoarding para entrada de humor
  async convertCurrentMoodToMoodEntry(): Promise<MoodEntry | null> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao converter humor atual para entrada de humor: token não disponível');
      return null; // Retorna null em caso de erro com o token
    }
    
    try {
      // Obter os dados do usuário da API
      const userData = await this.getUserData();
      
      if (!userData || userData.currentMood === undefined) {
        return null;
      }
      
      return {
        id: `onboarding-mood-${Date.now()}`,
        score: userData.currentMood,
        note: userData.moodNote || '',
        timestamp: userData.updatedAt || Date.now()
      };
    } catch (error) {
      console.error('Erro ao converter humor atual para entrada de humor:', error);
      return null;
    }
  }

  // Resetar o OnBoarding (para testes) usando a API
  async resetOnBoarding(): Promise<void> {
    const authToken = this.getAuthToken();
    
    if (!authToken) {
      console.error('Erro ao resetar onboarding: token não disponível');
      return;
    }
    
    try {
      // Chamar o endpoint específico para resetar o onboarding
      const response = await fetch(`${this.apiUrl}/onboarding/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      
      if (!response.ok) {
        throw new Error(`Erro ao resetar onboarding: ${response.statusText}`);
      }
      
      console.log('Onboarding resetado com sucesso');
    } catch (error) {
      console.error('Erro ao resetar onboarding na API:', error);
    }
  }
}

export default new OnBoardingService(); 