/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { getWeeklyChartData } from '../infra/utils/moodUtils';
import type { ChartData } from '../types';
import { Loader2 } from 'lucide-react';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MoodChartProps {
  entries?: any[];
  loading?: boolean;
}

const MoodChart: React.FC<MoodChartProps> = ({ entries, loading = false }) => {
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [localLoading, setLocalLoading] = useState(loading);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        // Se temos entries passados como props, usamos eles
        // em vez de fazer uma nova requisição
        if (entries && entries.length > 0) {
          console.log('Usando entries passados por props:', entries);
          const data = await getWeeklyChartData(entries);
          console.log('Dados do gráfico gerados:', data);
          setChartData(data);
        } else {
          console.log('Nenhuma entry fornecida, buscando do storage');
          const data = await getWeeklyChartData();
          console.log('Dados do gráfico gerados do storage:', data);
          setChartData(data);
        }
      } catch (error) {
        console.error('Erro ao carregar dados do gráfico:', error);
      } finally {
        setLocalLoading(false);
      }
    };

    fetchChartData();
  }, [entries]);

  if (localLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-4 w-full">
        <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-2" />
        <p className="text-neutral-600">Carregando gráfico...</p>
      </div>
    );
  }

  if (!chartData) {
    console.log('Nenhum dado de gráfico disponível');
    return (
      <div className="w-full">
        <h3 className="text-lg font-medium text-neutral-700 mb-2">Sem dados para exibir</h3>
      </div>
    );
  }

  // Processa os dados do gráfico para garantir que valores zero sejam exibidos corretamente
  const processedChartData = {
    ...chartData,
    datasets: chartData.datasets.map(dataset => ({
      ...dataset,
      // Configurações específicas para o Chart.js
      barPercentage: 0.8,
      categoryPercentage: 0.8,
      borderRadius: 4
    }))
  };

  // Array de emojis para a escala deslocada
  const shiftedEmojis = ['', '😢', '😔', '😐', '🙂', '😊', '🥰'];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const value = context.raw;
            if (value === 0) {
              return 'Sem registros';
            }
            // Converter valor deslocado para o valor original para exibição
            const originalValue = value - 1;
            if (originalValue === 0) {
              return 'Humor: Muito mal';
            }
            return `Humor médio: ${originalValue.toFixed(1)}`;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0, // Começar em 0 para dias sem registro
        max: 6, // Terminar em 6 para o humor máximo (escala deslocada)
        ticks: {
          stepSize: 1,
          callback: function(value: number) {
            if (value === 0) {
              return ''; // Não mostrar label para 0 (sem registros)
            }
            // Usar o array de emojis deslocado
            return shiftedEmojis[value] || value;
          }
        },
        grid: {
          display: true,
          drawBorder: true,
          color: function(context: any) {
            if (context.tick.value === 1) {
              return 'rgba(0, 0, 0, 0.2)'; // Linha mais escura para o valor 1 (humor 0)
            }
            return 'rgba(0, 0, 0, 0.1)';
          }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: {
            size: 11,
            // Aumentar o tamanho da fonte em telas maiores
            weight: window.innerWidth >= 768 ? '600' : '400'
          }
        }
      }
    },
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 1,
        borderColor: 'rgba(255, 255, 255, 0.5)'
      }
    }
  };
  
  // Verifica se há pelo menos um valor válido nos dados
  const hasValidData = chartData.datasets[0].data.some(value => 
    value !== undefined && value !== null && value > 0
  );
  
  return (
    <div className="w-full h-48 md:h-64 lg:h-72">
      {hasValidData ? (
        <Bar data={processedChartData} options={options as any} />
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-neutral-500 text-center mb-2">Sem dados de humor para exibir</p>
          <p className="text-sm text-neutral-400 text-center">
            Registre seu humor para ver o gráfico aqui
          </p>
        </div>
      )}
    </div>
  );
};

export default MoodChart;