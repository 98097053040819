import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Lista de tipos de suporte social
const supportTypes = [
  'Família',
  'Amigos',
  'Parceiro(a)',
  'Colegas de trabalho',
  'Grupos de apoio',
  'Comunidade religiosa',
  'Profissionais de saúde mental',
  'Outro'
];

const SocialSupportStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [hasSupportSystem, setHasSupportSystem] = useState<boolean | undefined>(
    userData.hasSupportSystem
  );
  const [selectedSupports, setSelectedSupports] = useState<string[]>(
    userData.supportSystemDetails || []
  );
  const [otherSupport, setOtherSupport] = useState<string>('');
  
  const handleSupportToggle = (support: string) => {
    setSelectedSupports(prev => {
      if (prev.includes(support)) {
        return prev.filter(s => s !== support);
      } else {
        return [...prev, support];
      }
    });
  };
  
  const handleSubmit = () => {
    // Preparar a lista de suportes
    const supports = [...selectedSupports.filter(s => s !== 'Outro')];
    
    // Adicionar o suporte "Outro" se selecionado e especificado
    if (selectedSupports.includes('Outro') && otherSupport.trim()) {
      supports.push(otherSupport.trim());
    }
    
    onNext({
      hasSupportSystem: hasSupportSystem === true,
      supportSystemDetails: supports.length > 0 ? supports : undefined
    });
  };
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Suporte Social</h3>
        <p className="text-neutral-600 mb-4">
          O suporte social é um fator importante para a saúde mental. Estas informações nos ajudarão a personalizar suas recomendações.
        </p>
      </div>
      
      <div className="space-y-4">
        <div>
          <p className="font-medium text-neutral-700 mb-2">
            Você sente que tem um sistema de suporte social adequado?
          </p>
          <div className="flex space-x-4">
            <div className="flex items-center">
              <input
                type="radio"
                id="has-support-yes"
                name="has-support"
                checked={hasSupportSystem === true}
                onChange={() => setHasSupportSystem(true)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="has-support-yes" className="ml-2 text-neutral-700">
                Sim
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="has-support-no"
                name="has-support"
                checked={hasSupportSystem === false}
                onChange={() => setHasSupportSystem(false)}
                className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
              />
              <label htmlFor="has-support-no" className="ml-2 text-neutral-700">
                Não
              </label>
            </div>
          </div>
        </div>
        
        <div className="mt-4">
          <p className="font-medium text-neutral-700 mb-2">
            Quais são suas principais fontes de suporte social?
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {supportTypes.map((support) => (
              <div key={support} className="flex items-center">
                <input
                  type="checkbox"
                  id={`support-${support}`}
                  checked={selectedSupports.includes(support)}
                  onChange={() => handleSupportToggle(support)}
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300 rounded"
                />
                <label htmlFor={`support-${support}`} className="ml-2 text-neutral-700">
                  {support}
                </label>
              </div>
            ))}
          </div>
          
          {selectedSupports.includes('Outro') && (
            <div className="mt-3">
              <label htmlFor="other-support" className="block text-sm font-medium text-neutral-700 mb-1">
                Especifique:
              </label>
              <input
                type="text"
                id="other-support"
                value={otherSupport}
                onChange={(e) => setOtherSupport(e.target.value)}
                className="w-full p-2 border border-neutral-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                placeholder="Digite a fonte de suporte"
              />
            </div>
          )}
        </div>
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="ghost"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={(hasSupportSystem === undefined) || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SocialSupportStep; 