import { initializeApp, FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, Messaging } from 'firebase/messaging';
import { INotificationService } from './interfaces/INotificationService';

export class FirebaseNotificationService implements INotificationService {
  private app: FirebaseApp;
  private messagingInstance: Messaging | null = null;
  private tokenCheckInterval: number | null = null;

  constructor(config: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  }) {
    this.app = initializeApp(config);
  }

  private getMessagingInstance(): Messaging {
    if (!this.messagingInstance) {
      this.messagingInstance = getMessaging(this.app);
    }
    return this.messagingInstance;
  }

  public hasFCMToken(): boolean {
    const token = localStorage.getItem('fcm_token');
    return !!token;
  }

  /**
   * Verifica se o service worker do Firebase Messaging está ativo
   * Isso é importante porque o FCM precisa de um service worker ativo para funcionar
   */
  private async waitForActiveServiceWorker(maxAttempts = 5, delayMs = 500): Promise<boolean> {
    if (!('serviceWorker' in navigator)) {
      return false;
    }

    try {
      // Verificar se o service worker do Firebase está registrado
      const registration = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
      
      // Se não estiver registrado, registrá-lo
      if (!registration) {
        console.log('Registrando Firebase Messaging Service Worker...');
        await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' });
        // Esperar um pouco para o service worker ser ativado
        await new Promise(resolve => setTimeout(resolve, 1000));
      } 
      // Se estiver em estado de espera, ativá-lo
      else if (registration.waiting) {
        console.log('Firebase Messaging Service Worker está em espera, ativando...');
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      // Verificar se o service worker está ativo
      for (let i = 0; i < maxAttempts; i++) {
        const currentReg = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
        if (currentReg?.active) {
          console.log('Firebase Messaging Service Worker está ativo');
          return true;
        }
        
        console.log(`Aguardando ativação do Firebase Messaging Service Worker (${i+1}/${maxAttempts})...`);
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }
      
      return false;
    } catch (error) {
      console.error('Erro ao verificar/registrar Firebase Messaging Service Worker:', error);
      return false;
    }
  }

  public async getToken(): Promise<string> {
    try {
      // Verificar se já temos permissão
      if (Notification.permission !== 'granted') {
        console.log('Permissão de notificações não concedida');
        throw new Error('Permissão de notificações não concedida');
      }

      // Tentar obter token do localStorage primeiro (exceto em desenvolvimento)
      const savedToken = localStorage.getItem('fcm_token');
      if (savedToken && import.meta.env.MODE !== 'development') {
        console.log('Token FCM recuperado do localStorage');
        return savedToken;
      }
      
      // Garantir que o Firebase Messaging Service Worker esteja ativo
      const isActive = await this.waitForActiveServiceWorker();
      if (!isActive) {
        console.error('Firebase Messaging Service Worker não está ativo');
        throw new Error('Firebase Messaging Service Worker não está ativo');
      }

      // Obter o token FCM
      const messaging = this.getMessagingInstance();
      console.log('Obtendo novo token FCM...');
      
      // Obter o registro do service worker do Firebase para passar ao getToken
      const swRegistration = await navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js');
      
      const currentToken = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: swRegistration
      });

      if (!currentToken) {
        console.log('Não foi possível obter o token FCM');
        throw new Error('Não foi possível obter o token FCM');
      }

      // Armazenar o token no localStorage
      localStorage.setItem('fcm_token', currentToken);
      console.log('Token FCM obtido com sucesso');

      return currentToken;
    } catch (error) {
      console.error('Erro ao obter token FCM:', error);
      // Limpar token inválido se houver erro
      localStorage.removeItem('fcm_token');
      throw error;
    }
  }

  public async requestPermission(): Promise<string> {
    try {
      const permission = await Notification.requestPermission();
      
      if (permission === 'granted') {
        return await this.getToken();
      } else {
        localStorage.removeItem('fcm_token');
        throw new Error('Permissão de notificações negada');
      }
    } catch (error) {
      console.error('Erro ao solicitar permissão:', error);
      throw error;
    }
  }

  public handlePermissionChange(previousPermission: NotificationPermission): void {
    const currentPermission = Notification.permission;
    if (previousPermission === 'granted' && 
        (currentPermission === 'denied' || currentPermission === 'default')) {
      localStorage.removeItem('fcm_token');
      console.log('Permissão de notificações revogada. Token FCM removido.');
    }
  }
} 