import React, { useState, useEffect } from 'react';
import { Mail, Shield, Info, Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';
import { Button } from '../../ui/button';

const RecoveryEmailStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onBack,
  onSkip,
  isSubmitting,
  showBackButton = true
}) => {
  const [recoveryEmail, setRecoveryEmail] = useState(userData.recoveryEmail || '');
  const [isOptedOut, setIsOptedOut] = useState(false);
  const [errors, setErrors] = useState<{ recoveryEmail?: string }>({});
  
  // Inicializar o estado inicial com base nos dados do usuário
  useEffect(() => {
    if (userData) {
      if (userData.recoveryEmail) {
        setRecoveryEmail(userData.recoveryEmail);
        setIsOptedOut(false);
      } else {
        // Se não há email de recuperação, definir como opted-out
        setRecoveryEmail('');
        setIsOptedOut(true);
      }
      console.log('RecoveryEmailStep - Estado inicial:', { 
        recoveryEmail: userData.recoveryEmail || 'não fornecido', 
        isOptedOut: !userData.recoveryEmail 
      });
    }
  }, [userData]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Se o usuário optou por não fornecer email
    if (isOptedOut) {
      console.log('RecoveryEmailStep - Removendo email de recuperação');
      
      // Para garantir que o email seja removido, enviamos um objeto 
      // com recoveryEmail: null explicitamente
      const dataToSend = {
        recoveryEmail: null as unknown as undefined
      };
      
      onNext(dataToSend);
      return;
    }
    
    // Validação de email apenas se não optou por opt-out
    const newErrors: { recoveryEmail?: string } = {};
    
    // Validar email de recuperação
    if (!recoveryEmail.trim()) {
      newErrors.recoveryEmail = 'Por favor, informe um email para recuperação';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(recoveryEmail)) {
      newErrors.recoveryEmail = 'Formato de email inválido';
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    // Enviar dados com o email informado
    console.log('RecoveryEmailStep - Enviando email de recuperação:', recoveryEmail.trim());
    onNext({ 
      recoveryEmail: recoveryEmail.trim()
    });
  };
  
  // Alternar a opção de não fornecer email
  const toggleOptOut = () => {
    const newOptedOut = !isOptedOut;
    console.log('RecoveryEmailStep - Alterando opted-out para:', newOptedOut);
    
    setIsOptedOut(newOptedOut);
    
    if (newOptedOut) {
      // Se optou por não fornecer, limpa o campo de email
      setRecoveryEmail('');
      setErrors({});
      console.log('RecoveryEmailStep - Campo de email limpo');
    } else if (userData.recoveryEmail) {
      // Se desmarcou a opção e havia um email salvo, restaura o valor original
      setRecoveryEmail(userData.recoveryEmail);
      console.log('RecoveryEmailStep - Email restaurado:', userData.recoveryEmail);
    }
  };
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          Email de Recuperação
        </h3>
        <p className="text-neutral-600">
          Um email para recuperar sua conta caso necessário
        </p>
      </div>
      
      <form onSubmit={handleSubmit}>
        {/* Explicação do email de recuperação */}
        <div className="mb-5 p-4 bg-blue-50 rounded-lg border border-blue-200">
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5">
              <Info size={20} className="text-blue-600" />
            </div>
            <div className="ml-3">
              <h4 className="text-base font-medium text-blue-800">Por que fornecer um email?</h4>
              <p className="text-sm text-blue-700 mt-1">
                O email de recuperação é usado apenas para que você possa recuperar sua conta caso 
                esqueça sua senha ou precise redefinir o acesso. Não usamos para enviar marketing.
              </p>
            </div>
          </div>
        </div>
        
        {/* Opção de não fornecer (colocada antes do campo para melhor experiência) */}
        <div className="mb-5">
          <div className="flex items-center">
            <input
              id="opt-out"
              type="checkbox"
              checked={isOptedOut}
              onChange={toggleOptOut}
              className="h-4 w-4 rounded border-neutral-300 text-primary-600 focus:ring-primary-500"
            />
            <label htmlFor="opt-out" className="ml-2 block text-sm text-neutral-700">
              Prefiro não fornecer um email de recuperação (não recomendado)
            </label>
          </div>
          
          {isOptedOut && userData.recoveryEmail && (
            <div className="mt-3 p-3 bg-red-50 border border-red-200 rounded-md">
              <div className="flex items-start">
                <Shield size={18} className="text-red-600 mt-0.5 mr-2 flex-shrink-0" />
                <p className="text-xs text-red-700">
                  <strong>Aviso:</strong> Você já possui um email de recuperação cadastrado. 
                  Se prosseguir, este email será removido e você perderá a capacidade de recuperar 
                  sua conta em caso de perda de senha.
                </p>
              </div>
            </div>
          )}
          
          {isOptedOut && (
            <div className="mt-3 p-3 bg-amber-50 border border-amber-200 rounded-md">
              <div className="flex items-start">
                <Shield size={18} className="text-amber-600 mt-0.5 mr-2 flex-shrink-0" />
                <p className="text-xs text-amber-700">
                  <strong>Atenção:</strong> Sem um email de recuperação, você não poderá recuperar sua 
                  conta caso esqueça sua senha. Recomendamos fortemente fornecer um email.
                </p>
              </div>
            </div>
          )}
        </div>
        
        {/* Campo de email - visível apenas se não optou por não fornecer */}
        {!isOptedOut && (
          <div className="mb-5">
            <label htmlFor="recovery-email" className="block text-sm font-medium text-neutral-700 mb-1">
              Email de recuperação
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail size={18} className="text-neutral-500" />
              </div>
              <input
                type="email"
                id="recovery-email"
                value={recoveryEmail}
                onChange={(e) => {
                  setRecoveryEmail(e.target.value);
                  if (errors.recoveryEmail) setErrors({});
                }}
                className={`pl-10 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm ${
                  errors.recoveryEmail ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
                }`}
                placeholder="seu-email@exemplo.com"
                aria-describedby={errors.recoveryEmail ? "recovery-email-error" : undefined}
              />
            </div>
            {errors.recoveryEmail && (
              <p id="recovery-email-error" className="mt-1 text-sm text-red-600">{errors.recoveryEmail}</p>
            )}
          </div>
        )}
        
        {/* Política de privacidade */}
        <div className="mb-6 p-3 bg-neutral-50 rounded-md border border-neutral-200">
          <p className="text-xs text-neutral-600">
            <span className="flex items-center mb-1">
              <Shield size={14} className="text-primary-600 mr-1" />
              <span className="font-medium">Compromisso com sua privacidade</span>
            </span>
            Seu email nunca será compartilhado com terceiros. Ele será usado exclusivamente para 
            recuperação de conta e comunicações essenciais relacionadas à segurança.
          </p>
        </div>
        
        {/* Botões */}
        <div className="flex flex-col sm:flex-row sm:justify-between gap-3">
          {showBackButton && (
            <Button
              type="button"
              variant="outline"
              onClick={onBack}
              className="w-full sm:w-auto"
            >
              Voltar
            </Button>
          )}
          
          {!isOptedOut && (
            <Button
              type="button"
              variant="ghost"
              onClick={onSkip}
              className="w-full sm:w-auto"
            >
              Pular
            </Button>
          )}
          
          <Button
            type="submit"
            className="w-full sm:w-auto bg-primary-600 hover:bg-primary-700 text-white"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 size={16} className="mr-2 animate-spin" />
                Processando...
              </>
            ) : (
              isOptedOut && userData.recoveryEmail ? 'Remover Email' : 'Continuar'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RecoveryEmailStep; 