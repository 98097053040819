import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { redirectToCheckout, STRIPE_PRICE_IDS } from '../services/stripeService';
import { Loader2 } from 'lucide-react';

interface PaymentButtonProps {
  planId: string;
  className?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'outline';
}

const PaymentButton: React.FC<PaymentButtonProps> = ({ 
  planId, 
  className = '', 
  children,
  variant = 'primary'
}) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async () => {
    if (!user) return;
    
    try {
      setIsLoading(true);
      
      // Determinar qual ID de preço usar com base no plano selecionado
      let priceId;
      switch (planId) {
        case 'essencial':
          priceId = STRIPE_PRICE_IDS.essencial;
          break;
        case 'premium':
          priceId = STRIPE_PRICE_IDS.premium;
          break;
        default:
          console.error('ID de plano inválido:', planId);
          alert('Plano inválido selecionado');
          setIsLoading(false);
          return;
      }
      
      console.log(`Iniciando checkout para o plano ${planId} com ID de preço ${priceId}`);
      
      // Redirecionar para o checkout do Stripe via backend
      const { error } = await redirectToCheckout({
        priceId,
        userId: user.id
      });
      
      if (error) {
        console.error('Erro ao redirecionar para o checkout:', error);
        alert(`Erro ao processar pagamento: ${error}`);
      }
    } catch (err) {
      console.error('Erro ao processar pagamento:', err);
      alert('Ocorreu um erro ao processar o pagamento. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };

  const buttonClass = `btn ${variant === 'primary' ? 'btn-primary' : 'btn-outline'} ${className}`;

  if (!user) {
    // Guardar informações do plano para redirecionamento após login/registro
    const handleRedirectToLogin = () => {
      // Salvar o ID do plano e a intenção de pagamento no localStorage
      localStorage.setItem('createAccount', 'true');
      localStorage.setItem('pendingPlanId', planId);
      localStorage.setItem('redirectToPayment', 'true');
      
      // Registrar timestamp para evitar redirecionamentos antigos
      localStorage.setItem('paymentRedirectTimestamp', Date.now().toString());
    };
    
    return (
      <Link 
        to={`/login?createAccount=true&redirectToPayment=true&planId=${planId}`} 
        className={buttonClass}
        onClick={handleRedirectToLogin}
      >
        {children}
      </Link>
    );
  }

  return (
    <button 
      onClick={handlePayment} 
      disabled={isLoading}
      className={buttonClass}
    >
      {isLoading ? (
        <>
          <Loader2 className="h-4 w-4 mr-2 animate-spin" />
          Processando...
        </>
      ) : children}
    </button>
  );
};

export default PaymentButton;
