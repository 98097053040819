import React from 'react';
import { Brain, MessageCircle, Sparkles } from 'lucide-react';
import { useChat } from '../../contexts/ChatContext';

// Interface para o componente de gatilho do chat
interface ChatTriggerProps {
  variant?: 'button' | 'link' | 'card';
  message?: string;
  label?: string;
  icon?: 'brain' | 'message' | 'sparkles';
  className?: string;
}

/**
 * Componente que serve como ponto de entrada para o ChatWidget
 * Pode ser usado em qualquer página para permitir interação com o assistente
 */
const ChatTrigger: React.FC<ChatTriggerProps> = ({
  variant = 'button',
  message = '',
  label = 'Falar com Assistente IA',
  icon = 'brain',
  className = ''
}) => {
  const { openChat } = useChat();

  // Função para abrir o chat com uma mensagem inicial
  const handleClick = () => {
    // Verifica se há uma mensagem para incluir
    if (message) {
      console.log("ChatTrigger: enviando mensagem inicial:", message);
      openChat(message);
    } else {
      openChat();
    }
  };
  
  // Seleciona o ícone apropriado
  const IconComponent = {
    brain: Brain,
    message: MessageCircle,
    sparkles: Sparkles
  }[icon];
  
  // Renderiza o componente de acordo com a variante
  switch (variant) {
    case 'link':
      return (
        <button 
          onClick={handleClick}
          className={`text-primary-600 hover:text-primary-700 flex items-center text-sm font-medium ${className}`}
        >
          <IconComponent size={16} className="mr-1" />
          {label}
        </button>
      );
      
    case 'card':
      return (
        <div 
          onClick={handleClick}
          className={`bg-white border border-neutral-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow cursor-pointer ${className}`}
        >
          <div className="flex items-center">
            <div className="bg-primary-100 p-2 rounded-full mr-3">
              <IconComponent size={20} className="text-primary-600" />
            </div>
            <div>
              <h3 className="font-medium text-neutral-800">{label}</h3>
              {message && (
                <p className="text-sm text-neutral-500 mt-1">
                  {message.length > 60 ? `${message.substring(0, 60)}...` : message}
                </p>
              )}
            </div>
          </div>
        </div>
      );
      
    case 'button':
    default:
      return (
        <button
          onClick={handleClick}
          className={`bg-primary-500 hover:bg-primary-600 text-white rounded-md px-4 py-2 flex items-center justify-center transition-colors ${className}`}
          aria-label={label}
        >
          <IconComponent size={18} className="mr-2" />
          {label}
        </button>
      );
  }
};

export default ChatTrigger; 