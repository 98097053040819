import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { OnBoardingUserData } from '../../types';
import { 
  updateOnBoardingUserData, 
  markOnBoardingAsSeen, 
  getPendingOnBoardingSteps,
  getOnBoardingUserData
} from '../../infra/OnBoardingFacade';
import { useOnBoardingStore } from '../../stores/onBoardingStore';

// Passos do OnBoarding
import PersonalInfoStep from './steps/PersonalInfoStep';
import DemographicsStep from './steps/DemographicsStep';
import MotivationStep from './steps/MotivationStep';
import CurrentMoodStep from './steps/CurrentMoodStep';
import DepressionScreeningStep from './steps/DepressionScreeningStep';
import AnxietyScreeningStep from './steps/AnxietyScreeningStep';
import MentalHealthHistoryStep from './steps/MentalHealthHistoryStep';
import CurrentTreatmentStep from './steps/CurrentTreatmentStep';
import SleepQualityStep from './steps/SleepQualityStep';
import SocialSupportStep from './steps/SocialSupportStep';
import RecoveryEmailStep from './steps/RecoveryEmailStep';
import CompletionStep from './steps/CompletionStep';

interface OnBoardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  isRequired?: boolean; // Indica se o OnBoarding é obrigatório (não pode ser fechado)
  startWithPendingSteps?: boolean; // Indica se deve começar pelos passos pendentes
  isReview?: boolean; // Indica se o modal está sendo aberto para revisão de dados
}

const steps = [
  { id: 'personal-info', component: PersonalInfoStep, isRequired: true },
  { id: 'recovery-email', component: RecoveryEmailStep, isRequired: true },
  { id: 'demographics', component: DemographicsStep, isRequired: false },
  { id: 'motivation', component: MotivationStep, isRequired: false },
  { id: 'current-mood', component: CurrentMoodStep, isRequired: true, skipInReview: true },
  { id: 'depression-screening', component: DepressionScreeningStep, isRequired: false },
  { id: 'anxiety-screening', component: AnxietyScreeningStep, isRequired: false },
  { id: 'mental-health-history', component: MentalHealthHistoryStep, isRequired: false },
  { id: 'current-treatment', component: CurrentTreatmentStep, isRequired: false },
  { id: 'sleep-quality', component: SleepQualityStep, isRequired: false },
  { id: 'social-support', component: SocialSupportStep, isRequired: false },
  { id: 'completion', component: CompletionStep, isRequired: false }
];

// Função para obter os passos filtrados para o modo de revisão
const getReviewSteps = () => {
  return steps.filter(step => !step.skipInReview);
};

const OnBoardingModal: React.FC<OnBoardingModalProps> = ({ 
  isOpen, 
  onClose, 
  isRequired = false,
  startWithPendingSteps = false,
  isReview = false
}) => {
  const { currentStep: globalStepIndex, setCurrentStep } = useOnBoardingStore();
  const [userData, setUserData] = useState<Partial<OnBoardingUserData>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingSteps, setPendingSteps] = useState<string[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [pendingStepsHistory, setPendingStepsHistory] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Referência para o container com scroll
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  
  // Função para scrollar para o topo
  const scrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  };
  
  // Determinar quais passos usar com base no modo
  const activeSteps = isReview ? getReviewSteps() : steps;
  
  const currentStep = activeSteps[globalStepIndex];
  const isLastStep = globalStepIndex === activeSteps.length - 1;
  const isCurrentStepRequired = currentStep?.isRequired || false;
  
  // Determinar se o botão de voltar deve ser exibido
  const showBackButton = startWithPendingSteps 
    ? pendingStepsHistory.length > 1 
    : globalStepIndex > 0;
  
  // Efeito para marcar o OnBoarding como visto quando aberto
  useEffect(() => {
    if (isOpen) {
      markOnBoardingAsSeen().catch(console.error);
    }
  }, [isOpen]);
  
  // Efeito para carregar os dados existentes do usuário quando o modal for aberto para revisão
  useEffect(() => {
    if (isOpen && isReview && !isInitialized) {
      const loadUserData = async () => {
        try {
          setIsLoading(true);
          const existingUserData = await getOnBoardingUserData();
          if (existingUserData) {
            // Garante que todos os dados são carregados incluindo a opção de anonimato e recoveryEmail
            setUserData(existingUserData);
            
            // Log para depuração
            console.log('Dados do usuário carregados para revisão:', existingUserData);
            
            // Verificar especificamente se o recoveryEmail está presente
            if (!existingUserData.recoveryEmail) {
              console.warn('Email de recuperação não encontrado nos dados do usuário');
            }
          } else {
            console.warn('Nenhum dado de usuário encontrado para revisão');
          }
        } catch (error) {
          console.error('Erro ao carregar dados do usuário para revisão:', error);
        } finally {
          setIsLoading(false);
        }
      };
      
      loadUserData();
    }
  }, [isOpen, isReview, isInitialized]);
  
  // Efeito para inicializar o OnBoarding com os passos pendentes ou obrigatórios
  useEffect(() => {
    if (isOpen && !isInitialized) {
      const initializeOnBoarding = async () => {
        try {
          setIsLoading(true);
          
          // Se estiver no modo de revisão, não precisamos buscar passos pendentes
          if (isReview) {
            // No modo de revisão, começamos pelo primeiro passo (ou pelo passo definido no store)
            if (globalStepIndex === 0) {
              setCurrentStep(0);
            }
          } else {
            // Obter os passos pendentes
            const pendingStepsList = await getPendingOnBoardingSteps();
            setPendingSteps(pendingStepsList);
            
            // Se deve começar pelos passos pendentes e existem passos pendentes
            if (startWithPendingSteps && pendingStepsList.length > 0) {
              // Encontrar o índice do primeiro passo pendente
              const firstPendingStepIndex = steps.findIndex(step => 
                pendingStepsList.includes(step.id) && 
                (!isRequired || step.isRequired)
              );
              
              if (firstPendingStepIndex >= 0) {
                setCurrentStep(firstPendingStepIndex);
                // Inicializar o histórico de passos pendentes
                setPendingStepsHistory([firstPendingStepIndex]);
              }
            } 
            // Se o OnBoarding for obrigatório, começar pelos passos obrigatórios
            else if (isRequired) {
              // Encontrar o primeiro passo obrigatório
              const requiredStepIndex = steps.findIndex(step => step.isRequired);
              if (requiredStepIndex > 0) {
                setCurrentStep(requiredStepIndex);
              }
            }
          }
          
          setIsInitialized(true);
        } catch (error) {
          console.error('Erro ao inicializar o OnBoarding:', error);
          setIsInitialized(true); // Marcar como inicializado mesmo em caso de erro
        } finally {
          setIsLoading(false);
        }
      };
      
      initializeOnBoarding();
    }
  }, [isOpen, isRequired, startWithPendingSteps, isInitialized, isReview, globalStepIndex, setCurrentStep]);
  
  const handleNext = async (stepData: Partial<OnBoardingUserData> = {}) => {
    try {
      setIsSubmitting(true);
      
      // Atualizar os dados do usuário
      const updatedData = {
        ...userData,
        ...stepData
      };
      if (updatedData.isAnonymous) {
        delete updatedData.name;
        delete updatedData.age;
        delete updatedData.location;
      }
      setUserData(updatedData);
      
      // Salvar os dados no backend
      if (Object.keys(stepData).length > 0) {
        await updateOnBoardingUserData(currentStep.id, stepData);
      }
      
      // Se estamos nos passos pendentes e há mais passos pendentes, ir para o próximo pendente
      if (startWithPendingSteps && pendingSteps.length > 0) {
        // Remover o passo atual da lista de pendentes
        const updatedPendingSteps = pendingSteps.filter(step => step !== currentStep.id);
        setPendingSteps(updatedPendingSteps);
        
        // Se ainda há passos pendentes, encontrar o próximo
        if (updatedPendingSteps.length > 0) {
          const nextPendingStepIndex = steps.findIndex(step => 
            updatedPendingSteps.includes(step.id)
          );
          
          if (nextPendingStepIndex >= 0) {
            setCurrentStep(nextPendingStepIndex);
            // Adicionar ao histórico de passos pendentes
            setPendingStepsHistory(prev => [...prev, nextPendingStepIndex]);
            // Scrollar para o topo
            scrollToTop();
            setIsSubmitting(false);
            return;
          }
        }
        
        // Se não há mais passos pendentes, ir para o passo de conclusão
        const completionStepIndex = steps.findIndex(step => step.id === 'completion');
        if (completionStepIndex >= 0) {
          setCurrentStep(completionStepIndex);
          // Adicionar ao histórico de passos pendentes
          setPendingStepsHistory(prev => [...prev, completionStepIndex]);
          // Scrollar para o topo
          scrollToTop();
          setIsSubmitting(false);
          return;
        }
      }
      
      // Comportamento padrão: avançar para o próximo passo
      if (!isLastStep) {
        setCurrentStep(globalStepIndex + 1);
        // Scrollar para o topo
        scrollToTop();
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Erro ao avançar no OnBoarding:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  const handleSkip = () => {
    if (!isCurrentStepRequired) {
      // Se estamos nos passos pendentes e há mais passos pendentes, ir para o próximo pendente
      if (startWithPendingSteps && pendingSteps.length > 0) {
        // Remover o passo atual da lista de pendentes
        const updatedPendingSteps = pendingSteps.filter(step => step !== currentStep.id);
        setPendingSteps(updatedPendingSteps);
        
        // Se ainda há passos pendentes, encontrar o próximo
        if (updatedPendingSteps.length > 0) {
          const nextPendingStepIndex = steps.findIndex(step => 
            updatedPendingSteps.includes(step.id)
          );
          
          if (nextPendingStepIndex >= 0) {
            setCurrentStep(nextPendingStepIndex);
            // Adicionar ao histórico de passos pendentes
            setPendingStepsHistory(prev => [...prev, nextPendingStepIndex]);
            // Scrollar para o topo
            scrollToTop();
            return;
          }
        }
        
        // Se não há mais passos pendentes, ir para o passo de conclusão
        const completionStepIndex = steps.findIndex(step => step.id === 'completion');
        if (completionStepIndex >= 0) {
          setCurrentStep(completionStepIndex);
          // Adicionar ao histórico de passos pendentes
          setPendingStepsHistory(prev => [...prev, completionStepIndex]);
          // Scrollar para o topo
          scrollToTop();
          return;
        }
      }
      
      // Comportamento padrão: avançar para o próximo passo
      if (!isLastStep) {
        setCurrentStep(globalStepIndex + 1);
        // Scrollar para o topo
        scrollToTop();
      } else {
        onClose();
      }
    }
  };
  
  const handleBack = () => {
    // Se estamos no modo de passos pendentes
    if (startWithPendingSteps && pendingStepsHistory.length > 1) {
      // Remover o passo atual do histórico e voltar para o passo anterior no histórico
      const newHistory = [...pendingStepsHistory];
      newHistory.pop(); // Remove o passo atual
      const previousStepIndex = newHistory[newHistory.length - 1]; // Obtém o passo anterior
      
      setCurrentStep(previousStepIndex);
      setPendingStepsHistory(newHistory);
      // Scrollar para o topo
      scrollToTop();
    } 
    // Comportamento padrão: voltar para o passo anterior
    else if (globalStepIndex > 0) {
      setCurrentStep(globalStepIndex - 1);
      // Scrollar para o topo
      scrollToTop();
    }
  };
  
  const handleClose = () => {
    // Permitir fechar o modal em qualquer um destes casos:
    // 1. No modo de revisão (isReview = true)
    // 2. Quando não for obrigatório e for um passo não obrigatório
    // 3. Quando não for obrigatório e estiver no último passo
    if (isReview || (!isRequired && (!isCurrentStepRequired || isLastStep))) {
      onClose();
    }
  };
  
  // Resetar o passo atual quando o modal for fechado
  useEffect(() => {
    if (!isOpen) {
      // Não resetar imediatamente para evitar animações estranhas durante o fechamento
      const timer = setTimeout(() => {
        if (!isOpen) {
          setCurrentStep(0);
        }
      }, 300);
      
      return () => clearTimeout(timer);
    }
  }, [isOpen, setCurrentStep]);
  
  // Renderizar o componente do passo atual
  const StepComponent = currentStep?.component;
  
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="relative w-full h-full md:w-[90%] md:h-[90%] md:max-w-4xl md:rounded-xl bg-white shadow-xl overflow-hidden flex flex-col"
          >
            {/* Cabeçalho */}
            <div className="flex justify-between items-center p-4 md:p-6 border-b border-neutral-200">
              <div>
                <h2 className="text-xl md:text-2xl font-semibold text-neutral-800">
                  {startWithPendingSteps ? "Complete seu perfil" : isReview ? "Revisar Informações de Perfil" : "Bem-vindo ao Meu Humor"}
                </h2>
                <p className="text-neutral-600 text-sm md:text-base">
                  {startWithPendingSteps 
                    ? `Passo ${pendingSteps.indexOf(currentStep?.id) + 1} de ${pendingSteps.length}` 
                    : `Passo ${globalStepIndex + 1} de ${activeSteps.length}`
                  }
                </p>
              </div>
              
              {/* Botão de fechar aparece em dois casos:
                  1. No modo de revisão (isReview = true), exibe sempre
                  2. Fora do modo de revisão, exibe apenas se não for obrigatório e o passo atual não for obrigatório */}
              {(isReview || (!isRequired && !isCurrentStepRequired)) && (
                <button
                  onClick={handleClose}
                  className="p-2 rounded-full hover:bg-neutral-100 transition-colors"
                  aria-label="Fechar"
                >
                  <X size={24} className="text-neutral-600" />
                </button>
              )}
            </div>
            
            {/* Conteúdo do passo */}
            <div ref={scrollContainerRef} className="flex-1 overflow-y-auto p-4 md:p-6">
              {isLoading ? (
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="w-12 h-12 border-4 border-primary-200 border-t-primary-600 rounded-full animate-spin mb-4"></div>
                  <p className="text-neutral-600">Carregando informações...</p>
                </div>
              ) : (
                StepComponent && isInitialized && (
                  <StepComponent
                    userData={userData}
                    onNext={handleNext}
                    onSkip={handleSkip}
                    onBack={handleBack}
                    isSubmitting={isSubmitting}
                    showBackButton={showBackButton}
                  />
                )
              )}
            </div>
            
            {/* Barra de progresso */}
            <div className="p-4 border-t border-neutral-200">
              <div className="w-full bg-neutral-200 rounded-full h-2">
                <div
                  className="bg-primary-500 h-2 rounded-full transition-all duration-300"
                  style={{ 
                    width: startWithPendingSteps && pendingSteps.length > 0
                      ? `${((pendingStepsHistory.length) / (pendingSteps.length + 1)) * 100}%`
                      : `${((globalStepIndex + 1) / activeSteps.length) * 100}%` 
                  }}
                ></div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OnBoardingModal; 