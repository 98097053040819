import React, { useState, useEffect } from 'react';
import { OnBoardingUserData } from '../../../types';
import { MapPin, Calendar, Users, Shield } from 'lucide-react';
import { StepProps } from './StepProps';

const genderOptions = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'feminino', label: 'Feminino' },
  { value: 'nao-binario', label: 'Não-binário' },
  { value: 'prefiro-nao-informar', label: 'Prefiro não informar' }
];

// Opções de faixa etária para modo anônimo
const ageRangeOptions = [
  { value: 'menor-18', label: 'Menor de 18 anos' },
  { value: '18-24', label: '18-24 anos' },
  { value: '25-34', label: '25-34 anos' },
  { value: '35-44', label: '35-44 anos' },
  { value: '45-54', label: '45-54 anos' },
  { value: '55-64', label: '55-64 anos' },
  { value: '65-mais', label: '65 anos ou mais' },
  { value: 'prefiro-nao-informar', label: 'Prefiro não informar' }
];

// Opções de região para modo anônimo (em vez de cidade/estado específico)
const regionOptions = [
  { value: 'norte', label: 'Região Norte' },
  { value: 'nordeste', label: 'Região Nordeste' },
  { value: 'centro-oeste', label: 'Região Centro-Oeste' },
  { value: 'sudeste', label: 'Região Sudeste' },
  { value: 'sul', label: 'Região Sul' },
  { value: 'prefiro-nao-informar', label: 'Prefiro não informar' }
];

const DemographicsStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  const [age, setAge] = useState<string>(userData.age?.toString() || '');
  const [ageRange, setAgeRange] = useState<string>(userData.ageRange || '');
  const [gender, setGender] = useState<string>(userData.gender || '');
  const [location, setLocation] = useState<string>(userData.location || '');
  const [region, setRegion] = useState<string>(userData.region || '');
  const [isAnonymous, setIsAnonymous] = useState<boolean>(userData.isAnonymous !== false);
  
  // Atualizar o estado isAnonymous quando userData mudar
  useEffect(() => {
    setIsAnonymous(userData.isAnonymous !== false);
  }, [userData.isAnonymous]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const data: Partial<OnBoardingUserData> = {};
    
    // Dados diferentes dependendo do modo (anônimo ou não)
    if (isAnonymous) {
      if (ageRange) {
        data.ageRange = ageRange;
      }
      
      if (region) {
        data.region = region;
      }
    } else {
      if (age) {
        data.age = parseInt(age, 10);
      }
      
      if (location) {
        data.location = location;
      }
    }
    
    // Gênero é coletado em ambos os modos
    if (gender) {
      data.gender = gender;
    }
    
    onNext(data);
  };
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-6">
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          Informações demográficas
        </h3>
        <p className="text-neutral-600">
          Estas informações são opcionais e nos ajudam a personalizar sua experiência
        </p>
      </div>
      
      {/* Indicador de modo anônimo */}
      {isAnonymous && (
        <div className="mb-6 p-3 bg-neutral-50 rounded-lg border border-neutral-200 flex items-center">
          <Shield size={18} className="text-primary-600 mr-2 flex-shrink-0" />
          <p className="text-sm text-neutral-700">
            Você está no <strong>modo anônimo</strong>. Coletamos apenas informações gerais que não permitem sua identificação.
          </p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          {/* Idade - versão diferente dependendo do modo */}
          {isAnonymous ? (
            <div>
              <label htmlFor="ageRange" className="block text-sm font-medium text-neutral-700 mb-1">
                Faixa etária
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-neutral-500" />
                </div>
                <select
                  id="ageRange"
                  value={ageRange}
                  onChange={(e) => setAgeRange(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 appearance-none"
                >
                  <option value="">Selecione uma opção</option>
                  {ageRangeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-neutral-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <label htmlFor="age" className="block text-sm font-medium text-neutral-700 mb-1">
                Idade
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar size={18} className="text-neutral-500" />
                </div>
                <input
                  type="number"
                  id="age"
                  min="1"
                  max="120"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Sua idade"
                />
              </div>
            </div>
          )}
          
          {/* Gênero - igual para ambos os modos */}
          <div>
            <label htmlFor="gender" className="block text-sm font-medium text-neutral-700 mb-1">
              Gênero
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Users size={18} className="text-neutral-500" />
              </div>
              <select
                id="gender"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 appearance-none"
              >
                <option value="">Selecione uma opção</option>
                {genderOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-neutral-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
          
          {/* Localização - versão diferente dependendo do modo */}
          {isAnonymous ? (
            <div>
              <label htmlFor="region" className="block text-sm font-medium text-neutral-700 mb-1">
                Região
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin size={18} className="text-neutral-500" />
                </div>
                <select
                  id="region"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 appearance-none"
                >
                  <option value="">Selecione uma opção</option>
                  {regionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <svg className="h-5 w-5 text-neutral-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-neutral-700 mb-1">
                Cidade/Estado
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin size={18} className="text-neutral-500" />
                </div>
                <input
                  type="text"
                  id="location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Ex: São Paulo, SP"
                />
              </div>
            </div>
          )}
        </div>
        
        <div className="pt-4 space-y-3">
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Salvando...' : 'Continuar'}
          </button>
          
          <button
            type="button"
            onClick={onSkip}
            disabled={isSubmitting}
            className="w-full flex justify-center py-2 px-4 border border-neutral-300 rounded-md shadow-sm text-sm font-medium text-neutral-700 bg-white hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Pular esta etapa
          </button>
          
          {showBackButton && (
            <button
              type="button"
              onClick={onBack}
              disabled={isSubmitting}
              className="w-full flex justify-center py-2 px-4 text-sm font-medium text-neutral-600 hover:text-neutral-800 focus:outline-none"
            >
              Voltar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default DemographicsStep; 