import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { registerServiceWorker } from './infra/NotificationFacade';

// Registrar service worker quando a aplicação iniciar
async function initializeApp() {
  try {
    // Registrar o service worker para notificações
    if ('serviceWorker' in navigator) {
      const registration = await registerServiceWorker();
      if (registration) {
        console.log('Service Worker registrado com sucesso:', registration.scope);
      }
    }
    
    // Renderizar a aplicação
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  } catch (error) {
    console.error('Erro ao inicializar a aplicação:', error);
    
    // Em caso de erro no registro do SW, renderizar a aplicação mesmo assim
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  }
}

initializeApp();