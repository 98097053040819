import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Questionário PHQ-9 simplificado para triagem de depressão
const depressionQuestions = [
  {
    id: 'phq1',
    text: 'Pouco interesse ou prazer em fazer as coisas'
  },
  {
    id: 'phq2',
    text: 'Sentindo-se para baixo, deprimido ou sem esperança'
  },
  {
    id: 'phq3',
    text: 'Dificuldade para dormir, permanecer dormindo ou dormir demais'
  },
  {
    id: 'phq4',
    text: 'Sentindo-se cansado ou com pouca energia'
  },
  {
    id: 'phq5',
    text: 'Pouco apetite ou comendo demais'
  },
  {
    id: 'phq6',
    text: 'Sentindo-se mal consigo mesmo, sentindo-se um fracasso ou que decepcionou a si mesmo ou sua família'
  },
  {
    id: 'phq7',
    text: 'Dificuldade para se concentrar em coisas como ler o jornal ou assistir televisão'
  },
  {
    id: 'phq8',
    text: 'Movimentando-se ou falando tão lentamente que outras pessoas notaram, ou o contrário – agitado ou inquieto mais do que o normal'
  },
  {
    id: 'phq9',
    text: 'Pensamentos de que seria melhor estar morto ou de se machucar de alguma forma'
  }
];

// Opções de resposta para cada pergunta
const responseOptions = [
  { value: 0, label: 'Nenhuma vez' },
  { value: 1, label: 'Vários dias' },
  { value: 2, label: 'Mais da metade dos dias' },
  { value: 3, label: 'Quase todos os dias' }
];

const DepressionScreeningStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  // Inicializar respostas com valores existentes ou vazios
  const [responses, setResponses] = useState<Record<string, number>>(
    userData.depressionScreening || {}
  );
  
  const handleResponseChange = (questionId: string, value: number) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
  };
  
  const handleSubmit = () => {
    // Calcular pontuação total
    const totalScore = Object.values(responses).reduce((sum, score) => sum + score, 0);
    
    onNext({
      depressionScreening: responses,
      depressionScore: totalScore
    });
  };
  
  // Verificar se todas as perguntas foram respondidas
  const isComplete = depressionQuestions.every(q => responses[q.id] !== undefined);
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Triagem de Depressão</h3>
        <p className="text-neutral-600 mb-4">
          Nas últimas 2 semanas, com que frequência você foi incomodado por algum dos problemas abaixo?
        </p>
      </div>
      
      <div className="space-y-6">
        {depressionQuestions.map((question) => (
          <div key={question.id} className="space-y-3">
            <p className="font-medium text-neutral-700">{question.text}</p>
            <div className="grid grid-cols-2 gap-2 md:flex md:flex-wrap">
              {responseOptions.map((option) => (
                <div key={option.value} className="flex items-center">
                  <input
                    type="radio"
                    id={`${question.id}-${option.value}`}
                    name={question.id}
                    value={option.value}
                    checked={responses[question.id] === option.value}
                    onChange={() => handleResponseChange(question.id, option.value)}
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
                  />
                  <label
                    htmlFor={`${question.id}-${option.value}`}
                    className="ml-2 text-sm text-neutral-700"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!isComplete || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DepressionScreeningStep; 