import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageSquare, Plus, ChevronRight, Calendar, Clock, Search, Brain, Loader2 } from 'lucide-react';
import { format, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ChatSession } from '../types';
import { getChatSessions } from '../infra/ChatAIFacade';
import { useChat } from '../contexts/ChatContext';

const ChatSessionsPage: React.FC = () => {
  const [sessions, setSessions] = useState<ChatSession[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { startNewSession } = useChat();
  
  // Busca as sessões quando a página carrega
  useEffect(() => {
    const fetchSessions = async () => {
      try {
        setLoading(true);
        const response = await getChatSessions();
        setSessions(response.sessions || []);
      } catch (err) {
        console.error('Erro ao buscar sessões:', err);
        setError('Não foi possível carregar suas sessões de chat. Por favor, tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchSessions();
  }, []);
  
  // Filtra as sessões com base no termo de busca
  const filteredSessions = sessions.filter(session => 
    session.lastMessage.content.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  // Formata a data de uma sessão
  const formatSessionDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return {
        formatted: format(date, "dd 'de' MMMM 'de' yyyy, HH:mm", { locale: ptBR }),
        relative: formatDistanceToNow(date, { addSuffix: true, locale: ptBR })
      };
    } catch (error) {
      console.error('Erro ao formatar data:', error);
      return { formatted: 'Data inválida', relative: '' };
    }
  };
  
  // Abre uma sessão existente
  const handleOpenSession = (session: ChatSession) => {
    navigate(`/chat?sessionId=${session.id}`);
  };
  
  // Inicia uma nova sessão
  const handleNewSession = () => {
    startNewSession();
    navigate('/chat');
  };
  
  return (
    <div className="container-responsive py-6">
      {/* Cabeçalho da página */}
      <div className="flex justify-between items-center mb-6 md:mb-8">
        <h1 className="section-title mb-0">Minhas Conversas</h1>
        <div className="bg-primary-100 p-2 md:p-3 rounded-full">
          <MessageSquare className="h-5 w-5 md:h-6 md:w-6 text-primary-500" />
        </div>
      </div>
      
      {/* Barra de pesquisa e botão de nova conversa */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Pesquisar em conversas anteriores..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-3 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-500" size={20} />
        </div>
        <button
          onClick={handleNewSession}
          className="bg-primary-500 hover:bg-primary-600 text-white rounded-lg py-3 px-5 flex items-center justify-center transition-colors"
        >
          <Plus size={20} className="mr-2" />
          <span>Nova Conversa</span>
        </button>
      </div>
      
      {/* Conteúdo principal - Lista de sessões ou mensagem de carregamento/erro */}
      <div className="card p-5">
        {loading ? (
          <div className="flex flex-col items-center justify-center py-8 text-center">
            <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-4" />
            <p className="text-neutral-600">Carregando suas conversas...</p>
          </div>
        ) : error ? (
          <div className="text-center py-8">
            <p className="text-red-500 mb-4">{error}</p>
            <button
              onClick={handleNewSession}
              className="btn-primary"
            >
              Iniciar Nova Conversa
            </button>
          </div>
        ) : filteredSessions.length > 0 ? (
          <div className="space-y-4">
            {filteredSessions.map((session) => {
              const dateInfo = formatSessionDate(session.updatedAt);
              return (
                <div
                  key={session.id}
                  onClick={() => handleOpenSession(session)}
                  className="bg-white border border-neutral-200 rounded-lg p-4 hover:shadow-md transition-shadow cursor-pointer"
                >
                  <div className="flex justify-between items-start">
                    <div className="flex-grow pr-4">
                      <div className="mb-2 flex items-center">
                        <Brain size={18} className="text-primary-500 mr-2" />
                        <span className="text-sm font-medium text-neutral-500">
                          {session.lastMessage.isUser ? 'Você' : 'Assistente'}
                        </span>
                      </div>
                      <p className="text-neutral-800 line-clamp-2 mb-2">
                        {session.lastMessage.content}
                      </p>
                      <div className="flex items-center text-xs text-neutral-500">
                        <Calendar size={14} className="mr-1" />
                        <span className="mr-3">{dateInfo.formatted}</span>
                        <Clock size={14} className="mr-1" />
                        <span>{dateInfo.relative}</span>
                      </div>
                    </div>
                    <div className="text-primary-500">
                      <ChevronRight size={20} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-center py-8">
            <p className="text-neutral-600 mb-4">
              {searchTerm
                ? 'Nenhuma conversa encontrada com esse termo de busca.'
                : 'Você ainda não iniciou nenhuma conversa com o assistente.'}
            </p>
            <button
              onClick={handleNewSession}
              className="btn-primary"
            >
              Iniciar Primeira Conversa
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatSessionsPage; 