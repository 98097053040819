import React, { useState } from 'react';
import { motion } from 'framer-motion';
import MoodEntryForm from '../components/MoodEntryForm';
import { useNavigate } from 'react-router-dom';

const RecordPage: React.FC = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const navigate = useNavigate();
  
  const handleComplete = () => {
    setIsCompleted(true);
    setTimeout(() => {
      navigate('/home');
    }, 2000);
  };
  
  return (
    <div className="py-6">
      <h1 className="text-2xl font-semibold text-neutral-800 mb-6">Registrar Humor</h1>
      
      {!isCompleted ? (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="card"
        >
          <MoodEntryForm onComplete={handleComplete} />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="card text-center py-8"
        >
          <div className="bg-accent-100 p-4 rounded-full inline-flex mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-accent-600">
              <path d="M20 6L9 17l-5-5"></path>
            </svg>
          </div>
          <h2 className="text-xl font-medium text-neutral-800 mb-2">Registro salvo com sucesso!</h2>
          <p className="text-neutral-600">
            Obrigado por compartilhar como está se sentindo.
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default RecordPage;