import { createClient } from '@supabase/supabase-js';

// Obter as variáveis de ambiente para Supabase
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL || '';
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';

// Criar uma única instância do cliente Supabase sem PKCE e sem autoRefreshToken
const supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: false, // Desativa completamente o refresh automático
    persistSession: true,
    detectSessionInUrl: false,
    flowType: 'implicit', // Usa o fluxo implícito em vez de PKCE
    storageKey: 'mindful-moments-auth',
  },
  global: {
    headers: {
      'X-Client-Info': 'mindful-moments-app'
    }
  },
});

// Adicionar retry com backoff exponencial para requisições que falharem
// @ts-expect-error - httpOptions não está na tipagem atual mas é suportado
supabaseInstance.httpOptions = {
  fetch: async (url: string, options: RequestInit) => {
    let attempts = 0;
    const maxAttempts = 3;
    
    // Função para implementar backoff exponencial
    const getBackoffDelay = (attempt: number): number => {
      return Math.min(1000 * Math.pow(2, attempt), 30000); // No máximo 30 segundos
    };
    
    while (attempts < maxAttempts) {
      try {
        const response = await fetch(url, options);
        
        // Se for um erro de rate limiting (429), esperar antes de tentar novamente
        if (response.status === 429) {
          const backoffDelay = getBackoffDelay(attempts);
          console.log(`[${new Date().toISOString()}] Rate limit atingido (429). Aguardando ${backoffDelay}ms antes de tentar novamente.`);
          await new Promise(resolve => setTimeout(resolve, backoffDelay));
          attempts++;
          continue;
        }
        
        return response;
      } catch (error) {
        attempts++;
        if (attempts >= maxAttempts) throw error;
        
        const backoffDelay = getBackoffDelay(attempts);
        console.log(`[${new Date().toISOString()}] Erro na requisição. Tentativa ${attempts}/${maxAttempts}. Aguardando ${backoffDelay}ms.`);
        await new Promise(resolve => setTimeout(resolve, backoffDelay));
      }
    }
    
    // Se chegou aqui, todas as tentativas falharam
    throw new Error(`Falha após ${maxAttempts} tentativas`);
  }
};

// Monitorar eventos de autenticação para fins de logging
supabaseInstance.auth.onAuthStateChange((event) => {
  if (event === 'SIGNED_IN') {
    console.log(`[${new Date().toISOString()}] Usuário conectado com sucesso`);
  } else if (event === 'SIGNED_OUT') {
    console.log(`[${new Date().toISOString()}] Usuário desconectado`);
  }
});

// Função simples para obter o cliente Supabase
export function getSupabase() {
  return supabaseInstance;
}

// Também exportar diretamente a instância para manter compatibilidade com importações existentes
export const supabase = supabaseInstance;
