/* eslint-disable @typescript-eslint/no-explicit-any */
import { getNotificationService } from './serviceLocator';
import { getCurrentUser, registerPush } from './supabaseGateway';

/**
 * Verifica se o navegador é o Safari no iOS
 */
function isSafariIOS(): boolean {
  const ua = navigator.userAgent;
  const isSafari = ua.includes('Safari') && !ua.includes('Chrome') && !ua.includes('Android');
  const isIOS = /iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  return isSafari && isIOS;
}

/**
 * Verifica se o aplicativo está sendo executado como um aplicativo de tela inicial no iOS
 * No iOS, isso é indicado pela ausência da UI do navegador (modo standalone)
 */
function isRunningAsHomeScreenApp(): boolean {
  return window.matchMedia('(display-mode: standalone)').matches || 
         (window.navigator as any).standalone === true;
}

/**
 * Verifica se o navegador suporta notificações push
 * Considera as especificidades do Safari no iOS, que só suporta notificações
 * quando o aplicativo é adicionado à tela inicial a partir do iOS 16.4
 */
function isPushNotificationSupported(): boolean {
  // Verificação padrão para a maioria dos navegadores
  const standardSupport = 'serviceWorker' in navigator && 'PushManager' in window;
  
  // Para Safari no iOS, verificamos se está rodando como app de tela inicial
  if (isSafariIOS()) {
    return standardSupport && isRunningAsHomeScreenApp();
  }
  
  return standardSupport;
}

// Solicitar permissão para enviar notificações
async function requestNotificationPermission(): Promise<NotificationPermission | 'unsupported'> {
  if (!('Notification' in window)) {
    return 'unsupported';
  }

  // Para Safari no iOS, verificamos se está rodando como app de tela inicial
  if (isSafariIOS() && !isRunningAsHomeScreenApp()) {
    console.log('Safari iOS detectado, mas não está rodando como app de tela inicial');
    // Retornamos 'default' para que a UI possa mostrar instruções específicas
    return 'default';
  }

  try {
    return await Notification.requestPermission();
  } catch (error) {
    console.error('Erro ao solicitar permissão para notificações:', error);
    return 'denied';
  }
}

// Registrar o service worker unificado
async function registerServiceWorker(): Promise<ServiceWorkerRegistration | null> {
  if (!('serviceWorker' in navigator)) {
    return null;
  }

  try {
    // Verificar se há service workers antigos para desregistrar
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    // Desregistrar o service worker principal antigo se existir
    const mainSW = registrations.find(reg => 
      reg.active?.scriptURL.includes('sw.js') && 
      !reg.active?.scriptURL.includes('firebase-messaging-sw.js')
    );
    
    if (mainSW) {
      console.log('Desregistrando service worker principal antigo...');
      await mainSW.unregister();
      console.log('Service worker principal antigo desregistrado com sucesso');
    }
    
    // Registrar ou atualizar o service worker unificado do Firebase
    let firebaseRegistration: ServiceWorkerRegistration | null = null;
    
    // Verificar se já existe um service worker do Firebase registrado
    const firebaseSW = registrations.find(reg => 
      reg.active?.scriptURL.includes('firebase-messaging-sw.js')
    );
    
    if (firebaseSW) {
      console.log('Service Worker unificado já registrado, atualizando...', firebaseSW);
      await firebaseSW.update().catch(err => 
        console.error('Erro ao atualizar Service Worker unificado:', err)
      );
      
      // Verificar se o service worker está em estado de espera e forçar ativação
      if (firebaseSW.waiting) {
        console.log('Service Worker unificado em estado de espera, ativando...');
        firebaseSW.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Esperar um pouco para dar tempo ao service worker se ativar
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      firebaseRegistration = firebaseSW;
    } else {
      console.log('Registrando Service Worker unificado...');
      // Registrar o service worker unificado
      firebaseRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
        updateViaCache: 'none',
        scope: '/' // Escopo raiz para garantir que ele funcione em todo o site
      });
      console.log('Service Worker unificado registrado com sucesso:', firebaseRegistration.scope);
    }
      
    // Ativar imediatamente o service worker do Firebase Messaging se estiver em estado de espera
    if (firebaseRegistration.waiting) {
      console.log('Firebase Messaging Service Worker em estado de espera, ativando...');
      firebaseRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Esperar um pouco para dar tempo ao service worker se ativar
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  
    // Retornar o service worker principal para manter compatibilidade com o código existente
    return firebaseRegistration;
  } catch (error) {
    console.error('Erro ao registrar Service Workers:', error);
    return null;
  }
}

/**
 * Força a reinstalação do service worker em dispositivos já instalados
 * Isso é útil quando há mudanças significativas no service worker
 * que precisam ser aplicadas em todos os dispositivos
 */
async function forceServiceWorkerReinstallation(): Promise<boolean> {
  if (!('serviceWorker' in navigator)) {
    console.warn('Service Worker não é suportado neste navegador');
    return false;
  }

  try {
    // Limpar qualquer token FCM armazenado
    localStorage.removeItem('fcm_token');
    
    // Obter todos os service workers registrados
    const registrations = await navigator.serviceWorker.getRegistrations();
    
    if (registrations.length === 0) {
      console.log('Nenhum Service Worker encontrado para desregistrar');
      // Registrar novo service worker
      await registerServiceWorker();
      return true;
    }
    
    // Desregistrar todos os service workers existentes
    console.log(`Desregistrando ${registrations.length} Service Workers...`);
    
    await Promise.all(
      registrations.map(async (registration) => {
        const result = await registration.unregister();
        console.log(`Service Worker desregistrado com sucesso: ${result}`);
        return result;
      })
    );
    
    // Limpar cache para garantir que o novo service worker seja baixado
    if ('caches' in window) {
      const cacheKeys = await caches.keys();
      await Promise.all(
        cacheKeys.map(key => caches.delete(key))
      );
      console.log('Caches limpos com sucesso');
    }
    
    // Aguardar um momento para garantir que o navegador está pronto para registrar um novo service worker
    // Este atraso é importante para evitar problemas de registro após desregistro
    console.log('Aguardando para garantir que o navegador está pronto para o novo registro...');
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // Primeira tentativa de registro
    let newRegistration = null;
    try {
      // Registrar novo service worker
      newRegistration = await registerServiceWorker();
    } catch (registerError) {
      console.error('Erro ao registrar novo Service Worker:', registerError);
      
      // Tentar novamente após um intervalo maior
      console.log('Tentando registrar novamente após um intervalo maior...');
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      try {
        newRegistration = await registerServiceWorker();
      } catch (secondError) {
        console.error('Erro na segunda tentativa de registro do Service Worker:', secondError);
        return false;
      }
    }
    
    // Verificar resultado do registro (seja da primeira ou segunda tentativa)
    if (newRegistration) {
      console.log('Novo Service Worker registrado com sucesso após reinstalação forçada');
      return true;
    } else {
      console.error('Falha ao registrar Service Worker após tentativas');
      return false;
    }
  } catch (error) {
    console.error('Erro ao forçar reinstalação do Service Worker:', error);
    return false;
  }
}

/**
 * Função auxiliar para esperar que o service worker unificado esteja ativo
 * @param registration O service worker registration a ser verificado
 * @param maxAttempts Número máximo de tentativas
 */
async function waitForServiceWorkerActivation(
  registration: ServiceWorkerRegistration, 
  maxAttempts = 20
): Promise<boolean> {
  // Verificar se já está ativo
  if (registration.active) {
    console.log('Service worker unificado já está ativo');
    // Esperar um pouco para garantir que está completamente inicializado
    await new Promise(resolve => setTimeout(resolve, 500));
    return true;
  }

  // Forçar a ativação se estiver em estado 'waiting'
  if (registration.waiting) {
    console.log('Service worker unificado em estado "waiting", forçando ativação...');
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    // Esperar um pouco para dar tempo ao service worker se ativar
    await new Promise(resolve => setTimeout(resolve, 1000));
  }

  // Se o service worker está em estado 'installing' ou ainda não ativou, precisamos esperar
  return new Promise((resolve) => {
    let attempts = 0;
    const checkInterval = setInterval(async () => {
      attempts++;
      console.log(`Verificando ativação dos service workers (tentativa ${attempts})...`);
      
      // Verificar o service worker principal
      const isMainActive = registration.active !== null;
      
      // Verificar o service worker do Firebase Messaging se necessário
      const isFirebaseActive = true;
      
      if (isMainActive && isFirebaseActive) {
        clearInterval(checkInterval);
        console.log('Todos os service workers estão ativos!');
        // Esperar mais um pouco para garantir que estão completamente inicializados
        setTimeout(() => resolve(true), 500);
      } else if (attempts >= maxAttempts) {
        clearInterval(checkInterval);
        console.warn(`Service workers não ativaram após ${maxAttempts} tentativas`);
        console.log(`Status: Main SW ativo: ${isMainActive}, Firebase SW ativo: ${isFirebaseActive}`);
        resolve(false);
      }
    }, 500); // Verificar a cada 500ms
  });
}

// Registrar para notificações push com FCM
async function registerForPushNotifications(): Promise<{ success: boolean }> {
  if (!isPushNotificationSupported()) {
    console.warn('Notificações push não são suportadas neste navegador');
    return { success: false };
  }

  try {
    // Verificar permissão
    const permission = await requestNotificationPermission();
    if (permission !== 'granted') {
      console.warn('Permissão para notificações não foi concedida');
      return { success: false };
    }
    
    // Registrar o service worker
    console.log('Registrando service worker...');
    const swRegistration = await registerServiceWorker();
    
    if (!swRegistration) {
      console.error('Falha ao registrar Service Worker');
      return { success: false };
    }
    
    // Forçar a ativação de qualquer service worker em espera
    if (swRegistration.waiting) {
      console.log('Service worker em estado "waiting", forçando ativação...');
      swRegistration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // Esperar para dar tempo ao service worker se ativar
      await new Promise(resolve => setTimeout(resolve, 1500));
    }
    
    // Garantir que o service worker esteja ativo antes de prosseguir
    console.log('Aguardando ativação do service worker...');
    const isActive = await waitForServiceWorkerActivation(swRegistration);
    
    if (!isActive) {
      console.error('Service worker não foi ativado após várias tentativas');
      // Tentar uma abordagem alternativa - forçar a reinstalação do service worker
      console.log('Tentando forçar a reinstalação do service worker...');
      await forceServiceWorkerReinstallation();
      
      // Esperar mais um pouco
      await new Promise(resolve => setTimeout(resolve, 2000));
    }
    
    // Verificar novamente se o service worker está ativo
    const registrations = await navigator.serviceWorker.getRegistrations();
    if (registrations.length === 0 || !registrations[0].active) {
      console.error('Service worker ainda não está ativo após todas as tentativas');
      return { success: false };
    }
    
    console.log('Service worker está ativo, prosseguindo...');
    
    // Esperar um pouco mais antes de tentar obter o token
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Obter o token FCM
    let fcmToken;
    let tokenAttempts = 0;
    const maxTokenAttempts = 3;
    
    while (tokenAttempts < maxTokenAttempts) {
      try {
        tokenAttempts++;
        console.log(`Obtendo token FCM (tentativa ${tokenAttempts})...`);
        fcmToken = await getNotificationService().getToken();
        if (fcmToken) break;
      } catch (error) {
        console.error(`Erro ao obter token FCM (tentativa ${tokenAttempts}):`, error);
        if (tokenAttempts < maxTokenAttempts) {
          console.log(`Aguardando antes da próxima tentativa (${tokenAttempts}/${maxTokenAttempts})...`);
          await new Promise(resolve => setTimeout(resolve, 1500));
        } else {
          console.error('Número máximo de tentativas excedido para obter o token FCM');
          return { success: false };
        }
      }
    }
    
    if (!fcmToken) {
      console.error('Token FCM não obtido após várias tentativas');
      return { success: false };
    }
    
    console.log('Token FCM obtido com sucesso:', fcmToken.substring(0, 10) + '...');
    
    // Enviar o token para o Supabase Edge Function
    try {
      const user = await getCurrentUser();
      const { error } = await registerPush(fcmToken, user?.id);

      if (error) {
        console.error('Erro ao registrar token FCM no Supabase:', error);
        return { success: false };
      }
      
      return { success: true };
    } catch (error) {
      console.error('Erro ao registrar para notificações push:', error);
      return { success: false };
    }
  } catch (error) {
    console.error('Erro ao registrar para notificações push:', error);
    return { success: false };
  }
}

// Verificar status das notificações
async function checkNotificationStatus(): Promise<{
  pushSupported: boolean,
  permission: NotificationPermission | 'unsupported',
  serviceWorkerRegistered: boolean,
  subscription: boolean,
  isSafariIOS: boolean,
  isHomeScreenApp: boolean
}> {
  // Verificar se o navegador suporta notificações push
  const pushSupported = isPushNotificationSupported();
  
  // Verificar permissão atual
  let permission: NotificationPermission | 'unsupported' = 'denied';
  if ('Notification' in window) {
    permission = Notification.permission;
  } else {
    permission = 'unsupported';
  }
  
  // Verificar status do service worker
  let serviceWorkerRegistered = false;
  let subscription = false;
  
  if (pushSupported) {
    try {
      // Obter todas as registrações de service worker
      const registrations = await navigator.serviceWorker.getRegistrations();
      serviceWorkerRegistered = registrations.length > 0;
      
      // Se temos permissão e SW registrado, verificamos se existe token FCM
      if (serviceWorkerRegistered && permission === 'granted') {
        // Verificar se temos um token FCM usando a função auxiliar
        subscription = getNotificationService().hasFCMToken();
      }
    } catch (error) {
      console.error('Erro ao verificar status do service worker:', error);
    }
  }
  
  // Verificar se é Safari no iOS e se está rodando como app de tela inicial
  const isSafariIOSValue = isSafariIOS();
  const isHomeScreenAppValue = isRunningAsHomeScreenApp();
  
  return {
    pushSupported,
    permission,
    serviceWorkerRegistered,
    subscription,
    isSafariIOS: isSafariIOSValue,
    isHomeScreenApp: isHomeScreenAppValue
  };
}

// Send a local notification
async function sendLocalNotification(title: string, options: NotificationOptions = {}): Promise<boolean> {
  const permission = await requestNotificationPermission();
  
  if (permission !== 'granted') {
    console.warn('Notification permission not granted');
    return false;
  }
  
  try {
    const defaultOptions: NotificationOptions = {
      body: 'Como você está se sentindo agora?',
      icon: '/favicon.svg',
      badge: '/favicon.svg',
      data: {
        url: '/registrar'
      },
      actions: [
        {
          action: 'open',
          title: 'Registrar humor'
        },
        {
          action: 'close',
          title: 'Mais tarde'
        }
      ]
    } as any;
    
    // Usar o service worker para exibir a notificação (suporta actions)
    try {
      const registration = await navigator.serviceWorker.ready;
      await registration.showNotification(title, { ...defaultOptions, ...options });
      return true;
    } catch (swError) {
      console.warn('Erro ao usar service worker para notificação, tentando método alternativo:', swError);
      
      // Fallback: usar notificação simples sem ações se o service worker falhar
      const simpleOptions = { ...defaultOptions, ...options } as any;
      delete simpleOptions.actions; // Remover ações para evitar erro
      new Notification(title, simpleOptions);
      return true;
    }
  } catch (error) {
    console.error('Erro ao enviar notificação:', error);
    return false;
  }
}

export {
  isPushNotificationSupported,
  requestNotificationPermission,
  registerServiceWorker,
  forceServiceWorkerReinstallation,
  registerForPushNotifications,
  checkNotificationStatus,
  sendLocalNotification,
  isSafariIOS,
  isRunningAsHomeScreenApp
};