import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import * as SupabaseGateway from '../infra/supabaseGateway';
import { useAuth } from '../contexts/AuthContext';
import { Brain, Check, X, Loader2, Shield } from 'lucide-react';

// Password strength criteria
const hasLowerCase = (str: string) => /[a-z]/.test(str);
const hasUpperCase = (str: string) => /[A-Z]/.test(str);
const hasNumber = (str: string) => /[0-9]/.test(str);
const hasSpecialChar = (str: string) => /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);
const isLongEnough = (str: string) => str.length >= 8;

const calculatePasswordStrength = (password: string): number => {
  if (!password) return 0;
  
  let strength = 0;
  if (isLongEnough(password)) strength += 1;
  if (hasLowerCase(password)) strength += 1;
  if (hasUpperCase(password)) strength += 1;
  if (hasNumber(password)) strength += 1;
  if (hasSpecialChar(password)) strength += 1;
  
  return strength;
};

const getStrengthLabel = (strength: number): string => {
  if (strength === 0) return 'Muito fraca';
  if (strength === 1) return 'Fraca';
  if (strength === 2) return 'Razoável';
  if (strength === 3) return 'Boa';
  if (strength === 4) return 'Forte';
  return 'Muito forte';
};

const getStrengthColor = (strength: number): string => {
  if (strength === 0) return 'bg-red-500';
  if (strength === 1) return 'bg-red-500';
  if (strength === 2) return 'bg-yellow-500';
  if (strength === 3) return 'bg-yellow-500';
  if (strength === 4) return 'bg-green-500';
  return 'bg-green-500';
};

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const { user, refreshUser } = useAuth();
  
  const passwordStrength = calculatePasswordStrength(password);
  const passwordsMatch = password === confirmPassword;
  const isPasswordValid = passwordStrength >= 3;
  const canSubmitNewAccount = email && password && passwordsMatch && isPasswordValid;

  // If user is already logged in, handle redirections
  useEffect(() => {
    if (user) {
      // Verificar se há um redirecionamento pendente para pagamento
      const redirectToPayment = localStorage.getItem('redirectToPayment');
      const pendingPlanId = localStorage.getItem('pendingPlanId');
      const paymentRedirectTimestamp = localStorage.getItem('paymentRedirectTimestamp');
      
      // Verificar se o redirecionamento é recente (menos de 10 minutos)
      const isRedirectValid = paymentRedirectTimestamp && 
        (Date.now() - parseInt(paymentRedirectTimestamp)) < 10 * 60 * 1000;
      
      if (redirectToPayment === 'true' && pendingPlanId && isRedirectValid) {
        // Limpar os dados de redirecionamento
        localStorage.removeItem('redirectToPayment');
        localStorage.removeItem('pendingPlanId');
        localStorage.removeItem('paymentRedirectTimestamp');
        
        console.log(`Redirecionando para pagamento do plano: ${pendingPlanId}`);
        
        // Redirecionar para a página de planos com o plano selecionado
        navigate(`/planos?selectedPlan=${pendingPlanId}&autoCheckout=true`);
      } else {
        // Redirecionamento padrão para a página inicial
        navigate('/home');
      }
    }
    
    // Check if user came from landing page with createAccount flag
    const shouldCreateAccount = localStorage.getItem('createAccount');
    if (shouldCreateAccount === 'true') {
      setIsCreatingAccount(true);
      // Não remover 'createAccount' aqui para manter a intenção de pagamento
    }
    
    // Check URL parameters for direct account creation
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('createAccount') === 'true') {
      setIsCreatingAccount(true);
    }
  }, [user, navigate, location]);

  const redirectsToCheckoutIfNeeded = async () => {
    const redirectToPayment = localStorage.getItem('redirectToPayment');
    const pendingPlanId = localStorage.getItem('pendingPlanId');
    const paymentRedirectTimestamp = localStorage.getItem('paymentRedirectTimestamp');
    
    // Verificar se o redirecionamento é recente (menos de 10 minutos)
    const isRedirectValid = paymentRedirectTimestamp && 
      (Date.now() - parseInt(paymentRedirectTimestamp)) < 10 * 60 * 1000;
    
    if (redirectToPayment === 'true' && pendingPlanId && isRedirectValid) {
      // Limpar os dados de redirecionamento
      localStorage.removeItem('redirectToPayment');
      localStorage.removeItem('pendingPlanId');
      localStorage.removeItem('paymentRedirectTimestamp');
      
      console.log(`Redirecionando para pagamento do plano após criação de conta: ${pendingPlanId}`);
      
      // Redirecionar para a página de planos com o plano selecionado
      navigate(`/planos?selectedPlan=${pendingPlanId}&autoCheckout=true`);
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      // Verificar se existe um email de login salvo
      const savedEmail = localStorage.getItem('login_email');
      
      // Se o usuário já usou o app antes, usar o email salvo
      const loginEmail = savedEmail || email;
      
      // Usar a função aprimorada que suporta migração de usuários com emails não hasheados
      const user = await SupabaseGateway.handleLoginWithMigration(loginEmail, password);
      if (user) {
        // Mostrar feedback caso tenha ocorrido uma migração
        if (user.user_metadata?.migrated_from_unhashed) {
          console.log('Conta migrada com sucesso para o novo formato anônimo');
        }
        
        // Atualizar os dados do usuário, incluindo seu plano ativo
        await refreshUser();
        
        const redirectToPayment = localStorage.getItem('redirectToPayment');
        if (redirectToPayment === 'true') {
          redirectsToCheckoutIfNeeded();
        } else {
          navigate('/home');
        }
      } else {
        setError("Login não encontrado. Verifique suas credenciais.");
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateAccount = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate passwords match
    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }
    
    // Validate password strength
    if (passwordStrength < 3) {
      setError('A senha não é forte o suficiente. Ela deve ter pelo menos 8 caracteres, incluir letras maiúsculas, minúsculas, números e caracteres especiais.');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const user = await SupabaseGateway.createUser(email, password);
      if (user) {
        // Atualizar os dados do usuário, incluindo seu plano ativo
        await refreshUser();
        
        redirectsToCheckoutIfNeeded();
      } else {
        // Atualizar os dados do usuário, incluindo seu plano ativo
        await refreshUser();
        
        // Redirecionamento padrão para a página inicial
        navigate('/home');
      }
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-primary-50 to-white p-4" id="page-loaded">
      <div className="flex items-center space-x-2 mb-6">
        <div className="bg-primary-100 p-2 rounded-full">
          <Brain className="h-5 w-5 text-primary-500" />
        </div>
        <Link to="/" className="text-xl font-semibold text-neutral-800">Meu Humor</Link>
      </div>
      
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h1 className="text-2xl font-semibold mb-2 text-center">{isCreatingAccount ? 'Criar Conta Anônima' : 'Login Anônimo'}</h1>
        
        <div className="bg-blue-50 p-3 rounded-lg mb-6 flex items-start">
          <Shield className="h-5 w-5 text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
          <p className="text-sm text-blue-700">
            Sua privacidade é importante para nós. Seu email será transformado em um código criptográfico irreversível no formato anônimo. 
            Guarde suas credenciais com segurança. Durante o processo de onboarding, você terá a opção de adicionar um email secundário para recuperação de conta.
          </p>
        </div>
        
        <form onSubmit={isCreatingAccount ? handleCreateAccount : handleLogin} className="flex flex-col space-y-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-neutral-700 mb-1">Email</label>
            <input
              id="email"
              type="email"
              placeholder="seu@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-neutral-300 rounded-md focus:ring-2 focus:ring-primary-500 focus:border-primary-500 outline-none transition-all"
              required
              disabled={loading}
            />
            <p className="text-xs text-neutral-500 mt-1">
              Seu email será protegido por criptografia e jamais poderá ser recuperado do banco de dados.
            </p>
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-neutral-700 mb-1">Senha</label>
            <input
              id="password"
              type="password"
              placeholder="Sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => password === '' && setPasswordFocused(false)}
              className="w-full p-3 border border-neutral-300 rounded-md focus:ring-2 focus:ring-primary-500 focus:border-primary-500 outline-none transition-all"
              required
              disabled={loading}
            />
            
            {isCreatingAccount && (passwordFocused || password !== '') && (
              <div className="mt-2 space-y-2">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium">Força da senha: {getStrengthLabel(passwordStrength)}</span>
                  <div className="flex space-x-1 w-24">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <div 
                        key={index}
                        className={`h-1 flex-1 rounded-full ${index <= passwordStrength ? getStrengthColor(passwordStrength) : 'bg-neutral-200'}`}
                      />
                    ))}
                  </div>
                </div>
                
                <ul className="text-xs space-y-1 text-neutral-600">
                  <li className="flex items-center">
                    {isLongEnough(password) ? 
                      <Check className="h-3 w-3 text-green-500 mr-1" /> : 
                      <X className="h-3 w-3 text-red-500 mr-1" />}
                    Pelo menos 8 caracteres
                  </li>
                  <li className="flex items-center">
                    {hasLowerCase(password) ? 
                      <Check className="h-3 w-3 text-green-500 mr-1" /> : 
                      <X className="h-3 w-3 text-red-500 mr-1" />}
                    Pelo menos uma letra minúscula
                  </li>
                  <li className="flex items-center">
                    {hasUpperCase(password) ? 
                      <Check className="h-3 w-3 text-green-500 mr-1" /> : 
                      <X className="h-3 w-3 text-red-500 mr-1" />}
                    Pelo menos uma letra maiúscula
                  </li>
                  <li className="flex items-center">
                    {hasNumber(password) ? 
                      <Check className="h-3 w-3 text-green-500 mr-1" /> : 
                      <X className="h-3 w-3 text-red-500 mr-1" />}
                    Pelo menos um número
                  </li>
                  <li className="flex items-center">
                    {hasSpecialChar(password) ? 
                      <Check className="h-3 w-3 text-green-500 mr-1" /> : 
                      <X className="h-3 w-3 text-red-500 mr-1" />}
                    Pelo menos um caractere especial
                  </li>
                </ul>
              </div>
            )}
            
            {!isCreatingAccount && (
              <p className="text-xs text-red-500 mt-1">
                Lembre-se: No modo anônimo não é possível recuperar a senha exceto pelo email de recuperação.
              </p>
            )}
          </div>
          
          {isCreatingAccount && (
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-neutral-700 mb-1">Confirmar Senha</label>
              <input
                id="confirmPassword"
                type="password"
                placeholder="Confirme sua senha"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={`w-full p-3 border rounded-md focus:ring-2 focus:ring-primary-500 outline-none transition-all ${confirmPassword && (passwordsMatch ? 'border-green-500' : 'border-red-500')}`}
                required
                disabled={loading}
              />
              {confirmPassword && !passwordsMatch && (
                <p className="text-red-500 text-xs mt-1 flex items-center">
                  <X className="h-3 w-3 mr-1" /> As senhas não coincidem
                </p>
              )}
              {confirmPassword && passwordsMatch && (
                <p className="text-green-500 text-xs mt-1 flex items-center">
                  <Check className="h-3 w-3 mr-1" /> As senhas coincidem
                </p>
              )}
            </div>
          )}
          <button 
            type="submit" 
            className="w-full p-3 bg-primary-500 text-white rounded-md hover:bg-primary-600 disabled:bg-primary-300 transition-colors font-medium flex items-center justify-center"
            disabled={loading || (isCreatingAccount && !canSubmitNewAccount)}
          >
            {loading ? (
              <>
                <Loader2 className="h-5 w-5 text-white animate-spin mr-2" />
                <span>Carregando...</span>
              </>
            ) : (
              <>
                <Shield className="h-5 w-5 mr-2" />
                <span>{isCreatingAccount ? 'Criar Conta Anônima' : 'Entrar de Forma Anônima'}</span>
              </>
            )}
          </button>
        </form>
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
        
        <div className="mt-6 text-center">
          <button
            onClick={() => setIsCreatingAccount(!isCreatingAccount)}
            className="text-primary-600 hover:underline transition-colors"
            disabled={loading}
          >
            {isCreatingAccount ? 'Já tem uma conta? Faça login' : 'Não tem uma conta? Crie uma'}
          </button>
        </div>
        
        <div className="mt-6 border-t border-neutral-200 pt-4">
          <details className="text-sm text-neutral-600">
            <summary className="cursor-pointer font-medium mb-2 text-primary-600 hover:text-primary-700">
              Como funciona a proteção de privacidade?
            </summary>
            <div className="pl-4 space-y-2">
              <p>
                <strong>Criptografia irreversível:</strong> Utilizamos um algoritmo de hash SHA-256 para transformar seu email em um código único (formato anon_[hash]@hashed.com) que não pode ser revertido para obter o email original.
              </p>
              <p>
                <strong>Sem rastreamento:</strong> Nenhuma informação pessoal identificável é armazenada em nossos servidores. Mesmo nossos administradores não conseguem acessar seus dados originais.
              </p>
              <p>
                <strong>Segurança local:</strong> Seu navegador armazena localmente uma conexão entre seu email real e sua conta anônima para facilitar futuros logins.
              </p>
              <p>
                <strong>Email de recuperação opcional:</strong> Durante o processo de onboarding, você poderá fornecer um email secundário que será armazenado de forma segura. Este email poderá ser usado para recuperar o acesso à sua conta, caso necessário.
              </p>
              <div className="bg-yellow-50 p-2 rounded my-2 text-xs text-yellow-800">
                <strong>Importante:</strong> Anote suas credenciais! Caso limpe os dados do navegador e não tenha configurado um email de recuperação, você precisará usar o email e senha exatos para acessar sua conta novamente.
              </div>
            </div>
          </details>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;