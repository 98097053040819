import React, { useState, useEffect } from 'react';
import { Bell, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import MoodSelector from './MoodSelector';
import { addMoodEntry } from '../infra/DatabaseFacade';
import { v4 as uuidv4 } from 'uuid';
import { shouldHideNotification, markNotificationAsDismissed, STORAGE_KEYS } from '../lib/utils';

const NotificationMock: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedMood, setSelectedMood] = useState<number>(-1);
  const [note, setNote] = useState('');
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Verificar se já foi fechado recentemente e mostrar notificação após um tempo aleatório
  useEffect(() => {
    // Verificar se já foi fechado recentemente
    const shouldHide = shouldHideNotification(STORAGE_KEYS.NOTIFICATION_MOCK);
    
    if (!shouldHide) {
      // Se não foi fechado recentemente, mostrar após um tempo aleatório
      const timeout = setTimeout(() => {
        setIsVisible(true);
      }, Math.random() * 30000 + 30000); // Entre 30 e 60 segundos
      
      return () => clearTimeout(timeout);
    }
  }, []);
  
  const handleClose = () => {
    // Marcar como fechado no localStorage
    markNotificationAsDismissed(STORAGE_KEYS.NOTIFICATION_MOCK);
    
    // Resetar o estado
    setIsVisible(false);
    setSelectedMood(-1);
    setNote('');
    setStep(1);
  };
  
  const handleMoodSelect = (score: number) => {
    setSelectedMood(score);
    setStep(2);
  };
  
  const handleSubmit = async () => {
    setIsSubmitting(true);
    
    const newEntry = {
      id: uuidv4(),
      timestamp: Date.now(),
      score: selectedMood,
      note: note.trim(),
      ai_analysis: ''
    };
    
    try {
      // Save the entry
      addMoodEntry(newEntry);
      
      // Show success message briefly
      setStep(3);
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      console.error('Error saving mood entry:', error);
      // Save without analysis in case of error
      addMoodEntry(newEntry);
      handleClose();
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className="fixed bottom-24 left-4 right-4 bg-white rounded-xl shadow-medium p-4 z-50 max-w-md mx-auto"
        >
          {step === 1 && (
            <>
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  <div className="bg-primary-100 p-2 rounded-full mr-3">
                    <Bell size={20} className="text-primary-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-neutral-800">Como você está se sentindo agora?</h3>
                    <p className="text-sm text-neutral-500">Escolha um emoji de 0 a 5</p>
                  </div>
                </div>
                <button
                  onClick={handleClose}
                  className="text-neutral-500 hover:text-neutral-700 p-1"
                  aria-label="Dispensar"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <MoodSelector selectedMood={selectedMood} onSelect={handleMoodSelect} />
              
              <button 
                onClick={handleClose}
                className="mt-4 text-sm text-neutral-500 hover:text-neutral-700"
              >
                Lembrar mais tarde
              </button>
            </>
          )}
          
          {step === 2 && (
            <>
              <div className="flex justify-between items-start mb-4">
                <div className="flex items-center">
                  <div className="bg-primary-100 p-2 rounded-full mr-3">
                    <Bell size={20} className="text-primary-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-neutral-800">Quer adicionar algum detalhe?</h3>
                    <p className="text-sm text-neutral-500">Opcional, máximo 100 caracteres</p>
                  </div>
                </div>
                <button
                  onClick={handleClose}
                  className="text-neutral-500 hover:text-neutral-700 p-1"
                  aria-label="Dispensar"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
              
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                maxLength={255}
                placeholder="Ex: Estou ansioso com uma reunião..."
                className="input h-20 resize-none mb-4"
              />
              
              <div className="flex justify-between">
                <button 
                  onClick={() => setStep(1)}
                  className="btn btn-outline"
                >
                  Voltar
                </button>
                <button 
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting ? 'Salvando...' : 'Salvar'}
                </button>
              </div>
            </>
          )}
          
          {step === 3 && (
            <div className="text-center py-4">
              <div className="bg-accent-100 p-3 rounded-full inline-flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-accent-600">
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </div>
              <p className="font-medium text-neutral-800">Registro salvo com sucesso!</p>
              <p className="text-sm text-neutral-500">Obrigado por compartilhar como está se sentindo.</p>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default NotificationMock;