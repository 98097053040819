import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check, Shield, Brain, Loader2 } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../contexts/AuthContext';
import PaymentButton from '../components/PaymentButton';
import { STRIPE_PRICE_IDS, redirectToCheckout } from '../services/stripeService';

const PlansPage: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [isProcessingAutoCheckout, setIsProcessingAutoCheckout] = useState(false);
  
  // Verificar se há um parâmetro de plano selecionado e autoCheckout na URL
  useEffect(() => {
    if (!user) return;
    
    const searchParams = new URLSearchParams(location.search);
    const selectedPlan = searchParams.get('selectedPlan');
    const autoCheckout = searchParams.get('autoCheckout');

    console.log('selectedPlan:', selectedPlan);
    console.log('autoCheckout:', autoCheckout);
    
    if (selectedPlan && autoCheckout === 'true' && !isProcessingAutoCheckout) {
      setIsProcessingAutoCheckout(true);
      
      console.log(`Processando checkout automático para o plano: ${selectedPlan}`);
        
      let priceId;
      switch (selectedPlan) {
        case 'essencial':
          priceId = STRIPE_PRICE_IDS.essencial;
          break;
        case 'premium':
          priceId = STRIPE_PRICE_IDS.premium;
          break;
        default:
          console.error('ID de plano inválido para checkout automático:', selectedPlan);
          setIsProcessingAutoCheckout(false);
          return;
      }
      
      // Redirecionar para o checkout do Stripe via backend
      redirectToCheckout({
        priceId,
        userId: user.id
      }).then(({ error }) => {
        if (error) {
          console.error('Erro ao processar checkout automático:', error);
          alert(`Erro ao processar pagamento: ${error}`);
        }
        setIsProcessingAutoCheckout(false);
      });
    }
  }, [user, location.search, isProcessingAutoCheckout]);

  const plans = [
    {
      name: 'Gratuito',
      price: '0',
      period: 'para sempre',
      description: 'Comece sua jornada de autoconhecimento',
      features: [
        'Registro diário de humor',
        'Gráficos básicos semanais',
        'Notas simples com expansão por IA',
        'Acesso a artigos básicos',
        '2 gerações de prontuários para teste',
        'Conteúdo com anúncios'
      ],
      cta: 'Começar Agora',
      highlight: false,
      planId: 'free'
    },
    {
      name: 'Essencial',
      price: '19,90',
      period: 'por mês',
      description: 'Experiência aprimorada sem interrupções',
      features: [
        'Todos os recursos do plano Gratuito',
        'Sem anúncios',
        'Gráficos detalhados semanais e mensais',
        'Acesso a artigos premium selecionados',
        '2 gerações de prontuários para teste',
        'Recomendações básicas de conteúdo'
      ],
      cta: 'Assinar Essencial',
      highlight: true,
      planId: 'essencial'
    },
    {
      name: 'Premium',
      price: '39,90',
      period: 'por mês',
      description: 'Experiência completa de saúde mental',
      features: [
        'Todos os recursos do plano Essencial',
        'Prontuários gerados por IA ilimitados',
        'Assistente de IA para conversa e registro',
        'Relatórios exclusivos semanais e mensais',
        'Recomendação automática de conteúdos',
        'Acesso completo à biblioteca de artigos'
      ],
      cta: 'Assinar Premium',
      highlight: false,
      planId: 'premium'
    }
  ];

  return (
    <>
      <Helmet>
        <title>Planos | Meu Humor - Monitore sua saúde mental</title>
        <meta name="description" content="Conheça os planos disponíveis do Meu Humor e escolha o que melhor se adapta às suas necessidades de monitoramento de saúde mental." />
        <meta name="keywords" content="planos, assinatura, saúde mental, monitoramento de humor, premium" />
        <meta property="og:title" content="Planos | Meu Humor" />
        <meta property="og:description" content="Conheça os planos disponíveis do Meu Humor e escolha o que melhor se adapta às suas necessidades." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://meuhumor.com.br/planos" />
        <link rel="canonical" href="https://meuhumor.com.br/planos" />
      </Helmet>
      
      {/* Overlay de carregamento para checkout automático */}
      {isProcessingAutoCheckout && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center max-w-md mx-4">
            <Loader2 className="h-12 w-12 text-primary-500 animate-spin mb-4" />
            <h3 className="text-xl font-semibold mb-2">Processando pagamento</h3>
            <p className="text-neutral-600 text-center">Você será redirecionado para o checkout do Stripe em instantes...</p>
          </div>
        </div>
      )}

      <div className="min-h-screen bg-gradient-to-b from-primary-50 to-white" id="page-loaded">
        {/* Header/Navigation */}
        <header className="py-3 sm:py-4 px-2 sm:px-4 md:px-8 max-w-7xl mx-auto">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-1 sm:space-x-2">
              <div className="bg-primary-100 p-1.5 sm:p-2 rounded-full">
                <Brain className="h-4 w-4 sm:h-5 sm:w-5 text-primary-500" />
              </div>
              <h1 className="text-base sm:text-lg md:text-xl font-semibold text-neutral-800">Meu Humor</h1>
            </Link>
            <div className="flex space-x-1.5 sm:space-x-2 md:space-x-4 items-center">
              {user ? (
                <Link to="/home" className="btn btn-primary px-3 py-1.5 sm:py-2 text-xs sm:text-sm">
                  Acessar
                </Link>
              ) : (
                <>
                  <Link to="/login" className="btn btn-outline px-5 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap">
                    Entrar
                  </Link>
                  <Link 
                    to="/login?createAccount=true" 
                    className="btn btn-primary px-8 py-1.5 sm:py-2 text-xs sm:text-sm whitespace-nowrap" 
                    onClick={() => localStorage.setItem('createAccount', 'true')}
                  >
                    Criar
                  </Link>
                </>
              )}
            </div>
          </div>
        </header>

        {/* Hero Section */}
        <section className="py-12 px-4 md:px-8 max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <motion.h2 
              className="text-3xl md:text-4xl font-bold text-neutral-800 mb-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Escolha o plano ideal para <span className="text-primary-500">sua jornada de bem-estar</span>
            </motion.h2>
            <motion.p 
              className="text-lg text-neutral-600 max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              Oferecemos opções flexíveis para atender às suas necessidades de monitoramento e cuidado com a saúde mental.
            </motion.p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
            {plans.map((plan, index) => (
              <motion.div 
                key={plan.planId}
                className={`bg-white rounded-xl shadow-medium overflow-hidden ${plan.highlight ? 'border-2 border-primary-500 relative' : 'border border-neutral-200'}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 * index }}
              >
                {plan.highlight && (
                  <div className="absolute top-0 right-0 bg-primary-500 text-white text-xs font-semibold px-3 py-1 rounded-bl-lg">
                    Mais popular
                  </div>
                )}
                <div className={`p-6 ${plan.highlight ? 'bg-primary-50' : 'bg-neutral-50'}`}>
                  <h3 className="text-xl font-bold mb-2">{plan.name}</h3>
                  <div className="flex items-end mb-4">
                    <span className="text-3xl font-bold">{plan.price}</span>
                    <span className="text-neutral-600 ml-1">{plan.period}</span>
                  </div>
                  {plan.planId === 'free' ? (
                    <Link 
                      to={user ? "/home" : "/login?createAccount=true"}
                      className="btn btn-outline w-full"
                      onClick={() => !user && localStorage.setItem('createAccount', 'true')}
                    >
                      {plan.cta}
                    </Link>
                  ) : (
                    <PaymentButton 
                      planId={plan.planId}
                      className="w-full"
                      variant={plan.highlight ? 'primary' : 'outline'}
                    >
                      {plan.cta}
                    </PaymentButton>
                  )}
                </div>
                <div className="p-6">
                  <ul className="space-y-3">
                    {plan.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start">
                        <div className={`mt-0.5 mr-2 ${plan.highlight ? 'text-primary-500' : 'text-secondary-500'}`}>
                          <Check size={16} />
                        </div>
                        <span className="text-neutral-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            ))}
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-16 px-4 md:px-8 max-w-5xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">Perguntas Frequentes</h2>
            <p className="text-neutral-600">Tire suas dúvidas sobre nossos planos e serviços</p>
          </div>

          <div className="space-y-6">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-2">Como funciona o período de teste?</h3>
              <p className="text-neutral-600">
                Oferecemos 7 dias de teste gratuito para os planos Essencial e Premium. Você pode cancelar a qualquer momento durante este período sem ser cobrado através do portal de gerenciamento de assinatura.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-2">Posso mudar de plano depois?</h3>
              <p className="text-neutral-600">
                Sim, você pode fazer upgrade ou downgrade do seu plano a qualquer momento através do portal de gerenciamento de assinatura. As alterações entram em vigor no próximo ciclo de cobrança.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-2">Como funciona o acesso a conteúdos premium?</h3>
              <p className="text-neutral-600">
                Ao assinar um plano Essencial ou Premium, você terá acesso imediato a todos os conteúdos exclusivos da plataforma, incluindo artigos especializados e recursos avançados.
              </p>
            </div>
            
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-2">Como são processados os pagamentos?</h3>
              <p className="text-neutral-600">
                Todos os pagamentos são processados de forma segura através do Stripe. Aceitamos os principais cartões de crédito e débito. Os dados do seu cartão são criptografados e nunca são armazenados em nossos servidores.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h3 className="text-lg font-semibold mb-2">Quais são as formas de pagamento aceitas?</h3>
              <p className="text-neutral-600">
                Aceitamos cartões de crédito, débito e PIX para pagamentos. Todas as transações são processadas com segurança através de gateways de pagamento confiáveis.
              </p>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-16 px-4 md:px-8 bg-primary-50">
          <div className="max-w-4xl mx-auto text-center">
            <div className="bg-primary-100 p-3 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-6">
              <Shield className="h-8 w-8 text-primary-500" />
            </div>
            <h2 className="text-2xl md:text-3xl font-bold text-neutral-800 mb-4">
              Comece sua jornada de bem-estar hoje
            </h2>
            <p className="text-lg text-neutral-600 mb-8 max-w-2xl mx-auto">
              Invista em sua saúde mental com o Meu Humor. Oferecemos ferramentas eficazes para monitoramento e melhoria do seu bem-estar emocional.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link 
                to={user ? "/home" : "/login?createAccount=true"} 
                className="btn btn-primary px-8 py-3"
                onClick={() => !user && localStorage.setItem('createAccount', 'true')}
              >
                {user ? "Acessar Dashboard" : "Criar Conta Gratuita"}
              </Link>
              <Link to="/sobre" className="btn btn-outline px-8 py-3">
                Saiba mais sobre nós
              </Link>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-white py-12 px-4 md:px-8 border-t border-neutral-200">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="flex items-center space-x-2 mb-4 md:mb-0">
                <div className="bg-primary-100 p-2 rounded-full">
                  <Brain className="h-5 w-5 text-primary-500" />
                </div>
                <span className="text-lg font-semibold text-neutral-800">Meu Humor</span>
              </div>
              <div className="flex space-x-6">
                <Link to="/sobre" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Sobre
                </Link>
                <Link to="/contato" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Contato
                </Link>
                <a href="#" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Termos
                </a>
                <a href="#" className="text-neutral-600 hover:text-primary-500 transition-colors">
                  Privacidade
                </a>
              </div>
            </div>
            <div className="mt-8 text-center text-neutral-500 text-sm">
              &copy; {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default PlansPage;
