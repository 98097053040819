import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Shield, Lock, Cookie, FileText, AlertCircle, CheckCircle } from 'lucide-react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white" id="page-loaded">
      <Helmet>
        <title>Política de Privacidade | Meu Humor</title>
        <meta 
          name="description" 
          content="Conheça nossa política de privacidade e entenda como protegemos seus dados no aplicativo Meu Humor."
        />
        <meta 
          name="keywords" 
          content="política de privacidade, LGPD, proteção de dados, saúde mental, privacidade, segurança"
        />
      </Helmet>

      {/* Header */}
      <header className="bg-white shadow-sm border-b border-neutral-200">
        <div className="max-w-5xl mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2">
            <div className="bg-primary-100 p-2 rounded-full">
              <Shield className="h-5 w-5 text-primary-500" />
            </div>
            <span className="font-semibold text-neutral-800">Meu Humor</span>
          </Link>
          <Link to="/" className="btn btn-outline-sm">Voltar ao Início</Link>
        </div>
      </header>

      {/* Content */}
      <main className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-10 text-center">
          <h1 className="text-3xl font-bold text-neutral-800 mb-2">Política de Privacidade</h1>
          <p className="text-neutral-600">Atualizada em 17 de Março de 2025</p>
        </div>

        <div className="prose prose-neutral max-w-none">
          <div className="bg-primary-50 p-6 rounded-xl mb-8 border-l-4 border-primary-500">
            <h2 className="flex items-center text-primary-700 mb-4">
              <Shield className="h-5 w-5 mr-2" />
              Compromisso com sua Privacidade
            </h2>
            <p className="text-neutral-700">
              A sua privacidade é extremamente importante para nós. É política do Meu Humor respeitar sua privacidade 
              em relação a qualquer informação que possamos coletar. Nosso aplicativo foi construído com o princípio 
              de <strong className="text-primary-700">Privacidade por Design</strong>, priorizando a 
              anonimidade e a proteção dos seus dados pessoais.
            </p>
          </div>

          <h2 className="flex items-center">
            <Lock className="h-5 w-5 mr-2 text-neutral-700" />
            Coleta e Uso de Dados
          </h2>
          <p>
            Solicitamos informações pessoais apenas quando realmente precisamos delas para lhe fornecer um serviço. 
            Fazemo-lo por meios justos e legais, com o seu conhecimento e consentimento. Também informamos por que 
            estamos coletando e como será usado.
          </p>
          <p>
            <strong>Dados coletados:</strong>
          </p>
          <ul>
            <li>
              <strong>Email:</strong> Transformado em um código criptográfico irreversível através de 
              função hash SHA-256, garantindo que sua identidade real não seja armazenada em nossos servidores.
            </li>
            <li>
              <strong>Email de recuperação (opcional):</strong> Caso você opte por configurar, é armazenado 
              de forma segura para possibilitar recuperação de conta.
            </li>
            <li>
              <strong>Registros de humor:</strong> Seus registros de humor, fatores associados e anotações são 
              armazenados de forma anônima e vinculados apenas ao seu identificador criptográfico.
            </li>
            <li>
              <strong>Dados de uso:</strong> Coletamos informações anônimas sobre como você utiliza o aplicativo 
              para melhorar nossa interface e funcionalidades.
            </li>
          </ul>

          <h2 className="flex items-center mt-8">
            <FileText className="h-5 w-5 mr-2 text-neutral-700" />
            Armazenamento e Proteção
          </h2>
          <p>
            Apenas retemos as informações coletadas pelo tempo necessário para fornecer o serviço solicitado. 
            Quando armazenamos dados, protegemos dentro de meios comercialmente aceitáveis para evitar perdas e 
            roubos, bem como acesso, divulgação, cópia, uso ou modificação não autorizados.
          </p>
          <p>
            <strong>Medidas de segurança implementadas:</strong>
          </p>
          <ul>
            <li>Criptografia de dados em trânsito e em repouso</li>
            <li>Anonimização de dados pessoais através de hashing</li>
            <li>Proteção contra injeções SQL e outros ataques</li>
            <li>Backup regular de dados com acesso restrito</li>
            <li>Monitoramento contínuo de tentativas de acesso não autorizado</li>
          </ul>

          <h2 className="flex items-center mt-8">
            <Cookie className="h-5 w-5 mr-2 text-neutral-700" />
            Política de Cookies
          </h2>
          <p>
            Como é prática comum, este site utiliza cookies para armazenar informações e personalizar sua 
            experiência. Cookies são pequenos arquivos armazenados em seu dispositivo.
          </p>
          <p><strong>Tipos de cookies utilizados:</strong></p>
          <ul>
            <li>
              <strong>Cookies de sessão:</strong> Expiram quando você fecha o navegador e permitem que o site 
              reconheça quando você navega entre diferentes páginas.
            </li>
            <li>
              <strong>Cookies persistentes:</strong> São armazenados por um período maior e ajudam a 
              lembrar suas preferências e configurações quando você retorna ao site.
            </li>
            <li>
              <strong>Cookies essenciais:</strong> Necessários para o funcionamento básico do aplicativo.
            </li>
            <li>
              <strong>Cookies de análise:</strong> Ajudam-nos a entender como você utiliza o aplicativo para 
              que possamos melhorá-lo.
            </li>
          </ul>
          <p>
            Você pode recusar o uso de cookies selecionando as configurações apropriadas no seu navegador. 
            No entanto, isso pode impedir que você use todas as funcionalidades do site.
          </p>

          <h2 className="flex items-center mt-8">
            <CheckCircle className="h-5 w-5 mr-2 text-neutral-700" />
            Seus Direitos
          </h2>
          <p>
            Em conformidade com a Lei Geral de Proteção de Dados (LGPD), você tem os seguintes direitos:
          </p>
          <ul>
            <li>Direito de acesso aos seus dados pessoais</li>
            <li>Direito de retificação de dados incompletos, inexatos ou desatualizados</li>
            <li>Direito de eliminação dos dados tratados com o seu consentimento</li>
            <li>Direito de ser informado sobre o compartilhamento de seus dados</li>
            <li>Direito de revogar o consentimento</li>
          </ul>
          <p>
            Para exercer qualquer um desses direitos, entre em contato conosco através do email 
            <a href="mailto:privacidade@meuhumor.app" className="text-primary-600 mx-1">privacidade@meuhumor.app</a>.
          </p>

          <h2 className="flex items-center mt-8">
            <AlertCircle className="h-5 w-5 mr-2 text-neutral-700" />
            Compartilhamento de Dados
          </h2>
          <p>
            Não compartilhamos informações de identificação pessoal publicamente ou com terceiros, exceto quando 
            exigido por lei ou com seu consentimento explícito.
          </p>
          <p>
            Em casos específicos onde você optar por compartilhar relatórios com profissionais de saúde, isso 
            será feito através de exportações de dados que manterão seu anonimato preservado, a menos que você 
            escolha explicitamente o contrário.
          </p>

          <div className="bg-neutral-50 p-6 rounded-xl mt-8 border-l-4 border-neutral-300">
            <h2 className="text-neutral-800 mb-4">Compartilhamento Anônimo de Dados Agregados</h2>
            <p className="text-neutral-700">
              Como parte da nossa missão de contribuir para pesquisas em saúde mental, oferecemos a opção de 
              compartilhar dados anônimos e agregados para fins científicos e estatísticos. Importante ressaltar:
            </p>
            <ul className="mt-2 space-y-2">
              <li className="flex items-start">
                <div className="bg-green-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <CheckCircle className="h-3 w-3 text-green-600" />
                </div>
                <span>Esta opção é totalmente voluntária e desativada por padrão</span>
              </li>
              <li className="flex items-start">
                <div className="bg-green-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <CheckCircle className="h-3 w-3 text-green-600" />
                </div>
                <span>Apenas dados estatísticos são compartilhados (nunca seus registros individuais)</span>
              </li>
              <li className="flex items-start">
                <div className="bg-green-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <CheckCircle className="h-3 w-3 text-green-600" />
                </div>
                <span>Você pode ativar ou desativar esta opção a qualquer momento</span>
              </li>
            </ul>
          </div>

          <h2 className="mt-8">Links para Sites de Terceiros</h2>
          <p>
            Nosso aplicativo pode conter links para sites externos que não são operados por nós. 
            Esteja ciente de que não temos controle sobre o conteúdo e práticas desses sites e não podemos 
            aceitar responsabilidade por suas respectivas políticas de privacidade.
          </p>

          <h2 className="mt-8">Alterações na Política de Privacidade</h2>
          <p>
            Podemos atualizar nossa Política de Privacidade de tempos em tempos. Assim, recomendamos que você 
            revise esta página periodicamente para quaisquer alterações. Notificaremos você sobre mudanças 
            significativas publicando a nova Política de Privacidade nesta página e através de um aviso no aplicativo.
          </p>

          <h2 className="mt-8">Contato</h2>
          <p>
            Se você tiver alguma dúvida ou sugestão sobre nossa Política de Privacidade, não hesite em entrar 
            em contato conosco através do email <a href="mailto:privacidade@meuhumor.app" className="text-primary-600">privacidade@meuhumor.app</a>.
          </p>

          <div className="border-t border-neutral-200 pt-6 mt-8">
            <p className="text-neutral-500 text-sm">
              Este documento foi atualizado pela última vez em 17 de Março de 2025.
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-neutral-50 border-t border-neutral-200 mt-8 py-6">
        <div className="max-w-4xl mx-auto px-4 text-center text-neutral-600 text-sm">
          <p>© {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <Link to="/" className="hover:text-primary-600">Início</Link>
            <Link to="/login" className="hover:text-primary-600">Entrar</Link>
            <Link to="/sobre" className="hover:text-primary-600">Sobre</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicyPage;