import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ClipboardList, X, ChevronRight } from 'lucide-react';
import { getPendingOnBoardingSteps } from '../infra/OnBoardingFacade';

interface OnBoardingReminderProps {
  onComplete: () => void;
}

// Mapeamento de IDs de passos para nomes amigáveis
const stepNames: Record<string, string> = {
  'demographics': 'Informações demográficas',
  'motivation': 'Motivação para uso',
  'depression-screening': 'Triagem de depressão',
  'anxiety-screening': 'Triagem de ansiedade',
  'mental-health-history': 'Histórico de saúde mental',
  'current-treatment': 'Tratamento atual',
  'sleep-quality': 'Qualidade do sono',
  'social-support': 'Suporte social'
};

const OnBoardingReminder: React.FC<OnBoardingReminderProps> = ({ onComplete }) => {
  const [pendingSteps, setPendingSteps] = useState<string[]>([]);
  const [dismissed, setDismissed] = useState(false);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const checkPendingSteps = async () => {
      try {
        setLoading(true);
        const steps = await getPendingOnBoardingSteps();
        // Filtrar apenas os passos opcionais (excluir personal-info, current-mood, additional-info e completion)
        const optionalPendingSteps = steps.filter(
          step => step !== 'personal-info' && 
                 step !== 'current-mood' && 
                 step !== 'completion' &&
                 step !== 'additional-info'
        );
        setPendingSteps(optionalPendingSteps);
      } catch (error) {
        console.error('Erro ao verificar passos pendentes do OnBoarding:', error);
      } finally {
        setLoading(false);
      }
    };
    
    checkPendingSteps();
  }, []);
  
  const handleDismiss = () => {
    setDismissed(true);
  };
  
  // Não mostrar nada se estiver carregando, se não houver passos pendentes ou se foi dispensado
  if (loading || pendingSteps.length === 0 || dismissed) {
    return null;
  }
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-primary-50 border border-primary-200 rounded-lg p-4 mb-6"
    >
      <div className="flex justify-between items-start">
        <div className="flex items-start">
          <div className="bg-primary-100 p-2 rounded-full mr-3">
            <ClipboardList className="h-5 w-5 text-primary-600" />
          </div>
          <div>
            <h3 className="font-medium text-primary-800 mb-1">Complete seu perfil</h3>
            <p className="text-sm text-primary-700 mb-2">
              Você tem {pendingSteps.length} {pendingSteps.length === 1 ? 'item' : 'itens'} pendentes no seu perfil. 
              Completar essas informações nos ajudará a personalizar melhor sua experiência.
            </p>
            
            {pendingSteps.length <= 3 && (
              <ul className="text-xs text-primary-700 mb-2 space-y-1">
                {pendingSteps.map(step => (
                  <li key={step} className="flex items-center">
                    <span className="w-2 h-2 bg-primary-400 rounded-full mr-2"></span>
                    {stepNames[step] || step}
                  </li>
                ))}
              </ul>
            )}
            
            <button
              onClick={onComplete}
              className="mt-2 text-sm font-medium text-primary-700 hover:text-primary-800 flex items-center"
            >
              Completar agora
              <ChevronRight className="h-4 w-4 ml-1" />
            </button>
          </div>
        </div>
        
        <button
          onClick={handleDismiss}
          className="text-primary-500 hover:text-primary-700 p-1"
          aria-label="Dispensar"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    </motion.div>
  );
};

export default OnBoardingReminder; 