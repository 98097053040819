import React, { useState } from 'react';
import { Button } from '../../ui/button';
import { Loader2 } from 'lucide-react';
import { StepProps } from './StepProps';

// Questionário GAD-7 simplificado para triagem de ansiedade
const anxietyQuestions = [
  {
    id: 'gad1',
    text: 'Sentindo-se nervoso, ansioso ou muito tenso'
  },
  {
    id: 'gad2',
    text: 'Não sendo capaz de parar ou controlar as preocupações'
  },
  {
    id: 'gad3',
    text: 'Preocupando-se muito com coisas diferentes'
  },
  {
    id: 'gad4',
    text: 'Dificuldade para relaxar'
  },
  {
    id: 'gad5',
    text: 'Ficando tão agitado que é difícil ficar parado'
  },
  {
    id: 'gad6',
    text: 'Ficando facilmente aborrecido ou irritado'
  },
  {
    id: 'gad7',
    text: 'Sentindo medo como se algo terrível pudesse acontecer'
  }
];

// Opções de resposta para cada pergunta
const responseOptions = [
  { value: 0, label: 'Nenhuma vez' },
  { value: 1, label: 'Vários dias' },
  { value: 2, label: 'Mais da metade dos dias' },
  { value: 3, label: 'Quase todos os dias' }
];

const AnxietyScreeningStep: React.FC<StepProps> = ({
  userData,
  onNext,
  onSkip,
  onBack,
  isSubmitting,
  showBackButton = true
}) => {
  // Inicializar respostas com valores existentes ou vazios
  const [responses, setResponses] = useState<Record<string, number>>(
    userData.anxietyScreening || {}
  );
  
  const handleResponseChange = (questionId: string, value: number) => {
    setResponses(prev => ({
      ...prev,
      [questionId]: value
    }));
  };
  
  const handleSubmit = () => {
    // Calcular pontuação total
    const totalScore = Object.values(responses).reduce((sum, score) => sum + score, 0);
    
    onNext({
      anxietyScreening: responses,
      anxietyScore: totalScore
    });
  };
  
  // Verificar se todas as perguntas foram respondidas
  const isComplete = anxietyQuestions.every(q => responses[q.id] !== undefined);
  
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-xl font-medium text-neutral-800 mb-2">Triagem de Ansiedade</h3>
        <p className="text-neutral-600 mb-4">
          Nas últimas 2 semanas, com que frequência você foi incomodado por algum dos problemas abaixo?
        </p>
      </div>
      
      <div className="space-y-6">
        {anxietyQuestions.map((question) => (
          <div key={question.id} className="space-y-3">
            <p className="font-medium text-neutral-700">{question.text}</p>
            <div className="grid grid-cols-2 gap-2 md:flex md:flex-wrap">
              {responseOptions.map((option) => (
                <div key={option.value} className="flex items-center">
                  <input
                    type="radio"
                    id={`${question.id}-${option.value}`}
                    name={question.id}
                    value={option.value}
                    checked={responses[question.id] === option.value}
                    onChange={() => handleResponseChange(question.id, option.value)}
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-neutral-300"
                  />
                  <label
                    htmlFor={`${question.id}-${option.value}`}
                    className="ml-2 text-sm text-neutral-700"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      
      <div className="pt-4 flex justify-between">
        {showBackButton && (
          <Button
            type="button"
            variant="outline"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Voltar
          </Button>
        )}
        
        <div className="space-x-2">
          <Button
            type="button"
            variant="ghost"
            onClick={onSkip}
            disabled={isSubmitting}
          >
            Pular
          </Button>
          
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={!isComplete || isSubmitting}
          >
            {isSubmitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Processando
              </>
            ) : (
              'Continuar'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AnxietyScreeningStep; 