import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { FileText, AlertCircle, Shield, Book, CheckSquare, X, User, PenTool } from 'lucide-react';

const TermsOfUsePage: React.FC = () => {
  return (
    <div className="min-h-screen bg-white" id="page-loaded">
      <Helmet>
        <title>Termos de Uso | Meu Humor</title>
        <meta 
          name="description" 
          content="Conheça os termos de uso do aplicativo Meu Humor e entenda as regras para utilização da plataforma."
        />
        <meta 
          name="keywords" 
          content="termos de uso, condições, regras, saúde mental, privacidade, segurança, anonimato"
        />
      </Helmet>

      {/* Header */}
      <header className="bg-white shadow-sm border-b border-neutral-200">
        <div className="max-w-5xl mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2">
            <div className="bg-primary-100 p-2 rounded-full">
              <Book className="h-5 w-5 text-primary-500" />
            </div>
            <span className="font-semibold text-neutral-800">Meu Humor</span>
          </Link>
          <Link to="/" className="btn btn-outline-sm">Voltar ao Início</Link>
        </div>
      </header>

      {/* Content */}
      <main className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-10 text-center">
          <h1 className="text-3xl font-bold text-neutral-800 mb-2">Termos de Uso</h1>
          <p className="text-neutral-600">Atualizado em 17 de Março de 2025</p>
        </div>

        <div className="prose prose-neutral max-w-none">
          <div className="bg-primary-50 p-6 rounded-xl mb-8 border-l-4 border-primary-500">
            <h2 className="flex items-center text-primary-700 mb-4">
              <Book className="h-5 w-5 mr-2" />
              Introdução aos Termos de Uso
            </h2>
            <p className="text-neutral-700">
              Ao acessar ou utilizar o aplicativo Meu Humor, você concorda com os termos e condições descritos neste documento. 
              Por favor, leia atentamente, pois estes termos constituem um acordo legal entre você e o Meu Humor 
              quanto ao uso da plataforma.
            </p>
          </div>

          <h2 className="flex items-center">
            <User className="h-5 w-5 mr-2 text-neutral-700" />
            Registro e Conta
          </h2>
          <p>
            Ao se registrar no Meu Humor, você concorda em fornecer informações precisas e completas. Embora 
            oferecemos um sistema de registro anônimo, você é responsável por manter a segurança de suas credenciais 
            de acesso e por todas as atividades realizadas em sua conta.
          </p>
          <p>
            <strong>Responsabilidades do usuário:</strong>
          </p>
          <ul>
            <li>Manter suas credenciais de acesso em segurança</li>
            <li>Não compartilhar sua conta com terceiros</li>
            <li>Escolher uma senha segura e alterá-la periodicamente</li>
            <li>Ser totalmente responsável por todo o conteúdo adicionado à sua conta</li>
          </ul>

          <h2 className="flex items-center mt-8">
            <FileText className="h-5 w-5 mr-2 text-neutral-700" />
            Uso de Dados para Melhoria de Serviços
          </h2>
          <p>
            Ao utilizar o Meu Humor, você concorda que podemos coletar e utilizar seus dados de forma anônima com o 
            objetivo de aprimorar nossos serviços. Mesmo sem saber a identidade real do usuário, podemos utilizar os 
            padrões e estatísticas para desenvolver melhores algoritmos e funcionalidades.
          </p>
          <p>
            <strong>Finalidades do uso de dados:</strong>
          </p>
          <ul>
            <li>Aperfeiçoamento da interface e experiência do usuário</li>
            <li>Criação de recomendações personalizadas através de nossa IA</li>
            <li>Desenvolvimento de novos recursos baseados no uso real</li>
            <li>Geração de insights e estatísticas para pesquisa em saúde mental</li>
            <li>Melhoria contínua dos algoritmos de sugestões e conteúdos</li>
          </ul>

          <div className="bg-neutral-50 p-6 rounded-xl mt-8 border-l-4 border-neutral-300">
            <h3 className="flex items-center text-neutral-800 mb-4">
              <Shield className="h-5 w-5 mr-2" />
              Anonimato não significa impunidade
            </h3>
            <p className="text-neutral-700">
              Embora o Meu Humor garanta o anonimato de seus usuários, isso não significa impunidade para atos que 
              violem a legislação brasileira ou as regras da plataforma. Nos reservamos o direito de:
            </p>
            <ul className="mt-4 space-y-2">
              <li className="flex items-start">
                <div className="bg-red-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <X className="h-3 w-3 text-red-600" />
                </div>
                <span>Monitorar conteúdos que possam indicar atividades ilegais ou prejudiciais</span>
              </li>
              <li className="flex items-start">
                <div className="bg-red-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <X className="h-3 w-3 text-red-600" />
                </div>
                <span>Notificar as autoridades competentes em casos exigidos por lei</span>
              </li>
              <li className="flex items-start">
                <div className="bg-red-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <X className="h-3 w-3 text-red-600" />
                </div>
                <span>Suspender ou encerrar contas que violem nossos termos de uso</span>
              </li>
              <li className="flex items-start">
                <div className="bg-red-100 p-1 rounded-full mr-2 mt-1 flex-shrink-0">
                  <X className="h-3 w-3 text-red-600" />
                </div>
                <span>Excluir conteúdos que promovam atividades ilegais ou prejudiciais</span>
              </li>
            </ul>
            <p className="mt-4 text-neutral-700">
              Caso seja identificado o uso da plataforma para fins ilícitos, sua conta poderá ser suspensa ou 
              excluída permanentemente, com todos os registros associados deletados.
            </p>
          </div>

          <h2 className="flex items-center mt-8">
            <AlertCircle className="h-5 w-5 mr-2 text-neutral-700" />
            Comportamento Esperado
          </h2>
          <p>
            Os usuários do Meu Humor devem utilizar a plataforma de forma responsável e ética. Não permitimos o uso 
            da plataforma para:
          </p>
          <ul>
            <li>Planejamento ou incentivo a atividades ilegais</li>
            <li>Promover automutilação ou suicídio</li>
            <li>Disseminar ódio, preconceito ou discriminação</li>
            <li>Compartilhar informações falsas sobre saúde mental</li>
            <li>Assediar outros usuários ou a equipe de suporte</li>
          </ul>
          <p>
            Estamos comprometidos em manter um ambiente seguro e saudável para todos os usuários. Conteúdos que 
            violem essas diretrizes serão removidos e contas responsáveis poderão ser banidas.
          </p>

          <h2 className="flex items-center mt-8">
            <CheckSquare className="h-5 w-5 mr-2 text-neutral-700" />
            Propriedade Intelectual
          </h2>
          <p>
            Todo o conteúdo disponibilizado pelo Meu Humor, incluindo mas não se limitando a textos, gráficos, 
            logotipos, ícones, imagens, clipes de áudio, downloads digitais e compilações de dados, é propriedade 
            do Meu Humor ou de seus fornecedores de conteúdo e está protegido pelas leis brasileiras e internacionais 
            de direitos autorais.
          </p>
          <p>
            O conteúdo criado pelo usuário permanece de propriedade do usuário, mas ao adicioná-lo à plataforma, 
            você concede ao Meu Humor uma licença mundial, não exclusiva, isenta de royalties para uso, reprodução, 
            adaptação, publicação e distribuição desse conteúdo em conexão com o serviço e a promoção do Meu Humor.
          </p>

          <h2 className="flex items-center mt-8">
            <PenTool className="h-5 w-5 mr-2 text-neutral-700" />
            Modificações nos Termos
          </h2>
          <p>
            O Meu Humor se reserva o direito de modificar estes termos a qualquer momento, publicando os termos 
            modificados no site. É sua responsabilidade revisar periodicamente os termos. O uso contínuo do 
            serviço após as alterações constitui aceitação dos novos termos.
          </p>
          <p>
            Notificaremos os usuários sobre mudanças significativas nos termos através de um aviso no aplicativo 
            ou por email, caso tenha optado por fornecer um email de contato.
          </p>

          <h2 className="mt-8">Limitação de Responsabilidade</h2>
          <p>
            O Meu Humor não é um substituto para orientação médica ou psicológica profissional. Não somos 
            responsáveis pelas decisões tomadas pelos usuários com base nas informações ou funcionalidades 
            oferecidas pelo aplicativo.
          </p>
          <p>
            Em nenhuma circunstância o Meu Humor, seus diretores, funcionários ou agentes serão responsáveis 
            por quaisquer danos diretos, indiretos, incidentais, especiais, punitivos ou consequentes decorrentes 
            do uso ou incapacidade de usar o serviço.
          </p>

          <h2 className="mt-8">Legislação Aplicável</h2>
          <p>
            Estes termos são regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa surgida 
            em conexão com estes termos estará sujeita à jurisdição exclusiva dos tribunais do Brasil.
          </p>

          <h2 className="mt-8">Contato</h2>
          <p>
            Se você tiver dúvidas sobre nossos Termos de Uso, por favor entre em contato conosco através do 
            email <a href="mailto:termos@meuhumor.app" className="text-primary-600">termos@meuhumor.app</a>.
          </p>

          <div className="border-t border-neutral-200 pt-6 mt-8">
            <p className="text-neutral-500 text-sm">
              Estes termos foram atualizados pela última vez em 17 de Março de 2025.
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-neutral-50 border-t border-neutral-200 mt-8 py-6">
        <div className="max-w-4xl mx-auto px-4 text-center text-neutral-600 text-sm">
          <p>© {new Date().getFullYear()} Meu Humor. Todos os direitos reservados.</p>
          <div className="flex justify-center space-x-4 mt-4">
            <Link to="/" className="hover:text-primary-600">Início</Link>
            <Link to="/login" className="hover:text-primary-600">Entrar</Link>
            <Link to="/politica-de-privacidade" className="hover:text-primary-600">Política de Privacidade</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TermsOfUsePage;