/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useMemo, Suspense, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { mentalHealthCategories } from '../data/mental-health-content';
import { Category, SubCategory, Article } from '../types';
import ContentCard from '../components/ContentCard';
import { DiseaseCodeDetails, DiseaseCodeWrapper } from '../components/DiseaseCodeDetails';
import { ChevronLeft, Search, BookOpen, Tag, Clock, Lock, Brain, Frown, Users, Utensils, Target, CheckCircle, AlertTriangle, Loader2 } from 'lucide-react';
import { useDebounce } from '../hooks/useDebounce';
import { useAuth, UserPlan } from '../contexts/AuthContext';
import ContentAdsense from '../components/ContentAdsense';

interface CategoryState {
  category: Category | null;
  subCategory: SubCategory | null;
  article: Article | null;
}

interface SearchState {
  query: string;
  isSearching: boolean;
  results: Array<{
    type: 'category' | 'subCategory' | 'article';
    item: Category | SubCategory | Article;
    breadcrumb: string;
  }>;
}

const ContentPage: React.FC = () => {
  const { categoryId, subCategoryId, articleId } = useParams<{ categoryId?: string, subCategoryId?: string, articleId?: string }>();
  const navigate = useNavigate();
  const { user, currentPlan, hasAccessToContent } = useAuth();
  
  const [state, setState] = useState<CategoryState>({
    category: null,
    subCategory: null,
    article: null
  });

  const [search, setSearch] = useState<SearchState>({
    query: '',
    isSearching: false,
    results: []
  });

  const debouncedSearch = useDebounce(search.query, 300);

  // Efeito para sincronizar os parâmetros da URL com o estado
  useEffect(() => {
    if (!categoryId) {
      setState({
        category: null,
        subCategory: null,
        article: null
      });
      return;
    }

    const category = mentalHealthCategories.find(c => c.id === categoryId);
    if (!category) {
      navigate('/conteudos');
      return;
    }

    if (!subCategoryId) {
      setState({
        category,
        subCategory: null,
        article: null
      });
      return;
    }

    const subCategory = category.subCategories.find(sc => sc.id === subCategoryId);
    if (!subCategory) {
      navigate(`/conteudos/${categoryId}`);
      return;
    }

    if (!articleId) {
      setState({
        category,
        subCategory,
        article: null
      });
      return;
    }

    const article = subCategory.articles.find(a => a.id === articleId);
    if (!article) {
      navigate(`/conteudos/${categoryId}/${subCategoryId}`);
      return;
    }

    setState({
      category,
      subCategory,
      article
    });
  }, [categoryId, subCategoryId, articleId, navigate]);

  // Memoize search results
  const searchResults = useMemo(() => {
    if (!debouncedSearch) return [];
    
    const results: SearchState['results'] = [];
    const searchLower = debouncedSearch.toLowerCase();

    mentalHealthCategories.forEach(category => {
      if (category.title.toLowerCase().includes(searchLower) ||
          category.description.toLowerCase().includes(searchLower)) {
        results.push({
          type: 'category',
          item: category,
          breadcrumb: category.title
        });
      }

      category.subCategories.forEach(subCategory => {
        if (subCategory.title.toLowerCase().includes(searchLower) ||
            subCategory.description.toLowerCase().includes(searchLower)) {
          results.push({
            type: 'subCategory',
            item: subCategory,
            breadcrumb: `${category.title} > ${subCategory.title}`
          });
        }

        subCategory.articles.forEach(article => {
          if (article.title.toLowerCase().includes(searchLower) ||
              article.content.toLowerCase().includes(searchLower) ||
              article.tags.some(tag => tag.toLowerCase().includes(searchLower))) {
            results.push({
              type: 'article',
              item: article,
              breadcrumb: `${category.title} > ${subCategory.title} > ${article.title}`
            });
          }
        });
      });
    });

    return results;
  }, [debouncedSearch]);

  const handleCategoryClick = useCallback((category: Category) => {
    navigate(`/conteudos/${category.id}`);
    setSearch(prev => ({ ...prev, isSearching: false, query: '' }));
  }, [navigate]);

  const handleSubCategoryClick = useCallback((subCategory: SubCategory) => {
    if (state.category) {
      navigate(`/conteudos/${state.category.id}/${subCategory.id}`);
    }
  }, [navigate, state.category]);

  const handleArticleClick = useCallback((article: Article) => {
    if (state.category && state.subCategory) {
      navigate(`/conteudos/${state.category.id}/${state.subCategory.id}/${article.id}`);
    }
  }, [navigate, state.category, state.subCategory]);

  const handleBack = useCallback(() => {
    if (state.article && state.category && state.subCategory) {
      navigate(`/conteudos/${state.category.id}/${state.subCategory.id}`);
    } else if (state.subCategory && state.category) {
      navigate(`/conteudos/${state.category.id}`);
    } else if (state.category) {
      navigate('/conteudos');
    }
  }, [navigate, state]);

  const handleSearch = useCallback((query: string) => {
    setSearch(prev => ({
      ...prev,
      query,
      isSearching: query.trim().length > 0
    }));
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearch({
      query: '',
      isSearching: false,
      results: []
    });
  }, []);

  const handleSearchResultClick = useCallback((result: SearchState['results'][0]) => {
    switch (result.type) {
      case 'category':
        handleCategoryClick(result.item as Category);
        break;
      case 'subCategory': {
        // Encontrar a categoria pai
        const subCategory = result.item as SubCategory;
        const parentCategory = mentalHealthCategories.find(category => 
          category.subCategories.some(sc => sc.id === subCategory.id)
        );
        
        if (parentCategory) {
          navigate(`/conteudos/${parentCategory.id}/${subCategory.id}`);
        }
        break;
      }
      case 'article': {
        const article = result.item as Article;
        // Encontrar a categoria e subcategoria pai
        let foundCategory: Category | undefined;
        let foundSubCategory: SubCategory | undefined;
        
        mentalHealthCategories.forEach(category => {
          category.subCategories.forEach(subCategory => {
            if (subCategory.articles.some(a => a.id === article.id)) {
              foundCategory = category;
              foundSubCategory = subCategory;
            }
          });
        });
        
        if (foundCategory && foundSubCategory) {
          navigate(`/conteudos/${foundCategory.id}/${foundSubCategory.id}/${article.id}`);
        }
        break;
      }
      default:
        setSearch(prev => ({ ...prev, isSearching: false, query: '' }));
    }
  }, [navigate, handleCategoryClick]);

  const renderCategories = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col gap-4 mt-2 w-full mx-auto"
    >
      {/* Top Ad for Categories Page - Somente exibido para usuários com plano basic */}
      {currentPlan === 'basic' && <ContentAdsense position="top" className="mb-4" />}
      
      {/* Grid responsivo para categorias - 3 colunas apenas em telas muito grandes (≥1600px) */}
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-6">
        {mentalHealthCategories.map((category, index) => {
          const Icon = category.icon === 'Brain' ? Brain : 
                      category.icon === 'Frown' ? Frown : 
                      category.icon === 'Users' ? Users : 
                      category.icon === 'Utensils' ? Utensils : Target;
          
          return (
            <motion.div
              key={category.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ y: -5, boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)' }}
              className="bg-white rounded-lg shadow-sm border border-neutral-200 p-4 xl:p-5 cursor-pointer transition-all flex gap-3 xl:gap-4 w-full h-auto"
              onClick={() => handleCategoryClick(category)}
            >
              <div className="flex-shrink-0 w-10 h-10 xl:w-12 xl:h-12 bg-primary-50 rounded-full flex items-center justify-center text-primary-600 shadow-sm">
                <Icon size={20} className="xl:h-6 xl:w-6" />
              </div>
              <div className="flex flex-col flex-grow">
                <h2 className="text-lg xl:text-xl font-medium text-neutral-800 mb-1 xl:mb-2 break-words">{category.title}</h2>
                <p className="text-neutral-600 text-sm xl:text-base break-words line-clamp-3">{category.description}</p>
              </div>
            </motion.div>
          );
        })}
      </div>
      
      {/* Anúncios entre categorias - agora em posições específicas do grid */}
      {currentPlan === 'basic' && (
        <div className="col-span-full my-4">
          <ContentAdsense position="middle" />
        </div>
      )}
      
      {/* Bottom Ad for Categories Page - Somente exibido para usuários com plano basic */}
      {currentPlan === 'basic' && <ContentAdsense position="bottom" className="mt-4" />}
    </motion.div>
  );

  const renderSubCategories = () => {
    if (!state.category) return null;
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col gap-4 mt-2 w-full mx-auto"
      >
        {/* Top Ad for SubCategories Page - Somente exibido para usuários com plano basic */}
        {currentPlan === 'basic' && <ContentAdsense position="top" className="mb-4" />}
        
        {/* Grid responsivo para subcategorias - 3 colunas apenas em telas muito grandes (≥1600px) */}
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-6">
          {state.category.subCategories.map((subCategory, index) => {
            return (
              <motion.div
                key={subCategory.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-sm border border-neutral-200 p-4 xl:p-5 cursor-pointer hover:shadow-md transition-all hover:bg-neutral-50 w-full h-auto flex flex-col"
                onClick={() => handleSubCategoryClick(subCategory)}
              >
                <h3 className="text-lg xl:text-xl font-medium text-neutral-800 mb-1 xl:mb-2 break-words">{subCategory.title}</h3>
                <p className="text-neutral-600 text-sm xl:text-base break-words line-clamp-3 flex-grow">{subCategory.description}</p>
                <div className="flex flex-wrap items-center justify-between mt-3 gap-2">
                  <div className="flex items-center">
                    <span className="text-xs xl:text-sm font-medium bg-neutral-100 text-neutral-700 px-2 py-1 rounded-md flex items-center">
                      CID: {subCategory.diseaseCode.code}
                      <span onClick={(e) => e.stopPropagation()}>
                        <DiseaseCodeDetails details={subCategory.diseaseCode} />
                      </span>
                    </span>
                  </div>
                  <div className="text-xs xl:text-sm text-neutral-500 italic">
                    Clique para ver artigos
                  </div>
                </div>
              </motion.div>
            );
          })}
        </div>
        
        {/* Anúncios entre subcategorias */}
        {currentPlan === 'basic' && (
          <div className="col-span-full my-4">
            <ContentAdsense position="middle" />
          </div>
        )}
        
        {/* Bottom Ad for SubCategories Page - Somente exibido para usuários com plano basic */}
        {currentPlan === 'basic' && <ContentAdsense position="bottom" className="mt-4" />}
      </motion.div>
    );
  };

  const renderArticles = () => {
    if (!state.subCategory) return null;
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col gap-4 mt-2 w-full mx-auto"
      >
        {/* Top Ad for Articles Page */}
        <ContentAdsense position="top" className="mb-4" />
        
        {/* Grid responsivo para artigos - 3 colunas apenas em telas muito grandes (≥1600px) */}
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4 xl:gap-6">
          {state.subCategory.articles.map((article, index) => {
            // Insert ad after every 3 articles
            const shouldRenderAd = index > 0 && index % 3 === 0;
            
            return (
              <React.Fragment key={article.id}>
                {shouldRenderAd && currentPlan === 'basic' && (
                  <div className="col-span-full my-4">
                    <ContentAdsense position="middle" className="my-2" />
                  </div>
                )}
                
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <ContentCard article={article} onClick={() => handleArticleClick(article)} />
                </motion.div>
              </React.Fragment>
            );
          })}
        </div>
        
        {/* Bottom Ad for Articles Page */}
        <ContentAdsense position="bottom" className="mt-4" />
      </motion.div>
    );
  };

  const renderArticleContent = () => {
    if (!state.article) return null;
    return (
      <>
        {/* Top Ad for Article Content Page - Somente exibido para usuários com plano basic */}
        {currentPlan === 'basic' && <ContentAdsense position="top" className="mb-4" />}
        
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-sm border border-neutral-200 p-4 xl:p-6"
        >
        <div className="flex flex-col xl:flex-row xl:justify-between xl:items-start gap-2 xl:gap-4 mb-4">
          <h2 className="text-xl xl:text-2xl font-semibold text-neutral-800 leading-tight">{state.article.title}</h2>
          {state.article.isPremium && (
            <span className="bg-gradient-to-r from-primary-500 to-primary-600 text-white px-3 py-1 rounded-full text-xs flex items-center shadow-sm animate-pulse whitespace-nowrap">
              <Lock size={12} className="mr-1" />
              {state.article.requiredPlan}
            </span>
          )}
        </div>
        
        <div className="flex flex-wrap gap-2 mb-4 xl:mb-6">
          {state.article.diseaseCode && state.article.diseaseCodeDetails && (
            <span className="bg-blue-50 text-blue-700 px-2 py-1 rounded-full text-xs xl:text-sm flex items-center border border-blue-100">
              <DiseaseCodeWrapper 
                code={state.article.diseaseCode} 
                details={state.article.diseaseCodeDetails} 
              />
            </span>
          )}
          {state.article.tags.map(tag => (
            <span key={tag} className="bg-neutral-100 text-neutral-600 px-2 py-1 rounded-full text-xs xl:text-sm flex items-center">
              <Tag size={10} className="mr-1" />
              {tag}
            </span>
          ))}
          <span className="bg-green-50 text-green-700 px-2 py-1 rounded-full text-xs xl:text-sm flex items-center border border-green-100">
            <Clock size={10} className="mr-1" />
            {state.article.readingTime || '5'} min de leitura
          </span>
        </div>
        
        {/* Verificar acesso ao conteúdo com base no plano do usuário */}
        {state.article.isPremium && !hasAccessToContent(state.article.requiredPlan as UserPlan) ? (
          <div className="prose max-w-none bg-white p-4 xl:p-6 rounded-lg border border-neutral-100 shadow-sm leading-relaxed">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-3 xl:p-4 mb-4">
              <div className="flex items-center">
                <AlertTriangle className="h-5 w-5 text-yellow-400 mr-2" />
                <h3 className="text-lg font-medium text-yellow-800">Conteúdo Premium</h3>
              </div>
              <div className="mt-2">
                <p className="text-sm xl:text-base text-yellow-700">
                  Este artigo requer o plano <strong>{state.article.requiredPlan}</strong> para acesso completo.
                  {!user ? (
                    <>
                      <br />
                      <Link to="/login" className="font-medium underline hover:text-yellow-800 transition-colors">
                        Faça login
                      </Link> ou 
                      <Link to="/planos" className="font-medium underline hover:text-yellow-800 transition-colors">
                        conheça nossos planos
                      </Link> para acessar este conteúdo.
                    </>
                  ) : (
                    <>
                      <br />
                      <Link to="/planos" className="font-medium underline hover:text-yellow-800 transition-colors">
                        Atualize seu plano
                      </Link> para acessar este conteúdo.
                    </>
                  )}
                </p>
              </div>
            </div>
            
            {/* Exibir um preview do conteúdo */}
            <div className="mb-4">
              <h3 className="text-lg font-medium mb-2">Preview do conteúdo:</h3>
              <div>
                {state.article.content.substring(0, 150).replace(/<[^>]*>/g, '')}...
                <div className="mt-4 p-3 bg-neutral-100 rounded-lg text-center">
                  <p className="text-neutral-600 font-medium">Atualize seu plano para continuar lendo</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="prose max-w-none bg-white p-4 xl:p-6 rounded-lg border border-neutral-100 shadow-sm leading-relaxed">
            {/* First part of the content */}
            <div dangerouslySetInnerHTML={{ __html: state.article.content.substring(0, Math.floor(state.article.content.length / 2)) }} />
            
            {/* Middle Ad in article content - Somente exibido para usuários com plano basic */}
            {currentPlan === 'basic' && (
              <div className="my-4 xl:my-6">
                <ContentAdsense position="middle" />
              </div>
            )}
            
            {/* Second part of the content */}
            <div dangerouslySetInnerHTML={{ __html: state.article.content.substring(Math.floor(state.article.content.length / 2)) }} />
          </div>
        )}
        
        <div className="mt-4 xl:mt-6 pt-4 xl:pt-6 border-t border-neutral-200">
          <h4 className="text-sm xl:text-base font-medium text-neutral-700 mb-3 flex items-center">
            <BookOpen size={16} className="mr-2 text-primary-600" />
            Referências Científicas
          </h4>
          <ul className="text-sm text-neutral-600 space-y-2 xl:space-y-3">
            {state.article.references.map((ref, index) => {
              // Mapeamento de referências comuns para seus URLs
              const referenceMap: Record<string, string> = {
                'DSM-5': 'https://doi.org/10.1176/appi.books.9780890425596',
                'DSM-IV': 'https://www.psychiatry.org/psychiatrists/practice/dsm/dsm-iv',
                'ICD-10': 'https://icd.who.int/browse10/2019/en',
                'ICD-11': 'https://icd.who.int/browse11/l-m/en',
                'WHO': 'https://www.who.int/mental_health',
                'APA': 'https://www.apa.org',
                'NIMH': 'https://www.nimh.nih.gov'
              };
              
              // Verifica se a referência contém uma URL
              const urlRegex = /(https?:\/\/[^\s]+)/g;
              const matches = ref.match(urlRegex);
              let url = matches ? matches[0] : null;
              
              // Se não encontrou URL diretamente, verifica no mapeamento
              if (!url) {
                // Verifica se alguma das chaves do mapeamento está presente na referência
                for (const [key, mappedUrl] of Object.entries(referenceMap)) {
                  if (ref.includes(key)) {
                    url = mappedUrl;
                    break;
                  }
                }
              }
              
              return (
                <li key={index} className="bg-neutral-50 p-3 rounded-md border border-neutral-100 hover:bg-neutral-100 transition-colors">
                  <div className="flex flex-col">
                    <p className="text-sm xl:text-base">{ref}</p>
                    {url && (
                      <a 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-primary-600 hover:underline mt-2 flex items-center text-xs xl:text-sm font-medium"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                        Acessar fonte original
                      </a>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
          
          {state.article.expertReviewed !== undefined && state.article.expertReviewed && (
            <div className="mt-4 text-sm text-green-600 flex items-center">
              <CheckCircle size={14} className="mr-2" />
              Revisado por especialistas em saúde mental
            </div>
          )}
        </div>
      </motion.div>
      
      {/* Bottom Ad for Article Content Page - Somente exibido para usuários com plano basic */}
      {currentPlan === 'basic' && <ContentAdsense position="bottom" className="mt-4" />}
    </>
    );
  };

  // Gerar metadados SEO com base no estado atual
  const generateSeoMetadata = () => {
    const baseTitle = 'Conteúdos sobre Saúde Mental | Meu Humor';
    const baseDescription = 'Explore artigos, guias e recursos sobre saúde mental baseados em evidências científicas.';
    
    if (state.article) {
      return {
        title: `${state.article.title} | Meu Humor`,
        description: state.article.content.substring(0, 160).replace(/<[^>]*>/g, '') + '...',
        keywords: state.article.tags.join(', '),
        canonicalUrl: `/conteudos/${state.category?.id}/${state.subCategory?.id}/${state.article.id}`
      };
    }
    
    if (state.subCategory) {
      return {
        title: `${state.subCategory.title} | ${state.category?.title} | Meu Humor`,
        description: state.subCategory.description,
        keywords: `saúde mental, ${state.category?.title}, ${state.subCategory.title}`,
        canonicalUrl: `/conteudos/${state.category?.id}/${state.subCategory.id}`
      };
    }
    
    if (state.category) {
      return {
        title: `${state.category.title} | Meu Humor`,
        description: state.category.description,
        keywords: `saúde mental, ${state.category.title}`,
        canonicalUrl: `/conteudos/${state.category.id}`
      };
    }
    
    return {
      title: baseTitle,
      description: baseDescription,
      keywords: 'saúde mental, bem-estar, psicologia, ansiedade, depressão',
      canonicalUrl: '/conteudos'
    };
  };
  
  const seo = generateSeoMetadata();

  return (
    <div className="container-responsive py-4 xl:py-6" id="content-page-loaded">
      <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="keywords" content={seo.keywords} />
        <link rel="canonical" href={`https://mindful-moments.app${seo.canonicalUrl}`} />
        {state.article && (
          <>
            <meta property="og:title" content={state.article.title} />
            <meta property="og:description" content={seo.description} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={`https://mindful-moments.app${seo.canonicalUrl}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={state.article.title} />
            <meta name="twitter:description" content={seo.description} />
          </>
        )}
      </Helmet>
      <div className="flex flex-col gap-3 xl:gap-4 mb-4 xl:mb-6">
        {/* Cabeçalho da página */}
        <div className="flex flex-col md:flex-row md:items-center gap-2 mb-2">
          {(state.category || state.subCategory || state.article) && (
            <button
              onClick={handleBack}
              className="flex items-center text-neutral-600 hover:text-neutral-800 transition-colors mb-2 md:mb-0"
              aria-label="Voltar"
            >
              <ChevronLeft size={18} className="xl:size-20" />
              <span>Voltar</span>
            </button>
          )}
          <h1 className="text-xl md:text-2xl xl:text-3xl font-semibold text-neutral-800 truncate-text">
            {state.article ? state.article.title :
             state.subCategory ? state.subCategory.title :
             state.category ? state.category.title :
             'Conteúdos Exclusivos'}
          </h1>
        </div>
        
        {/* Descrição e barra de busca */}
        {!state.category && !state.subCategory && !state.article && (
          <p className="text-neutral-600 mb-2 xl:mb-4 max-w-3xl text-sm md:text-base xl:text-lg">
            Explore nosso acervo de conteúdos sobre saúde mental, com artigos revisados por especialistas.
          </p>
        )}
        
        {/* Barra de busca */}
        {!state.article && (
          <div className="w-full max-w-md md:max-w-lg mb-2 xl:mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Buscar conteúdo..."
                className="w-full pl-10 pr-10 py-2 md:py-3 border border-neutral-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 shadow-sm"
                value={search.query}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Search size={16} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400" />
              {search.query && (
                <button
                  onClick={handleClearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-neutral-400 hover:text-neutral-600 transition-colors focus:outline-none"
                  aria-label="Limpar busca"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <div>
        <Suspense fallback={
          <div className="flex justify-center py-6 xl:py-8">
            <div className="flex flex-col items-center space-y-2 text-primary-600">
              <Loader2 className="h-6 w-6 xl:h-8 xl:w-8 text-primary-500 animate-spin" />
              <span className="text-neutral-600 text-sm xl:text-base">Carregando...</span>
            </div>
          </div>
        }>
          <AnimatePresence mode="wait">
            {search.isSearching ? (
            <motion.div
              key="search"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="space-y-4"
            >
              <div className="flex items-center mb-4 bg-primary-50 p-3 rounded-lg border border-primary-100">
                <Search size={18} className="text-primary-600 mr-2" />
                <h2 className="text-lg font-medium text-neutral-800">Resultados para: <span className="text-primary-600 font-semibold">"{search.query}"</span></h2>
              </div>
              {searchResults.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
                  {searchResults.map((result, index) => (
                    <motion.div
                      key={`${result.type}-${index}`}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="bg-white rounded-lg shadow-sm border border-neutral-200 p-4 cursor-pointer hover:shadow-md transition-all hover:bg-neutral-50 w-full h-auto flex flex-col"
                      onClick={() => handleSearchResultClick(result)}
                    >
                      <div className="flex items-center gap-2 text-sm text-neutral-500 mb-1">
                        <BookOpen size={14} className="text-primary-600 flex-shrink-0" />
                        <span className="break-words line-clamp-1">{result.breadcrumb}</span>
                      </div>
                      <h3 className="text-lg font-medium text-neutral-800 mb-2 break-words">
                        {(result.item as any).title}
                      </h3>
                      {result.type === 'article' && (
                        <div className="flex flex-wrap gap-1 mt-1">
                          {(result.item as Article).tags.slice(0, 3).map(tag => (
                            <span key={tag} className="bg-neutral-100 text-neutral-600 px-2 py-0.5 rounded-full text-xs flex items-center">
                              <Tag size={8} className="mr-1 flex-shrink-0" />
                              <span className="break-words">{tag}</span>
                            </span>
                          ))}
                          {(result.item as Article).readingTime && (
                            <span className="bg-green-50 text-green-700 px-2 py-0.5 rounded-full text-xs flex items-center">
                              <Clock size={8} className="mr-1 flex-shrink-0" />
                              {(result.item as Article).readingTime} min
                            </span>
                          )}
                        </div>
                      )}
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className="bg-white rounded-lg shadow-sm border border-neutral-200 p-6 text-center">
                  <div className="flex flex-col items-center justify-center py-4">
                    <Search size={40} className="text-neutral-300 mb-4" />
                    <p className="text-neutral-600 mb-2">Nenhum resultado encontrado para <span className="font-semibold">"{search.query}"</span></p>
                    <p className="text-neutral-500 text-sm">Tente usar termos mais gerais ou verifique a ortografia.</p>
                  </div>
                </div>
              )}
            </motion.div>
          ) : state.article ? (
            <div className="max-w-4xl mx-auto">
              {renderArticleContent()}
            </div>
          ) : state.subCategory ? (
            <>
              <div className="mb-4 p-3 bg-neutral-50 rounded-lg border border-neutral-100 flex items-center shadow-sm overflow-x-auto">
                <BookOpen size={16} className="text-primary-600 mr-2 flex-shrink-0" />
                <div className="flex items-center text-sm whitespace-nowrap">
                  <Link to={`/conteudos/${state.category?.id}`} className="text-neutral-600 hover:text-primary-600 transition-colors flex items-center">
                    {state.category?.title}
                    <ChevronLeft size={14} className="mx-1 rotate-180" />
                  </Link>
                  <span className="font-medium text-neutral-800 truncate-text">{state.subCategory.title}</span>
                </div>
              </div>
              {renderArticles()}
            </>
          ) : state.category ? (
            <>
              <div className="mb-4 p-3 bg-neutral-50 rounded-lg border border-neutral-100 flex items-center shadow-sm">
                <BookOpen size={16} className="text-primary-600 mr-2" />
                <span className="font-medium text-neutral-800 truncate-text">{state.category.title}</span>
              </div>
              {renderSubCategories()}
            </>
          ) : (
            renderCategories()
            )}
          </AnimatePresence>
        </Suspense>
      </div>
    </div>
  );
};

export default ContentPage;