import React from 'react';
import { Info } from 'lucide-react';

type UnifiedPointerEvent = MouseEvent | TouchEvent;

// Implementação simplificada de tooltip já que não temos o Shadcn UI completo
const Tooltip = ({ children, content }: { children: React.ReactNode, content: React.ReactNode }) => {
  // Estado para controlar a abertura do tooltip
  // Em dispositivos móveis, o tooltip será aberto apenas com clique
  const [isOpen, setIsOpen] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  
  // Função para fechar o tooltip quando clicar fora dele
  React.useEffect(() => {
    const handleClickOutside = (event: UnifiedPointerEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };    

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isOpen]);
  
  // Calcular a posição do tooltip para centralizá-lo
  const [tooltipPosition, setTooltipPosition] = React.useState({ left: 0, top: 0 });
  
  React.useEffect(() => {
    if (isOpen && triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      
      // Centralizar o tooltip em relação ao elemento pai
      // Garantir que não ultrapasse as bordas da tela
      const tooltipWidth = 320; // w-80 = 20rem = 320px
      
      // Calcular a posição central
      const idealLeft = triggerRect.left + (triggerRect.width / 2) - (tooltipWidth / 2);
      
      // Ajustar para não ultrapassar as bordas
      let adjustedLeft = Math.max(10, idealLeft); // Pelo menos 10px da borda esquerda
      adjustedLeft = Math.min(adjustedLeft, windowWidth - tooltipWidth - 10); // Pelo menos 10px da borda direita
      
      // Calcular o deslocamento em relação ao elemento pai
      const leftOffset = adjustedLeft - triggerRect.left;
      
      setTooltipPosition({ left: leftOffset, top: triggerRect.height + 5 });
    }
  }, [isOpen]);
  
  return (
    <div className="relative inline-block" ref={tooltipRef}>
      <div 
        ref={triggerRef}
        onMouseEnter={() => setIsOpen(true)}
        onClick={() => setIsOpen(!isOpen)}
        className="cursor-pointer hover:opacity-80 transition-opacity flex items-center"
        aria-label="Ver detalhes do código da doença"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
          }
        }}
      >
        {children}
      </div>
      {isOpen && (
        <div 
          className="absolute z-50 bg-white rounded-md shadow-lg p-4 border border-neutral-200 animate-in fade-in duration-200 max-h-[80vh] overflow-y-auto w-80"
          style={{ 
            left: `${tooltipPosition.left}px`, 
            top: `${tooltipPosition.top}px`,
            maxWidth: 'calc(100vw - 20px)' // Garantir que não ultrapasse a largura da tela
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {content}
          <button 
            className="absolute top-2 right-2 text-neutral-500 hover:text-neutral-700"
            onClick={() => setIsOpen(false)}
            aria-label="Fechar detalhes"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

interface DiseaseCodeDetails {
  code: string;
  description: string;
  dsm5Criteria: string[];
  references: {
    icd10?: string;
    dsm5?: string;
    who?: string;
  };
}

// Componente wrapper para tornar toda a área da CID clicável
export const DiseaseCodeWrapper = ({ code, details }: { code: string, details: DiseaseCodeDetails }) => {
  return (
    <Tooltip
      children={
        <div className="flex items-center">
          <span>CID: {code}</span>
          <Info size={16} className="text-primary-600 ml-1 inline-flex" />
        </div>
      }
      content={
        <div className="relative">
          <div className="flex items-center justify-between mb-2">
            <h4 className="font-semibold">CID {details.code}</h4>
            <span className="text-xs px-2 py-1 bg-primary-100 text-primary-800 rounded-full">{details.code}</span>
          </div>
          <p className="text-sm mb-3 text-neutral-700">{details.description}</p>
          
          <div className="mb-3">
            <h5 className="text-xs font-medium mb-1">Critérios DSM-5:</h5>
            <ul className="list-disc pl-4 text-xs space-y-1">
              {details.dsm5Criteria.map((criteria, index) => (
                <li key={index}>{criteria}</li>
              ))}
            </ul>
          </div>
          
          <div className="text-xs space-y-1">
            <h5 className="text-xs font-medium mb-1">Referências:</h5>
            {details.references.dsm5 && (
              <a 
                href={details.references.dsm5} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                Referência DSM-5
              </a>
            )}
            {details.references.icd10 && (
              <a 
                href={details.references.icd10} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                CID-10
              </a>
            )}
            {details.references.who && (
              <a 
                href={details.references.who} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                OMS (WHO)
              </a>
            )}
          </div>
        </div>
      }
    />
  );
};

// Componente original para compatibilidade com o código existente
export const DiseaseCodeDetails = ({ details }: { details: DiseaseCodeDetails }) => {
  if (!details) return null;
  
  return (
    <Tooltip 
      children={<Info size={16} className="text-primary-600 ml-1 inline-flex" />}
      content={
        <div className="relative">
          <div className="flex items-center justify-between mb-2">
            <h4 className="font-semibold">CID {details.code}</h4>
            <span className="text-xs px-2 py-1 bg-primary-100 text-primary-800 rounded-full">{details.code}</span>
          </div>
          <p className="text-sm mb-3 text-neutral-700">{details.description}</p>
          
          <div className="mb-3">
            <h5 className="text-xs font-medium mb-1">Critérios DSM-5:</h5>
            <ul className="list-disc pl-4 text-xs space-y-1">
              {details.dsm5Criteria.map((criteria, index) => (
                <li key={index}>{criteria}</li>
              ))}
            </ul>
          </div>
          
          <div className="text-xs space-y-1">
            <h5 className="text-xs font-medium mb-1">Referências:</h5>
            {details.references.dsm5 && (
              <a 
                href={details.references.dsm5} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                Referência DSM-5
              </a>
            )}
            {details.references.icd10 && (
              <a 
                href={details.references.icd10} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                CID-10
              </a>
            )}
            {details.references.who && (
              <a 
                href={details.references.who} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-primary-600 hover:underline block flex items-center"
                onClick={(e) => e.stopPropagation()}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
                OMS (WHO)
              </a>
            )}
          </div>
        </div>
      }
    />
  );
};
