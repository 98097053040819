import React, { useState, useRef, useEffect } from 'react';
import { Send, X, Minimize2, Maximize2, Brain, Plus, ExternalLink } from 'lucide-react';
import { sendChatMessage, getChatSessionMessages } from '../../infra/ChatAIFacade';
import { useChat } from '../../contexts/ChatContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './ChatMarkdown.css';
import { ChatMessage } from '../../types';

interface Message {
  id: string;
  content: string;
  isUser: boolean;
  timestamp: number;
  isHighlighted?: boolean;
  isThinking?: boolean;
}

// Prop opcional para indicar quando o widget está sendo usado em tela cheia
interface ChatWidgetProps {
  fullscreen?: boolean;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({ fullscreen = false }) => {
  const { 
    isOpen, 
    initialMessage, 
    closeChat,
    currentSessionId,
    isNewSession,
    startNewSession,
    isOnChatPage,
    setCurrentSession
  } = useChat();
  
  const location = useLocation();
  const navigate = useNavigate();
  const isOnChatPageRoute = isOnChatPage(location.pathname);
  const isFullscreen = fullscreen || isOnChatPageRoute;
  
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    {
      id: 'welcome',
      content: 'Olá! Sou seu assistente pessoal com IA. Como posso ajudar com seu registro de humor hoje?',
      isUser: false,
      timestamp: Date.now()
    }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreviousMessages, setIsLoadingPreviousMessages] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const chatMessagesRef = useRef<HTMLDivElement>(null);
  const [isBotTyping, setIsBotTyping] = useState(false);

  // Efeito para ajustar a altura da textarea quando o valor muda
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${Math.min(inputRef.current.scrollHeight, 120)}px`;
    }
  }, [inputValue]);

  // Efeito para rolar para a última mensagem
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
  
  // Efeito para focar a textarea quando o chat é aberto
  useEffect(() => {
    if (isOpen && !isMinimized && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen, isMinimized]);
  
  // Efeito para carregar mensagens de uma sessão existente
  useEffect(() => {
    if (currentSessionId && !isNewSession) {
      loadSessionMessages(currentSessionId);
    }
  }, [currentSessionId, isNewSession]);
  
  // Efeito adicional para garantir que as mensagens sejam carregadas
  // quando a página é acessada diretamente via URL com um sessionId
  useEffect(() => {
    // Este efeito só deve ser executado em modo tela cheia (na página de chat)
    // e quando temos um sessionId válido para evitar chamadas desnecessárias
    if (isFullscreen && currentSessionId && !isLoadingPreviousMessages && messages.length === 1) {
      // Se estamos na página de chat, temos um sessionId e só temos a mensagem de boas-vindas
      // então é provável que estejamos carregando a página diretamente via URL
      loadSessionMessages(currentSessionId);
    }
  }, [isFullscreen, currentSessionId, isLoadingPreviousMessages, messages.length]);
  
  // Efeito para definir a mensagem inicial
  useEffect(() => {
    if (initialMessage && initialMessage.trim() !== '') {
      setInputValue(initialMessage);
    }
  }, [initialMessage]);
  
  // Função para carregar mensagens de uma sessão
  const loadSessionMessages = async (sessionId: string) => {
    setIsLoadingPreviousMessages(true);
    try {
      const response = await getChatSessionMessages(sessionId);
      
      if (response && response.messages && response.messages.length > 0) {
        const formattedMessages = response.messages.map((msg: ChatMessage) => ({
          id: msg.id || Math.random().toString(36).substring(7),
          content: msg.content,
          isUser: msg.isUser,
          timestamp: new Date(msg.timestamp || Date.now()).getTime(),
          isHighlighted: false,
          isThinking: false
        }));
        
        setMessages(formattedMessages);
      }
    } catch (error) {
      console.error('Erro ao carregar mensagens da sessão:', error);
      // Manter as mensagens de boas-vindas em caso de erro
    } finally {
      setIsLoadingPreviousMessages(false);
    }
  };
  
  // Função para ajustar a altura da textarea
  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    element.style.height = `${Math.min(element.scrollHeight, 120)}px`;
  };
  
  // Função para enviar mensagem
  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;
    
    const userMessageId = Date.now().toString();
    const userMessage = {
      id: userMessageId,
      content: inputValue,
      isUser: true,
      timestamp: Date.now()
    };
    
    // Adiciona a mensagem do usuário à lista
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setIsLoading(true);
    
    // Adiciona um indicador de digitação
    const thinkingMessageId = `thinking-${Date.now()}`;
    setMessages(prev => [...prev, {
      id: thinkingMessageId,
      content: '...',
      isUser: false,
      timestamp: Date.now(),
      isThinking: true
    }]);
    
    try {
      setIsBotTyping(true);
      
      // Guarda o estado atual de isNewSession para uso na API
      const shouldCreateNewSession = isNewSession && !currentSessionId;
      
      // Chama a API para enviar a mensagem
      const response = await sendChatMessage(
        userMessage.content, 
        currentSessionId || undefined,
        shouldCreateNewSession // Só passa true na primeira mensagem de uma sessão nova
      );
      
      // Remove o indicador de digitação
      setMessages(prev => prev.filter(msg => msg.id !== thinkingMessageId));
      
      if (response) {
        // Se esta era uma nova sessão, agora não é mais
        if (shouldCreateNewSession) {
          // Após enviar a primeira mensagem de uma nova sessão,
          // garantimos que as próximas mensagens são enviadas para 
          // a mesma sessão marcando a sessão como não sendo mais nova
          // Se a sessão foi enviada com sucesso, consideramos que não é mais nova
          // mesmo que não tenhamos um ID de sessão para armazenar (ele estará no backend)
          if (currentSessionId) {
            // Se já temos um ID de sessão, usamos setCurrentSession para manter esse ID
            setCurrentSession(currentSessionId);
          } else {
            // Se não temos um ID de sessão, podemos simplesmente atualizar a flag isNewSession
            // através do contexto, enviando o sessionId como null
            setCurrentSession(null);
          }
        }
        
        // Adiciona a resposta do assistente
        setMessages(prev => [...prev, {
          id: `bot-${Date.now()}`,
          content: response.answer,
          isUser: false,
          timestamp: Date.now()
        }]);
      }
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error);
      // Remove o indicador de digitação em caso de erro
      setMessages(prev => prev.filter(msg => msg.id !== thinkingMessageId));
      
      // Adiciona uma mensagem de erro
      setMessages(prev => [...prev, {
        id: `error-${Date.now()}`,
        content: 'Desculpe, ocorreu um erro ao processar sua mensagem. Por favor, tente novamente.',
        isUser: false,
        timestamp: Date.now()
      }]);
    } finally {
      setIsLoading(false);
      setIsBotTyping(false);
    }
  };
  
  // Handler para tecla Enter no input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };
  
  // Handler para mudança no input
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    adjustTextareaHeight(e.target);
  };
  
  // Handler para iniciar nova sessão
  const handleNewSession = () => {
    startNewSession();
    setMessages([
      {
        id: 'welcome',
        content: 'Olá! Sou seu assistente pessoal com IA. Como posso ajudar com seu registro de humor hoje?',
        isUser: false,
        timestamp: Date.now()
      }
    ]);
  };
  
  // Função para expandir o chat em tela cheia
  const handleExpandToFullscreen = () => {
    // Se já temos um ID de sessão, navegamos para a página dedicada com esse ID
    if (currentSessionId) {
      navigate(`/chat?sessionId=${currentSessionId}`);
    } else {
      // Se não temos um ID (sessão nova), navegamos para a página dedicada sem parâmetros
      navigate('/chat');
    }
    
    // Fechamos o chat flutuante, pois estaremos indo para a versão em tela cheia
    closeChat();
  };
  
  // Determinar a classe de posicionamento com base se está na página dedicada ou não
  const positionClass = isFullscreen 
    ? "w-full h-full" // Quando estiver na página de chat, ocupa todo o espaço disponível
    : "fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50 lg:bottom-8"; // Comportamento flutuante nas demais páginas
  
  // Se o chat não estiver aberto e não estiver na página dedicada, não renderiza nada
  if (!isOpen && !isFullscreen) {
    return null;
  }
  
  // Na página dedicada, sempre renderizamos o widget mesmo que isOpen seja falso
  
  return (
    <div className={positionClass}>
      {/* Janela do chat */}
      <div 
        className={`bg-white rounded-lg ${!isFullscreen ? 'shadow-xl' : ''} flex flex-col transition-all duration-300 ${
          isMinimized 
            ? 'w-80 h-14' 
            : isFullscreen 
              ? 'w-full h-full'
              : 'w-80 sm:w-96 md:w-[450px] h-[420px] sm:h-96 md:h-[500px]'
        }`}
      >
        {/* Cabeçalho */}
        <div className={`p-3 border-b flex justify-between items-center ${isMinimized ? 'rounded-lg' : 'rounded-t-lg'}`}>
          <div className="flex items-center">
            <Brain className="h-5 w-5 text-primary-600 mr-2" />
            <h3 className="text-sm font-medium">
              {isNewSession ? 'Nova Conversa' : 'Conversa Contínua'}
            </h3>
          </div>
          <div className="flex items-center space-x-1">
            {!isFullscreen && (
              <>
                <button
                  onClick={handleExpandToFullscreen}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                  title="Expandir para tela cheia"
                >
                  <ExternalLink className="h-4 w-4 text-primary-500" />
                </button>
                <button
                  onClick={handleNewSession}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                  title="Nova conversa"
                >
                  <Plus className="h-4 w-4 text-primary-500" />
                </button>
                <button
                  onClick={() => setIsMinimized(!isMinimized)}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                >
                  {isMinimized ? <Maximize2 className="h-4 w-4" /> : <Minimize2 className="h-4 w-4" />}
                </button>
                <button
                  onClick={closeChat}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
                >
                  <X className="h-4 w-4" />
                </button>
              </>
            )}
          </div>
        </div>

        {/* Corpo do chat (oculto quando minimizado) */}
        {!isMinimized && (
          <div className="flex-1 overflow-y-auto p-3 space-y-3" ref={chatMessagesRef}>
            {isLoadingPreviousMessages && (
              <div className="flex justify-center p-3">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary-500"></div>
              </div>
            )}

            {messages.map((message, index) => (
              <div key={message.id} className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}>
                <div 
                  className={`max-w-[80%] p-3 rounded-lg ${
                    message.isUser 
                      ? 'bg-primary-500 text-white rounded-br-none' 
                      : 'bg-gray-100 rounded-bl-none'
                  } ${message.isHighlighted ? 'border-2 border-yellow-400' : ''}`}
                  ref={index === messages.length - 1 ? messagesEndRef : null}
                >
                  {message.isThinking ? (
                    <div className="flex items-center space-x-1">
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                    </div>
                  ) : (
                    <div className="chat-message markdown-body">
                      <ReactMarkdown>{message.content}</ReactMarkdown>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        )}

        {/* Digitando e rodapé (oculto quando minimizado) */}
        {!isMinimized && (
          <>
            {isBotTyping && (
              <div className="px-3 py-2">
                <div className="flex items-center text-sm text-gray-500">
                  <div className="w-2 h-2 bg-primary-500 rounded-full animate-pulse mr-1"></div>
                  <span>Assistente está digitando...</span>
                </div>
              </div>
            )}

            <div className="border-t p-3 relative">
              <div className="flex items-end space-x-2">
                {isFullscreen && (
                  <button
                    onClick={handleNewSession}
                    className="p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
                    title="Nova conversa"
                  >
                    <Plus className="h-5 w-5" />
                  </button>
                )}
                
                <div className="flex-1 relative">
                  <textarea
                    ref={inputRef}
                    className="w-full border rounded-lg pl-3 pr-10 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 resize-none min-h-[40px] max-h-[120px]"
                    placeholder="Digite sua mensagem..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    disabled={isLoading}
                    rows={1}
                  />
                </div>
                <button
                  onClick={() => handleSendMessage()}
                  disabled={!inputValue.trim() || isLoading}
                  className={`p-2 rounded-full transition-colors ${
                    inputValue.trim() && !isLoading
                      ? 'bg-primary-500 text-white hover:bg-primary-600'
                      : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  }`}
                >
                  <Send className="h-5 w-5" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatWidget;