import React, { useState } from 'react';
import { X } from 'lucide-react';
import { getMoodEmoji, getMoodText } from '../infra/utils/moodUtils';
import FocusTextarea from './FocusTextarea';
// import { formatFullDatePtBR } from '../infra/utils/dateUtils';

interface MoodAddFormProps {
  date: string;
  formattedDate: string;
  initialTime: string;
  onSave: (data: {
    score: number;
    note: string;
    time: string;
  }) => Promise<void>;
  onCancel: () => void;
  isSaving: boolean;
}

export const MoodAddForm: React.FC<MoodAddFormProps> = ({
  date,
  formattedDate,
  initialTime,
  onSave,
  onCancel,
  isSaving
}) => {
  const [score, setScore] = useState<number>(-1);
  const [note, setNote] = useState<string>('');
  const [time, setTime] = useState<string>(initialTime);

  const handleMoodSelect = (newScore: number) => {
    setScore(newScore);
  };

  const handleSaveClick = async () => {
    if (score === -1) return;
    
    await onSave({
      score,
      note,
      time
    });
  };

  return (
    <div className="card relative ring-2 ring-primary-300">
      <div className="flex justify-between items-center mb-3">
        <span className="text-neutral-700 font-medium">
          Novo registro para {formattedDate}
        </span>
        <button 
          onClick={onCancel}
          className="p-2.5 bg-neutral-100 text-neutral-600 rounded-full hover:bg-neutral-200 touch-manipulation"
          aria-label="Cancelar adição"
        >
          <X size={20} />
        </button>
      </div>
      
      <div className="mb-4 bg-neutral-50 p-3 rounded-lg">
        <div className="flex justify-between items-center mb-2">
          <div className="text-sm text-neutral-600">Como você estava se sentindo?</div>
          <div className="flex items-center gap-2">
            <label htmlFor={`time-${date}`} className="text-sm text-neutral-500">Horário:</label>
            <input
              type="time"
              id={`time-${date}`}
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="bg-white border border-neutral-200 rounded px-2 py-1 text-sm text-neutral-700 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>
        <div className="flex justify-between items-center">
          {[0, 1, 2, 3, 4, 5].map((moodScore) => (
            <button
              type="button"
              key={moodScore}
              className={`emoji-btn-small ${score === moodScore ? 'emoji-btn-selected' : ''}`}
              onClick={() => handleMoodSelect(moodScore)}
              aria-label={`Humor: ${getMoodText(moodScore)}`}
            >
              {getMoodEmoji(moodScore)}
            </button>
          ))}
        </div>
        {score !== -1 && (
          <p className="text-center text-neutral-600 text-xs mt-1">
            {getMoodText(score)}
          </p>
        )}
      </div>
      
      <FocusTextarea 
        value={note}
        onChange={(e) => setNote(e.target.value)}
        className="input mb-3 min-h-[80px] resize-none"
        placeholder="Como você estava se sentindo? (opcional)"
        maxLength={255}
        showCount={true}
        autoFocus={true}
      />
      
      <button
        onClick={handleSaveClick}
        disabled={score === -1 || isSaving}
        className="btn btn-primary w-full"
      >
        {isSaving ? 'Salvando...' : 'Salvar registro'}
      </button>
    </div>
  );
}; 