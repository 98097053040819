import React, { useState, useEffect } from 'react';
import { CheckCircle, ArrowRight, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { StepProps } from './StepProps';
import { getOnBoardingUserData } from '../../../infra/OnBoardingFacade';
import { OnBoardingUserData } from '../../../types';
import { useNavigate } from 'react-router-dom';

const CompletionStep: React.FC<StepProps> = ({
  userData: propUserData,
  onNext,
  isSubmitting
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [completeUserData, setCompleteUserData] = useState<Partial<OnBoardingUserData>>(propUserData);
  const navigate = useNavigate();

  // Buscar dados completos do usuário ao carregar o componente
  useEffect(() => {
    const fetchCompleteUserData = async () => {
      try {
        setIsLoading(true);
        const data = await getOnBoardingUserData();
        if (data) {
          // Mesclar os dados recebidos via props com os dados completos do usuário
          setCompleteUserData({
            ...data,
            ...propUserData // Priorizar os dados mais recentes das props
          });
        }
      } catch (error) {
        console.error('Erro ao buscar dados completos do usuário:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompleteUserData();
  }, [propUserData]);

  // Função para formatar a qualidade do sono
  const getSleepQualityLabel = (quality: string | undefined) => {
    if (!quality) return 'Não informado';
    
    const labels: Record<string, string> = {
      'ruim': 'Ruim',
      'razoavel': 'Razoável',
      'boa': 'Boa',
      'excelente': 'Excelente'
    };
    
    return labels[quality] || quality;
  };
  
  // Função para formatar o gênero
  const getGenderLabel = (gender: string | undefined) => {
    if (!gender) return 'Não informado';
    
    const labels: Record<string, string> = {
      'masculino': 'Masculino',
      'feminino': 'Feminino',
      'nao-binario': 'Não-binário',
      'prefiro-nao-informar': 'Prefiro não informar'
    };
    
    return labels[gender] || gender;
  };
  
  // Função para formatar a motivação
  const getMotivationLabel = (motivation: string | undefined) => {
    if (!motivation) return 'Não informado';
    
    const labels: Record<string, string> = {
      'melhorar-humor': 'Melhorar meu humor',
      'gerenciar-estresse': 'Gerenciar estresse',
      'acompanhar-emocoes': 'Acompanhar emoções',
      'recomendacao-profissional': 'Recomendação profissional',
      'curiosidade': 'Curiosidade',
      'outro': 'Outro motivo'
    };
    
    return labels[motivation] || motivation;
  };

  const handleStartUsingApp = () => {
    // Fechar o modal de onboarding
    onNext({});
    // Remover os parâmetros de query da URL para evitar loops de carregamento
    navigate('/home');
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <Loader2 className="h-8 w-8 animate-spin text-primary-500 mb-4" />
        <p className="text-neutral-600">Carregando informações...</p>
      </div>
    );
  }
  
  return (
    <div className="max-w-md mx-auto">
      <div className="text-center mb-8">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <CheckCircle size={40} className="text-green-600" />
        </motion.div>
        
        <h3 className="text-xl md:text-2xl font-semibold text-neutral-800 mb-2">
          Configuração concluída!
        </h3>
        <p className="text-neutral-600">
          Obrigado por compartilhar suas informações. Estamos prontos para ajudar você a acompanhar e melhorar seu bem-estar emocional.
        </p>
      </div>
      
      <div className="bg-neutral-50 rounded-lg p-4 border border-neutral-200 mb-6 overflow-y-auto max-h-96">
        <h4 className="font-medium text-neutral-800 mb-3">Resumo das informações</h4>
        
        <div className="space-y-4">
          {/* Informações pessoais */}
          <div className="border-b border-neutral-200 pb-3">
            <h5 className="text-sm font-medium text-neutral-700 mb-2">Informações pessoais</h5>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="text-neutral-600">Nome</span>
                <span className="font-medium">{completeUserData.name || 'Não informado'}</span>
              </div>
              
              <div className="flex justify-between items-center">
                <span className="text-neutral-600">Telefone</span>
                <span className="font-medium">{completeUserData.phone || 'Não informado'}</span>
              </div>
            </div>
          </div>
          
          {/* Informações demográficas */}
          {(completeUserData.age || completeUserData.gender || completeUserData.location) && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Informações demográficas</h5>
              <div className="space-y-2">
                {completeUserData.age && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Idade</span>
                    <span className="font-medium">{completeUserData.age} anos</span>
                  </div>
                )}
                
                {completeUserData.gender && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Gênero</span>
                    <span className="font-medium">{getGenderLabel(completeUserData.gender)}</span>
                  </div>
                )}
                
                {completeUserData.location && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Localização</span>
                    <span className="font-medium">{completeUserData.location}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Motivação */}
          {(completeUserData.motivation || completeUserData.motivationOther) && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Motivação</h5>
              <div className="space-y-2">
                {completeUserData.motivation && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Motivação principal</span>
                    <span className="font-medium">{getMotivationLabel(completeUserData.motivation)}</span>
                  </div>
                )}
                
                {completeUserData.motivationOther && (
                  <div className="flex flex-col">
                    <span className="text-neutral-600">Detalhes da motivação</span>
                    <span className="font-medium mt-1">{completeUserData.motivationOther}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Saúde mental */}
          {(completeUserData.hasMentalHealthCondition || 
            completeUserData.depressionScore !== undefined || 
            completeUserData.anxietyScore !== undefined) && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Saúde mental</h5>
              <div className="space-y-2">
                {completeUserData.depressionScore !== undefined && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Pontuação de depressão (PHQ-9)</span>
                    <span className="font-medium">{completeUserData.depressionScore}</span>
                  </div>
                )}
                
                {completeUserData.anxietyScore !== undefined && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Pontuação de ansiedade (GAD-7)</span>
                    <span className="font-medium">{completeUserData.anxietyScore}</span>
                  </div>
                )}
                
                {completeUserData.hasMentalHealthCondition && completeUserData.mentalHealthConditionDetails && (
                  <div className="flex flex-col">
                    <span className="text-neutral-600">Condições de saúde mental</span>
                    <span className="font-medium mt-1">{completeUserData.mentalHealthConditionDetails}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Tratamento atual */}
          {completeUserData.isReceivingTreatment && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Tratamento atual</h5>
              <div className="space-y-2">
                {completeUserData.treatmentDetails && (
                  <div className="flex flex-col">
                    <span className="text-neutral-600">Tipos de tratamento</span>
                    <span className="font-medium mt-1">{completeUserData.treatmentDetails}</span>
                  </div>
                )}
                
                {completeUserData.psychiatricMedications && (
                  <div className="flex flex-col">
                    <span className="text-neutral-600">Medicamentos psiquiátricos</span>
                    <span className="font-medium mt-1">{completeUserData.psychiatricMedications}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Qualidade do sono */}
          {(completeUserData.sleepQuality || completeUserData.sleepHoursPerNight || completeUserData.sleepIssues) && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Qualidade do sono</h5>
              <div className="space-y-2">
                {completeUserData.sleepQuality && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Qualidade do sono</span>
                    <span className="font-medium">{getSleepQualityLabel(completeUserData.sleepQuality)}</span>
                  </div>
                )}
                
                {completeUserData.sleepHoursPerNight && (
                  <div className="flex justify-between items-center">
                    <span className="text-neutral-600">Horas de sono por noite</span>
                    <span className="font-medium">{completeUserData.sleepHoursPerNight} horas</span>
                  </div>
                )}
                
                {completeUserData.sleepIssues && completeUserData.sleepIssues.length > 0 && (
                  <div className="flex flex-col">
                    <span className="text-neutral-600">Problemas de sono</span>
                    <span className="font-medium mt-1">{completeUserData.sleepIssues.join(', ')}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {/* Suporte social */}
          {completeUserData.hasSupportSystem && completeUserData.supportSystemDetails && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Suporte social</h5>
              <div className="flex flex-col">
                <span className="text-neutral-600">Fontes de suporte</span>
                <span className="font-medium mt-1">{completeUserData.supportSystemDetails.join(', ')}</span>
              </div>
            </div>
          )}
          
          {/* Objetivos */}
          {completeUserData.goals && completeUserData.goals.length > 0 && (
            <div className="border-b border-neutral-200 pb-3">
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Objetivos</h5>
              <div className="flex flex-col">
                <span className="font-medium mt-1">{completeUserData.goals.join(', ')}</span>
              </div>
            </div>
          )}
          
          {/* Informações adicionais */}
          {completeUserData.additionalInfo && (
            <div>
              <h5 className="text-sm font-medium text-neutral-700 mb-2">Informações adicionais</h5>
              <p className="text-neutral-700">{completeUserData.additionalInfo}</p>
            </div>
          )}
        </div>
      </div>
      
      <div className="pt-4">
        <button
          type="button"
          onClick={handleStartUsingApp}
          disabled={isSubmitting}
          className="w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Começar a usar o aplicativo
          <ArrowRight size={16} className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default CompletionStep; 