export const adsenseConfig = {
  // Google AdSense client ID
  client: 'ca-pub-1767421654516889',
  // Different ad slots for various placements
  slots: {
    header: '4594976852', // Slot ID para anúncios no cabeçalho
    footer: '4594976852', // Slot ID para anúncios no rodapé
    inContent: '4198287385', // Slot ID para anúncios no meio do conteúdo
  }
};
