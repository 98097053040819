import { useState, useEffect } from 'react';

/**
 * A hook that returns a debounced value after a specified delay.
 * Useful for search inputs and other scenarios where you want to limit
 * the rate at which a value updates.
 * 
 * @param value - The value to debounce
 * @param delay - The delay in milliseconds before the value updates
 * @returns The debounced value
 */
function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export { useDebounce };
