import React, { useState, useEffect } from 'react';
import { Mail, Save, Edit, Shield, AlertTriangle, Loader2, Check } from 'lucide-react';
import { Button } from '../ui/button';
import { getOnBoardingUserData, updateUserRecoveryEmail } from '../../infra/OnBoardingFacade';
import { OnBoardingUserData } from '../../types';

interface RecoveryEmailManagerProps {
  onUpdate?: (email: string) => void;
}

const RecoveryEmailManager: React.FC<RecoveryEmailManagerProps> = ({ onUpdate }) => {
  const [recoveryEmail, setRecoveryEmail] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [userData, setUserData] = useState<OnBoardingUserData | null>(null);
  
  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        const data = await getOnBoardingUserData();
        setUserData(data);
        
        if (data?.recoveryEmail) {
          setRecoveryEmail(data.recoveryEmail);
        }
      } catch (err) {
        console.error('Erro ao carregar dados do usuário:', err);
        setError('Não foi possível carregar os dados. Tente novamente mais tarde.');
      } finally {
        setIsLoading(false);
      }
    };
    
    loadUserData();
  }, []);
  
  const handleEdit = () => {
    setNewEmail(recoveryEmail);
    setIsEditing(true);
    setError('');
    setSuccess('');
  };
  
  const handleCancel = () => {
    setIsEditing(false);
    setNewEmail(recoveryEmail);
    setError('');
  };
  
  const handleSave = async () => {
    // Validação básica
    if (!newEmail.trim()) {
      setError('Por favor, insira um email válido');
      return;
    }
    
    // Validar formato do email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newEmail)) {
      setError('Formato de email inválido');
      return;
    }
    
    setIsLoading(true);
    setError('');
    
    try {
      await updateUserRecoveryEmail(newEmail);
      setRecoveryEmail(newEmail);
      setIsEditing(false);
      setSuccess('Email de recuperação atualizado com sucesso!');
      
      if (onUpdate) {
        onUpdate(newEmail);
      }
    } catch (err) {
      console.error('Erro ao atualizar email de recuperação:', err);
      setError('Não foi possível atualizar o email. Por favor, tente novamente.');
    } finally {
      setIsLoading(false);
    }
  };
  
  if (isLoading && !userData) {
    return (
      <div className="flex items-center justify-center p-6">
        <Loader2 className="h-6 w-6 animate-spin text-primary-500" />
        <span className="ml-2 text-neutral-600">Carregando...</span>
      </div>
    );
  }
  
  return (
    <div className="bg-white rounded-lg shadow-sm p-5 mb-4">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-medium text-neutral-800 flex items-center">
          <Shield className="h-5 w-5 text-primary-500 mr-2" />
          Email de Recuperação
        </h3>
        
        {!isEditing && (
          <Button
            variant="outline"
            size="sm"
            onClick={handleEdit}
            className="flex items-center"
          >
            <Edit className="h-4 w-4 mr-1" />
            {recoveryEmail ? 'Alterar' : 'Adicionar'}
          </Button>
        )}
      </div>
      
      {!isEditing ? (
        <>
          <div className="mb-3">
            {recoveryEmail ? (
              <div className="flex items-center text-neutral-700">
                <Mail className="h-4 w-4 text-neutral-500 mr-2" />
                <span>{recoveryEmail}</span>
              </div>
            ) : (
              <div className="flex items-start p-3 bg-amber-50 border border-amber-200 rounded-md">
                <AlertTriangle className="h-5 w-5 text-amber-500 mt-0.5 mr-2 flex-shrink-0" />
                <div>
                  <p className="text-sm text-amber-800 font-medium">Email de recuperação não configurado</p>
                  <p className="text-xs text-amber-700 mt-1">
                    Recomendamos adicionar um email de recuperação para garantir acesso à sua conta caso você esqueça sua senha.
                  </p>
                </div>
              </div>
            )}
          </div>
          
          {success && (
            <div className="flex items-center p-3 bg-green-50 border border-green-200 rounded-md mb-3">
              <Check className="h-5 w-5 text-green-500 mr-2" />
              <p className="text-sm text-green-700">{success}</p>
            </div>
          )}
        </>
      ) : (
        <div className="mb-4">
          <div className="mb-3">
            <label htmlFor="recoveryEmail" className="block text-sm font-medium text-neutral-700 mb-1">
              Novo email de recuperação
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-neutral-500" />
              </div>
              <input
                type="email"
                id="recoveryEmail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="seu-email@exemplo.com"
                className="block w-full pl-10 pr-3 py-2 border border-neutral-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500"
                disabled={isLoading}
              />
            </div>
            {error && (
              <p className="mt-1 text-sm text-red-600">{error}</p>
            )}
          </div>
          
          <div className="flex space-x-2">
            <Button
              variant="default"
              onClick={handleSave}
              className="flex items-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin mr-1" />
                  Salvando...
                </>
              ) : (
                <>
                  <Save className="h-4 w-4 mr-1" />
                  Salvar
                </>
              )}
            </Button>
            <Button
              variant="outline"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancelar
            </Button>
          </div>
        </div>
      )}
      
      <div className="border-t border-neutral-200 pt-3 mt-3">
        <p className="text-xs text-neutral-500">
          <strong>Informações importantes:</strong>
        </p>
        <ul className="text-xs text-neutral-500 list-disc list-inside mt-1">
          <li>Este email será usado apenas para recuperação de conta e não será compartilhado com terceiros.</li>
          <li>Você poderá alterar ou remover este email a qualquer momento.</li>
          <li>Se você esquecer sua senha e não tiver um email de recuperação, não será possível recuperar sua conta.</li>
        </ul>
      </div>
    </div>
  );
};

export default RecoveryEmailManager; 