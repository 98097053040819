import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';

// Props para o provedor de contexto
interface ChatProviderProps {
  children: ReactNode;
}

// Interface para o contexto do chat
export interface ChatContextType {
  isOpen: boolean;
  openChat: (initialMsg?: string) => void;
  closeChat: () => void;
  initialMessage: string;
  setInitialMessage: (message: string) => void;
  currentSessionId: string | null;
  setCurrentSession: (sessionId: string | null) => void;
  isNewSession: boolean;
  startNewSession: () => void;
  isOnChatPage: (pathname: string) => boolean;
}

// Criando o contexto
const ChatContext = createContext<ChatContextType>({
  isOpen: false,
  initialMessage: '',
  currentSessionId: null,
  isNewSession: true,
  openChat: () => {},
  closeChat: () => {},
  startNewSession: () => {},
  setCurrentSession: () => {},
  setInitialMessage: () => {},
  isOnChatPage: () => false
});

// Hook para usar o contexto de chat
export const useChat = () => useContext(ChatContext);

// Provedor do contexto
export const ChatProvider: React.FC<ChatProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialMessage, setInitialMessage] = useState<string>('');
  const [currentSessionId, setCurrentSessionId] = useState<string | null>(null);
  const [isNewSession, setIsNewSession] = useState(true);
  
  // Função para abrir o chat
  const openChat = useCallback((initialMsg?: string) => {
    setIsOpen(true);
    if (initialMsg) {
      setInitialMessage(initialMsg);
    }
  }, []);
  
  // Função para fechar o chat
  const closeChat = useCallback(() => {
    setIsOpen(false);
    
    // Limpar a mensagem inicial ao fechar
    if (initialMessage) {
      setInitialMessage('');
    }
  }, [initialMessage]);
  
  // Função para iniciar uma nova sessão
  const startNewSession = useCallback(() => {
    setCurrentSessionId(null);
    setIsNewSession(true);
    setInitialMessage('');
  }, []);
  
  // Função para definir a sessão atual
  const setCurrentSession = useCallback((sessionId: string | null) => {
    setCurrentSessionId(sessionId);
    setIsNewSession(false);
    setInitialMessage('');
  }, []);
  
  return (
    <ChatContext.Provider value={{
      isOpen,
      initialMessage,
      currentSessionId,
      isNewSession,
      openChat,
      closeChat,
      startNewSession,
      setCurrentSession,
      setInitialMessage,
      isOnChatPage: (pathname: string) => pathname === '/chat' || pathname.startsWith('/chat/')
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext; 