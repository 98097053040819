import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { User, Session } from '@supabase/supabase-js';
import { supabase } from '../infra/supabaseClient';
import { getServiceLocator } from '../infra/serviceLocator';

export type UserPlan = 'basic' | 'essential' | 'premium';

interface AuthContextType {
  user: User | null;
  session: Session | null;
  loading: boolean;
  signOut: () => Promise<void>;
  currentPlan: UserPlan;
  hasAccessToContent: (requiredPlan: UserPlan) => boolean;
  isLoading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, name: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUserProfile: (data: Partial<User>) => Promise<void>;
  refreshUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  // Valor mockado do plano do usuário para desenvolvimento
  const [currentPlan, setCurrentPlan] = useState<UserPlan>('premium');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const updateAuthState = (newSession: Session | null) => {
    setSession(newSession);
    setUser(newSession?.user || null);
    
    if (newSession?.user?.id) {
      // Armazenar o userId no localStorage para persistência
      localStorage.setItem('userId', newSession.user.id);
      
      // Armazenar o token de acesso no localStorage
      if (newSession.access_token) {
        localStorage.setItem('authToken', newSession.access_token);
      }
    } else {
      // Remover dados de autenticação quando não há sessão
      localStorage.removeItem('userId');
      localStorage.removeItem('authToken');
    }
  };

  useEffect(() => {
    let mounted = true;
    
    const getInitialSession = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        
        if (mounted) {
          updateAuthState(data.session);
          setLoading(false);
        }
      } catch (error) {
        console.error(`[${new Date().toISOString()}] Erro ao obter sessão inicial:`, error);
        if (mounted) {
          setLoading(false);
        }
      }
    };

    getInitialSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, newSession) => {        
        if (!mounted) return;
        
        if (event === 'SIGNED_IN' || event === 'USER_UPDATED') {
          updateAuthState(newSession);
          setLoading(false);
        } else if (event === 'SIGNED_OUT') {
          setSession(null);
          setUser(null);
          setLoading(false);
        }
      }
    );

    return () => {
      mounted = false;
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  // Função para verificar se o usuário tem acesso ao conteúdo com base no plano requerido
  const hasAccessToContent = (requiredPlan: UserPlan): boolean => {
    const planHierarchy: Record<UserPlan, number> = {
      'basic': 1,
      'essential': 2,
      'premium': 3
    };
    
    return planHierarchy[currentPlan] >= planHierarchy[requiredPlan];
  };

  const login = async (email: string, password: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      
      if (error) throw error;
      
      if (data.user) {
        // Atualizar o userId no ServiceLocator para o MoodService
        import('../infra/serviceLocator').then(({ getServiceLocator }) => {
          getServiceLocator().updateUserId(data.user.id);
        }).catch(err => {
          console.error('Erro ao atualizar userId no ServiceLocator:', err);
        });
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao fazer login';
      setError(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const register = async (email: string, password: string, name: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signUp({ 
        email, 
        password,
        options: {
          data: {
            name
          }
        }
      });
      if (error) throw error;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao registrar';
      setError(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await supabase.auth.signOut();
      // Remover dados de autenticação
      localStorage.removeItem('userId');
      localStorage.removeItem('authToken');
    } catch (err) {
      console.error('Erro ao fazer logout:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async (email: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) throw error;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao resetar senha';
      setError(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserProfile = async (data: Partial<User>): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      // Implementação de atualização de perfil
      console.log('Atualizando perfil:', data);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Erro ao atualizar perfil';
      setError(errorMessage);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const refreshUser = async (): Promise<void> => {
    if (!user) {
      setError('Nenhum usuário autenticado para atualizar');
      return;
    }
    
    try {
      setIsLoading(true);
      setError(null);
      
      // Obter os dados atualizados do usuário do Supabase
      const { data: userData, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        throw userError;
      }
      
      if (!userData.user) {
        throw new Error('Usuário não encontrado');
      }
      
      // Atualizar o userId no ServiceLocator para o MoodService
      import('../infra/serviceLocator').then(({ getServiceLocator }) => {
        getServiceLocator().updateUserId(userData.user.id);
      }).catch(err => {
        console.error('Erro ao atualizar userId no ServiceLocator:', err);
      });
      
      // Buscar plano ativo do usuário usando o serviço
      const userPlanService = getServiceLocator().getUserPlanService();
      
      try {
        const activePlan = await userPlanService.getUserActivePlan(userData.user.id);
        
        // Atualizar o plano atual do usuário
        if (activePlan?.planId) {
          const newPlan = activePlan.planId || 'basic';
          setCurrentPlan(newPlan as UserPlan);
        }
      } catch (planError) {
        console.error('Erro ao buscar plano do usuário:', planError);
        // Continua a execução mesmo com erro no plano
      }
      
      // Atualizar o usuário no estado
      setUser(userData.user);
      
      // Se houver uma sessão ativa, atualizá-la também
      const { data: sessionData } = await supabase.auth.getSession();
      if (sessionData.session) {
        setSession(sessionData.session);
      }
      
    } catch (error) {
      console.error('Erro ao atualizar dados do usuário:', error);
      const errorMessage = error instanceof Error ? error.message : 'Erro ao atualizar dados do usuário';
      setError(errorMessage);
      throw error; // Propaga o erro para quem chamou a função
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    user,
    session,
    loading,
    signOut,
    currentPlan,
    hasAccessToContent,
    isLoading,
    error,
    login,
    register,
    logout,
    resetPassword,
    updateUserProfile,
    refreshUser
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
