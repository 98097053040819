import { ChatAIResponse, ChatSessionsResponse, ChatSessionMessagesResponse } from '../types';
import { getChatAIService } from './serviceLocator';

/**
 * Envia uma mensagem para a API do chat e retorna a resposta após o processo de polling
 * @param message - Mensagem do usuário para enviar ao chatbot
 * @param sessionId - ID opcional da sessão existente para continuar a conversa
 * @param createNewSession - Flag para indicar se deve criar uma nova sessão
 * @param extraAttempts - Número adicional de tentativas para o polling (para mensagens reenviadas)
 * @returns Resposta da API de chat após o processamento completo
 */
export const sendChatMessage = async (
  message: string, 
  sessionId?: string, 
  createNewSession?: boolean,
  extraAttempts?: number
): Promise<ChatAIResponse> => {
  const chatAIService = getChatAIService();
  return await chatAIService.sendMessage(message, sessionId, createNewSession, extraAttempts);
};

/**
 * Obtém todas as sessões de chat do usuário
 * @returns Lista de sessões de chat
 */
export const getChatSessions = async (): Promise<ChatSessionsResponse> => {
  const chatAIService = getChatAIService();
  return await chatAIService.getSessions();
};

/**
 * Obtém todas as mensagens de uma sessão específica
 * @param sessionId - ID da sessão para buscar as mensagens
 * @returns Lista de mensagens da sessão
 */
export const getChatSessionMessages = async (sessionId: string): Promise<ChatSessionMessagesResponse> => {
  const chatAIService = getChatAIService();
  return await chatAIService.getSessionMessages(sessionId);
};

/**
 * Analisa o humor a partir de uma mensagem de texto
 * @param message - Mensagem do usuário para analisar
 * @returns Análise de humor ou null se não for possível analisar
 */
export const analyzeMoodFromText = async (message: string): Promise<ChatAIResponse | null> => {
  const chatAIService = getChatAIService();
  return await chatAIService.analyzeMood(message);
};

/**
 * Busca dados do histórico de humor através do chat
 * @param query - Consulta para buscar no histórico
 * @returns Resposta da IA com os dados encontrados
 */
export const searchMoodHistory = async (query: string): Promise<ChatAIResponse> => {
  const chatAIService = getChatAIService();
  return await chatAIService.searchMoodHistory(query);
};

/**
 * Recebe sugestões personalizadas da IA baseadas no histórico
 * @returns Resposta da IA com sugestões
 */
export const getAISuggestions = async (): Promise<ChatAIResponse> => {
  const chatAIService = getChatAIService();
  return await chatAIService.getSuggestions();
}; 