/* eslint-disable @typescript-eslint/no-explicit-any */
import { User, Session } from '@supabase/supabase-js';
import { MoodEntry, HealthRecord } from '../types';
import { supabase } from './supabaseClient';
import { v4 as uuidv4 } from 'uuid';

// Register for push notifications
export const registerPush = async (fcmToken: string, userId: string | undefined): Promise<any> => {
  return await supabase.functions.invoke('register-push', {
    body: {
      subscription: fcmToken, // Enviamos o token FCM
      user_id: userId
    }
  })
};

// Get current session
export const getCurrentSession = async (): Promise<Session | null> => {
  const { data } = await supabase.auth.getSession();
  return data.session;
};

// Get current user
export const getCurrentUser = async (): Promise<User | null> => {
  const { data } = await supabase.auth.getUser();
  return data.user;
};

// Função para criar um hash irreversível do email usando a Web Crypto API
export const hashEmail = async (email: string): Promise<string> => {
  try {
    // Normalizar o email para consistência
    const normalizedEmail = email.toLowerCase().trim();
    
    // Salt para aumentar a segurança (em produção, use um salt secreto e mais forte)
    const salt = 'mindful-moments-email-security-salt';
    
    // Criar o texto completo para hash (email + salt)
    const textToHash = `${normalizedEmail}${salt}`;
    
    // Converter para ArrayBuffer usando TextEncoder
    const encoder = new TextEncoder();
    const data = encoder.encode(textToHash);
    
    // Usar Web Crypto API para gerar o hash SHA-256
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    
    // Converter para formato hexadecimal
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
    // Retornar o hash formatado como um email válido
    return `anon_${hashHex}@hashed.com`;
  } catch (error) {
    console.error('Erro ao gerar hash do email:', error);
    
    // Implementação alternativa em caso de falha da Web Crypto API
    let hash = 0;
    const normalizedEmail = email.toLowerCase().trim();
    const salt = 'mindful-moments-email-security-salt';
    const textToHash = `${normalizedEmail}${salt}`;
    
    for (let i = 0; i < textToHash.length; i++) {
      const char = textToHash.charCodeAt(i);
      hash = ((hash << 5) - hash) + char;
      hash = hash & hash; // Converter para um inteiro de 32 bits
    }
    
    return `anon_${Math.abs(hash).toString(16)}_${Date.now().toString(16)}@hashed.com`;
  }
};

// Function to find the hashed email for a given regular email
export const findHashedEmail = async (email: string): Promise<string | null> => {
  try {
    // Primeiro, tente usar a tabela de mapeamento de emails (em uma implementação real)
    // Para este exemplo, tentaremos usar o localStorage como uma solução temporária
    const loginEmail = localStorage.getItem('login_email');
    
    if (loginEmail === email) {
      // Verificar se já existe uma sessão ativa para obter o email hasheado
      const session = await getCurrentSession();
      if (session?.user) {
        return session.user.email || null;
      }
      
      // Se não houver sessão, gerar o hash usando o algoritmo atual
      return await hashEmail(email);
    }
    
    // Se não encontrar no localStorage, verificar se o email parece ser um hash
    if (email.startsWith('anon_') || email.endsWith('@hashed.com')) {
      return email; // O email já é um hash, retornar como está
    }
    
    // Se não houver mapeamento, gerar um novo hash
    return await hashEmail(email);
  } catch (error) {
    console.error('Erro ao buscar email hasheado:', error);
    return null;
  }
};

// Function to log in a user with email and password
export const loginUser = async (email: string, password: string): Promise<User> => {
  try {
    // Verificar se é o primeiro login ou se já temos o email hasheado
    const hashedEmail = await findHashedEmail(email);
    
    // Primeiro, tentar com o email hasheado
    let loginResult = await attemptLogin(hashedEmail || '', password);
    
    // Se falhar e estamos usando um email hasheado, tentar com o email original
    if (!loginResult.success && hashedEmail !== email) {
      console.log('Login com email hasheado falhou, tentando com email original');
      loginResult = await attemptLogin(email, password);
    }
    
    // Se ainda falhar, lançar erro
    if (!loginResult.success) {
      throw new Error(loginResult.error || 'Falha ao fazer login');
    }
    
    // Se o login foi bem-sucedido com email original, salvar para uso futuro
    if (loginResult.success && loginResult.emailUsed === email) {
      localStorage.setItem('login_email', email);
    }
    
    return loginResult.user!;
  } catch (error) {
    console.error('Erro ao fazer login:', error);
    throw error;
  }
};

// Função auxiliar para tentar login com um determinado email
const attemptLogin = async (email: string, password: string): Promise<{
  success: boolean;
  user?: User;
  error?: string;
  emailUsed: string;
}> => {
  if (!email) {
    return { success: false, error: 'Email inválido', emailUsed: '' };
  }
  
  try {
    const { data, error } = await supabase.auth.signInWithPassword({ 
      email, 
      password 
    });
    
    if (error) {
      return { 
        success: false, 
        error: error.message,
        emailUsed: email
      };
    }
    
    if (!data.user) {
      return { 
        success: false, 
        error: 'Usuário não encontrado',
        emailUsed: email
      };
    }
    
    return {
      success: true,
      user: data.user,
      emailUsed: email
    };
  } catch (error) {
    return {
      success: false,
      error: (error as Error).message,
      emailUsed: email
    };
  }
};

// Function to create a new user with anonymized email
export const createUser = async (email: string, password: string): Promise<User> => {
  // Gerar um hash do email para armazenamento
  const hashedEmail = await hashEmail(email);
  
  // Criar um registro no metadata que indica que este é um usuário anônimo
  const { data, error } = await supabase.auth.signUp({ 
    email: hashedEmail, 
    password,
    options: {
      data: {
        is_anonymous: true,
        anonymized_at: new Date().toISOString(),
        // Não armazenamos nenhum traço do email original, apenas um indicador de que foi anonimizado
        email_anonymized: true
      }
    }
  });
  
  if (error) throw new Error(error.message);
  if (!data.user) throw new Error('Falha ao criar usuário');
  
  // Salvar o email original de forma segura para login
  localStorage.setItem('login_email', email);
  
  return data.user;
};

// Sign out the current user
export const signOut = async (): Promise<void> => {
  const { error } = await supabase.auth.signOut();
  if (error) throw new Error(error.message);
};

// Check if user is authenticated
export const isAuthenticated = async (): Promise<boolean> => {
  const session = await getCurrentSession();
  return !!session;
};

// Reexportar funções úteis relacionadas ao Auth
export const getSession = async (): Promise<Session | null> => {
  try {
    const { data } = await supabase.auth.getSession();
    return data.session;
  } catch (error) {
    console.error('Erro ao obter sessão:', error);
    return null;
  }
};

export const getUser = async (): Promise<User | null> => {
  try {
    const { data } = await supabase.auth.getUser();
    return data.user;
  } catch (error) {
    console.error('Erro ao obter usuário:', error);
    return null;
  }
};

// Function to update an existing mood entry
export const updateMoodEntry = async (entry: MoodEntry): Promise<void> => {
  console.log('Atualizando entrada:', entry);
  
  // Garantir que temos um usuário autenticado
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');
  
  // Primeiro, obter a entrada atual do banco de dados
  const { data: currentEntry, error: fetchError } = await supabase
    .from('mood_entries')
    .select('*')
    .eq('id', entry.id)
    .single();
    
  if (fetchError) {
    console.error('Erro ao buscar entrada atual:', fetchError);
    throw new Error(`Error fetching current entry: ${fetchError.message}`);
  }
  
  // Mesclar a entrada atual com as atualizações, preservando campos que não estão sendo atualizados
  const updatedEntry = {
    ...currentEntry,
    note: entry.note,
    score: entry.score,
    timestamp: entry.timestamp,
    ai_analysis: entry.ai_analysis,
    user_id: user.id
  };
  
  console.log('Entrada mesclada para atualização:', updatedEntry);
  
  // Atualizar a entrada no banco de dados
  const { error } = await supabase
    .from('mood_entries')
    .update(updatedEntry)
    .eq('id', entry.id);
  
  if (error) {
    console.error('Erro ao atualizar entrada:', error);
    throw new Error(`Error updating mood entry: ${error.message}`);
  }
  
  // Verificar se a atualização foi bem-sucedida
  const { data: updatedData, error: checkError } = await supabase
    .from('mood_entries')
    .select('*')
    .eq('id', entry.id)
    .single();
    
  console.log('Dados após atualização:', updatedData);
  
  if (checkError) {
    console.error('Erro ao verificar atualização:', checkError);
  }
};

// Function to save a health record
export const saveHealthRecord = async (content: string): Promise<HealthRecord> => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');
  
  const newRecord: HealthRecord = {
    id: uuidv4(),
    user_id: user.id,
    content,
    timestamp: Date.now()
  };
  
  const { error } = await supabase.from('health_records').insert(newRecord);
  if (error) throw new Error(`Error saving health record: ${error.message}`);
  
  return newRecord;
};

// Function to get health records for the current user
export const getHealthRecords = async (limit = 2): Promise<HealthRecord[]> => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');
  
  const { data, error } = await supabase
    .from('health_records')
    .select('*')
    .eq('user_id', user.id)
    .order('created_at', { ascending: false })
    .limit(limit);
    
  if (error) throw new Error(`Error fetching health records: ${error.message}`);
  return data as HealthRecord[];
};

// Function to update an existing health record
export const updateHealthRecord = async (recordId: string, content: string): Promise<HealthRecord> => {
  const user = await getCurrentUser();
  if (!user) throw new Error('User not authenticated');
  
  // Buscar o prontuário existente para verificar se pertence ao usuário
  const { data: existingRecord, error: fetchError } = await supabase
    .from('health_records')
    .select('*')
    .eq('id', recordId)
    .eq('user_id', user.id)
    .single();
    
  if (fetchError) throw new Error(`Error fetching health record: ${fetchError.message}`);
  if (!existingRecord) throw new Error('Health record not found or not owned by user');
  
  // Atualizar o prontuário
  const updatedRecord: HealthRecord = {
    ...existingRecord as HealthRecord,
    content,
    timestamp: Date.now() // Atualizar o timestamp para refletir a atualização
  };
  
  const { error } = await supabase
    .from('health_records')
    .update(updatedRecord)
    .eq('id', recordId);
    
  if (error) throw new Error(`Error updating health record: ${error.message}`);
  
  return updatedRecord;
};

// Function to get active plan for a user
export const getUserActivePlan = async (userId: string): Promise<{ planId: string } | null> => {
  try {
    const { data, error } = await supabase
      .from('user_plans')
      .select('plan_id')
      .eq('user_id', userId)
      .eq('status', 'active')
      .order('created_at', { ascending: false })
      .limit(1)
      .single();
    
    if (error) {
      console.error('Erro ao buscar plano ativo do usuário:', error);
      return null;
    }
    
    if (!data) {
      return null;
    }
    
    return { planId: data.plan_id };
  } catch (error) {
    console.error('Erro ao buscar plano ativo do usuário:', error);
    return null;
  }
};

// Function to migrate a user from non-hashed email to hashed format
export const migrateUserToHashedEmail = async (originalEmail: string, password: string): Promise<User | null> => {
  try {
    console.log('Iniciando migração de usuário para formato hasheado');
    
    // Primeiro, tentar login com email original
    const loginResult = await attemptLogin(originalEmail, password);
    
    // Se login falhar, retorna nulo
    if (!loginResult.success || !loginResult.user) {
      console.error('Falha ao autenticar usuário para migração');
      return null;
    }
    
    // Usuário autenticado com email original, precisamos migrar para o formato hasheado
    const hashedEmail = await hashEmail(originalEmail);
    
    console.log(`Usuário autenticado, migrando para email hasheado: ${hashedEmail}`);
    
    // Atualizar o email do usuário para a versão hasheada
    const { data: updateData, error: updateError } = await supabase.auth.updateUser({
      email: hashedEmail,
      data: {
        is_anonymous: true,
        anonymized_at: new Date().toISOString(),
        email_anonymized: true,
        migrated_from_unhashed: true,
        migration_date: new Date().toISOString()
      }
    });
    
    if (updateError) {
      console.error('Erro ao atualizar email do usuário:', updateError);
      return null;
    }
    
    // Salvar mapeamento para uso futuro
    localStorage.setItem('login_email', originalEmail);
    
    console.log('Migração de usuário concluída com sucesso');
    return updateData.user;
  } catch (error) {
    console.error('Erro ao migrar usuário:', error);
    return null;
  }
};

// Esta função decide se deve tentar migrar um usuário após uma tentativa de login
export const handleLoginWithMigration = async (email: string, password: string): Promise<User> => {
  try {
    // Primeiro tentar login normal
    try {
      return await loginUser(email, password);
    } catch (loginError) {
      console.log('Login normal falhou, verificando necessidade de migração');
      
      // Se falhou, verificar se o email não está no formato hasheado
      if (!email.includes('@hashed.com') && !email.startsWith('anon_')) {
        // Tentar migrar o usuário
        const migratedUser = await migrateUserToHashedEmail(email, password);
        
        if (migratedUser) {
          console.log('Usuário migrado com sucesso');
          return migratedUser;
        }
      }
      
      // Se não conseguiu migrar, repassar o erro original
      throw loginError;
    }
  } catch (error) {
    console.error('Erro no processo de login/migração:', error);
    throw error;
  }
};
