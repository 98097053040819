import React, { useEffect, useState } from 'react';
import { 
  requestNotificationPermission, 
  registerForPushNotifications, 
  checkNotificationStatus,
  forceServiceWorkerReinstallation,
} from '../infra/NotificationFacade';
import { Bell, RefreshCw, PlusCircle, Share2, X } from 'lucide-react';
import { shouldHideNotification, markNotificationAsDismissed, STORAGE_KEYS } from '../lib/utils';

// Componente para exibir instruções específicas para Safari no iOS
const SafariIOSInstructions: React.FC = () => {
  const [dismissed, setDismissed] = useState(false);
  
  // Verificar se já foi fechado recentemente
  useEffect(() => {
    const shouldHide = shouldHideNotification(STORAGE_KEYS.SAFARI_IOS_INSTRUCTIONS);
    if (shouldHide) {
      setDismissed(true);
    }
  }, []);
  
  const handleDismiss = () => {
    markNotificationAsDismissed(STORAGE_KEYS.SAFARI_IOS_INSTRUCTIONS);
    setDismissed(true);
  };
  
  if (dismissed) {
    return null;
  }
  
  return (
    <div className="bg-yellow-50 p-4 rounded-lg mb-6 border border-yellow-200">
      <div className="flex justify-between items-start">
        <div className="flex items-center mb-2">
          <PlusCircle className="text-yellow-600 mr-3" size={20} />
          <p className="text-sm text-yellow-800 font-medium">Adicione à tela inicial para receber notificações</p>
        </div>
        <button
          onClick={handleDismiss}
          className="text-yellow-500 hover:text-yellow-700 p-1"
          aria-label="Dispensar"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      <p className="text-xs text-yellow-700 mb-3">
        No Safari do iOS, as notificações só funcionam quando o aplicativo é adicionado à tela inicial.
        Siga os passos abaixo:
      </p>
      <ol className="text-xs text-yellow-700 mb-3 pl-5 list-decimal">
        <li className="mb-1">Toque no botão <Share2 className="inline h-3 w-3" /> de compartilhamento</li>
        <li className="mb-1">Role para baixo e toque em "Adicionar à Tela de Início"</li>
        <li>Após adicionar, abra o aplicativo a partir do ícone na tela inicial</li>
      </ol>
    </div>
  );
};

const NotificationPermission: React.FC = () => {
  const [status, setStatus] = useState<{
    pushSupported: boolean;
    permission: NotificationPermission | 'unsupported';
    serviceWorkerRegistered: boolean;
    subscription: boolean;
    registrationStatus: string;
    isSafariIOS: boolean;
    isHomeScreenApp: boolean;
  }>({
    pushSupported: false,
    permission: 'default',
    serviceWorkerRegistered: false,
    subscription: false,
    registrationStatus: '',
    isSafariIOS: false,
    isHomeScreenApp: false
  });

  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [reinstalling, setReinstalling] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  // Verificar o status inicial das notificações e se já foi fechado recentemente
  useEffect(() => {
    updateStatus();
    
    // Verificar se já foi fechado recentemente
    const shouldHide = shouldHideNotification(STORAGE_KEYS.NOTIFICATION_PERMISSION);
    if (shouldHide) {
      setDismissed(true);
    }
  }, []);

  // Função para atualizar o status de notificações
  const updateStatus = async () => {
    const statusInfo = await checkNotificationStatus();
    setStatus({
      ...statusInfo,
      registrationStatus: ''
    });
  };

  // Solicitar permissão para notificações
  const requestPermission = async () => {
    setLoading(true);
    try {
      const permission = await requestNotificationPermission();
      console.log('Permissão recebida:', permission);
      
      // Atualiza o status após obter a permissão
      await updateStatus();
      
      // Se a permissão foi concedida, registra automaticamente para push
      if (permission === 'granted') {
        console.log('Permissão concedida, registrando para push automaticamente');
        await registerForPush();
      }
    } catch (error) {
      console.error('Erro ao solicitar permissão:', error);
    } finally {
      setLoading(false);
    }
  };

  // Registrar para notificações push
  const registerForPush = async () => {
    setLoading(true);
    setStatus(prev => ({ ...prev, registrationStatus: 'Registrando para notificações push...' }));

    try {
      const result = await registerForPushNotifications();
      
      if (result.success) {
        setStatus(prev => ({ 
          ...prev, 
          registrationStatus: 'Registro para notificações push concluído com sucesso!' 
        }));
      } else {
        setStatus(prev => ({ 
          ...prev, 
          registrationStatus: 'Falha ao registrar para notificações push.' 
        }));
      }
      
      await updateStatus();
    } catch (error) {
      console.error('Erro ao registrar para notificações push:', error);
      setStatus(prev => ({ 
        ...prev, 
        registrationStatus: `Erro: ${error instanceof Error ? error.message : 'Erro desconhecido'}` 
      }));
    } finally {
      setLoading(false);
    }
  };

  // Forçar reinstalação do service worker
  const handleForceReinstall = async () => {
    setReinstalling(true);
    setStatusMessage('Reinstalando service worker...');
    
    try {
      const result = await forceServiceWorkerReinstallation();
      if (result) {
        setStatusMessage('Service worker reinstalado com sucesso! Atualizando status...');
        // Atualizar o status após a reinstalação
        await updateStatus();
        setStatusMessage('Pronto! Tente ativar as notificações novamente.');
      } else {
        setStatusMessage('Falha ao reinstalar o service worker. Tente recarregar a página.');
      }
    } catch (error) {
      console.error('Erro ao forçar reinstalação:', error);
      setStatusMessage(`Erro: ${error instanceof Error ? error.message : 'Erro desconhecido'}`);
    } finally {
      setReinstalling(false);
    }
  };

  // Função para lidar com o fechamento da notificação
  const handleDismiss = () => {
    markNotificationAsDismissed(STORAGE_KEYS.NOTIFICATION_PERMISSION);
    setDismissed(true);
  };

  // Verifica se notificações estão completamente ativas
  const isNotificationActive = status.permission === 'granted' && status.subscription;

  // Verifica se é Safari no iOS mas não está rodando como app de tela inicial
  const showSafariIOSInstructions = status.isSafariIOS && !status.isHomeScreenApp;

  if (dismissed) {
    return null;
  }

  if (isNotificationActive) {
    // Layout ativado
    return (
      <div className="bg-accent-100 p-4 rounded-lg mb-6">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <svg className="lucide lucide-bell text-accent-600 mr-3" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
              <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
            </svg>
            <div>
              <p className="text-sm text-neutral-700 font-medium">Notificações ativadas</p>
              <p className="text-xs text-neutral-600">
                Você receberá lembretes para registrar seu humor ao longo do dia.
              </p>
            </div>
          </div>
          <button
            onClick={handleDismiss}
            className="text-accent-500 hover:text-accent-700 p-1"
            aria-label="Dispensar"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <button
          onClick={handleForceReinstall}
          disabled={reinstalling}
          className="btn btn-outline btn-sm w-full mt-3 flex items-center justify-center gap-2"
        >
          <RefreshCw size={16} className={reinstalling ? 'animate-spin' : ''} />
          {reinstalling ? 'Ativando...' : 'Problemas com notificações? Clique aqui!'}
        </button>
        
        {statusMessage && (
          <div className="mt-2 text-xs text-neutral-600">
            {statusMessage}
          </div>
        )}
      </div>
    );
  }

  // Se for Safari no iOS e não estiver rodando como app de tela inicial, mostrar instruções específicas
  if (showSafariIOSInstructions) {
    return <SafariIOSInstructions />;
  }

  // Layout não ativado (para outros navegadores ou Safari no iOS rodando como app de tela inicial)
  return (
    <div className="bg-primary-50 p-4 rounded-lg mb-6">
      <div className="flex justify-between items-start">
        <div className="flex items-center mb-2">
          <Bell className="lucide lucide-bell text-primary-600 mr-3" />
          <p className="text-sm text-neutral-700 font-medium">Ative as notificações</p>
        </div>
        <button
          onClick={handleDismiss}
          className="text-primary-500 hover:text-primary-700 p-1"
          aria-label="Dispensar"
        >
          <X className="h-5 w-5" />
        </button>
      </div>
      <p className="text-xs text-neutral-600 mb-3">
        Receba lembretes para registrar seu humor ao longo do dia, mesmo quando não estiver usando o aplicativo.
      </p>
      
      <button
        onClick={status.permission !== 'granted' ? requestPermission : registerForPush}
        disabled={loading || !status.pushSupported}
        className="btn btn-primary btn-sm w-full"
      >
        {loading ? 'Processando...' : 
          status.permission !== 'granted' 
            ? 'Permitir notificações' 
            : 'Registrar para lembretes'}
      </button>
      
      {status.registrationStatus && (
        <div className="mt-2 text-xs text-neutral-600">
          {status.registrationStatus}
        </div>
      )}
    </div>
  );
};

export default NotificationPermission;