import React, { memo } from 'react';
import { Lock, Clock, CheckCircle, Tag } from 'lucide-react';
import { motion } from 'framer-motion';
import { Article } from '../types';

interface ContentCardProps {
  article: Article;
  onClick: () => void;
  index?: number;
}

const ContentCard: React.FC<ContentCardProps> = ({ article, onClick, index = 0 }) => {
  // Extract a short excerpt from the content
  const getExcerpt = (content: string): string => {
    const maxLength = 120;
    if (content.length <= maxLength) return content;
    return content.substring(0, maxLength).trim() + '...';
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      whileHover={{ y: -4, boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9fafb' }}
      className="content-card"
      onClick={onClick}
      role="article"
      aria-label={`Artigo: ${article.title}`}
    >
      <div className="flex justify-between items-start mb-3 gap-2">
        <h3 className="text-lg xl:text-xl font-medium text-neutral-800 leading-tight flex-grow truncate-text">{article.title}</h3>
        {article.isPremium && (
          <span className="bg-primary-100 text-primary-700 px-2 py-1 rounded-full text-xs flex items-center whitespace-nowrap flex-shrink-0">
            <Lock size={12} className="mr-1" />
            {article.requiredPlan}
          </span>
        )}
      </div>
      
      <p className="text-neutral-600 text-sm xl:text-base mb-3 leading-relaxed line-clamp-2">
        {getExcerpt(article.content)}
      </p>
      
      <div className="flex flex-wrap items-center justify-between mt-3 mb-2 gap-2">
        <span className="text-xs xl:text-sm font-medium bg-neutral-100 text-neutral-700 px-2 py-1 rounded-md flex items-center">
          CID: <span className="font-semibold ml-1">{article.diseaseCode}</span>
          <a 
            href={`https://icd.who.int/browse10/2019/en#/${article.diseaseCode}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-600 ml-2 hover:underline flex items-center"
            onClick={(e) => e.stopPropagation()}
            title="Ver detalhes no CID-10"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg>
          </a>
        </span>
        {article.readingTime && (
          <span className="text-xs xl:text-sm text-neutral-500 flex items-center">
            <Clock size={12} className="mr-1" />
            {article.readingTime} min
          </span>
        )}
      </div>
      
      <div className="flex flex-wrap gap-2 w-full">
        {article.tags.slice(0, 3).map((tag) => (
          <span 
            key={tag} 
            className="bg-neutral-100 text-neutral-600 px-2 py-1 rounded-full text-xs xl:text-sm flex items-center"
          >
            <Tag size={10} className="mr-1" />
            {tag}
          </span>
        ))}
        {article.tags.length > 3 && (
          <span className="text-neutral-500 text-xs xl:text-sm flex items-center">
            +{article.tags.length - 3} mais
          </span>
        )}
      </div>
      
      <div className="flex flex-wrap items-center justify-between mt-4 pt-3 border-t border-neutral-100 w-full gap-2">
        {article.expertReviewed && (
          <div className="text-xs xl:text-sm text-green-600 flex items-center">
            <CheckCircle size={12} className="mr-1" />
            Revisado por especialistas
          </div>
        )}
        <div className="text-xs xl:text-sm text-neutral-500 italic">
          Clique para ler o artigo completo
        </div>
      </div>
    </motion.div>
  );
}

export default memo(ContentCard);
