import { MoodService } from './services/MoodService';
import { HealthRecordService } from './services/HealthRecordService';
import { INotificationService } from './services/interfaces/INotificationService';
import { FirebaseNotificationService } from './services/FirebaseNotificationService';
import { IUserPlanService } from './services/interfaces/IUserPlanService';
import { IUserPlanRepository } from './repositories/interfaces/IUserPlanRepository';
import { SupabaseUserPlanRepository } from './repositories/SupabaseUserPlanRepository';
import { UserPlanService } from './services/UserPlanService';
import { WeeklySummaryService } from './services/WeeklySummaryService';
import OnBoardingService from './services/OnBoardingService';
import { ChatAIService } from './services/ChatAIService';

// Singleton para gerenciar as instâncias dos repositórios e serviços
class ServiceLocator {
  private static instance: ServiceLocator;
  
  private notificationService: INotificationService;
  private moodService: MoodService;
  private healthRecordService: HealthRecordService;
  private userPlanRepository: IUserPlanRepository;
  private userPlanService: UserPlanService;
  private weeklySummaryService: WeeklySummaryService;
  private onBoardingService: typeof OnBoardingService;
  private chatAIService: ChatAIService;
  private userId: string = '';
  
  private constructor() {    
    // Inicializa o serviço de notificações
    this.notificationService = new FirebaseNotificationService({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY || '',
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || '',
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || '',
      storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || '',
      messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || '',
      appId: import.meta.env.VITE_FIREBASE_APP_ID || ''
    });
    
    // Inicializa os serviços com as dependências
    this.moodService = new MoodService();
    this.healthRecordService = new HealthRecordService();
    this.chatAIService = new ChatAIService();
    
    // Inicializa o repositório e serviço de planos
    this.userPlanRepository = new SupabaseUserPlanRepository();
    this.userPlanService = new UserPlanService(this.userPlanRepository);
    this.weeklySummaryService = new WeeklySummaryService();
    this.onBoardingService = OnBoardingService;
    
    // Tenta recuperar o userId do localStorage
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      this.userId = storedUserId;
    }
  }
  
  public static getInstance(): ServiceLocator {
    if (!ServiceLocator.instance) {
      ServiceLocator.instance = new ServiceLocator();
    }
    return ServiceLocator.instance;
  }
  
  public getMoodService(): MoodService {
    return this.moodService;
  }
  
  public getHealthRecordService(): HealthRecordService {
    return this.healthRecordService;
  }
  
  public getNotificationService(): INotificationService {
    return this.notificationService;
  }

  public getWeeklySummaryService(): WeeklySummaryService {
    return this.weeklySummaryService;
  }
  
  public getUserPlanService(): IUserPlanService {
    return this.userPlanService;
  }
  
  public getOnBoardingService() {
    return this.onBoardingService;
  }
  
  public getChatAIService(): ChatAIService {
    return this.chatAIService;
  }
  
  // Apenas para testes/mock
  public setMoodRepository(): void {
    this.moodService = new MoodService();
    this.healthRecordService = new HealthRecordService();
  }
  
  public setHealthRecordRepository(): void {
    this.healthRecordService = new HealthRecordService();
  }
  
  public setNotificationService(service: INotificationService): void {
    this.notificationService = service;
  }
  
  public setUserPlanRepository(repository: IUserPlanRepository): void {
    this.userPlanRepository = repository;
    this.userPlanService = new UserPlanService(this.userPlanRepository);
  }  
  
  public updateUserId(userId: string): void {
    // Armazenar o userId no localStorage para persistência
    if (userId) {
      localStorage.setItem('userId', userId);
      this.userId = userId;
    }
    
    // Atualizar o userId nos serviços
    this.moodService.setUserId(userId);
    this.chatAIService.setUserId(userId);
  }
}

// Exporta funções auxiliares para obter os serviços
export const getMoodService = (): MoodService => {
  return ServiceLocator.getInstance().getMoodService();
};

export const getHealthRecordService = (): HealthRecordService => {
  return ServiceLocator.getInstance().getHealthRecordService();
};

export const getNotificationService = (): INotificationService => {
  return ServiceLocator.getInstance().getNotificationService();
};

export const getWeeklySummaryService = (): WeeklySummaryService => {
  return ServiceLocator.getInstance().getWeeklySummaryService();
};

export const getUserPlanService = (): IUserPlanService => {
  return ServiceLocator.getInstance().getUserPlanService();
};

export const getOnBoardingService = () => {
  return ServiceLocator.getInstance().getOnBoardingService();
};

export const getChatAIService = (): ChatAIService => {
  return ServiceLocator.getInstance().getChatAIService();
};

// Exporta a instância do ServiceLocator para testes/mock
export const getServiceLocator = (): ServiceLocator => {
  return ServiceLocator.getInstance();
}; 