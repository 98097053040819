import React, { useEffect, useState, useCallback, useRef } from 'react';
import { generateWeeklySummary, getMoodEmoji } from '../infra/utils/moodUtils';
import type { WeeklySummary as WeeklySummaryType, MoodEntry } from '../types';
import { getWeeklySummaryService } from '../infra/serviceLocator';
import { Loader2, ChevronDown, ChevronUp, Sparkles, Brain } from 'lucide-react';
import { WeeklySummaryResponse } from '../infra/services/WeeklySummaryService';

interface WeeklySummaryProps {
  entries?: MoodEntry[];
  loading?: boolean;
}

const WeeklySummary = ({ entries, loading: propLoading = false }: WeeklySummaryProps): JSX.Element => {
  const [summary, setSummary] = useState<WeeklySummaryType | null>(null);
  const [loading, setLoading] = useState(propLoading);
  const [recommendation, setRecommendation] = useState<string | null>(null);
  const [loadingRecommendation, setLoadingRecommendation] = useState(false);
  const [pollingStatus, setPollingStatus] = useState<string | null>(null);
  const [isPollingActive, setIsPollingActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [summaryPeriod, setSummaryPeriod] = useState<{ startDate: string; endDate: string } | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRY_COUNT = 3;
  
  // Refs para controle de chamadas à API
  const apiCallInProgressRef = useRef(false);
  const apiCallCompletedRef = useRef(false);
  const unmountedRef = useRef(false);

  // Função para buscar o resumo básico
  const fetchBasicSummary = useCallback(async () => {
    try {
      setLoading(true);
      let data;
      
      if (entries && entries.length > 0) {
        data = await generateWeeklySummary(entries);
      } else {
        data = await generateWeeklySummary();
      }
      
      setSummary(data);
    } catch (error) {
      console.error('Erro ao carregar o resumo semanal:', error);
    } finally {
      setLoading(false);
    }
  }, [entries]);

  // Função para buscar a recomendação personalizada via API
  const fetchRecommendation = useCallback(async () => {
    // Sistema robusto para evitar chamadas duplicadas
    if (apiCallInProgressRef.current) {
      console.log('Chamada à API já em andamento - ignorando solicitação duplicada');
      return;
    }
    
    if (apiCallCompletedRef.current) {
      console.log('Chamada à API já foi concluída com sucesso - ignorando solicitação duplicada');
      return;
    }
    
    // Marcar início da chamada
    apiCallInProgressRef.current = true;
    
    try {
      setIsPollingActive(true);
      setLoadingRecommendation(true);
      setPollingStatus("Gerando insights com IA...");
      
      console.log('Iniciando solicitação de insights de IA');
      const response = await getWeeklySummaryService().getWeeklySummary();
      
      // Verificar se o componente foi desmontado durante a chamada
      if (unmountedRef.current) {
        console.log('Componente desmontado durante a chamada à API - abortando processamento');
        return;
      }
      
      // Verificar se temos uma resposta com texto e metadata
      if (response && typeof response === 'object') {
        const apiResponse = response as WeeklySummaryResponse;
        if (apiResponse.summary) {
          console.log('Insights de IA obtidos com sucesso:', apiResponse.summary.substring(0, 50) + '...');
          
          // Atualizar estados com os dados recebidos
          setRecommendation(apiResponse.summary);
          setRequestId(apiResponse.requestId);
          setSummaryPeriod({ 
            startDate: apiResponse.startDate, 
            endDate: apiResponse.endDate 
          });
          
          // Limpar estado de polling quando a resposta for recebida com sucesso
          setPollingStatus(null);
          setIsPollingActive(false);
          setLoadingRecommendation(false);
          // Resetar o contador de tentativas após sucesso
          setRetryCount(0);
          
          // Marcar que a chamada foi concluída com sucesso
          apiCallCompletedRef.current = true;
        } else {
          // Se não tiver resumo, reiniciar o processo
          handlePollingFailure("Resposta sem conteúdo de insights");
        }
      } else if (typeof response === 'string') {
        // Compatibilidade com versão anterior que retornava apenas string
        console.log('Insights de IA obtidos com sucesso:', response.substring(0, 50) + '...');
        setRecommendation(response);
        
        // Limpar estado de polling quando a resposta for recebida com sucesso
        setPollingStatus(null);
        setIsPollingActive(false);
        setLoadingRecommendation(false);
        // Resetar o contador de tentativas após sucesso
        setRetryCount(0);
        
        // Marcar que a chamada foi concluída com sucesso
        apiCallCompletedRef.current = true;
      } else {
        // Se a resposta for vazia, reiniciar o processo
        handlePollingFailure("Resposta vazia da API");
      }
    } catch (error) {
      // Verificar se o componente foi desmontado durante a chamada
      if (unmountedRef.current) {
        console.log('Componente desmontado durante a chamada à API - abortando processamento de erro');
        return;
      }
      
      console.error('Erro ao obter insights de IA:', error);
      
      if (error instanceof Error) {
        const errorMessage = error.message;
        
        if (errorMessage.includes('processamento assíncrono') || 
            errorMessage.includes('Já existe um processamento')) {
          // Erro normal durante processamento assíncrono - manter mensagem de status
          console.log('Processamento assíncrono em andamento');
          setPollingStatus("Processando insights de IA para seu histórico. Isso pode levar alguns segundos...");
          
          // Manter estado de polling ativo, mas liberar para novas tentativas após timeout
          setTimeout(() => {
            if (unmountedRef.current) return;
            
            if (isPollingActive) {
              console.log('Timeout do polling atual, reiniciando processo...');
              setIsPollingActive(false);
              apiCallInProgressRef.current = false; // Permitir nova tentativa
              // Não limpar o pollingStatus para indicar que ainda estamos tentando
            }
          }, 30000); // 30 segundos timeout
        } else {
          // Para outros erros, reiniciar o processo
          handlePollingFailure(`Erro: ${errorMessage}`);
        }
      } else {
        handlePollingFailure("Erro desconhecido");
      }
    }
  }, []);

  // Função para lidar com falhas no polling e decidir se tenta novamente
  const handlePollingFailure = useCallback((reason: string) => {
    // Verificar se o componente foi desmontado
    if (unmountedRef.current) return;
    
    console.log(`Falha no polling: ${reason}. Tentativa ${retryCount + 1} de ${MAX_RETRY_COUNT}`);
    
    // Limpar estados atuais
    setIsPollingActive(false);
    
    // Incrementar contador de tentativas
    const newRetryCount = retryCount + 1;
    setRetryCount(newRetryCount);
    
    if (newRetryCount < MAX_RETRY_COUNT) {
      // Se ainda temos tentativas disponíveis, preparar para retry
      setPollingStatus(`Reconectando ao serviço de IA... (tentativa ${newRetryCount} de ${MAX_RETRY_COUNT})`);
      
      // Agendar nova tentativa após um breve delay
      setTimeout(() => {
        if (unmountedRef.current) return;
        
        setLoadingRecommendation(true);
        apiCallInProgressRef.current = false; // Permitir nova tentativa
        fetchRecommendation();
      }, 3000); // 3 segundos antes da próxima tentativa
    } else {
      // Esgotamos as tentativas
      setPollingStatus("Não foi possível conectar ao serviço de IA no momento. Tente novamente mais tarde.");
      setLoadingRecommendation(false);
      
      // Liberar para potencial nova tentativa manual
      apiCallInProgressRef.current = false;
      
      // Resetar após um tempo para permitir nova tentativa manual
      setTimeout(() => {
        if (unmountedRef.current) return;
        
        setPollingStatus(null);
        setRetryCount(0);
      }, 5000);
    }
  }, [retryCount, fetchRecommendation]);

  // Efeito para verificar se devemos invalidar o cache quando as entradas mudarem
  useEffect(() => {
    if (entries && entries.length > 0) {
      // Usar o novo método que compara os registros de humor completos
      getWeeklySummaryService().shouldInvalidateCache(entries);
    }
  }, [entries]);

  // Carregar dados básicos do resumo quando o componente montar ou entries mudar
  useEffect(() => {
    unmountedRef.current = false;
    fetchBasicSummary();
    
    // Cleanup ao desmontar
    return () => {
      unmountedRef.current = true;
    };
  }, [fetchBasicSummary]);

  // Iniciar solicitação de recomendação quando o resumo básico estiver pronto
  useEffect(() => {
    if (summary && !apiCallInProgressRef.current && !apiCallCompletedRef.current && !recommendation) {
      console.log('Resumo básico carregado, iniciando solicitação única de insights de IA');
      fetchRecommendation();
    }
  }, [summary, recommendation, fetchRecommendation]);

  // Efeito para verificar periodicamente se o polling está preso
  useEffect(() => {
    // Se estamos em um estado de polling ativo por muito tempo, podemos ter perdido alguma resposta
    // Criar um timer para verificar e potencialmente reiniciar o polling
    let pollingCheckTimer: NodeJS.Timeout | null = null;
    
    if (isPollingActive && apiCallInProgressRef.current && !apiCallCompletedRef.current) {
      pollingCheckTimer = setTimeout(() => {
        if (unmountedRef.current) return;
        
        console.log('Verificação periódica de polling ativo por tempo excessivo');
        // Força reset do polling apenas se ainda estiver ativo após o timeout
        if (isPollingActive && apiCallInProgressRef.current && !apiCallCompletedRef.current) {
          console.log('Polling ativo por muito tempo, reiniciando processo');
          setIsPollingActive(false);
          apiCallInProgressRef.current = false;
          
          // Agendar uma nova tentativa
          setTimeout(() => {
            if (unmountedRef.current) return;
            
            if (!recommendation && !apiCallCompletedRef.current) {
              fetchRecommendation();
            }
          }, 1000);
        }
      }, 60000); // Verificar após 60 segundos
    }
    
    // Limpar o timer quando o componente for desmontado ou o estado mudar
    return () => {
      if (pollingCheckTimer) {
        clearTimeout(pollingCheckTimer);
      }
    };
  }, [isPollingActive, recommendation, fetchRecommendation]);

  if (loading) {
    return (
      <div className="card mb-6 w-full">
        <div className="flex flex-col items-center justify-center py-4">
          <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-2" />
          <p className="text-neutral-600">Carregando...</p>
        </div>
      </div>
    );
  }

  if (!summary) return <></>;

  // Se não tiver dados
  if (summary.averageMood === -1) {
    return (
      <div className="card mb-6 w-full">
        <h3 className="text-lg md:text-xl font-medium text-neutral-700 mb-2">Resumo Semanal</h3>
        <p className="text-neutral-600">
          Comece a registrar seu humor para ver um resumo semanal aqui.
        </p>
      </div>
    );
  }
  
  // Formatar período do resumo se disponível
  const formatPeriodDate = (dateStr: string) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
  };
  
  const periodText = summaryPeriod 
    ? `${formatPeriodDate(summaryPeriod.startDate)} a ${formatPeriodDate(summaryPeriod.endDate)}`
    : 'Últimos 7 dias';
  
  return (
    <div className="card mb-6 w-full">
      <h3 className="text-lg md:text-xl font-medium text-neutral-700 mb-3">Resumo Semanal</h3>
      
      <div className="flex items-center mb-4">
        <div className="bg-primary-100 rounded-full p-3 md:p-4 mr-3">
          <span className="text-2xl md:text-3xl">{getMoodEmoji(Math.round(summary.averageMood))}</span>
        </div>
        <div>
          <p className="text-neutral-700 font-medium md:text-lg">
            Humor médio: {summary.averageMood.toFixed(1)}/5
          </p>
          <p className="text-sm md:text-base text-neutral-500">
            {periodText}
          </p>
        </div>
      </div>
      
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 mb-4">
        <div className="bg-neutral-50 p-3 md:p-4 rounded-lg">
          <p className="text-sm text-neutral-500 mb-1">Melhor dia</p>
          <p className="font-medium flex items-center">
            {summary.bestDay.date} 
            <span className="ml-2">{getMoodEmoji(Math.round(summary.bestDay.score))}</span>
          </p>
        </div>
        <div className="bg-neutral-50 p-3 md:p-4 rounded-lg">
          <p className="text-sm text-neutral-500 mb-1">Dia mais desafiador</p>
          <p className="font-medium flex items-center">
            {summary.worstDay.date}
            <span className="ml-2">{getMoodEmoji(Math.round(summary.worstDay.score))}</span>
          </p>
        </div>
        
        <div className="bg-neutral-50 p-3 md:p-4 rounded-lg">
          <p className="text-sm text-neutral-500 mb-1">Registros</p>
          <p className="font-medium flex items-center">
            {entries?.length || 0} registros
          </p>
        </div>
        <div className="bg-neutral-50 p-3 md:p-4 rounded-lg">
          <p className="text-sm text-neutral-500 mb-1">Tendência</p>
          <p className="font-medium flex items-center">
            {summary.averageMood > 3 ? 'Positiva ↗️' : 'Neutra →'}
          </p>
        </div>
      </div>
      
      <div className="bg-indigo-50 p-4 md:p-5 rounded-lg border-l-4 border-indigo-400 relative">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <Brain className="h-5 w-5 text-indigo-500 mr-2" />
            <h4 className="text-indigo-700 font-medium md:text-lg">Insights de IA</h4>
          </div>
          {recommendation && !loadingRecommendation && !pollingStatus && (
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-indigo-600 hover:text-indigo-800 p-1 rounded-full"
              aria-label={isExpanded ? "Recolher texto" : "Expandir texto"}
            >
              {isExpanded ? (
                <ChevronUp className="h-5 w-5" />
              ) : (
                <ChevronDown className="h-5 w-5" />
              )}
            </button>
          )}
        </div>
        
        {loadingRecommendation || pollingStatus ? (
          <div className="flex items-center gap-2 py-2">
            <Loader2 className="h-4 w-4 text-indigo-500 animate-spin" />
            <p className="text-neutral-600 text-sm">
              {pollingStatus || "Analisando seu histórico de humor..."}
            </p>
          </div>
        ) : (
          <>
            <div className={`overflow-hidden transition-all duration-300 relative ${isExpanded ? "" : "max-h-[5.2rem] md:max-h-[6.5rem]"}`}>
              <p className="text-neutral-700 text-sm md:text-base whitespace-pre-line">
                {recommendation}
              </p>
              
              {!isExpanded && recommendation && recommendation.length > 200 && (
                <div className="h-8 bg-gradient-to-t from-indigo-50 to-transparent absolute bottom-0 left-0 right-0 pointer-events-none" />
              )}
            </div>
            
            {recommendation && recommendation.length > 200 && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="w-full text-center py-2 text-sm text-indigo-600 hover:text-indigo-800 hover:bg-indigo-100 rounded-md transition-colors cursor-pointer flex items-center justify-center mt-1"
              >
                <span className="pointer-events-none">{isExpanded ? "Mostrar menos" : "Ler mais"}</span>
              </button>
            )}
            
            <div className="flex items-center justify-end mt-2 text-xs text-indigo-400">
              <Sparkles className="h-3 w-3 mr-1" />
              <span>Gerado por IA com base no seu histórico</span>
            </div>
          </>
        )}
        
        {requestId && (
          <p className="text-xs text-neutral-400 mt-1">
            ID: {requestId}
          </p>
        )}
      </div>
    </div>
  );
};

export default WeeklySummary;