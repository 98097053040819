import React, { useState, useEffect } from 'react';
import { format, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { addMoodEntryForDate } from '../infra/DatabaseFacade';
import { getMoodEmoji, getMoodText } from '../infra/utils/moodUtils';
import { useNavigate } from 'react-router-dom';
import { PlusCircle, Trash2 } from 'lucide-react';

interface MoodItem {
  id: string;
  moodScore: number;
  note: string;
  time: string;
}

interface DayEntry {
  date: Date;
  formattedDate: string;
  selected: boolean;
  moods: MoodItem[];
}

const BatchMoodEntryPage: React.FC = () => {
  const navigate = useNavigate();
  const [days, setDays] = useState<DayEntry[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savedCount, setSavedCount] = useState(0);
  const [totalToSave, setTotalToSave] = useState(0);

  // Inicializar os últimos 7 dias
  useEffect(() => {
    const lastSevenDays = [];
    for (let i = 0; i < 7; i++) {
      const date = subDays(new Date(), i);
      lastSevenDays.push({
        date,
        formattedDate: format(date, "EEEE, d 'de' MMMM", { locale: ptBR }),
        selected: false,
        moods: [{
          id: crypto.randomUUID(),
          moodScore: -1,
          note: '',
          time: '12:00'
        }]
      });
    }
    setDays(lastSevenDays);
  }, []);

  const handleMoodSelect = (dayIndex: number, moodIndex: number, score: number) => {
    setDays(prev => {
      const newDays = [...prev];
      const newMoods = [...newDays[dayIndex].moods];
      newMoods[moodIndex] = {
        ...newMoods[moodIndex],
        moodScore: score
      };
      newDays[dayIndex] = {
        ...newDays[dayIndex],
        moods: newMoods,
        selected: true
      };
      return newDays;
    });
  };

  const handleNoteChange = (dayIndex: number, moodIndex: number, note: string) => {
    setDays(prev => {
      const newDays = [...prev];
      const newMoods = [...newDays[dayIndex].moods];
      newMoods[moodIndex] = {
        ...newMoods[moodIndex],
        note
      };
      newDays[dayIndex] = {
        ...newDays[dayIndex],
        moods: newMoods
      };
      return newDays;
    });
  };

  const handleTimeChange = (dayIndex: number, moodIndex: number, time: string) => {
    setDays(prev => {
      const newDays = [...prev];
      const newMoods = [...newDays[dayIndex].moods];
      newMoods[moodIndex] = {
        ...newMoods[moodIndex],
        time
      };
      newDays[dayIndex] = {
        ...newDays[dayIndex],
        moods: newMoods
      };
      return newDays;
    });
  };
  
  const handleAddMood = (dayIndex: number) => {
    setDays(prev => {
      const newDays = [...prev];
      newDays[dayIndex] = {
        ...newDays[dayIndex],
        moods: [
          ...newDays[dayIndex].moods,
          {
            id: crypto.randomUUID(),
            moodScore: -1,
            note: '',
            time: '12:00'
          }
        ]
      };
      return newDays;
    });
  };
  
  const handleRemoveMood = (dayIndex: number, moodIndex: number) => {
    setDays(prev => {
      const newDays = [...prev];
      // Não remover se for o único humor do dia
      if (newDays[dayIndex].moods.length <= 1) {
        return newDays;
      }
      
      const newMoods = newDays[dayIndex].moods.filter((_, index) => index !== moodIndex);
      newDays[dayIndex] = {
        ...newDays[dayIndex],
        moods: newMoods
      };
      return newDays;
    });
  };

  const handleToggleSelect = (index: number) => {
    setDays(prev => {
      const newDays = [...prev];
      newDays[index] = {
        ...newDays[index],
        selected: !newDays[index].selected
      };
      return newDays;
    });
  };

  const handleSaveAll = async () => {
    // Encontrar todos os humores válidos em dias selecionados
    const moodsToSave: { dayIndex: number; moodIndex: number; date: Date; mood: MoodItem }[] = [];
    
    days.forEach((day, dayIndex) => {
      if (day.selected) {
        day.moods.forEach((mood, moodIndex) => {
          if (mood.moodScore !== -1) {
            moodsToSave.push({
              dayIndex,
              moodIndex,
              date: day.date,
              mood
            });
          }
        });
      }
    });
    
    if (moodsToSave.length === 0) {
      return;
    }

    setIsSaving(true);
    setTotalToSave(moodsToSave.length);
    setSavedCount(0);

    for (const { date, mood } of moodsToSave) {
      try {
        // Criar uma nova data com a hora selecionada
        const dateWithTime = new Date(date);
        const [hours, minutes] = mood.time.split(':').map(Number);
        dateWithTime.setHours(hours, minutes, 0, 0);

        await addMoodEntryForDate(mood.moodScore, mood.note, dateWithTime);
        setSavedCount(prev => prev + 1);
      } catch (error) {
        console.error('Erro ao salvar entrada:', error);
      }
    }

    // Redirecionar para a página de histórico após salvar
    setTimeout(() => {
      navigate('/historico');
    }, 2000);
  };

  const getSelectedCount = () => {
    let count = 0;
    days.forEach(day => {
      if (day.selected) {
        day.moods.forEach(mood => {
          if (mood.moodScore !== -1) {
            count++;
          }
        });
      }
    });
    return count;
  };

  return (
    <div className="py-6">
      <h1 className="text-2xl font-semibold text-neutral-800 mb-2">Registrar humores passados</h1>
      <p className="text-neutral-600 mb-6">
        Selecione os dias e registre seu humor para vários dias de uma só vez.
      </p>

      {isSaving ? (
        <div className="card text-center py-8">
          <div className="mb-4">
            <div className="w-16 h-16 border-t-4 border-primary-500 border-solid rounded-full animate-spin mx-auto"></div>
          </div>
          <h2 className="text-xl font-medium text-neutral-800 mb-2">
            Salvando registros...
          </h2>
          <p className="text-neutral-600">
            {savedCount} de {totalToSave} registros salvos
          </p>
        </div>
      ) : (
        <>
          <div className="space-y-4 mb-6">
            {days.map((day, index) => (
              <div key={index} className={`card ${day.selected ? 'ring-2 ring-primary-300' : ''}`}>
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id={`day-${index}`}
                      checked={day.selected}
                      onChange={() => handleToggleSelect(index)}
                      className="mr-3 h-5 w-5 accent-primary-500"
                    />
                    <label htmlFor={`day-${index}`} className="font-medium text-neutral-800">
                      {day.formattedDate}
                    </label>
                  </div>
                </div>

                {day.selected && (
                  <>
                    {day.moods.map((mood, moodIndex) => (
                      <div key={mood.id} className="mb-4 p-3 border border-neutral-200 rounded-lg">
                        <div className="flex justify-between items-center mb-3">
                          <h3 className="text-sm font-medium text-neutral-700">
                            Humor {moodIndex + 1}
                          </h3>
                          <div className="flex items-center gap-2">
                            <input
                              type="time"
                              value={mood.time}
                              onChange={(e) => handleTimeChange(index, moodIndex, e.target.value)}
                              className="input input-sm"
                            />
                            {day.moods.length > 1 && (
                              <button
                                type="button"
                                onClick={() => handleRemoveMood(index, moodIndex)}
                                className="text-red-500 hover:text-red-700 p-1"
                                aria-label="Remover humor"
                              >
                                <Trash2 size={16} />
                              </button>
                            )}
                          </div>
                        </div>
                        
                        <div className="mb-4 bg-neutral-50 p-3 rounded-lg transition-all">
                          <div className="text-sm text-neutral-600 mb-2">Como você estava se sentindo?</div>
                          <div className="flex justify-between items-center">
                            {[0, 1, 2, 3, 4, 5].map((score) => (
                              <button
                                type="button"
                                key={score}
                                className={`emoji-btn-small ${mood.moodScore === score ? 'emoji-btn-selected' : ''}`}
                                onClick={() => handleMoodSelect(index, moodIndex, score)}
                                aria-label={`Humor: ${getMoodText(score)}`}
                              >
                                {getMoodEmoji(score)}
                              </button>
                            ))}
                          </div>
                          {mood.moodScore !== -1 && (
                            <p className="text-center text-neutral-600 text-xs mt-1">
                              {getMoodText(mood.moodScore)}
                            </p>
                          )}
                        </div>

                        <textarea
                          value={mood.note}
                          onChange={(e) => handleNoteChange(index, moodIndex, e.target.value)}
                          className="input mb-2 min-h-[60px] resize-none"
                          placeholder="Como você estava se sentindo? (opcional)"
                          maxLength={255}
                          dir="auto"
                        />
                        <p className="text-xs text-neutral-500 text-right">
                          {mood.note.length}/255 caracteres
                        </p>
                      </div>
                    ))}
                    
                    <button
                      type="button"
                      onClick={() => handleAddMood(index)}
                      className="flex items-center justify-center w-full py-2 text-sm text-primary-600 bg-primary-50 hover:bg-primary-100 rounded-lg transition-colors mb-2"
                    >
                      <PlusCircle size={16} className="mr-1" />
                      Adicionar outro humor para este dia
                    </button>
                  </>
                )}
              </div>
            ))}
          </div>

          <button
            onClick={handleSaveAll}
            disabled={getSelectedCount() === 0}
            className="btn btn-primary w-full"
          >
            Salvar {getSelectedCount()} {getSelectedCount() === 1 ? 'registro' : 'registros'}
          </button>
        </>
      )}
    </div>
  );
};

export default BatchMoodEntryPage;
