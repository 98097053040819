/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Brain, Calendar, Loader2, Plus, Home } from 'lucide-react';
import MoodChart from '../components/MoodChart';
import WeeklySummary from '../components/WeeklySummary';
import NotificationMock from '../components/NotificationMock';
import NotificationPermission from '../components/NotificationPermission';
import QuickActions from '../components/QuickActions';
import OnBoardingReminder from '../components/OnBoardingReminder';
import OnBoardingModal from '../components/onboarding/OnBoardingModal';
import FloatingChatButton from '../components/ChatAI/FloatingChatButton';
import { getEntriesForLastDays, hasConcerningPattern } from '../infra/DatabaseFacade';
import { isRequiredOnBoardingComplete } from '../infra/OnBoardingFacade';
import { Link, useLocation } from 'react-router-dom';
import { MoodEntry } from '../types';
import { useOnBoardingStore } from '../stores/onBoardingStore';
import { useAuth } from '../contexts/AuthContext';

// Hook personalizado para buscar registros de humor
const useMoodEntries = () => {
  const [entries, setEntries] = useState<MoodEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const dataLoadedRef = useRef(false);

  // Função para forçar uma atualização quando necessário
  const refreshEntries = () => {
    dataLoadedRef.current = false; // Resetar a flag para permitir nova busca
    setLastUpdate(Date.now());
  };

  useEffect(() => {
    // Evitar múltiplas execuções se já carregamos os dados
    // Exceto quando lastUpdate muda (refreshEntries foi chamado)
    if (dataLoadedRef.current && lastUpdate === 0) return;
    
    const fetchEntries = async () => {
      setLoading(true);
      setError(null);
      
      try {
        // Marcar que já iniciamos o carregamento
        dataLoadedRef.current = true;
        
        // Usar nosso wrapper seguro para fazer a solicitação
        const loadedEntries = await getEntriesForLastDays(7);
        setEntries(loadedEntries || []);
      } catch (err) {
        console.error('Erro ao buscar registros de humor:', err);
        setError('Não foi possível carregar seus registros de humor. Por favor, tente novamente mais tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchEntries();
    // Adicionamos lastUpdate como dependência para permitir atualizações sob demanda
  }, [lastUpdate]);

  return { entries, loading, error, refreshEntries };
};

const HomePage: React.FC = () => {
  const [showPsychologistRecommendation, setShowPsychologistRecommendation] = useState(false);
  const [loading, setLoading] = useState(true);
  const concerningPatternCheckedRef = useRef(false);
  const location = useLocation();
  const { user } = useAuth();
  
  // Usando a store do Zustand para gerenciar o estado do OnBoarding
  const { 
    isModalOpen: showOnBoardingModal, 
    isRequired: isOnBoardingRequired,
    startWithPendingSteps,
    setModalOpen, 
    setRequired, 
    setStartWithPendingSteps,
    resetState
  } = useOnBoardingStore();
  
  const { entries, loading: loadingEntries, error, refreshEntries } = useMoodEntries();
  
  // Verificar se há informações de state do React Router para atualização
  useEffect(() => {
    // Verificar state do React Router
    const state = location.state as { refreshData?: boolean; source?: string } | null;
    const shouldRefresh = !!state?.refreshData;
    const source = state?.source;
    
    if (shouldRefresh) {
      console.log(`Atualizando dados do gráfico de humor. Fonte: ${source || 'não especificada'}`);
      refreshEntries();
      
      // Limpar o state para evitar atualizações desnecessárias em futuros recarregamentos
      window.history.replaceState(null, '', location.pathname);
    }
    
    // Manter compatibilidade com parâmetros de query (legado)
    const queryParams = new URLSearchParams(location.search);
    const shouldRefreshFromQuery = queryParams.get('refresh') === 'true';
    const sourceFromQuery = queryParams.get('source');
    
    if (shouldRefreshFromQuery) {
      console.log(`Atualizando dados do gráfico de humor a partir de parâmetros de query. Fonte: ${sourceFromQuery || 'não especificada'}`);
      refreshEntries();
      
      // Limpar os parâmetros de consulta da URL
      if (window.history.replaceState) {
        const newUrl = window.location.pathname;
        window.history.replaceState({path: newUrl}, '', newUrl);
      }
    }
  }, [location, refreshEntries]);
  
  useEffect(() => {
    // Evitar múltiplas execuções se já verificamos os padrões preocupantes
    if (concerningPatternCheckedRef.current) return;
    
    const init = async () => {
      try {
        // Marcar que já iniciamos a verificação
        concerningPatternCheckedRef.current = true;
        
        // Check for concerning patterns
        const hasConcerning = await hasConcerningPattern();
        setShowPsychologistRecommendation(hasConcerning);
        
        // Verificar se o OnBoarding obrigatório foi concluído
        const requiredOnBoardingComplete = await isRequiredOnBoardingComplete();
        setRequired(!requiredOnBoardingComplete);
        
        // Se o OnBoarding obrigatório não foi concluído, mostrar o modal automaticamente
        if (!requiredOnBoardingComplete) {
          setStartWithPendingSteps(false); // Começar do início para OnBoarding obrigatório
          setModalOpen(true);
        }
      } catch (error) {
        console.error('Erro ao inicializar a página:', error);
      } finally {
        setLoading(false);
      }
    };
    
    init();
  }, [setModalOpen, setRequired, setStartWithPendingSteps]);

  const handleOpenOnBoarding = () => {
    setStartWithPendingSteps(true); // Começar pelos passos pendentes quando clica em "Completar agora"
    setModalOpen(true);
  };
  
  const handleCloseOnBoarding = () => {
    setModalOpen(false);
    setStartWithPendingSteps(false); // Resetar o estado
    
    // Atualizar o gráfico ao fechar o OnBoarding (em caso de alterações)
    refreshEntries();
  };

  return (
    <>
      {/* Container principal com largura máxima para desktop */}
      <div className="container-responsive py-6">
        {/* Cabeçalho com adaptação para telas maiores */}
        <div className="flex justify-between items-center mb-6 md:mb-8">
          <h1 className="section-title mb-0">Página Inicial</h1>
          <div className="bg-primary-100 p-2 md:p-3 rounded-full">
            <Home className="h-5 w-5 md:h-6 md:w-6 text-primary-500" />
          </div>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="md:grid md:grid-cols-12 md:gap-6 lg:gap-8 w-full"
        >
          {/* Coluna principal para conteúdo em telas maiores */}
          <div className="md:col-span-7 lg:col-span-8 w-full">
            <NotificationPermission />
            
            {/* OnBoarding Reminder - só aparece se não for obrigatório */}
            {!isOnBoardingRequired && (
              <OnBoardingReminder onComplete={handleOpenOnBoarding} />
            )}
            
            {loadingEntries ? (
              <div className="card mb-6 w-full">
                <div className="flex flex-col items-center justify-center py-4">
                  <Loader2 className="h-8 w-8 text-primary-500 animate-spin mb-2" />
                  <p className="text-neutral-600">Carregando dados...</p>
                </div>
              </div>
            ) : entries && entries.length > 0 ? (
              <>
                <div className="card mb-6 w-full">
                  <h2 className="text-lg md:text-xl font-medium text-neutral-700 mb-4">Seu humor na semana</h2>
                  {/* Passa as entradas e loading state como props */}
                  <div className="w-full md:px-4">
                    <MoodChart entries={entries} loading={loadingEntries} />
                  </div>
                </div>
                
                {/* Passa as entradas e loading state como props */}
                <WeeklySummary entries={entries} loading={loadingEntries} />
              </>
            ) : (
              <div className="card mb-6 w-full">
                <h2 className="text-lg md:text-xl font-medium text-neutral-700 mb-2">Sem registros de humor</h2>
                <p className="text-neutral-600 mb-4">
                  Você ainda não registrou seu humor esta semana. 
                  Comece a registrar para ver seu progresso aqui.
                </p>
                <Link to="/registrar" className="btn-primary md:inline-block">
                  Registrar agora
                </Link>
              </div>
            )}
            
            {showPsychologistRecommendation && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.3 }}
                className="card mb-6 border-l-4 border-secondary-500 w-full"
              >
                <h3 className="text-lg md:text-xl font-medium text-neutral-700 mb-2">
                  Notamos um padrão em seus registros
                </h3>
                <p className="text-neutral-600 mb-4">
                  Você tem registrado humor baixo por vários dias consecutivos. Conversar com um profissional pode ajudar.
                </p>
                <Link to="/psicologos" className="btn btn-secondary w-full md:w-auto">
                  Ver psicólogos recomendados
                </Link>
              </motion.div>
            )}
          </div>
          
          {/* Coluna lateral para ações em telas maiores e mobile */}
          <div className="md:col-span-5 lg:col-span-4 w-full">
            <QuickActions />
          </div>
        </motion.div>
      </div>
      
      {/* Modal de OnBoarding */}
      {showOnBoardingModal && (
        <OnBoardingModal 
          isOpen={showOnBoardingModal} 
          onClose={handleCloseOnBoarding}
          isRequired={isOnBoardingRequired}
          startWithPendingSteps={startWithPendingSteps}
        />
      )}
      
      <NotificationMock />
      
      {/* FloatingChatButton */}
      {user && <FloatingChatButton />}
    </>
  );
};

export default HomePage;