import React, { useMemo } from 'react';
import GoogleAdsense from './GoogleAdsense';
import { adsenseConfig } from '../config/adsenseConfig';
import { useAuth } from '../contexts/AuthContext';

interface ContentAdsenseProps {
  position: 'top' | 'middle' | 'bottom';
  className?: string;
}

/**
 * A specialized AdSense component for content pages
 * Displays ads at different positions within content
 */
// Contador para gerar IDs únicos para cada anúncio
let adCounter = 0;

const ContentAdsense: React.FC<ContentAdsenseProps> = ({ position, className = '' }) => {
  // Obter o plano atual do usuário do contexto de autenticação
  const { currentPlan } = useAuth();
  
  // Gerar um ID único para este anúncio
  const adId = useMemo(() => `ad-${position}-${adCounter++}`, [position]);
  
  // Select the appropriate ad slot based on position
  const getSlot = () => {
    switch (position) {
      case 'top':
        return adsenseConfig.slots.header;
      case 'middle':
        return adsenseConfig.slots.inContent;
      case 'bottom':
        return adsenseConfig.slots.footer;
      default:
        return adsenseConfig.slots.inContent;
    }
  };

  // Não renderizar anúncios para usuários com planos diferentes de "basic"
  if (currentPlan !== 'basic') {
    return null;
  }

  // Aplicar classes específicas com base na posição
  const positionClass = `${position}-ad`;

  return (
    <div className={`content-ad-container ${positionClass} ${className}`} id={adId}>
      <div className="py-2 xl:py-3 w-full overflow-hidden">
        <GoogleAdsense
          client={adsenseConfig.client}
          slot={getSlot()}
          style={{ 
            display: 'block', 
            textAlign: 'center', 
            margin: '0 auto',
            maxWidth: '100%',
            overflow: 'hidden'
          }}
          responsive={true}
          format="auto"
        />
      </div>
    </div>
  );
};

export default ContentAdsense;
